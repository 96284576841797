body.checkout-step-2 {

    #search,
    #breadcrumbs,
    #usp-topbalk,
    #navigation,
    #cart-normal,
    #mobile-search-wrapper,
    #fluid-search-spacer {
        display: none;
    }

    #header #cart #cart-in-checkout {
        display: block;
    }

    #checkout {

        .help-inline {
            display: inline-block;
            margin: 0 0 6px 14px;
        }

        #checkout-shipping-method {
            padding-right: 10px;
        }

        #checkout-payment-method {
            padding-left: 10px;

            .fr {
                float: none;
            }
        }

        #payment-method-creditcard {
            background-image: url('../../../../img/creditcards-nl.png');
            background-position: top right;
            background-repeat: no-repeat;
            background-origin: content-box;
            padding: 10px 10px 0 0;

            @media screen and (max-width: (@breakpoint-xsmall - 1)) {
                background-size: 20%;
            }

            &.fr {
                background-image: url('../../../../img/creditcards-fr.png');
            }
        }

        .checkout-shipping-payment-options {
            width: 50%;
            float: left;
            box-sizing: border-box;

            .checkout-bar {
                margin-bottom: 0;
                -webkit-border-bottom-right-radius: 0;
                -webkit-border-bottom-left-radius: 0;
                -moz-border-radius-bottomright: 0;
                -moz-border-radius-bottomleft: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            .shipping-method-wrap {
                border: 1px solid #CACACA;
                border-top: none;
                margin-bottom: 0;
                padding: 11px 20px 5px 20px;
                overflow: hidden;

                &.active {
                    background: #ecf5f8;
                    border: 1px solid #00A2E0;

                    .shipping-method-description {
                        display: block;
                    }
                }

                &:hover {
                    background: #ecf5f8;
                }

                .radio input[type="radio"] {
                    margin-top: 17px;
                }

                span.error {
                    margin-left: 0;
                }

                .shipping-method-delivery-note {
                    width: 100%;
                }

                .shipping-method-icon {
                    background-image: url('../../../../img/sprite.png');
                    background-size: 400px;
                    width: 64px;
                    height: 40px;
                    float: left;
                    margin: 4px 20px 10px 0;

                    &#shipping-icon-pickup {
                        background-position: 169px -89px;
                    }

                    &#shipping-icon-postnl {
                        background-position: -560px 200px;
                    }

                    &#shipping-icon-colisprive {
                        background-position: -480px 150px;
                    }

                    &#shipping-icon-truckFreight {
                        background-position: 240px -183px;
                    }

                    &#shipping-icon-budbee {
                        background-position: 102px 245px;
                    }

                    &#shipping-icon-existing_order {
                        background-position: 185px 200px;
                    }
                }

                .shipping-method-name-price {
                    float: left;
                    margin-bottom: 10px;

                    p {
                        margin: 0;
                    }

                    .shipping-method-price {
                        display: inline-block;
                        font-weight: normal;
                        font-size: 14px;
                        color: #898989;

                        &[data-free-shipping="GRATIS!"] {
                            color: #00A2E0;
                        }
                    }

                    .shipping-method-name {
                        display: block;
                        font-weight: bold;
                        font-size: 14px;

                    }
                }

                .shipping-method-description {
                    clear: both;
                    line-height: 24px;
                    display: none;
                }
            }

            .checkout-radio-option-block {
                border: 1px solid #CACACA;
                border-top: none;
                margin-bottom: 0;

                &:hover {
                    background: #ecf5f8;
                }

                &.active {
                    background: #ecf5f8;
                    border: 1px solid #00A2E0;

                    .payment-method-description {
                        display: block;
                    }
                }

                .payment-method-description {
                    clear: both;
                    line-height: 24px;
                    display: none;
                    font-size: 12px;
                    padding: 0 10px 0 0;

                    &--ideal {
                        label {
                            padding: 4px 0 13px 21px;
                        }

                        select {
                            display: block;
                            margin: 5px 0 0;
                            vertical-align: initial;
                        }
                    }
                }

                &:last-child {
                    -webkit-border-bottom-right-radius: 3px;
                    -webkit-border-bottom-left-radius: 3px;
                    -moz-border-radius-bottomright: 3px;
                    -moz-border-radius-bottomleft: 3px;
                    border-bottom-right-radius: 3px;
                    border-bottom-left-radius: 3px;
                }

                .payment-method-icon {
                    background-image: url('../../../../img/sprite.png');
                    background-size: 400px;
                    width: 65px;
                    height: 40px;
                    display: inline-block;

                    &#payment-method-icon-ideal {
                        background-position: 0 0;
                    }

                    &#payment-method-icon-paypal {
                        background-position: -239px 0;
                    }

                    &#payment-method-icon-mastercard {
                        background-position: -480px 0;
                    }

                    &#payment-method-icon-bancontact {
                        background-position: -160px 0;
                    }

                    &#payment-method-icon-visa {
                        background-position: -320px 0;
                    }

                    &#payment-method-icon-moneyorder {
                        background-position: -160px -46px;
                    }

                    &#payment-method-icon-account {
                        background-position: 0 -123px;
                    }

                    &#payment-method-icon-billink,  &#payment-method-icon-billinkwrisk, &#payment-method-icon-billinkb2c {
                        background-position: 0 -165px;
                    }

                    &#payment-method-icon-billie {
                        background-position: 0 -123px;
                    }

                    &#payment-method-icon-directbank {
                        background-position: 0 -248px;
                    }

                    &#payment-method-icon-cartebleue {
                        background-position: 0 -205px;
                    }

                    &#payment-method-icon-inghome {
                        background-position: -80px -138px;
                    }

                    &#payment-method-icon-giropay {
                        background-position: -80px -183px;
                    }

                    &#payment-method-icon-sofort {
                        background-position: -1px -247px;
                    }

                    &#payment-method-icon-kbc {
                        background-position: -80px -92px;
                    }

                    &#payment-method-icon-americanexpress {
                        background-position: -159px -92px;
                    }

                    &#payment-method-icon-in3 {
                        background-position: -229px -138px;
                    }

                    &#payment-method-icon-creditcard {
                        background-position: -298px -248px;
                    }

                    &#payment-method-icon-klarnapaylater {
                        background-position: -1px -291px;
                    }

                    &#payment-method-icon-klarnasliceit {
                        background-position: -158px -291px;
                    }
                }

                label {
                    margin: 0;
                    padding: 11px 0 5px 20px;

                    input {
                        margin: 13px 14px 0 -6px;
                    }

                    .payment-method-name-price {
                        display: inline-block;
                        vertical-align: top;
                        margin: 0 5px 10px 20px;

                        .payment-method-name {
                            display: block;
                            font-weight: bold;
                            font-size: 14px;
                        }

                        .price {
                            display: block;
                            color: #898989;
                        }
                    }
                }
            }

            @media screen and (max-width: (@breakpoint-xlarge - 1)) {
                width: 100%;
                padding: 0 !important;
            }
        }

        #checkout-shipping-method {
            margin-bottom: 20px;
        }

        #checkout-payment-method {
            margin-bottom: 20px;
        }
    }
}


.checkout-step-2 {
    .menu-bar.scroll {
        position: relative !important;
        margin-top: 0 !important;
    }
}


.checkout-step-2 {
    #checkout-steps {
        margin-top: 8px;
    }
}



body.checkout-step-2 #checkout .checkout-shipping-payment-options .shipping-method-wrap.active .selected-store {
    display: block;
}

body.checkout-step-2 #checkout .checkout-shipping-payment-options .shipping-method-wrap.active .selected-store.hide {
    display: none;
}

body.checkout-step-2 #checkout .checkout-shipping-payment-options .selected-store {
    display: none;
}


@media screen and (max-width: (@breakpoint-small - 1)) {
    body.checkout-step-2 #checkout .checkout-shipping-payment-options .shipping-method-wrap.active .selected-store {
        width: 91% !important;
    }
}

body.checkout-step-2 #checkout .checkout-shipping-payment-options .shipping-method-wrap .dpd-pickup {
    display: none;
}

body.checkout-step-2 #checkout .checkout-shipping-payment-options .shipping-method-wrap.active .dpd-pickup {
    display: block;
}
