.menu-bar {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1000;

    .la-switcher {
        float: right;
        border-left: 1px solid #bbbbbb;
        padding-left: 5px;
        margin-left: 10px;

        .active {
            display: none;
        }

        a {
            padding: 0 5px;
            font-size: 13px;
            float: left;
        }

        .flag {
            float: left;
        }

        img {
            height: 10px;
            float: left;
            margin: 3px 3px 0 0;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
        }
    }

    .grid {
        position: relative;
    }

    &.scroll {
        position: fixed;
        margin-top: -122px;

        .mid-bar {
            #cart {
                position: absolute;
                bottom: -65px;
                right: 0;
                z-index: 1000;
                height: 32px;

                .vat,
                .vat-span {
                    display: none;
                }

                a {
                    padding: 2px 15px;
                }

                span.count {
                    width: 15px;
                    height: 15px;
                    right: 25px;
                    top: -10px;
                    line-height: 15px;
                    font-size: 11px;
                    background: #00A2E0;
                }

                span.text {
                    display: none;
                }

                .polycon-user,
                .polycon-cart {
                    font-size: 19px;
                }

            }
        }

        .bottom-bar ul#desktop-nav li.last {
            display: none;
        }
    }

    .top-bar {
        background: @c-white;
        width: 100%;
        float: left;
        padding: 3px 0 5px 0;

        .contact-info {
            float: right;
            padding: 5px 5px 0 0;
            font-size: 14px;

            .icon-whatsapp {
                float: left;
                margin-top: 0;
                margin-right: 3px;
                overflow: hidden;
                padding: 0;
                color: @c-white;
                cursor: default;
                height: 19px;
                pointer-events: none;
                width: 19px;
                background-image: url('../../img/whatsapp.svg');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: unset;
            }

            .rule {
                float: left;
                margin-left: 10px;
                color: #4c4c4c;
                cursor: pointer;

                &.contact-link {
                    border-left: 1px solid darken(#eee, 20);
                    padding-left: 10px;
                }

                .advice-icon {
                    color: @color-blue-primary;
                }
            }
        }
    }

    .mid-bar {
        background: @color-blue-primary;
        background: #00A2E0;
        width: 100%;
        float: left;
        padding: 15px 0 20px 0;
        position: relative;

        #cart {
            float: right;
            margin-top: 20px;

            ul {
                float: right;
                list-style: none;

                li {
                    float: left;
                    position: relative;

                    &.vat-span {
                        font-weight: bold;
                        color: @c-white;
                        font-size: 12px;
                    }

                    &.vat {
                        margin-top: -5px;
                        border: 1px solid @c-white;
                        margin-left: 5px;
                        background: @c-white;
                        border-radius: 5px;
                        overflow: hidden;
                        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

                        span {
                            text-align: center;
                            width: 50%;
                            float: left;
                            box-sizing: border-box;
                            padding: 3px 5px;
                            font-weight: 400;
                            font-size: 12px;
                            color: #00A2E0;
                            cursor: pointer;

                            &:hover {
                                opacity: 0.5;
                            }

                            &.active {
                                color: @c-white;
                                font-weight: bold;
                                background: #00A2E0;

                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    span.count {
                        position: absolute;
                        top: -18px;
                        line-height: 25px;
                        text-align: center;
                        font-size: 14px;
                        right: 20px;
                        width: 25px;
                        height: 25px;
                        border-radius: 25px;
                        color: @c-white;
                        background: #1885c8;
                        z-index: 100;
                    }

                    a {
                        padding: 2px 10px;
                        color: #fff;

                        &:hover {
                            color: @c-white;
                        }
                    }

                    &.cart {

                        &.no-items {
                            span.count {
                                right: 0;
                            }

                            .open-cart_btn {
                                display: none;
                            }
                        }

                        a {
                            border-left: 1px solid white;
                        }

                        &.open {
                            .cart-items {
                                display: block;
                            }

                            .open-cart {
                                .open-cart_btn {
                                    background: @c-white;
                                }

                                .caret {
                                    border-top: 6px solid #00A2E0;
                                    border-right: 6px solid transparent;
                                    border-left: 6px solid transparent;
                                }
                            }
                        }

                        .cart-items {
                            position: absolute;
                            top: 25px;
                            display: none;
                            right: 0;
                            width: 350px;
                            background: @c-white;
                            z-index: 1000;
                            padding: 10px 20px 20px 20px;
                            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
                            text-align: left;

                            .shipping,
                            .btw,
                            .discount,
                            .subtotal,
                            .total {
                                margin-top: 5px;
                                width: 100%;
                                float: left;
                                font-size: 14px;

                                .name {
                                    float: left;
                                }

                                .val {
                                    float: right;

                                    &.strong {
                                        font-weight: bold;
                                    }
                                }
                            }

                            .total {
                                padding: 3px 0;
                            }

                            .edit-cart,
                            .go-cart {
                                width: 50%;
                                box-sizing: border-box;
                                line-height: 36px;
                                margin-top: 10px;
                            }

                            .edit-cart {
                                color: #00A2E0;
                                float: left;
                                text-decoration: underline;
                                text-align: center;
                            }

                            .go-cart {
                                float: right;
                                background: #5fc719;
                                color: @c-white;
                                border-radius: 6px;
                                text-align: center;

                                &:hover {
                                    background: darken(#5fc719, 10);
                                }
                            }

                            .cart-products {
                                width: 100%;
                                float: left;

                                .product-item {
                                    width: 100%;
                                    float: left;
                                    padding: 10px 0 10px 0;
                                    color: rgba(0, 0, 0, 0.7);
                                    border-bottom: 1px solid #eee;

                                    &:hover {
                                        opacity: 0.8;
                                    }

                                    .figure {
                                        width: 45px;
                                        float: left;
                                    }

                                    .text {
                                        width: 290px;
                                        float: left;
                                        margin-left: 15px;

                                        .name {
                                            font-weight: 400;
                                            margin-bottom: 3px;
                                            color: #00A2E0;
                                        }

                                        .count,
                                        .price {
                                            font-size: 14px;
                                            float: left;
                                            width: 50%;
                                        }

                                        .price {
                                            text-align: right;
                                        }
                                    }
                                }
                            }
                        }

                        .open-cart {
                            float: right;

                            .open-cart_btn {
                                float: left;
                                width: 20px;
                                height: 20px;
                                border-radius: 4px;
                                line-height: 20px;
                                text-align: center;
                                cursor: pointer;
                                border: 1px solid @c-white;

                                &:hover {
                                    background: @c-white;

                                    .caret {
                                        border-top: 6px solid #00A2E0;
                                        border-right: 6px solid transparent;
                                        border-left: 6px solid transparent;
                                    }
                                }
                            }
                        }

                        .caret {
                            display: inline-block;
                            width: 0;
                            height: 0;
                            margin-top: 7px;
                            position: relative;
                            vertical-align: top;
                            border-top: 6px solid @c-white;
                            border-right: 6px solid transparent;
                            border-left: 6px solid transparent;
                            content: "";
                        }
                    }

                }
            }
        }

        #logo {
            margin: 0;
            float: left;
            width: 225px;

            .logo-trademark {
                float: left;
                width: 46px;
                height: 56px;
            }

            .logo-signature-a,
            .logo-signature-b {
                float: left;
                .neuron;
                font-weight: normal;
                font-style: normal;
                font-size: 21px;
                color: @c-white;
                line-height: 15px;
                margin-left: 5px;
                margin-top: 13px;
            }

            .logo-signature-b {
                font-size: 14px;
                display: none;
            }

            .logo-slogem {
                float: left;
                font-size: 14px;
                font-style: italic;
                color: white;
                margin: 5px;
            }

            .logo-blok {
                width: 15px;
                height: 15px;
                background: @c-white;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            .logo-middenblauw {
                background: @c-white;
            }

            .logo-lichtblauw {
                background: @c-white;
            }

            .logo-b1 {
                margin: 3px 0 0 3px;
            }

            .logo-b2,
            .logo-b4 {
                margin: -15px 0 0 24px;
            }

            .logo-b3,
            .logo-b5 {
                margin: 6px 0 0 3px;
            }

            .logo-b2 {
                background: rgba(255, 255, 255, 0.95);
            }

            .logo-b3 {
                background: rgba(255, 255, 255, 0.9);
            }

            .logo-b4 {
                background: rgba(255, 255, 255, 0.7);
            }

            .logo-b5 {
                background: rgba(255, 255, 255, 0.65);
            }

            h1 {
                text-indent: -999999px;
                position: absolute;
                font-size: 0;
            }

            @media screen and (max-width: (@breakpoint-medium - 1)) {
                .logo-signature-a {
                    margin-top: 7px;
                }
            }

            @media screen and (max-width: (@breakpoint-small - 1)) {
                width: 175px;

                .logo-signature-a {
                    margin-left: 0;
                    font-size: 16px;
                }

                .logo-slogem {
                    margin: 0;
                    font-size: 12px;
                }
            }
        }

        .main-search {
            width: 480px;
            top: 50%;
            margin-top: -25px;
            position: absolute;
            left: 50%;
            margin-left: -300px;

            input {
                box-sizing: border-box;
                box-shadow: none;
                margin: 2px;
                padding: 0 20px;
                border-radius: 0;
                background: @c-white;
                border: none;
                width: 98%;
                height: 45px;
                line-height: 45px;
                float: left;

                &:focus {
                    box-shadow: none;
                }
            }

            button {
                margin-top: 5px;
            }
        }
    }

    .mobile-bar {
        width: 100%;
        float: left;
        background: #1688ce;
        display: none;
        padding: 10px 0;

        .grid {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .mobile-cart {
            float: right;
            position: relative;
            font-size: 21px;
            margin-right: 5px;
            color: rgba(255, 255, 255, 0.9);
            opacity: 0;
            padding: 0;
            width: 0;
            transition: all .3s ease-in-out;

            &:hover {
                color: @c-white;
            }

            span.count {
                position: absolute;
                top: -5px;
                line-height: 15px;
                text-align: center;
                font-size: 11px;
                right: -0px;
                width: 15px;
                height: 15px;
                border-radius: 25px;
                color: @c-white;
                background: #00A2E0;
                z-index: 100;
            }
        }

        .main-search {
            float: left;
            width: 100%;
            position: relative;

            form {
                margin: 0;
            }

            input {
                border: 0;
                padding: 0 28px 0 10px;
                background: @c-white;
                float: left;
                box-sizing: border-box;
                width: 100%;
                height: 30px;
                line-height: 30px;
                margin: 0;
                font-size: 12px;
            }
        }

        .open-menu {
            float: left;
            color: @c-white;
            margin: 5px 5px 0 0;
            cursor: pointer;
            display: flex;

            &:hover {
                opacity: 0.8;
            }

            .menu-icon {
                font-size: 32px;
                float: left;
                margin-right: 5px;
                margin-top: -7px;
            }
        }
    }

    .bottom-bar {
        width: 100%;
        float: left;
        background: #1688ce;
        border-bottom: 5px solid #106da6;

        .mobile-items,
        .close-menu {
            display: none;
        }

        .grid {
            position: relative;
        }

        ul {
            float: left;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                float: left;
                text-shadow: 1px solid rgba(0, 0, 0, 0.1);
                border-right: 1px solid rgba(255, 255, 255, 0.15);

                &.home {
                    a {
                        padding: 0 15px 0 0;
                        height: 50px;
                    }

                    .menu-icon {
                        float: left;
                        margin-top: 9px;
                        font-size: 28px;
                    }
                }

                &:hover {
                    opacity: 0.5;
                }

                &.active {
                    a {
                        background: #106da6;
                    }
                }

                &.blackfriday {
                  background: #000000;
                }

                a {
                    padding: 15px 20px;
                    font-size: 18px;
                    color: @c-white;
                    text-transform: none;
                    display: block;
                    text-decoration: none !important;
                }
            }
        }
    }

    @media screen and (max-width: (@breakpoint-grid-large - 1)) {
        .mid-bar .main-search {
            width: 400px;
            margin-left: -200px;
        }

        .bottom-bar ul li a {
            padding: 15px 10px;
            font-size: 16px;
        }
    }

    @media screen and (min-width: @breakpoint-large) and (max-width: (@breakpoint-xlarge - 1)) {
        .mid-bar #cart ul li a {
            .user-name {
                display: none;
            }
        }
    }

    @media screen and (max-width: (@breakpoint-large - 1)) {
        float: left;

        &.scroll {
            margin-top: -112px;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

            #cart {
                display: none;
            }

            .mobile-bar {
                .mobile-cart {
                    width: auto;
                    opacity: 1;
                    padding: 2px 13px;
                    margin-right: 5px;
                }
            }
        }

        .mid-bar .main-search {
            display: none;
        }

        .mid-bar {
            padding: 10px 0 15px 0;
        }

        .mobile-bar {
            display: block;
        }

        .bottom-bar {
            position: fixed;
            right: -350px;
            opacity: 0.5;
            top: 0;
            width: 75%;
            height: 100%;
            max-width: 350px;
            padding: 25px;
            z-index: 9999;
            box-sizing: border-box;
            box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.2);
            transition: all 0.3s ease;
            overflow-y: scroll;
            /* has to be scroll, not auto */
            -webkit-overflow-scrolling: touch;

            .mobile-items {
                width: 100%;
                float: left;
                height: 100%;

                .la-switcher {
                    width: 100%;
                    padding: 10px 0 0 0;
                    border-left: 0;
                    margin-top: 5px;
                    border-top: 1px solid rgba(255, 255, 255, 0.1);

                    a {
                        width: 100%;
                        font-size: 16px;
                        color: white;

                        img {
                            margin: 4px 6px 0 0;
                            height: 12px;
                        }

                        &:hover {
                            color: @c-white;
                        }
                    }
                }
            }

            .fluidhide {
                display: none;
            }

            &.open {
                opacity: 1;
                right: 0;
            }

            .grid {
                width: 100%;
                float: left;
            }

            .mobile-items {
                float: left;
                width: 100%;
                display: block;

                .top-menu {
                    li {
                        a {
                            padding: 5px 0 8px 0;
                            text-transform: none;
                        }
                    }
                }

                .bottom-menu {
                    margin-top: 15px;
                    border-top: 1px solid rgba(255, 255, 255, 0.1);

                    a {
                        color: white;

                        &:hover {
                            color: white;
                        }
                    }
                }

                a {
                    font-size: 16px;
                    padding: 10px 15px;
                }
            }

            .close-menu {
                color: @c-white;
                position: absolute;
                top: -20px;
                right: -10px;
                cursor: pointer;
                font-size: 40px;
                display: block;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);

                &:hover {
                    opacity: 0.5;
                }
            }

            .mobile-items a {
                padding: 10px 0;
            }

            ul {
                width: 100%;
                padding: 0;

                li {
                    width: 100%;
                    float: left;
                    padding: 0;
                    border-right: 0;

                    a {
                        padding: 15px 0;
                    }

                    &.home {
                        display: none;
                    }
                }
            }
        }
    }
}

.checkout-page {
    .menu-bar {
        float: left;
        box-shadow: 0 4px 3px rgba(0, 0, 0, 0.1);

        &.scroll {
            position: relative;
            margin-top: 0;
        }
    }

    #usp-topbalk.on-top.scroll {
        margin: 0;
    }
}


@media screen and (max-width: (@breakpoint-medium - 1)) {
    .menu-bar {

        &.scroll {
            margin-top: -62px;
        }

        .top-bar {
            display: none;
        }

        .mid-bar {
            padding: 7px 0;

            .vat-span,
            .vat {
                display: none;
            }

            #logo {
                .logo-trademark {
                    width: 40px;
                    height: 46px;
                    overflow: hidden;
                    padding-top: 3px;
                }

                .logo-blok {
                    width: 10px;
                    height: 10px;
                }

                .logo-b1 {
                    margin-top: 0;
                }

                .logo-b2,
                .logo-b4 {
                    margin: -10px 0 0 17px;
                }

                .logo-signature-b {
                    margin-top: 9px;
                }
            }
        }
    }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    .menu-bar {
        .mid-bar {
            #cart {
                ul {
                    li {
                        span.text {
                            display: none;
                        }

                        .polycon-cart {
                            margin-right: 10px;
                        }

                        &.cart {
                            .cart-items {
                                padding: 0 10px 10px 10px;
                                width: 290px;

                                .cart-products {
                                    .product-item {
                                        .figure {
                                            display: none;
                                        }

                                        .text {
                                            margin-left: 0;
                                        }
                                    }
                                }

                                .edit-cart {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: (@breakpoint-xxsmall - 1)) {
    .menu-bar .mid-bar #logo {
        width: 165px;
    }

    .menu-bar .mid-bar #logo .logo-trademark {
        width: 35px;
    }

    .menu-bar .mid-bar #cart ul {
        margin-left: 0;
    }
}

#mobile-side-menu {
    z-index: 9999;
    position: fixed;
    -webkit-transition: left .4s;
    -moz-transition: left .4s;
    -o-transition: left .4s;
    transition: left .4s;
    width: 268px;
    left: -270px;
    top: -1px;
    height: 100%;
    background: @color-background-page;
    .neuron;
    font-size: 21px;
    color: @color-text-primary;
    .box;
    -webkit-box-shadow: 0 7px 8px -3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 7px 8px -3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 7px 8px -3px rgba(0, 0, 0, 0.2);

    ul {
        list-style: none;
        margin: 0;

        li {
            a {
                display: block;
                padding: 12px 19px 12px 55px;
                color: @color-text-primary;
                border-bottom: 1px solid @color-border-dark;
                position: relative;

                &:hover,
                &:active {
                    background: @c-blue2;
                    color: @color-background-page;
                }

                &.last {
                    border: none;
                }

                span {
                    margin-right: 8px;
                    font-size: 18px;
                    position: absolute;
                    left: 17px;
                    top: 12px;

                    &.polycon-home {
                        font-size: 22px;
                        top: 10px;
                    }
                }
            }
        }
    }
}

.menu-mobile_overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.8);
}
