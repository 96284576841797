#usp-topbalk {
    width: 100%;

    .grid {
        padding: 0;
        box-sizing: border-box;

        @media screen and (max-width: (@breakpoint-medium - 1)) {
            width: 100%;
        }

        @media screen and (min-width: @breakpoint-medium) {
            padding: 0 4px;
        }
    }

    .inner {
        width: 100%;
        padding: 8px 0;
        box-sizing: border-box;
        font-size: 14px;
        border-top: 0;
        border-radius: 0 0 4px 4px;

        @media screen and (min-width: @breakpoint-medium) {
            padding: 8px 10px;
            display: flex;
            align-content: center;
            justify-content: space-between;
        }

        .usp {
            float: left;
            font-size: 13px;
            line-height: 26px;

            &:not(:nth-last-of-type(1)) {
                margin-right: 15px;
            }

            .icon-whatsapp {
                float: left;
                margin-right: 3px;
                overflow: hidden;
                padding: 0;
                color: @c-white;
                cursor: default;
                height: 19px;
                pointer-events: none;
                width: 19px;
                background-image: url('../../../img/whatsapp.svg');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: unset;
            }

            &.mobile {
                display: none;
            }

            .menu-icon {
                font-size: 19px;
                float: left;
                margin: 3px 3px 0 0;
                color: #5FC719;
            }

            span.small {
                font-size: 11px;
                opacity: 0.5;
            }

            @media screen and (max-width: (@breakpoint-xlarge - 1)) {
                &.no-tablet {
                    display: none;
                }
            }

            @media screen and (max-width: (@breakpoint-large - 1)) {
                &.no-tablet-small {
                    display: none;
                }
            }

            @media screen and (max-width: (@breakpoint-medium - 1)) {
                width: 50%;
                margin-left: 0;
                margin-right: 0;

                &.no-mobile {
                    display: none;
                }

                &.mobile {
                    display: block;
                }
            }

            @media screen and (max-width: (@breakpoint-small - 1)) {
                width: 100%;
            }
        }

        #webshop-seal {
            float: right;
            margin-top: 4.5px;

            @media screen and (max-width: (@breakpoint-medium - 1)) {
                display: none;
            }

            img {
                margin-right: 50px;
            }

            &.webshop-seal-fr {
                margin-top: 1px;
            }

            >a {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &.bottom-cart {
        clear: both;
        border: 0;
        padding: 0;

        .inner {
            border: 0;
            padding: 0;
            margin: 0;
        }

        #webshop-seal {
            margin-top: -5px;
            width: 283px;
        }

        @media screen and (max-width: (@breakpoint-medium - 1)) {
            display: none;
        }
    }
}

.usp_bar_side {
    width: 100%;
    float: left;
    padding: 10px;
    background: @c-white;
    border: 1px solid #cacaca;
    box-sizing: border-box;
    margin-top: 15px;
    font-size: 14px;

    .mobile {
        display: none;
    }

    .usp {
        float: left;
        margin-right: 0;
        position: relative;
        padding-left: 20px;
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 10px;

        .menu-icon {
            position: absolute;
            //color: @color-blue-primary;
            color: #5FC719;
            left: 0;
            top: 3px;
        }
    }
}


#usp-topbalk.on-top {
    margin-top: 0;
    background: @c-white;
    margin-bottom: 10px;

    &.scroll {
        margin-top: 177px;
    }
}

.usp-mobile {
    &__list {
        margin: 0;
        list-style: none;
    }

    &__item {
        text-align: center;
    }

    &__icon {
        color: @color-blue-primary;
        font-size: 16px;
        margin-right: 3px;

        @media screen and (min-width: @breakpoint-xsmall) {
            font-size: 17px;
        }

        @media screen and (min-width: @breakpoint-small) {
            font-size: 18px;
        }

        &--whatsapp {
            color: @c-whatsapp;
        }
    }

    &__content {
        font-size: 13px;

        @media screen and (min-width: @breakpoint-xsmall) {
            font-size: 14px;
        }

        @media screen and (min-width: @breakpoint-small) {
            font-size: 15px;
        }
    }

    &__link {
        color: @color-blue-primary;

        &:hover {
            color: @color-blue-primary;
            text-decoration: underline;
        }
    }
}
