.roadmap-button {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    margin-bottom: 10px;

    @media screen and (min-width: @breakpoint-small) {
        font-size: 15px;
    }

    .roadmap-button {
        &__button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            border: none;
            border-bottom: 3px solid @c-blue-darker;
            font-size: 14px;
            cursor: pointer;
            border-radius: 4px;
            text-align: center;
            padding: 10px 25px;
            line-height: inherit;
            color: @c-white  !important;
            background-color: @c-blue;
            transition: all 0.15s ease-in;

            @media screen and (min-width: @breakpoint-xsmall) {
                font-size: 15px;
            }

            &:hover {
                background-color: @c-blue-dark;
            }
        }

        &__icon-holder {
            margin-right: 10px;
        }

        &__icon {
            float: left;
        }
    }

}