#relatedproducts {
    #related-header {
        .padh2;
        font-size: 18px;
        padding-top: 13px;
        padding-bottom: 13px;

        p {
            .neuron;
            font-size: 23px;
            margin: 0;
        }
    }

    .related-product {
        position: relative;
        .box;

        img {
            margin: 0 auto;
        }

        .related-product-tekst {
            padding: 4px 11px;

            .related-product-titel {
                margin:0 0 10px 0;
                font-size: 17px;
                color: @color-blue-primary;
            }

            .related-product-beschrijving {
                font-size: 11px;
                margin:0 0 1px 0;
                line-height: 18px;
                float: left;
            }

            .related-product-prijs {
                display: inline-block;
                margin-top: 10px;
                padding: 0 8px 0 0;
                border-right: 1px solid @color-border-dark;
            }

            .related-product-meta {
                margin-top: 10px;
                padding-left: 5px;
                display: inline-block;
                color: @color-text-secondary;
            }

            .btn.in-winkelwagen {
                width: 80%;
                margin: 4px 8% 14px 8%;
            }
        }
    }
}