#product-attachments {
    display: inline-block;
    width: 100%;
    padding: 0;
    background: none;

    .product-attachments {
        &__title {
            margin-bottom: 10px;
            font-size: 19px;
        }

        &__list {
            margin: 0;

            .product-attachments__item {
                margin-bottom: 20px;
            }
        }

        &__item {
            display: inline-block;
            width: 100%;
            height: auto;
            margin-bottom: 6px;
        }

        &__link {
            display: flex;
            align-items: center;
            text-decoration: underline;
        }

        &__icon {
            flex: 1 1 auto;
            height: 24px;
            width: 24px;
            margin-right: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            &--pdf {
                background-image: url('../../../img/pdf-icon.png');
            }
        }

        &__text {
            flex: 1 1 auto;
            width: 100%;
            margin-bottom: 0;
            font-size: 13px;
            line-height: inherit;
        }
    }
}
