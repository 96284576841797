.modal-backdrop, .modal-backdrop.fade.in {
  background-color: #000 !important;
}

.tooltip, .tooltip-inner {
  white-space: normal !important;
}

div.editable {
  padding: .5rem;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  -webkit-transition: border linear .2s,box-shadow linear .2s;
  -moz-transition: border linear .2s,box-shadow linear .2s;
  -o-transition: border linear .2s,box-shadow linear .2s;
  transition: border linear .2s,box-shadow linear .2s;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px
}
