.retour-banner-step-2 {
    margin-bottom: 25px !important;
}

#return-form {
    .page-box .box-header {
        min-height: 40px;
        height: unset;
    }
}

.box-retour {

    .box-body-retour {

        select,
        option,
        textarea,
        input[type="text"],
        input[type="password"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="date"],
        input[type="month"],
        input[type="time"],
        input[type="week"],
        input[type="number"],
        input[type="email"],
        input[type="url"],
        input[type="search"],
        input[type="tel"],
        input[type="color"],
        .uneditable-input {
            height: 27px;
            margin-bottom: 0;
        }

        select {
            height: 36px;
        }

    }
}

.box-retour-2 {

    select,
    option,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"],
    .uneditable-input {
        height: 27px;
        margin-bottom: 0;
        width: 380px;
    }

    select {
        height: 36px;
        width: 393px;
    }
}

.box-retour-1 {
    #pickupTime-select {
        display: none;
    }
}

.box-retour {

    .btn-blue-style {
        text-shadow: none !important;
    }

    .product-retour-single {
        border-bottom: 1px solid #cccccc;
        padding: 25px 0 10px;
    }

    .product-retour-single:nth-child(1) {
        border-top: 1px solid #cccccc;
    }

    .product-retour-single:nth-last-child(1) {
        border-bottom: 1px solid #cccccc;
    }

    .form {
        margin-bottom: 10px;
    }

    .btn-checkout {
        margin-top: 30px;
    }

    .retour-banner-step-2 {
        margin-bottom: 20px;
    }

    .retour-prod-specs {
        margin: 0 0 10px 17px;
    }

    .retour-prod-name,
    .retour-prod-amount,
    .retour-prod-reden,
    .retour-prod-comment {
        margin-bottom: 15px;
    }

    .retour-prod-comment {
        textarea {
            max-width: 441px;
            min-height: 75px;
            max-height: 150px;
            width: 441px;
        }
    }

    .retour-prod-specs-title {
        text-align: left;
        margin-bottom: 7px;
        color: #000;
        padding: 10px 0 0;
        border-radius: 4px;
        font-weight: 700;
    }

    .retour-prod-name {
        text-align: center;
        color: #000;
        padding: 10px 0 0;
        border-radius: 4px;
        margin-bottom: 10px;
        font-weight: 700;
    }

    .retour-prod-specifications {
        font-size: 14px;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 4px;
    }

    .retour-prod-img {
        display: block;
        margin: 0 auto 20px;
        min-height: 126px;
    }

    .ordernr {
        .retour-desc {
            display: none;
            background: rgb(238, 238, 238);
            padding: 10px 15px;
            border-radius: 7px;
            margin-top: 5px;
            margin-bottom: 10px;
            color: rgb(51, 51, 51);
            border: 2px solid #dcd8d8;
        }

        .show-retour {
            display: table !important;
        }
    }

    .product-bottom-text {
        margin-bottom: -10px;
    }

    .btn-checkout {
        padding: 10px 28px 10px 18px;
    }

    .form-header {
        font-size: 19px;
        margin-bottom: 10px;
        margin-top: 0;
        font-weight: 700;
    }

    /* The container */
    .retour-prod-activate {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .retour-btn {
        cursor: pointer;
        .neuron();
        text-shadow: none;
        padding: 10px 0;
        min-height: 26px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        width: 90%;
        background: #00a2e0;
        color: @c-white  !important;
        border-radius: 4px;
        border-bottom: 3px solid #005884;
        text-align: center;
        color: @c-white  !important;
        cursor: pointer;

        strong {
            position: absolute;
            left: 56%;
            transform: translateX(-50%);
            top: 3px;
            font-size: 21px;
            color: @c-white;
        }
    }

    .retour-btn:hover {
        background-color: #4374a6 !important;
        background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #1688ce), color-stop(1, #03a2e0));
        color: @c-white;
    }

    /* Hide the browser's default checkbox */
    .retour-prod-activate input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        left: 35px;
        transform: translateX(-50%);
    }

    /* On mouse-over, add a grey background color */
    .retour-prod-activate:hover input~.checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .retour-prod-activate input:checked~.checkmark {
        background-color: #5FC719;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .retour-prod-activate input:checked~.checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .retour-prod-activate .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -10px;
        margin-left: -10px;

        .inner {
            border-right: 1px solid #cccccc;
            padding: 20px 15px 20px;
            border-bottom: 1px solid #cccccc;
            border-top: 1px solid #cccccc;
        }

        @media screen and (max-width: (@breakpoint-small - 1)) {
            .cell-foto {
                display: none !important;
            }
        }

        .cell {
            flex-basis: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
            position: relative;
            width: 100%;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
            display: block;

            img {
                max-height: 375px;
                display: block;
                margin: 0 auto;
            }
        }

        .col {
            margin-bottom: 30px;
        }

        .col-md-6 {
            max-width: 50%;
            width: 50%;
            position: relative;
        }

        .col-md-4 {
            width: 33.33333333%;
            max-width: 33.33333333%;
            position: relative;
        }

        .col-md-12 {
            width: 100%;
            position: relative;
            max-width: 100%;
        }

        .col-md-3 {
            max-width: 25%;
            position: relative;
            width: 24%;
        }

        .col-md-7 {
            width: 58.33333333%;
            max-width: 58.33333333%;
            position: relative;
        }

        .col-md-2 {
            width: 16.66666667%;
            position: relative;
            max-width: 16.66666667%;
        }
    }

    .control-group {
        margin-bottom: 15px;
    }

    .retour-banner {
        margin-bottom: 10px;

        .retour-banner-content-text {
            margin-bottom: 25px;
        }

        .retour-banner-title {
            font-size: 28px;
            margin: 15px 0 5px 0;
            float: left;
            width: 100%;
            display: block;
            color: #00A2E0;
        }

        .retour-banner-subtitle {
            font-size: 19px;
            margin-bottom: 5px;
            margin-top: 0;
            font-weight: 700;
        }

        .retour-stappen {
            width: 100%;
            padding: 0 7px;

            .stap-blue {
                color: #00A2E0;
            }
        }
    }
}

.controls-postal {
    .control-group {
        display: inline-block !important;
        margin-bottom: 0 !important;
    }
}

.box-retour-3 {

    .row .cell img {
        max-height: 375px;
        display: block;
        margin: 0 auto;
        max-width: 400px;
    }

    .btn-checkout {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .retour-banner {
        margin-bottom: 30px;
    }

    .retour-send-options {
        border-top: 1px solid #cccccc;
    }

    .btn-blue-style {
        text-shadow: none !important;
    }

    .row {
        display: -webkit-box;
    }

    .retour-send-options {

        .dhl-shops {
            margin-top: 15px;

            .single-shop {
                margin-bottom: 10px;

                input[type=radio] {
                    float: left;
                    margin-right: 8px;
                }

                .content {
                    display: inline-block;

                    p {
                        margin-bottom: 0;
                        font-size: 15px;
                    }

                    .single-shop-place,
                    .single-shop-openingstijden {
                        font-size: 13px;
                    }
                }
            }
        }

        .shipping-method-wrap {
            border: 1px solid #CACACA;
            border-top: none;
            margin-bottom: 0;
            padding: 11px 20px 5px 20px;
            overflow: hidden;

            &.active {
                background: #ecf5f8;
                border: 1px solid #00A2E0 !important;

                .shipping-method-description {
                    display: block;

                    .inner-desc {
                        width: 100%;
                        margin-bottom: 10px;
                    }
                }
            }

            &:hover {
                background: #ecf5f8;
            }

            .radio input[type="radio"] {
                margin-top: 17px;
            }

            span.error {
                margin-left: 0;
            }

            .shipping-method-delivery-note {
                width: 100%;
            }

            .shipping-method-icon {
                background-image: url('../../img/sprite.png');
                background-size: 400px;
                width: 64px;
                height: 40px;
                float: left;
                margin: 4px 20px 10px 0;

                &#shipping-icon-pickup {
                    background-position: 169px -89px;
                }

                &#shipping-icon-postnl {
                    background-position: -560px 200px;
                }

                &#shipping-icon-colisprive {
                    background-position: -480px 150px;
                }

                &#shipping-icon-truckFreight {
                    background-position: 240px -183px;
                }

                &#shipping-icon-budbee {
                    background-position: 102px 245px;
                }
            }

            .shipping-method-name-price {
                float: left;
                margin-bottom: 10px;

                p {
                    margin: 0;
                }

                .shipping-method-price {
                    display: inline-block;
                    font-weight: normal;
                    font-size: 14px;
                    color: #898989;

                    &[data-free-shipping="GRATIS!"] {
                        color: #00A2E0;
                    }
                }

                .shipping-method-name {
                    display: block;
                    font-weight: bold;
                    font-size: 14px;

                }
            }

            .shipping-method-description {
                clear: both;
                line-height: 24px;
                display: none;
            }

            .shipping-active {
                display: block !important;
            }
        }

        .shipping-method-wrap:nth-child(1) {
            border-top: 1px solid #CACACA;
        }

        .checkout-radio-option-block {
            border: 1px solid #CACACA;
            border-top: none;
            margin-bottom: 0;

            &:hover {
                background: #ecf5f8;
            }

            &.active {
                background: #ecf5f8;
                border: 1px solid #00A2E0 !important;

                .payment-method-description {
                    display: block;
                }
            }

            .payment-method-description {
                clear: both;
                line-height: 24px;
                display: none;
                font-size: 12px;
                padding: 0 10px 0 0;
            }

            &:last-child {
                -webkit-border-bottom-right-radius: 3px;
                -webkit-border-bottom-left-radius: 3px;
                -moz-border-radius-bottomright: 3px;
                -moz-border-radius-bottomleft: 3px;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
            }

            .payment-method-icon {
                background-image: url('../../img/sprite.png');
                background-size: 400px;
                width: 65px;
                height: 40px;
                display: inline-block;

                &#payment-method-icon-ideal {
                    background-position: 0 0;
                }

                &#payment-method-icon-paypal {
                    background-position: -239px 0;
                }

                &#payment-method-icon-mastercard {
                    background-position: -480px 0;
                }

                &#payment-method-icon-bancontact {
                    background-position: -160px 0;
                }

                &#payment-method-icon-visa {
                    background-position: -320px 0;
                }

                &#payment-method-icon-moneyorder {
                    background-position: -160px -46px;
                }

                &#payment-method-icon-account {
                    background-position: 0 -165px;
                }

                &#payment-method-icon-billink {
                    background-position: 0 -165px;
                }

                &#payment-method-icon-billinkwrisk {
                    background-position: 0 -165px;
                }

                &#payment-method-icon-billinkb2c {
                    background-position: 0 -165px;
                }

                &#payment-method-icon-bizpay {
                    background-position: 0 -165px;
                }

                &#payment-method-icon-directbank {
                    background-position: 0 -248px;
                }

                &#payment-method-icon-cartebleue {
                    background-position: 0 -205px;
                }

                &#payment-method-icon-inghome {
                    background-position: -80px -138px;
                }

                &#payment-method-icon-giropay {
                    background-position: -80px -183px;
                }

                &#payment-method-icon-kbc {
                    background-position: -80px -92px;
                }

                &#payment-method-icon-americanexpress {
                    background-position: -159px -92px;
                }
            }

            label {
                margin: 0;
                padding: 11px 0 5px 20px;

                input {
                    margin: 13px 14px 0 -6px;
                }

                .payment-method-name-price {
                    display: inline-block;
                    vertical-align: top;
                    margin: 0 5px 10px 20px;

                    .payment-method-name {
                        display: block;
                        font-weight: bold;
                        font-size: 14px;
                    }

                    .price {
                        display: block;
                        color: #898989;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: (@breakpoint-large - 1)) {
    #return-form {
        #home-content {
            width: 100%;
        }
    }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    #return-form {

        .box-retour .box-body-retour textarea,
        .box-retour .box-body-retour input[type="text"],
        .box-retour .box-body-retour input[type="password"],
        .box-retour .box-body-retour input[type="datetime"],
        .box-retour .box-body-retour input[type="datetime-local"],
        .box-retour .box-body-retour input[type="date"],
        .box-retour .box-body-retour input[type="month"],
        .box-retour .box-body-retour input[type="time"],
        .box-retour .box-body-retour input[type="week"],
        .box-retour .box-body-retour input[type="number"],
        .box-retour .box-body-retour input[type="email"],
        .box-retour .box-body-retour input[type="url"],
        .box-retour .box-body-retour input[type="search"],
        .box-retour .box-body-retour input[type="tel"],
        .box-retour .box-body-retour input[type="color"],
        .box-retour .box-body-retour .uneditable-input {
            margin-bottom: 6px;
            width: 95%;
        }

        .box-retour .box-body-retour select,
        .box-retour .box-body-retour option {
            width: 100%;
        }

        .box-retour .retour-naam .input-mini {
            width: 95% !important;
        }

        .box-retour .input-mini {
            width: 100px !important;
        }

        .box-retour .control-group {
            margin-bottom: 9px;
        }

    }
}

.box-retour-3 {
    .box-body-retour {
        padding-bottom: 30px;
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    #return-form {
        .box-retour .input-mini {
            width: 65px !important;
        }
    }
}

@media screen and (max-width: (@breakpoint-xlarge - 1)) {
    .box-retour-3 {
        .row .cell-foto {
            display: none;
        }
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .box-retour-3 .retour-send-options .shipping-method-wrap .shipping-method-name-price {
        width: 100%;
    }
}

@media screen and (max-width: (@breakpoint-xlarge - 1)) {
    #return-form {

        .box-retour-2 select,
        .box-retour-2 option,
        .box-retour-2 textarea,
        .box-retour-2 input[type="text"],
        .box-retour-2 input[type="password"],
        .box-retour-2 input[type="datetime"],
        .box-retour-2 input[type="datetime-local"],
        .box-retour-2 input[type="date"],
        .box-retour-2 input[type="month"],
        .box-retour-2 input[type="time"],
        .box-retour-2 input[type="week"],
        .box-retour-2 input[type="number"],
        .box-retour-2 input[type="email"],
        .box-retour-2 input[type="url"],
        .box-retour-2 input[type="search"],
        .box-retour-2 input[type="tel"],
        .box-retour-2 input[type="color"],
        .box-retour-2 .uneditable-input {
            width: 220px !important;
        }

        .box-retour-2 {
            .product-retour-single {
                select {
                    height: 36px;
                    width: 232px !important;
                }

                .retour-prod-comment textarea {
                    max-width: 441px;
                    min-height: 75px;
                    max-height: 150px;
                    width: 278px !important;
                }
            }
        }
    }
}

body .box-retour-2 .retour-prod-specs-title {
    text-align: left;
    margin-bottom: 7px;
    color: #000;
    padding: 0;
    border-radius: 4px;
    font-weight: 700;
}

body .box-retour-2 .retour-prod-name {
    text-align: center;
    color: #000;
    padding: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    font-weight: 700;
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    #return-form .box-retour-2 .row .cell-img {
        display: none;
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    #return-form .box-retour-2 .row .col-md-3 {
        max-width: 100%;
        width: 100%;
        min-height: 50px;
    }

    #return-form .box-retour-2 .row .col-md-6 {
        max-width: 100%;
        position: relative;
        width: 100%;
    }

    #return-form .box-retour-2 .row {
        position: relative;
    }

    #return-form .box-retour-2 .inner.row {
        padding: 0 15px;
    }

    #return-form .box-retour-2 {
        .retour-prod-name {
            text-align: center;
        }

        .retour-prod-img {
            display: block;
            margin: 0 auto 20px auto;
            min-height: 126px;
        }

        .col-md-6 {
            text-align: center;
        }

        .retour-prod-specs-title {
            text-align: center;
        }

        .retour-prod-specs {
            max-width: 320px;
            margin: 0 auto;
            text-align: center;
            list-style: none;
        }

        .col-md-3 {
            margin-bottom: 15px;
        }
    }

    #return-form .box-retour-2 .retour-btn {
        width: 200px;
        position: relative;
        left: 0;
        top: 0;
        transform: translateX(0) translateY(0);
        margin-bottom: 10px;
    }

}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    #return-form .box-retour-2 .product-retour-single .retour-prod-comment textarea {
        max-width: 441px;
        min-height: 75px;
        max-height: 150px;
        width: 95% !important;
    }

    #return-form .box-retour-2 .product-retour-single select {
        height: 36px;
        width: 100% !important;
    }

    #return-form .box-retour-2 input[type="number"] {
        width: 95% !important;
        margin-bottom: 0 !important;
    }

    .retour-prod-amount,
    .retour-prod-reden {
        text-align: left !important;
    }

    .retour-prod-amount strong,
    .retour-prod-reden strong {
        text-align: left !important;
        margin-bottom: 8px;
        float: left;
    }

    #return-form .box-retour .retour-prod-specs-title {
        text-align: left;
    }

    #return-form .box-retour .retour-prod-specs {
        text-align: left;
        margin: 0;
    }

    #return-form .box-retour .retour-prod-amount {
        margin-bottom: 10px;
    }

    #return-form .box-retour .retour-prod-specs-title {
        padding-top: 0;
    }

    .box-retour .retour-prod-comment {
        margin-bottom: 10px;
    }
}


.retour-banner-4 {
    background: @c-white;
}

.box-retour {
    .timeline {
        background: #F8F8F8;
        position: relative;
        width: 100%;
        overflow: hidden;

        h3 {
            font-size: 26px;
            text-align: center;
            margin-top: 25px;
            margin-bottom: 25px;
        }

        .line {
            position: absolute;
            height: 100%;
            width: 15px;
            background: #00A5E4;
            top: 50px;
            left: 100px;
            z-index: 5;

            .top_img {
                width: 140px;
                height: 65px;
                background: url(../../img/account.png);
                background-size: 100% auto;
                position: absolute;
                top: 0;
                margin-left: -68px;
            }
        }

        .item {
            width: 100%;
            float: left;
            position: relative;
            z-index: 100;
            overflow: hidden;
            background: @c-white;
            padding: 20px 0;

            &.n1 {
                .content {
                    margin-top: 47px;
                }

                .line {
                    height: 50%;
                    bottom: 0;
                    top: unset;
                }
            }

            &.n2 {
                .content {
                    margin-top: 25px;
                }
            }

            &.n3 {
                .content {
                    margin-top: 49px;
                }
            }

            &.n4 {
                .content {
                    margin-top: 47px;
                }
            }

            &.n5 {
                .content {
                    margin-top: 25px;
                }
            }

            &.n6 {
                .content {
                    margin-top: 25px;
                }
            }

            .line {
                top: 0;
            }

            &.dark {
                background: #EEEEEE;
            }

            .year {
                text-align: center;
                font-weight: bold;
                width: 100px;
                position: absolute;
                top: 50%;
                margin-top: 0;
                z-index: 100;

                .circle {
                    width: 20px;
                    height: 20px;
                    border: 3px solid #00A5E4;
                    float: right;
                    border-radius: 20px;
                    margin: -3px -20px 0 0;
                    z-index: 100;
                    background: @c-white;
                }
            }

            img {
                margin-right: 50px;
                float: right;
                max-width: 200px;
                max-height: 120px;
            }

            .content {
                width: 400px;
                float: left;
                margin-left: 150px;

                p {
                    width: 100%;
                    float: left;
                }
            }

            @media screen and (max-width: (@breakpoint-xlarge - 1)) {
                img {
                    margin-right: 50px;
                    float: right;
                    max-width: 200px;
                    max-height: 160px;
                }

                .content {
                    width: 260px !important;
                    margin-top: 20px;
                }

                &.n1 {
                    .content {
                        margin-top: 38px !important;
                    }
                }

                &.n3 {
                    .content {
                        margin-top: 60px;
                    }
                }
            }

            @media screen and (max-width: (@breakpoint-large - 1)) {
                .content {
                    margin-top: 0;
                    width: 260px !important;
                }

                img {
                    width: 200px;
                }
            }
        }

        @media screen and (max-width: (@breakpoint-medium - 1)) {
            .line {
                display: none;
            }

            img {
                max-height: 200px !important;
            }

            h3 {
                margin-bottom: 0;
            }

            .item {
                padding: 10px 20px;
                box-sizing: border-box;
                text-align: center;

                .year {
                    width: 100%;
                    position: relative;
                    text-align: center;
                    margin-top: 20px;

                    .circle {
                        display: none;
                    }
                }

                .content {
                    width: 100% !important;
                    margin-top: 10px !important;
                    margin-left: 0;
                }

                img {
                    max-width: 100%;
                    margin: 0;
                    float: none;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
