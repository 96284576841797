.usps-attributes {
    display: inline-block;
    display: none;
    width: 100%;
    margin-bottom: 20px;

    @media screen and (min-width: @breakpoint-large) {
        margin-bottom: 0;
    }

    .usps-attributes {
        &__list {
            margin: 0;
        }

        &__item {
            display: flex;
            align-items: flex-start;
            margin-top: 3px;
            margin-bottom: 3px;

            @media screen and (min-width: @breakpoint-medium) {
                margin-top: 5px;
                margin-bottom: 5px;
            }

            &:nth-of-type(1) {
                margin-top: 0;
            }

            &:nth-last-of-type(1) {
                margin-bottom: 0;
            }
        }

        &__icon {
            font-size: 14px;
            color: @c-green;
            margin-right: 5px;

            @media screen and (min-width: @breakpoint-medium) {
                font-size: 16px;
                margin-right: 10px;
            }
        }

        &__text {
            font-size: 14px;
            font-weight: bold;
            .neuron();

            @media screen and (min-width: @breakpoint-medium) {
                font-size: 16px;
            }

            @media screen and (min-width: @breakpoint-large) {
                font-size: 17px;
            }
        }
    }
}
