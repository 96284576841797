.nieuwsbrief-signup {
    background: #174166;
    padding: 26px 25px 1px;
    width: 100%;

    .titel {
        display: inline-block;
        width: 100%;
        .neuron();
        color: @c-white;
        margin: 4px 25px 0 0;
        font-size: 28px;
    }

    #ps_campaign_newsletter_email {
        height: 40px;
        width: 370px;
    }

    .submit-newsletter {
        background: @color-blue-primary;
        color: @c-white  !important;
        border-radius: 4px;
        padding: 0 30px;
        border: none;
        border-bottom: 3px solid #005884;
        text-align: center;
        display: inline-block;
        margin: 0 auto 0;
        height: 42px;
        cursor: pointer;
        position: relative;
        -webkit-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        transform: translateY(-5px);

        &:hover {
            background: #007eba;
        }
    }
}

@media (max-width: 1300px) {
    .nieuwsbrief-signup {
        .titel {
            float: unset;
            text-align: center;
        }

        .content {
            display: table;
            margin: 10px auto 0;
        }

        #ps_campaign_newsletter_email {
            margin-right: 10px;
        }
    }
}

@media (max-width: 553px) {
    .nieuwsbrief-signup {
        #ps_campaign_newsletter_email {
            width: 200px;
        }
    }
}

@media (max-width: 380px) {
    .nieuwsbrief-signup #ps_campaign_newsletter_email {
        width: 160px !important;
    }
}

@media (max-width: 350px) {
    .nieuwsbrief-signup {
        #ps_campaign_newsletter_email {
            width: 160px;
        }
    }
}