.order-status {
  &:before {
    content: '';
    width: 3px;
    background: @c-gray-default;
    position: absolute;
    top: 9%;
    bottom: 9%;
    left: 16px;
  }
}

.order-status__step {
  display: block;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  color: @c-gray-default;
  margin-left: 24px;

  a {
    color: @c-blue;
    text-decoration: underline;
  }
}

.order-status__bullet {
  width: 14px;
  height: 14px;
  border-radius: 14px;
  border: 3px solid @c-gray-default;
  margin-right: 10px;
  position: absolute;
  left: -23px;
  background: @c-white;
}

.order-status__step--complete {
  color: @color-text-primary;

  .order-status__bullet {
    background: @c-gray-default;
  }
}

.order-status__step--pending {
  color: @c-blue;

  .order-status__bullet {
    border-color: @c-blue;
    background: @c-blue;
    width: 21px;
    height: 21px;
    left: -27px;
  }
}