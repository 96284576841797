.tooltip2 {
  display: inline-block;
  position: relative;

  img {
    max-width: 15px;
    margin-left: 1px;
    margin-top: -20px;
  }
}

.tooltip2:hover .tooltiptext {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s;
}

.tooltiptext {
  visibility: hidden;
  width: 310px;
  background-color: #555;
  color: @c-white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 30px;
  left: -100px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}