.highlighted-content {
    display: inline-block;
    width: 100%;
    background-color: @c-gray-lighter;
    border: 1px solid @color-border-dark;
    padding: 16px;
    box-sizing: border-box;

    @media screen and (min-width: @breakpoint-medium) {
        width: auto;
    }

    h5 {
        color: @color-text-primary;
        font-size: 20px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 10px;
    }

    &__list {
        margin: 0;
        list-style: none;
    }

    &__item {
        &:not(:first-child) {
            margin-top: 6px;
        }

        &--icon-with-text {

            .highlighted-content {
                &__content {
                    display: inline-flex;
                    align-items: flex-start;
                }

                &__icon {
                    svg {
                        margin-top: -3px;
                    }
                }
            }

        }
    }

    &__icon {
        flex-shrink: 0;
        margin-right: 8px;

        svg {
            display: inline-block;
            fill: currentColor;
            vertical-align: middle;
            height: 16px;
            width: 16px;
            background-image: none;
            line-height: initial;
            margin: 0;

            @media screen and (min-width: @breakpoint-large) {
                height: 18px;
                width: 18px;
            }
        }

        .icon-svg {
            &--color-green {
                fill: @c-green-light;
                color: @c-green-light;
            }
        }
    }

    &__text {
        font-size: 13px;

        @media screen and (min-width: @breakpoint-large) {
            font-size: 14px;
        }
    }
}