body.checkout-step-1 {

    #search,
    #breadcrumbs,
    #navigation,
    #cart-normal,
    #mobile-search-wrapper,
    #fluid-search-spacer {
        display: none;
    }

    #usp-topbalk {
        margin-bottom: 0;
    }

    #header #cart #cart-in-checkout {
        display: block;
    }

    .login-form {
        margin: 22px 0 33px 0;

        #login-remember-me-group {
            margin-bottom: 0;
        }

        label.checkbox {
            margin: 0 26px 13px 0;
            padding-top: 12px;
        }

        button {
            text-shadow: none;
            font-size: 19px;
            padding: 5px 13px;
            color: @c-white;
            background-color: #62a605;
            .gradient-img(#7bca16, #62a605, #8cf306);
            border: 1px solid #029500;
            .neuron;
        }

        .checkout-form-heading {
            display: block;
        }

        .controls {
            margin-left: 200px;
        }
    }

    #checkout-steps {
        margin-top: 8px;
    }
}