#breadcrumbs {
    height: 35px;

    #crumbs {
        margin-bottom: 0;
        padding-bottom: 2px;
        padding-left:0 !important;
        .padh1;

        .breadcrumb-divider {
            display: inline-block;
            height: 0;
            width: 0;
            margin: 0 3px;
            border-top: 4px solid rgba(0, 0, 0, 0);
            border-left: 6px solid @color-blue-primary;
            border-bottom: 4px solid rgba(0, 0, 0, 0);
        }
    }
}

#crumbs-wrap {
    margin-bottom: 5px;
}
