#checkout #checkout-content #checkout-main {
    &.checkout-main {
        border: none;
        border-radius: 0;
        background-color: transparent;
    }

    #checkout-main-inner {
        &.checkout-main__inner {
            border: 1px solid #cacaca;
            padding: 19px 19px 14px;
        }
    }
}

#checkout {
    .checkout-main {
        * {
            box-sizing: border-box;
        }

        .checkout-main {
            &__block {
                display: inline-block;
                width: 100%;
                padding: 16px 32px;
                background: @c-white;
                box-shadow: 0 2px 4px -2px fade(@c-black, 20%);

                &--register {
                    padding: 16px 0;

                    .checkout-main__title {
                        margin-bottom: 0;
                    }

                    .checkout-form-heading {
                        margin-top: 0;
                        margin-bottom: 24px;
                        color: @c-blue-dark;
                        width: 100%;
                        text-align: center;
                    }

                    .checkout-form-wrapper {
                        padding: 0 32px;
                    }
                }

                &:not(:nth-last-of-type(1)) {
                    margin-bottom: 32px;
                }
            }

            &__title {
                display: inline-block;
                width: 100%;
                margin: 8px 0 16px;
                font-size: 24px;
                color: @c-blue;
                text-align: center;
            }

            &__subtitle {
                display: inline-block;
                width: 100%;
                margin: 8px 0 16px;
                font-size: 20px;
                color: @c-blue;
            }
        }


        #checkout-button {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media screen and (min-width: @breakpoint-medium) {
                display: block;
            }
        }

        #checkout-back-link {
            display: flex;
            line-height: initial;
            padding: 12px;
            margin-right: 16px;
            margin-bottom: 0;
            width: auto;
            flex: 0 0 auto;
            font-size: 20px;

            span {
                display: none;
            }
        }

        .button {
            display: inline-block;
            width: 100%;
            border-radius: 2px;
            background-image: none;
            border: 1px solid transparent;
            box-shadow: none;
            padding: 12px 32px;
            font-size: 18px;
            .neuron();
            transition: @transition-cubic;

            @media screen and (min-width: @breakpoint-medium) {
                width: auto;
            }

            &--green {
                background-color: @c-green-light;
                border-color: @c-green-light;
                color: @c-white;

                &:hover {
                    background-color: @c-green-dark;
                    border-color: @c-green-dark;
                }
            }

            &--transparent {
                background-color: @c-white;
                border-color: @c-blue;
                color: @c-blue;

                &:hover {
                    color: @c-white  !important;
                    background-color: @c-blue;
                    border-color: @c-blue;
                }
            }
        }

        .section-divider {
            display: flex;
            margin: 16px 0;
            height: 1px;
            border: none;
            background: @color-border-dark;
        }

        .additional-text {
            display: inline-block;
            width: 100%;
            margin-top: 8px;
            text-align: center;
            color: @color-text-secondary;
        }

        .email-form {
            select,
            textarea,
            input[type="text"],
            input[type="email"],
            input[type="password"],
            input[type="email"] {
                height: 40px;
                margin-bottom: 0;
                padding: 8px 15px 8px 10px;
                border-radius: 2px;
                transition: background-color .2s ease-in-out;
            }
        }

    }
}

//.checkout-page.checkout-page--new {
//    #checkout {
//        .checkout-main {
//            margin: 0 auto;
//
//            @media screen and (min-width: @breakpoint-large) {
//                width: 688px;
//            }
//
//            @media screen and (min-width: @breakpoint-grid-large) {
//                width: 908px;
//            }
//        }
//    }
//}
