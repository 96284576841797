@circle-size-xs: 12px;
@circle-size-sm: 15px;
@circle-size-md: 15px;

.shipping-time {
  display: flex;
  width: 100%;
  font-size: 12px;

  @media screen and (max-width: @breakpoint-large) {
    margin-top: 10px;
  }

  @media screen and (min-width: @breakpoint-small) {
    font-size: 14px;
  }

  &__circle {
    float: left;
    border-radius: 50%;
    transform: translateY(1px);
    margin-right: 8px;
    width: @circle-size-xs;
    height: @circle-size-xs;
    min-width: @circle-size-xs;
    max-width: @circle-size-xs;

    &--green {
      background: @c-green1;
    }

    &--red {
      background: @c-red;
    }

    &--gray {
      background: @c-gray;
    }

    &--orange {
      background: @c-orange;
    }

    @media screen and (min-width: @breakpoint-small) {
      width: @circle-size-sm;
      height: @circle-size-sm;
      min-width: @circle-size-sm;
      max-width: @circle-size-sm;
    }
  }
}
