.cc-banner.cc-bottom {
    z-index: 99999 !important;
}

@media screen and (max-width: (@breakpoint-large - 1)) {
    .cc-banner.cc-bottom {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .cc-window.cc-banner {
        -ms-flex-align: initial !important;
        align-items: initial !important;
    }
}