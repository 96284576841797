html[lang="fr-FR"], html[lang="fr-BE"] {
    .menu-bar .bottom-bar ul li {
        a {
            padding: 15px 20px;
            font-size: 15px;
        }

        &.home a {
            padding:0 15px 0 0;
        }
    }

    @media screen and (max-width: (@breakpoint-grid-large - 1)) {
        .menu-bar .bottom-bar ul li {
            &:nth-last-of-type(2) {
                display: none;
            }

            a {
                padding: 15px 14px;
                font-size: 14px;
            }

            &.home a {
                padding: 0 5px 0 0;
            }
        }
    }
}
