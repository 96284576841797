#password {
    .password-box-header {
        margin-bottom: -1px;
        height: 40px;
        background-color: #FDFDFD;
        border: 1px solid @color-border-dark;
        -webkit-border-top-left-radius: 3px;
        -webkit-border-top-right-radius: 3px;
        -moz-border-radius-topleft: 3px;
        -moz-border-radius-topright: 3px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background: #FCFCFC;
        background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #E4E4E4), color-stop(1, #FCFCFC));
        background: -ms-linear-gradient(bottom, #E4E4E4, #FCFCFC);
        background: -moz-linear-gradient(center bottom, #E4E4E4 0%, #FCFCFC 100%);
        background: -o-linear-gradient(#FCFCFC, #E4E4E4);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#e4e4e4', GradientType=0);

        h4 {
            padding: 10px;
            font-size: 20px;
            margin: 0;
            line-height: 20px;
        }
    }

    .password-box-body {
        padding: 20px;
        -webkit-border-bottom-right-radius: 3px;
        -webkit-border-bottom-left-radius: 3px;
        -moz-border-radius-bottomright: 3px;
        -moz-border-radius-bottomleft: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border: 1px solid @color-border-dark;
        background-color: @color-background-page;
        .clearfix;

        form {
            margin: 0;
        }

        h4 {
            color: @color-blue-primary;
            margin: 4px 0;
            font-size: 18px;
        }

        address {
            margin: 0;
            font-size: 14px;
        }

        p {
            font-size: 14px;
        }
    }

    button {
        font-size: 19px;
        padding: 5px 13px;
        color: @c-white;
        .neuron();
    }
}