.order-item {
  .box;
  padding: 0 0 10px 0;
  margin-bottom: 10px;
  position: relative;
  margin-bottom: 0;

  &--pending {
    border-color: @color-blue-primary;

    .order-item__header {
      background: @color-blue-primary;
    }
  }

  &+& {
    margin-top: 30px;
  }

  h3 {
    margin: 0;
    color: @c-white;
  }

  .order-buttons {
    a + a {
      margin-left: 5px;
    }
  }

  .order-item__product {
    min-height: unset;
    min-width: 130px;
    max-width: 165px;
    margin-bottom: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .product-image-link {
      min-height: unset;
    }
  }

  .order-status {
    position: relative;
    margin-top: 18px;
    padding: 0 10px;
  }

  .order-product-slider {
    padding: 0 10px;
  }
}

.order-item__product {
  &+& {
    margin-left: 10px;
  }
}

.order-item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: @c-gray-default;

  @media screen and (max-width: (@breakpoint-small - 1)) {
    display: block;

    h3 {
      margin: 0 0 6px;
    }
  }
}