.image-thumbnail {
    @media screen and (max-width: (@breakpoint-xsmall - 1)) {
        display: block !important;
        float: none;
        text-align: center;
        margin: 0 0 1em 0;
    }

    &:hover {
        cursor: pointer;
    }

    img {
        border-radius: 4px;
        max-width: 180px;

        @media screen and (max-width: (@breakpoint-xsmall - 1)) {
            max-width: 100%;
        }
    }

    &.large {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;

        img {
            width: auto !important;
            display: block;
            margin: 5% auto;
            max-width: 80%;
            max-height: 80%;
        }
    }
}

.image-gallery {
    text-align: center;
    margin-bottom: 1em;

    .image-thumbnail {
        display: inline-block;
        margin: 0 1em 1em 0;

        &:last-child {
            margin-right: 0;
        }
    }
}

.image-left {
    float: left;
    margin: 0 1em 1em 0;
}

.image-right {
    float: right;
    margin-left: 1em;
    margin: 0 0 1em 1em;
}