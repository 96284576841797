.color_modal {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    left: 0;
    z-index: 1000;
    top: 0;
    overflow-y: scroll;
    /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    display: none;

    .modal_top {
        width: 100%;
        float: left;
        padding-bottom: 10px;

        .left {
            width: 40%;
            float: left;
        }

        .right {
            width: 60%;
            float: left;
            box-sizing: border-box;
            padding-top: 30px;
            padding-top: 15px;
            padding-right: 40px;

            .search {
                float: right;
            }

            .filter_stock {
                font-size: 12px;
                float: right;
                margin-right: 10px;
                margin-top: 6px;
                cursor: pointer;
                text-decoration: underline;

                &.active {
                    .polycon-checkmark {
                        color: #5FC719;
                    }
                }

                .polycon-checkmark {
                    color: #878787;
                }

                .btn {
                    position: relative;
                    left: 0;
                    top: -6px;
                }
            }
        }
    }

    .close {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 32px;
        transform: rotate(45deg);
    }

    .modal_inner {
        box-sizing: border-box;
        border-radius: 5px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        background: @c-white;
        width: 840px;
        left: 50%;
        float: left;
        margin: 50px 0 50px -420px;
        position: relative;
        padding: 10px 20px 0 20px;

        h3 {
            margin: 10px 000;
            font-size: 26px;
            color: #00A2E0;
            width: 100%;
            float: left;
            padding: 0 5px;
            box-sizing: border-box;

            span {
                color: #000;
                font-size: 18px;
                float: left;
                width: 100%;
            }
        }

        .colors {
            width: 100%;
            float: left;
            max-height: 425px;
            margin-bottom: 10px;
            overflow-y: scroll;
            /* has to be scroll, not auto */
            -webkit-overflow-scrolling: touch;
            position: relative;
            box-sizing: border-box;
            z-index: 5;

            h4 {
                font-weight: bold;
            }

            .most_selected {
                width: 100%;
                padding: 10px;
                float: left;
                box-sizing: border-box;
                background: #eef7fb;
                border-radius: 4px;
                border-bottom: 3px solid #00A2E0;
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

                h4 {
                    font-size: 18px;
                    margin-top: 0;
                }
            }

          .special-color {
            clear: both;
            padding: 10px;
            border-radius: 5px;
            box-sizing: border-box;
            background: rgb(132,216,249);
            background: linear-gradient(180deg, rgba(132,216,249,1) 0%, rgba(231,248,255,1) 100%);
            margin-top: 5px;

            h4 {
              margin: 0;
              padding: 0;
              font-size: 18px;
            }

            .request-elements {
              display: flex;
              align-items: baseline;
              gap: 20px;

              input.request-color-input {
                flex: 1;
                width: 70%;
              }

              input.request-color-button {
                white-space: nowrap;
              }
            }
          }

          .special-color {
            clear: both;
            padding: 10px;
            border-radius: 5px;
            box-sizing: border-box;
            background: rgb(132,216,249);
            background: linear-gradient(180deg, rgba(132,216,249,1) 0%, rgba(231,248,255,1) 100%);
            margin-top: 5px;

            h4 {
              margin: 0;
              padding: 0;
              font-size: 18px;
            }

            .request-elements {
              display: flex;
              align-items: baseline;
              gap: 20px;

              input {
                flex: 1;
              }

              button {
                white-space: nowrap;
                width: 200px;
              }
            }
          }
        }

        .overlay {
            height: 40px;
            width: 100%;
            margin-top: -40px;
            position: relative;
            z-index: 1000;
            float: left;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,ffffff+100&0+32,1+100 */
            background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 32%, rgba(255, 255, 255, 1) 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 32%, rgba(255, 255, 255, 1) 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 32%, rgba(255, 255, 255, 1) 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@c-white', endColorstr='@c-white', GradientType=0);
            /* IE6-9 */
        }

        .status {
            &.in-stock {
                .round {
                    background: #42c91d;
                }
            }

            &.out-stock {
                .round {
                    background: #ef6b13;
                }
            }

            .round {
                width: 8px;
                height: 8px;
                margin-top: 3px;
                border-radius: 20px;
                float: left;
                margin-right: 3px;
                box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
            }
        }

        h4 {
            width: 100%;
            float: left;
            margin: 10px 0 5px 0;
            padding: 0 5px;
            box-sizing: border-box;
            font-size: 16px;
        }

        .swatch {
            width: 25%;
            float: left;
            padding: 4px 4px;
            box-sizing: border-box;
            cursor: pointer;

            .costs {
                position: absolute;
                right: -6px;
                top: -10px;
                background: @c-white;
                box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
                color: #000;
                padding: 3px 5px;
                font-size: 12px;
                border-radius: 4px;
            }

            &:hover {
                .inner {
                    opacity: 0.8;
                }
            }

            .cur_choise {
                display: none;
            }

            &.active {
                .inner {
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                }

                .cur_choise {
                    opacity: 0.7;
                    font-weight: 300;
                    font-size: 12px;
                    display: block;
                }
            }

            &.no-color {
                .inner {
                    background: url(../../../img/no-color.jpg) no-repeat;
                    background-size: cover;
                }
            }

            .inner {
                width: 100%;
                height: 95px;
                float: left;
                border: 4px solid @c-white;
                box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
                color: @c-white;
                background: @c-white;
                padding: 12px 10px 10px 10px;
                box-sizing: border-box;
                position: relative;
                text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);

                .num,
                .name {
                    font-weight: bold;
                    width: 100%;
                    float: left;
                }

                .name {
                    font-size: 12px;
                    font-weight: normal;
                    color: white;
                    text-transform: uppercase;
                }

                .status {
                    position: absolute;
                    bottom: 7px;
                    font-size: 12px;
                }
            }

            &.light {
                .inner {
                    text-shadow: 0 0 1px rgba(0, 0, 0, 0);
                    color: rgba(0, 0, 0, 0.8);

                    .name {
                        color: rgba(0, 0, 0, 0.95);
                    }
                }
            }
        }

        .bottom-note {
            font-size: 14px;
            width: 100%;
            float: left;

            #advice_side {
                float: left;
                border: 0;
                width: 40%;
                margin-bottom: 0;
            }

            .bottom_note-text {
                float: left;
                width: 60%;
                padding-top: 14px;

                a {
                    color: #00A2E0;
                    text-decoration: underline;
                }
            }
        }


        @media screen and (max-width: (@breakpoint-medium - 1)) {
            width: 95%;
            left: 0;
            margin: 20px 2.5%;

            .swatch {
                width: 33.33%;
            }

            #advice_side {
                display: none;
            }

            .bottom-note .bottom_note-text {
                width: 100%;
            }

            .modal_top {
                .left {
                    width: 100%;
                }

                .right {
                    width: 100%;
                    padding-top: 5px;

                    .filter_stock {
                        float: left;
                        margin-left: 10px;
                    }

                    .search {
                        float: left;
                    }
                }
            }
        }

        @media screen and (max-width: (@breakpoint-small - 1)) {
            padding: 5px 10px 10px 10px;
            margin-top: 10px;
            margin-bottom: 0;

            .bottom_note-text {
                br {
                    display: none;
                }
            }

            .colors {
                max-height: ~"calc(100vh - 230px)"
            }

            .modal_top {
                padding-bottom: 10px;
                margin-bottom: 0;

                .right {
                    padding-right: 0;

                    .filter_stock {
                        display: none;
                    }

                    .search {
                        width: 100%;
                        padding: 5px 10px;
                        height: 35px;
                        box-sizing: border-box;
                    }
                }
            }

            .colors .most_selected {
                padding: 5px 3px 5px 3px;
            }

            h3 {
                font-size: 21px;
                margin-top: 2px;
                margin-bottom: 0;
            }

            .close {
                top: 10px;
                right: 10px;
                font-size: 26px;
            }

            .swatch {
                width: 50%;
            }
        }

        @media screen and (max-width: (@breakpoint-xxsmall - 1)) {
            .swatch {
                width: 100%;
            }
        }
    }
}
