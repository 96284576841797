#product-questions {
    position: relative;
    width: 268px;
    height: 78px;
    background: #4DC1EE;
    padding: 8px 11px 4px 11px;

    &:hover {
        #product-questions-overlay {
            opacity: 0.5;
        }
    }

    #product-questions-overlay {
        .gradient-img(#4DC1EE, #2F7FAA, #4DC1EE);
        height: 100%;
        width: 100%;
        float: left;
        padding: 8px 11px 4px 11px;
        position: relative;
        left: -11px;
        top: -8px;
        -webkit-transition: opacity 0.2s;
        -moz-transition: opacity 0.2s;
        -o-transition: opacity 0.2s;
        transition: opacity 0.2s;

        .product-questions-txt {
            color: @c-txt-white;
            .neuron;
            margin: 0;
            -webkit-transition: opacity 0.2s;
            -moz-transition: opacity 0.2s;
            -o-transition: opacity 0.2s;
            transition: opacity 0.2s;

        }

        .product-questions-1 {
            font-size: 23px;
            line-height: 23px;
        }

        .product-questions-2 {
            font-size: 20px;
            line-height: 19px;
            margin-top: 18px;
        }
    }

    .product-questions-3 {
        color: @c-txt-white;
        .neuron;
        margin: 0;
        position: absolute;
        height: 0;
        width: 0;
        bottom: 35px;
        z-index: 99;
        font-size: 22px;
        line-height: 22px;
    }

    #product-questions-circle {
        position: absolute;
        right: 11px;
        top: 10px;
        height: 19px;
        width: 19px;
        border: 2px solid @c-txt-white;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px;

        #product-questions-triangle {
            position: absolute;
            right: 4px;
            top: 4px;
            height: 0;
            width: 0;
            border-top: 5px solid transparent;
            border-left: 8px solid @c-txt-white;
            border-bottom: 5px solid transparent;
        }
    }
}

// Blue product questions box
#mobile-product-questions {
    position: relative;
    width: auto;
    height: 78px;
    background: #4DC1EE;
    padding: 8px 11px 4px 11px;

    #mobile-product-questions-overlay {
        .gradient-img(#4DC1EE, #2F7FAA, #4DC1EE);
        height: 100%;
        width: 100%;
        float: left;
        margin-bottom: 20px;
        padding: 8px 11px 4px 11px;
        position: relative;
        left: -11px;
        top: -8px;
        -webkit-transition: opacity 0.2s;
        -moz-transition: opacity 0.2s;
        -o-transition: opacity 0.2s;
        transition: opacity 0.2s;

        .product-questions-1 {
            font-size: 23px;
            line-height: 23px;
        }

        .product-questions-2 {
            margin-top: 10px;
            font-size: 20px;
            line-height: 19px;
        }
    }

    .product-questions-txt {
        color: @c-txt-white;
        .neuron;
        margin: 0;
        -webkit-transition: opacity 0.2s;
        -moz-transition: opacity 0.2s;
        -o-transition: opacity 0.2s;
        transition: opacity 0.2s;

    }

    .product-questions-3 {
        position: absolute;
        height: 0;
        width: 100%;
        bottom: 35px;
        z-index: 99;
        font-size: 22px;
        line-height: 22px;
    }

    #mobile-product-questions-circle {
        position: absolute;
        right: 11px;
        top: 10px;
        height: 19px;
        width: 19px;
        border: 2px solid @c-txt-white;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px;

        #mobile-product-questions-triangle {
            position: absolute;
            right: 4px;
            top: 4px;
            height: 0;
            width: 0;
            border-top: 5px solid transparent;
            border-left: 8px solid @c-txt-white;
            border-bottom: 5px solid transparent;
        }
    }
}