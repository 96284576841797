#product-page-body {
    @media screen and (max-width: @breakpoint-medium) {
        min-height: 2000px;
    }

    #product-page-content {
        margin: 0 4px;
        .box;
        overflow: hidden; // Prevent collapse

        @media screen and (min-width: @breakpoint-medium) {
            top: 0;
        }

        table td {
            font-size: 13px;
        }

        table td.product-label {
            width: 30%;
        }
    }

    p {
        font-size: 14px;
        line-height: 1.4em;
    }

    ul li {
        font-size: 14px;
        line-height: 1.4em;
    }

    .nav-tabs {
        display: flex;

        > .active > a,
        > .active > a:hover,
        > .active > a:focus {
            background-color: @color-background-page;
        }

        .nav-tab-label {
            margin-left: 8px;
        }

        .active {
            .nav-tab-label {
                display: block;
            }
        }

        li {
            display: flex;
            flex: 0 1;
            align-items: center;
            a {
                display: flex;
                flex: 1 1;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                .neuron();
            }
        }

        @media screen and (max-width: (@breakpoint-large - 1)) {
            li a {
                font-size: 16px;
            }
        }

        @media screen and (max-width: 640px) {
            .nav-tab-label {
                margin-left: 0;
            }

            li {
                flex: 1 1;

                a {
                    padding-right: 3px;
                    padding-left: 3px;
                    font-size: 13px;

                    svg {
                        display: none;
                    }
                }
            }
        }

        @media screen and (max-width: 460px) {

            li {
                a {
                    font-family: Tahoma, Geneva, sans-serif;
                    font-size: 11px;
                }
            }
        }

        @media screen and (min-width: @breakpoint-large) and (max-width: (@breakpoint-grid-large - 1)) {
            .nav-tab-label {
                margin-left: 0;
            }

            li {
                a {
                    font-size: 16px;

                    svg {
                        display: none;
                    }
                }
            }
        }
    }

    .risk-symbols {
        display: flex;
        margin: 30px 0;

        .risk-symbol {
            width: 150px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            img {
                width: 100px;
            }

            span {
                margin-top: 10px;
                font-size: 14px;
            }
        }
    }

    .leverancier-img {
        width: 100px;
        margin-bottom: 20px;
    }

    #product-page-main {
        padding: 0;

        #product-page-main-inner {
            float: left;
            padding: 15px;
            width: ~'calc(100% - 30px)';

            @media screen and (max-width: @breakpoint-medium - 1) {
                float: none;
            }

            @media screen and (min-width: @breakpoint-medium) {
                width: 628px;
            }

            @media screen and (min-width: @breakpoint-large) {
                width: 453px;
                padding: 15px 25px;
            }

            @media screen and (min-width: @breakpoint-grid-large) {
                width: 650px;
            }

            #mobile-product-page-image-frame {
                padding: 5px;
                border: 1px solid @color-border-dark;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                -o-border-radius: 2px;
                border-radius: 2px;
                margin-bottom: 20px;
            }

            .product-page-slider {
                position: relative;

                .product-daily-deal {
                    position: relative;
                    top: 26px;
                    z-index: 1;
                    margin-top: -26px;
                }
            }

            #product-page-head {
                margin-bottom: 20px;
                padding-bottom: 20px;

                @media screen and (max-width: @breakpoint-medium) {
                    margin-bottom: 0px;
                }

                h1 {
                    margin: 0 0 .5em 0;
                    line-height: 1em;
                    font-size: 22px;
                    color: @color-blue-primary;

                    @media screen and (min-width: @breakpoint-small) {
                        font-size: 26px;
                    }

                    @media screen and (min-width: @breakpoint-large) {
                        font-size: 30px;
                    }
                }

                p {
                    font-size: 13px;
                    font-family: "Trebuchet MS";
                    line-height: 1.4em;
                    margin: 0;
                }



                header {
                    display: flex;
                    flex-direction: column;
                    @media screen and (min-width: @breakpoint-xlarge) {
                        flex-direction: row;
                        justify-content: space-between;
                        .trustpilot-widget {
                            margin-top: 8px;
                        }
                    }
                }
            }

            #product-page-mobile-top-button {
                margin: 0 auto;
                width: 235px;
                margin-bottom: 20px;

                button {
                    font-size: 21px;
                    line-height: 25px;
                    padding: 4px 36px 6px 36px;
                }
            }

            #product-page-body {

                p {
                    line-height: 1.5em;
                    margin-bottom: 1.2em;
                    font-size: 14px;

                    b {
                        font-size: 14px;
                        font-weight: bold;
                    }
                }

                .product-description {
                    float: left;
                    width: 100%;
                    margin: 0 0 0.2em 0;
                    font-size: 26px;
                    line-height: 1em;
                    color: @c-blue;
                }

                @media screen and (max-width: (@breakpoint-large - 1)) {
                    summary {
                        width: 100%;

                        #product-page-summary {
                            font-size: 16px;
                            margin-bottom: 1.5em;
                        }
                    }
                }
            }
        }

        #further-reading {
            width: 100%;

            h4 {
                color: @color-blue-primary;
                font-size: 24px;
                margin: 0 0 0.1em;
            }

            ul {
                margin-left: 26px;

                li {
                    color: @color-blue-primary;
                    line-height: 23px;

                    a {
                        font-size: 14px;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    #product-page-side {
        padding: 0;

        @media screen and (min-width: @breakpoint-large) {
            float: right;
            height: 100%;
        }

        #product-page-side-inner {
            width: auto;
            padding: 15px;

            @media screen and (min-width: @breakpoint-large) {
                padding: 25px;
                width: 345px;
            }

            @media screen and (min-width: @breakpoint-grid-large) {
                width: 445px;
            }

            .zoom {
                display: inline-block;
                position: relative;
                cursor: pointer;

                &.zoomed {
                    cursor: crosshair;
                }

                &:after {
                    content: '';
                    display: block;
                    width: 33px;
                    height: 33px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: url(../../img/icon.png);
                }

                img {
                    display: block;

                    &::selection {
                        background-color: transparent;
                    }

                }
            }

            #product-page-image-frame {
                padding: 0;
                border: 1px solid @color-border-dark;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                -o-border-radius: 2px;
                border-radius: 2px;
            }

            #product-configuration {
                margin-top: 8px;
                margin-bottom: 8px;

                form {
                    margin: 0;
                }

                h4 {
                    color: @color-blue-primary;
                    font-size: 24px;
                    margin: 0 0 10px 0;
                }

                .select2-container {
                    width: 100%;
                    min-width: 180px;
                }

                .config-wrap {
                    overflow: hidden;
                    margin-bottom: 8px;

                    .config-step {
                        float: left;
                        background: #F6F6F6;
                        height: 57px;
                        width: 46px;
                        position: relative;
                        padding-top: 11px;
                        display: none;

                        span {
                            margin-left: 18px;
                            line-height: 47px;
                            font-size: 27px;
                            .neuron;
                        }
                    }

                    .config-block {
                        float: left;

                        label {
                            margin: 0;
                            .neuron;
                            font-size: 17px;
                            line-height: 12px;
                            width: 100%;
                        }

                        input {
                            margin-top: 10px;
                            width: 40px;
                            display: block;
                            text-align: center;
                        }

                        select {
                            max-width: 100%;
                            width: auto;
                            display: block;
                            margin-top: 10px;
                        }
                    }
                }

                #config-price-wrap {
                    height: 46px;

                    .config-step {
                        height: 46px;
                        padding: 0;
                    }

                    .config-block {
                        height: 46px;
                        width: 200px;

                        p {
                            font-size: 17px;
                            line-height: 30px;
                            float: left;
                        }

                        span {
                            text-align: right;
                        }
                    }

                    .giveaway {
                        color:#ba2023;
                        font-size:12px;
                    }
                }

                #config-add-to-cart-wrap {
                    height: 46px;

                    .config-step {
                        height: 46px;
                        padding: 0;
                    }

                    .config-block {
                        height: 46px;
                        padding-top: 4px;
                        text-align: center;

                        button {
                            font-size: 20px;
                            width: 172px;
                            height: 38px;
                            padding: 0;

                            span {
                                font-size: 15px;
                                padding-left: 0;
                                left: 4px;
                            }
                        }
                    }
                }

                p.volume-discounts {
                  clear: both;
                  .neuron();
                  font-size: 15px;
                  font-style: italic;
                }
            }

            #product-volumediscounts {
                #product-volumediscounts-header {
                    margin: 0;
                }

                table {
                    margin-top: 5px;
                }

                table th,
                table td {
                    font-size: 13px;
                }
            }

            div.choice-help {
                box-sizing: border-box;
                border-radius: 4px;
                background: #e9e9e9;
                padding: 5px 15px 15px 15px;

                h3 {
                    font-size: 18px;
                }
            }

            #product-usps {
                padding: 0 10px;
                margin-bottom: 8px;
                .box;

                h3 {
                    margin: 10px 0 5px;
                    font-size: 18px;
                    .neuron;
                    font-weight: 100;
                }

                ul {
                    list-style-type: none;
                    margin-left: 3px;

                    li {
                        position: relative;
                        line-height: 20px;
                        font-size: 14px;
                        padding-left: 20px;
                        margin-bottom: 8px;

                        span {
                            position: absolute;
                            left: 0;
                            top: 4px;
                            margin-right: 5px;
                            font-size: 14px;
                            color: @c-green1;

                        }
                    }
                }
            }

            #product-kiyoh-widget {
                margin-bottom: 8px;
            }
        }
    }
}


.price-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .price--discounted {
        display: none;
    }

    &.price-wrapper--discounted {
        .price--discounted {
            color: @c-red;
            display: block;
            margin-right: 0;
        }

        .price--original {
            position: relative;
            font-size: 26px;
            margin-left: 5px;
        }

        .price--original::after{
            content:'';
            display:block;
            position:absolute;
            top:50%;
            left:0;
            right:0;
            height:2px;
            background:#808080;
            -webkit-transform:translateY(-50%);
            -ms-transform:translateY(-50%);
            transform:translateY(-50%)
        }
    }

    .price {
        white-space: nowrap;
        font-size: 33px;
        margin: 0 0 11px 0;
        color:#808080;
        .neuron;

        @media screen and (max-width: (@breakpoint-medium - 1)) {
            font-size: 26px;
        }

        sup {
            top:-.4em;
            font-size:60%;
        }

        @media screen and (max-width: (@breakpoint-medium - 1)) {
            span.eurosign {
                display: none;
            }
        }
    }

    @media screen and (min-width: @breakpoint-medium) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 0 11px;
        -webkit-box-align: center;

        &.price-wrapper--discounted {
            .price--discounted {
                order: 1;
                -webkit-box-ordinal-group: 2;
                margin: 2px 24px 0;
                font-size: 26px;
                color: #f48413;
                align-self: flex-start;
            }

            .price--original {
                font-size: 33px;
                color: inherit;
                margin: 0;
                -webkit-box-ordinal-group: 1;
                order: 0;

                &:after {
                    content: '';
                    position: absolute;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3Cline x1='5%25' y1='10%25' x2='95%25' y2='90%25' stroke='%23f48413' stroke-width='1.5'/%3E%3C/svg%3E");
                    display: block;
                    top: -2px;
                    left: -2px;
                    right: -4px;
                    bottom: -2px;
                    height: auto;
                    background-color: transparent;
                    -webkit-transform: none;
                    -ms-transform: none;
                    transform: none;
                }
            }
        }

        .price-volume-discount {
            margin: 0 0 2px;
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
        }
    }
}

.price-volume-discount {
    background: @c-green1;
    color: white;
    font-weight: 100;
    font-size: 14px;
    display: none;
    position: relative;
    padding: 3px 8px;
    border-radius: 10px;
    margin-left: 5px;
}

.stock-reminder {
    .btn-reminder {
        display: block;
        margin-bottom: 2px;
    }

    p {
        margin: 0 auto 15px auto;
        width: 96%;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 12px !important;
        font-style: italic;

        @media screen and (max-width: (@breakpoint-large - 1)) {
            padding: 0;
            width: 100%;
        }

    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    .mobile-sticky.disable {
        transform: translate3d(0, 62px, 0);
        -webkit-transform: translate3d(0, 62px, 0);
        -ms-transform: translate3d(0, 62px, 0);
    }
}

html body #product-mobile-config {
    @media screen and (max-width: (@breakpoint-large - 1)) {
        padding-bottom: 15px;
        margin-bottom: 25px;
        min-height: 600px;

        #product-mobile-config #product-configuration #config-price-wrap p {
            float: left;
        }

        #product-usps {
            float: none;
        }

        #product-configuration .config-wrap-cart .btn.add-to-cart {
            width: 100%;
            position: inherit;
        }

        #config-add-to-cart-wrap {
            margin: 10px 0;
        }

        .btn-reminder {
            width: 100%;
            margin-top: 10px;
        }
    }

    div.choice-help {
        box-sizing: border-box;
        border-radius: 4px;
        background: #e9e9e9;
        padding: 5px 15px 15px 15px;

        h3 {
            font-size: 18px;
        }
    }

    #product-configuration {

        &:after {
            content: "";
            clear: both;
            display: table;
        }

        .config-wrap {
            &:nth-of-type(even) {
                .config-block input {
                    margin-top: 10px;
                    width: 140px;
                    display: block;
                    text-align: left;
                }

                #config-amount {
                    #select_color {
                        margin-top: 7px;
                        padding: 10px 8px;
                    }
                }
            }

            &:nth-of-type(odd) {
                .config-block {
                    input {
                        margin-top: 10px;
                        width: 240px;
                        display: block;
                        text-align: left;
                    }

                    select {
                        width: 253px;
                    }
                }
            }

            .config-block {
                margin-left: 0;
                padding: 0;
                width: 100%;

                select {
                    height: 41px;
                }

                input {
                    height: 30px;
                }
            }
        }

        #config-price-wrap {
            .config-block {
                .price-wrapper {
                    .price:first-of-type {
                        margin-left: 0;
                    }

                    p {
                        display: none !important;
                    }
                }
            }
        }

        p.volume-discounts {
          clear: both;
          .neuron();
          font-size: 12px;
          font-style: italic;
        }
    }


    #product-configuration .config-wrap-cart {
        width: 100% !important;
        height: unset !important;

        .btn.add-to-cart.btn-green {
            margin-bottom: 15px;
            padding: 14px 22px 14px 15px;
            background: @c-green1;
            border: none;
            transition: @transition-cubic;

            &:hover {
                background: @c-green-dark;

                span {
                    left: 10px;
                }
            }

            span {
                transition: @transition-cubic;
            }
        }
    }

    #product-configuration .config-wrap:nth-last-child(1) {
        width: 100% !important;
        padding: 0 !important;

        .config-block {
            margin-left: 0;
            padding: 0;
            width: 100%;
        }
    }

    #product-configuration .config-wrap .config-step {
        display: none;
    }

    .stock-wrapper {
        float: right !important;
        padding-top: 6px;

        .stock {
            font-size: 17px !important;
            line-height: 30px !important;
        }

        .stock-count {
            font-size: 17px !important;
            line-height: 30px !important;
        }
    }

    #product-configuration #config-price-wrap {
        padding: 0 !important;
        width: 100%;
        height: unset;
        float: left;
        margin: 12px 0;

        p {
            margin-bottom: 0;
            float: left;
        }

        #config-price {
            height: unset;
            width: 100%;
            margin-left: 0;
            padding: 0;
        }
    }

    @media screen and (max-width: (@breakpoint-large - 1)) {

        #product-page-side #product-page-side-inner {
            width: auto;
        }

        #product-configuration .config-wrap:nth-of-type(even) .config-block .color-choice {
            width: 15px;
            height: 15px;
            margin-top: 0;
        }

        #product-configuration .config-wrap:nth-of-type(odd) {
            .config-block select {
                width: 293px;
            }
        }

        #product-configuration .config-wrap:nth-of-type(even) #config-amount #select_color {
            margin-top: 0;
            left: 0;
            margin-left: 0;
            position: relative;
            width: 296px;
            padding: 10px 8px;
        }

        #product-configuration .config-wrap:nth-of-type(even) {
            .config-block #select_color span {
                float: left;
                font-size: 14px;
                margin-top: -1px;
                white-space: nowrap;
                text-align: left;
                width: unset;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .product-images .slick-slide img {
            max-height: 320px;
        }

        #product-configuration .config-wrap:nth-of-type(even) {
            .config-block select {
                width: 100%;
            }
        }

        #product-configuration .config-wrap:nth-of-type(odd) {
            .config-block input {
                width: 90%;
            }
        }
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        .product-availability {
            display: none;
        }

        #product-configuration h4 {
            display: none;
        }

        #product-page-content {
            margin: 10px 0 0;
            float: left;
            width: 100%;
        }

        #config-add-to-cart-wrap {
            margin: 0 0;
        }

        #product-configuration .config-wrap:nth-of-type(even) #config-amount #select_color {
            width: 99%;
        }

        #product-configuration .config-wrap:nth-of-type(odd) .config-block select {
            width: 95%;
            float: left;
        }

        #product-configuration .config-wrap .config-block {
            position: unset !important;
        }

        #product-page-main #product-page-main-inner {
            padding: 15px !important;
        }

        #product-page-main #product-page-main-inner {
            margin-top: 10px;
        }
    }

    @media screen and (max-width: (@breakpoint-small - 1)) {
        .stock-wrapper {
            float: left !important;
            width: 100%;
            padding-top: 2px;
        }

        .product-sub-images {
            display: none;
        }

        .show-500 {
            display: block !important;
        }
    }

    @media screen and (max-width: (@breakpoint-small - 1)) {
        #product-configuration .config-wrap:nth-of-type(odd) {
            width: 100%;
        }

        #product-configuration .config-wrap:nth-of-type(even) {
            width: 100%;
        }

        #product-configuration .config-wrap:nth-of-type(even) .config-block select {
            width: 98%;
        }

        #product-configuration .config-wrap:nth-of-type(odd) .config-block select {
            width: 98%;
        }

        #product-configuration .config-wrap:nth-of-type(odd) .config-block input {
            width: 95%;
        }

        #product-configuration .config-wrap:nth-of-type(even) .config-block input {
            width: 95%;
        }
    }

    @media screen and (max-width: (@breakpoint-small - 1)) {
        #product-configuration .config-wrap:nth-of-type(even) .config-block select {
            width: 99%;
        }

        #product-configuration .config-wrap:nth-of-type(odd) .config-block select {
            width: 99%;
        }
    }

    @media screen and (max-width: (@breakpoint-xsmall - 1)) {
        #product-configuration .config-wrap:nth-of-type(odd) .config-block input {
            width: 94%;
        }

        #product-configuration .config-wrap:nth-of-type(even) .config-block input {
            width: 94%;
        }
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    #product-page-body.product-page-body-scroll {
        padding-top: 100px;
    }
}

#product-page-body .product-images {
    background: @c-white;
}

#product-page-content.product-page--new .alert {
    float: left;
    margin-bottom: 15px;
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    #product-page-content .alert {
        width: ~"calc(100% - 50px)";
    }
}
