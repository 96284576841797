.thankyou {
    h2 {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 5px;
    }

    .text_wrap {
        width: 50%;
        line-height: 26px;
        font-size: 14px;
        position: relative;
        float: left;
        padding-bottom: 60px;
    }

    .bottom_box {
        width: 100%;
        float: left;
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }

    .account {
        width: 50%;
        float: left;
        text-align: center;

        .btn {
            background: #00A7E5;
            border: 0;
            color: @c-white;
            padding: 10px 20px;
            font-size: 16px;
            text-shadow: 0 0 0 rgba(0, 0, 0, 0.0);

            &:hover {
                background: #0494c9;
            }

            span {
                margin-left: 10px;
            }
        }

        .account_img {
            background: url(../../../img/account.png) no-repeat;
            width: 150px;
            height: 80px;
            position: relative;
            clear: both;
            left: 50%;
            margin: 30px 0 30px -75px;
        }

        h3 {
            width: 100%;
            float: left;
            text-align: center;
            font-weight: 400;
            margin: 0;
            font-size: 26px;
            margin-bottom: 20px;
        }

        p {
            line-height: 21px;
            padding: 0 30px;
            font-size: 14px;
            width: 100%;
            box-sizing: border-box;
        }
    }

    .facebook {
        width: 50%;
        background: url(../../../img/fb_like.png) no-repeat;
        background-size: 270px auto;
        float: left;
        padding-left: 130px;
        padding-top: 70px;
        box-sizing: border-box;
        padding-bottom: 25px;
        min-height: 150px;
    }

    .bedankt {
        position: absolute;
        width: 140px;
        height: 70px;
        right: 0;
        bottom: 0;
        background: url(../../../img/bedankt.png) no-repeat;
        background-size: 100% auto;
    }

    .video {
        width: 50%;
        padding: 10px;
        box-sizing: border-box;
        float: left;
        height: auto;
    }

    .process {
        width: 100%;
        background: #F8F8F8;
        float: left;
        padding: 40px 20px;
        margin-top: 20px;
        box-sizing: border-box;

        .left {
            width: 60%;

            &--fr {
                display: inline-block;
                width: 100%;
                margin-bottom: 40px;
            }

            h3 {
                width: 100%;
                float: left;
                text-align: center;
                font-weight: 400;
                margin: 0;
                font-size: 26px;
                margin-bottom: 20px;
            }

            .step {
                width: 33.33%;
                float: left;
                padding: 0 10px;
                box-sizing: border-box;
                text-align: center;
                font-size: 14px;

                p {
                    width: 100%;
                    float: left;
                    margin-top: 10px;
                }

                .icon {
                    width: 120px;
                    height: 120px;
                    left: 50%;
                    position: relative;
                    float: left;
                    margin-left: -60px;
                    margin-top: 20px;

                    &.step_1 {
                        background: url(../../../img/steps/step_1.png) no-repeat;
                        background-size: 100% auto;
                    }

                    &.step_2 {
                        background: url(../../../img/steps/step_2.png) no-repeat;
                        background-size: 100% auto;
                    }

                    &.step_3 {
                        background: url(../../../img/steps/step_3.png) no-repeat;
                        background-size: 100% auto;
                    }
                }
            }
        }

        .right {
            width: 40%;
            float: left;
        }
    }

    .clear {
        clear: both;
    }

    @media screen and (max-width: (@breakpoint-xlarge - 1)) {
        .text_wrap {
            p {
                display: none;
            }
        }

        .process {
            .left {
                width: 100%;
            }

            .right {
                display: none;
            }
        }

        .bottom_box {
            .right {
                width: 100%;
                float: left;
                padding-left: 0;

                iframe {
                    width: 300px;
                    float: left;
                    left: 50%;
                    position: relative;
                }
            }

            .left {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        .text_wrap {
            width: 100%;
            float: left;
            padding-bottom: 20px;

            .bedankt {
                display: none;
            }

            p {
                display: block;
            }
        }

        .video {
            width: 100%;
        }

        .process {
            .left .step {
                width: 100%;
                float: left;
            }
        }

        .bottom_box {
            .facebook {
                background: none;

                iframe {
                    left: 0;
                }
            }
        }
    }
}
