.bestel_page {
  p {
    width: 100%;
    float: left;
  }
}

.bestel_levering {
  width: 100%;
  float: left;
  padding: 20px 0;
  margin: 20px 0;
  box-sizing: border-box;

  img {
    float: right;
  }

  .content {
    width: 900px;
    float: left;

    h2 {
      margin: 0 0 10px 0;
      font-size: 24px;
    }
  }

  @media screen and (max-width: (@breakpoint-xlarge - 1)) {
    .content {
      width: 600px;
    }
  }

  @media screen and (max-width: (@breakpoint-large - 1)) {
    padding: 0;

    img {
      display: none;
    }

    .content {
      width: 100%;
      padding: 0;
      float: right;
    }
  }
}

.bestel_iconbox {
  width: 100%;
  float: left;
  padding: 40px;
  margin-bottom: 40px;
  box-sizing: border-box;
  background: #F9F9F9;

  .icon {
    width: 25%;
    float: left;
    text-align: center;
    padding: 0 20px;
    box-sizing: border-box;

    p {
      margin: 0;
    }

    img {
      margin-bottom: 20px;
      max-width: 100%;
    }
  }

  @media screen and (max-width: (@breakpoint-xlarge - 1)) {
    padding: 20px;

    .icon {
      padding: 0 10px;

      img {
        width: 50%;
      }
    }
  }

  @media screen and (max-width: (@breakpoint-large - 1)) {
    padding-bottom: 0;

    .icon {
      width: 50%;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: (@breakpoint-medium - 1)) {
    padding: 15px 5px;

    .icon {
      width: 100%;
    }
  }
}

.bestel_advies {
  width: 100%;
  float: left;
  padding: 40px;
  margin: 40px 0;
  box-sizing: border-box;
  background: #F9F9F9;

  img {
    float: left;
  }

  .content {
    width: 900px;
    float: left;
    padding-left: 20px;

    h3 {
      margin: 0 0 10px 0;
      font-size: 24px;
    }

    .btn-small {
      background: #00A7E5;
      color: @c-white  !important;
      font-size: 14px;
      padding: 5px 10px 5px 20px;
      font-weight: 300;
      margin-right: 10px;
      float: left;
      text-shadow: 0 0 0 rgba(0, 0, 0, 0.0);

      .youtube_icon {
        width: 20px;
        height: 15px;
        background: url('../../img/bestellen/youtube.png') no-repeat;
        float: right;
        margin-left: 10px;
        margin-top: 1px;
      }

      span {
        float: right;
        margin-left: 10px;
      }

      &:hover {
        background: #0695ca;
      }
    }
  }

  @media screen and (max-width: (@breakpoint-xlarge - 1)) {
    .content {
      width: 600px;
    }
  }

  @media screen and (max-width: (@breakpoint-large - 1)) {
    padding: 20px;
    margin-bottom: 0;

    img {
      width: 100px;
      left: 50%;
      margin-left: -50px;
      position: relative;
      margin-bottom: 20px;
    }

    .content {
      width: 100%;
      padding: 0;
      float: left;
    }
  }

  @media screen and (max-width: (@breakpoint-medium - 1)) {
    padding: 15px;

    .btn-small {
      width: 100%;
      margin-top: 5px;
      box-sizing: border-box;
      padding: 5px;
    }
  }
}
