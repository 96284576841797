#info .contact-usp {

    .contact-usp {
        &__title {
            margin: 0 0 10px;
            color: @c-blue;
            font-size: 17px;

            @media screen and (min-width: @breakpoint-medium) {
                font-size: 18px;
            }
        }

        &__content {
            display: inline-block;
            width: 100%;

            p {
                margin: 0;
                font-size: 14px;
                line-height: 20px;

                @media screen and (min-width: @breakpoint-medium) {
                    line-height: 24px;
                }
            }
        }

        &__item {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 20px;

            @media screen and (min-width: @breakpoint-medium) {
                margin-top: 10px;
            }

            &:nth-last-of-type(1) {
                margin-top: 10px;

                @media screen and (min-width: @breakpoint-medium) {
                    margin-top: 5px;
                }
            }

            &--phone {
                color: @c-blue;
            }

            &--whatsapp {
                color: @c-whatsapp;
            }
        }

        &__link {
            color: @color-text-primary;
            text-decoration: none;
            transition: @transition-cubic;
            font-size: 13px;
            line-height: 1em;
            margin-left: 5px;

            &:hover {
                text-decoration: underline;
            }
        }

        &__image-holder {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        &__image {
            max-height: 100px;
            border-radius: 5px;
            @media screen and (min-width: @breakpoint-medium) {
                max-height: 125px;
            }

            @media screen and (min-width: @breakpoint-grid-large) {
                max-height: 150px;
            }
        }
    }

    .open-live-chat {
        color: @c-blue;
        cursor: pointer;
        transition: @transition-cubic;

        &:hover {
            color: @c-blue2;
            text-decoration: underline;
        }
    }

}
