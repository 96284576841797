.information-wrapper {
    float: left;
    width: 100%;
}

.information-block {
    background-color: @c-white;
    position: relative;
    min-height: 100px;
    float: left;
    margin: 0 0 20px;
    box-shadow: 2px 2px 6px @c-gray-default;
    width: 100%;

    .blue {
        background-color: @c-blue;
        float: left;
        width: 20%;
        position: relative;
        min-height: 140px;

        p {
            font-size: 25px;
            text-align: center;
            color: @c-white;
            font-weight: 700;
            top: 50%;
            letter-spacing: 1px;
            position: absolute;
            transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
        }
    }

    .information-mid {
        width: 60%;
        float: left;

        .support {
            width: 33.333%;
            float: left;
            color: #00A2E0;
            min-height: 140px;
            font-size: 25px;

            .support-text {
                top: 50%;
                position: absolute;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                margin-left: 35px;

                img {
                    max-width: 23px;
                    height: auto;
                    margin-right: 10px;
                }

                a {
                    color: #00A2E0;
                }

                p.chat {
                    cursor: pointer;
                }
            }
        }

        .midden {
            width: 33.333%;
            float: left;
            text-align: center;

            .midden-tekst {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateY(-50%);
            }

            p:before {
                content: '';
                width: 2px;
                height: 40px;
                background: #979797;
                position: absolute;
                bottom: -50px;
                left: 50%;
                margin-left: -1px;
            }

            p:after {
                content: '';
                width: 2px;
                height: 40px;
                background: #979797;
                position: absolute;
                bottom: 30px;
                left: 50%;
                margin-left: -1px;
            }
        }

        .info-button {
            width: 33.333%;
            float: left;
            min-height: 140px;
            position: relative;

            .info-button-cta {
                background: #00A2E0;
                color: @c-white  !important;
                border-radius: 4px;
                padding: 10px 10px;
                border-bottom: 3px solid #005884;
                text-align: center;
                width: 100%;
                display: block;
                max-width: 165px;
                margin: 0 auto;
                position: absolute;
                transform: translateY(-50%) translateX(-50%);
                -ms-transform: translateY(-50%) translateX(-50%);
                -webkit-transform: translateY(-50%) translateX(-50%);
                top: 50%;
                left: 50%;
                cursor: pointer;
            }

            .info-button-cta:hover {
                background: #007eba;
            }
        }
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    .information-block .blue {
        width: 100%;
        float: left;
        min-height: 40px;

        p {
            margin-left: 7px;
        }
    }

    .information-block .support-image {
        display: block;

        img {
            right: 3px;
        }
    }

    .information-block .information-mid {
        width: 66%;
    }

    .information-block .information-mid .support .support-text {
        transform: translateY(-25%);
        -webkit-transform: translateY(-25%);
        -ms-transform: translateY(-25%);
        -o-transform: translateY(-25%);
    }

}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    .information-block .information-mid .midden {
        display: none;
    }

    .information-block .information-mid .info-button .info-button-cta {
        left: 107%;
        max-width: 150px;
    }

    .information-block .information-mid .info-button {
        width: 59%;
    }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    .information-block .support-image {
        display: none;
    }

    .information-block .information-mid .support {
        width: 50%;
    }

    .information-block .information-mid .info-button {
        width: 50%;
    }

    .information-block .information-mid {
        width: 100%;
    }

    .information-block .information-mid .info-button .info-button-cta {
        left: 0;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
    }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    .information-block .information-mid .info-button .info-button-cta {
        left: unset;
        right: 35px;
        margin: 0;
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .information-block .information-mid .support .support-text {
        margin-left: 17px;
    }

    .information-block .information-mid .info-button .info-button-cta {
        right: 17px;
    }
}

@media screen and (max-width: (@breakpoint-xxsmall - 1)) {
    body .information-block {
        background: url(../../../img/herman.webp) 100% 100% no-repeat;
        background-size: 43%;
        position: relative;
        min-height: 100px;
        float: left;
        margin: 0 0 20px;
        box-shadow: 2px 2px 6px @c-gray-default;
        width: 100%;
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .information-block .information-mid .support {
        width: 100%;
    }

    body .information-block {
        background: url(../../../img/herman.webp) 100% 100% no-repeat;
        background-size: 50%;
        position: relative;
        min-height: 100px;
        float: left;
        margin: 0 0 20px;
        box-shadow: 2px 2px 6px @c-gray-default;
        width: 100%;
    }

    .information-block .information-mid .info-button {
        width: 100%;
    }

    .information-block .blue {
        margin-bottom: 40px;
    }

    .information-block .information-mid .support .support-text {
        transform: translateY(0) translateX(0);
        -ms-transform: translateY(0) translateX(0);
        -webkit-transform: translateY(0) translateX(0);
        -o-transform: translateY(0) translateX(0);
    }

    .information-block .information-mid .support {
        min-height: 100px;
    }

    .information-block .information-mid .info-button {
        min-height: 85px;
    }

    .information-block .information-mid .support .support-text {
        position: relative;
        text-align: left;
        margin: 0 0 0 15px;
    }

    .information-block .information-mid .info-button .info-button-cta {
        position: relative;
        transform: translateY(0) translateX(0);
        -ms-transform: translateY(0) translateX(0);
        -webkit-transform: translateY(0) translateX(0);
        left: auto;
        right: auto;
        margin: 0 0 0 15px;
    }

    .information-block .information-mid .midden {
        display: block;
        visibility: visible;
        width: 50%;
    }

    .information-block .information-mid .midden .midden-tekst {
        position: relative;
        left: auto;
        right: auto;
        transform: translateY(0) translateX(0);
        -ms-transform: translateY(0) translateX(0);
        -webkit-transform: translateY(0) translateX(0);
    }

    .information-block .information-mid .midden p:before {
        transform: rotate(90deg);
        content: '';
        width: 2px;
        height: 40px;
        background: #979797;
        position: absolute;
        bottom: -13px;
        margin-left: -20px;
        left: 40%;
    }

    .information-block .information-mid .midden p:after {
        transform: rotate(90deg);
        content: '';
        width: 2px;
        height: 40px;
        background: #979797;
        position: absolute;
        bottom: -14px;
        margin-left: 20px;
        left: 60%;
    }
}

@media screen and (max-width: (@breakpoint-xlarge - 1)) {
    .information-wrapper {
        width: 670px;
    }
}


@media (max-width: (@breakpoint-large - 1)) {
    .information-wrapper {
        width: 676px;
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    .information-wrapper {
        width: 100%;
        padding: 0;
    }
}



@media screen and (max-width: (@breakpoint-xlarge - 1)) {
    .information-block .support-image img {
        right: 0;
    }
}

//
// Information wrapper

.information-wrapper {
    @media screen and (max-width: (@breakpoint-xlarge - 1)) {
        padding: 0 4px;
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        padding: 0;
    }

    .information-block {
        background-size: 45%;
        background-color: @c-white;
        margin-bottom: 10px;

        .blue {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;
            width: 65%;
            min-height: 35px;

            p {
                margin: 0;
                font-weight: 500;
                font-size: 14px;
            }
        }

        .information-mid {
            .support {
                font-size: 14px;
                min-height: 65px;
                position: relative;
            }

            .support-text {
                transform: none;

                p {
                    display: flex;
                    align-items: center;
                }

                img {
                    max-width: 16px;
                }
            }

            .midden {
                display: none;
            }

            .info-button {
                .info-button-cta {
                    left: 0;
                    font-size: 14px;
                }
            }
        }
    }

    @media screen and (min-width: 465px) {
        .information-block {
            .blue {
                min-height: 40px;
                margin-bottom: 0;
                width: 100%;

                p {
                    font-size: 16px;
                }
            }

            .information-mid {
                .support {
                    font-size: 18px;
                    min-height: 140px;
                }

                .support-text {
                    transform: translateY(-50%);

                    img {
                        max-width: 18px;
                    }
                }

                .info-button {
                    .info-button-cta {
                        left: 50%;
                        top: 50%;
                        right: unset;
                        transform: translate(-50%, -50%);
                        font-size: 15px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 601px) {
        .information-block {
            .information-mid {
                width: 100%;

                .support {
                    width: 41%;
                }
            }
        }
    }

    @media screen and (min-width: 651px) {
        .information-block {
            .blue {
                p {
                    font-size: 18px;
                }
            }

            .information-mid {
                display: flex;
                width: 100%;

                >div {
                    flex: 1 1 auto;
                }

                .support {
                    width: 30%;
                    padding-left: 25px;
                }

                .support-text {
                    margin-left: 0;
                }

                .midden {
                    display: block;
                    visibility: visible;
                    width: 25%;
                    position: relative;
                }

                .midden-text {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    &::before {
                        top: 30px;
                        transform: translateX(-50%);
                    }

                    &::after {
                        bottom: 30px;
                        transform: translateX(-50%);
                    }
                }

                .info-button {
                    width: 30%;
                    float: left;
                    min-height: 140px;
                    position: relative;
                    padding-right: 20px;
                }

                .info-button-cta {
                    left: 0;
                    transform: translateY(-50%);
                }
            }
        }
    }

    @media screen and (min-width: 661px) {
        .information-block {
            .blue {
                p {
                    font-size: 20px;
                }
            }

            .information-mid {
                .support {
                    font-size: 22px;
                }

                .support-text {
                    img {
                        max-width: 22px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1310px) {
        .information-block {
            .blue {
                min-height: 140px;
                width: 20%;

                p {
                    padding: 5px;
                }
            }

            .information-mid {
                width: 60%;
            }
        }
    }
}
