#general-page {
  #general-page-inner {
    float: left;
    margin: 0 4px;
    padding: 25px;

    .box;
    overflow: hidden; // Prevent collapse

    p {
      line-height: 1.5em;
      margin-bottom: 1.2em;
      font-size: 14px;

      b {
        font-size: 14px;
        font-weight: bold;
      }
    }

    #general-page-head {
      margin-bottom: 20px;

      h1 {
        margin: 0 0 0.2em 0;
        line-height: 1em;
        font-size: 24px;
        color: @color-blue-primary;
      }

      p {
        font-size: 13px;
        font-family: "Trebuchet MS";
        line-height: 1.4em;
        margin: 0;
      }
    }
  }
}