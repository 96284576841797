// Modal popup
.modal {

    // Modal
    &.container {
        width: 678px;
        margin-left: -340px;
    }

    form {
        margin: 0;
    }

    .modal-header {
        h3 {
            color: @color-blue-primary;
            line-height: 1.2em;
        }
    }

    .modal-body {
        padding: 15px 15px 15px 15px;

        p {
            margin-bottom: 20px;
        }

        form.modal-form {
            .control-group {
                label {
                    text-align: left;
                    width: 100px;
                    font-size: 16px;
                }

                .controls {
                    margin: 0;
                }

                select {
                    width: auto;
                }

                input {
                    width: 40px;
                }

                span.price {
                    padding-top: 5px;
                    display: block;
                    font-weight: bold;
                }

                span.stock {
                    padding-top: 5px;
                    display: block;
                    color: @c-green1;
                    font-weight: bold;
                }

                span.stock-description {
                    padding-top: 5px;
                    padding-left: 5px;
                    display: block;
                }
            }
        }
    }

    .modal-footer {
        padding: 0 15px 15px;

        .btn {
            margin-top: 14px;
        }

        button {
            .neuron;
            font-size: 18px;
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }
}

.modal.with-access {
    margin-bottom: 50px;

    .clear {
        clear: both;
    }

    h4 {
        margin: 0;
        color: #00A2E0;
        font-size: 16px;
    }

    .modal-body {
        padding-bottom: 0;
    }

    .modal-footer {
        padding-top: 5px;
        padding-bottom: 10px;

        .btn {
            margin-top: 5px;

            &.btn-dismiss {
                float: left;
            }

            &.btn-green {
                float: left;
            }

            .polycon-arrow-right {
                font-size: 12px;
                top: 0;
                margin-left: 5px;
            }
        }

        button {
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }

    .modal-header,
    .modal-body,
    .modal-footer {
        width: 100%;
        float: left;
        box-sizing: border-box;
    }

    .modal-product {
        width: 50%;
        padding: 10px 0;
        float: left;
        box-sizing: border-box;

        .image {
            width: 35%;
            float: left;

            img {
                width: 100%;
                height: auto;
            }
        }

        .info {
            width: 65%;
            float: left;
            box-sizing: border-box;
            padding-left: 15px;

            .price {
                position: block;
                margin-top: 4px;
                font-size: 14px;
            }
        }
    }

    .modal-cart {
        width: 49%;
        padding: 10px 0;
        box-sizing: border-box;
        float: left;
        border-left: 1px solid #eee;
        padding-left: 20px;

        .tip {
            width: 100%;
            margin-top: 10px;
            padding: 5px;
            border-radius: 5px;
            background: #e8f2f6;
            border: 1px solid #c1dce8;
            color: #7da9bc;
            font-size: 13px;
            line-height: 21px;
            box-sizing: border-box;
            display: block;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 14px;

            li {
                width: 100%;
                display: block;
                margin-top: 3px;

                .price {
                    float: right;

                    &.free {
                        font-weight: bold;
                        color: #00A2E0;
                    }
                }
            }
        }
    }

    .product-options {
        width: 100%;
        float: left;
        position: relative;

        h4 {
            font-size: 18px;
            width: 100%;
            text-align: center;
            margin: 20px 0;
            padding: 0;
        }

        .arrows {
            width: 150px;
            height: 75px;
            background: url('../../../img/arrows.png') no-repeat;
            background-size: 100% auto;
            position: absolute;
            left: 50%;
            top: 50px;
            z-index: 100;
            margin-left: -75px;
        }

        .line {
            height: 150px;
            width: 100%;
            float: left;
            z-index: 0;
            position: absolute;
            top: 100px;
        }

        .products {
            width: 100%;
            float: left;

            .product {
                width: 42%;
                padding: 15px;
                margin: 0 4%;
                height: auto;
                margin-bottom: 20px;
                min-height: 0;
                padding-bottom: 70px;
                float: left;
                box-sizing: border-box;

                .image {
                    width: 100%;
                    float: left;
                    padding: 0 20px;
                    position: relative;
                    box-sizing: border-box;

                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }

                .price {
                    width: 70px;
                    height: 70px;
                    border-radius: 70px;
                    color: @c-white;
                    font-size: 18px;
                    background: #5CB7F4;
                    text-align: center;
                    line-height: 70px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }

                .name {
                    font-size: 16px;
                    width: 100%;
                    float: left;
                    margin: 10px 0 5px 0;
                    color: #00A2E0;
                }

                a {
                    width: 100%;
                    float: left;
                }

                p {
                    line-height: 21px;
                    width: 100%;
                    float: left;
                    font-size: 14px;
                }

                .product-meta {
                    margin-bottom: 0;

                    span {
                        margin-top: 5px;
                        display: inline-block;
                        float: none;
                    }
                }
            }
        }

    }

    @media screen and (max-width: (@breakpoint-small - 1)) {
        .modal-product {
            width: 100%;
        }

        .modal-cart {
            display: none;
        }

        .modal-footer {
            padding-bottom: 5px;

            .btn {
                margin-top: 5px;
                width: 100%;
            }
        }

        .product-options {
            .arrows {
                display: none;
            }

            .products {
                .product {
                    width: 88%;
                    margin: 0 0 20px 6%;
                    height: auto !important;

                    .image img {
                        max-width: 70%;
                    }

                    &.last {
                        display: none;
                    }
                }
            }
        }
    }
}
