.product-calculator {
    display: inline-block;
    width: 100%;
    margin-top: 15px;

    &.product-calculator {
        &--open {

            .product-calculator {
                &__top {
                    padding-bottom: 20px;
                }

                &__icon {
                    &--arrow {
                        transform: rotateZ(-90deg);
                    }
                }

                &__form {
                    &.form {
                        max-height: unset;
                    }
                }
            }

        }
    }

    .product-calculator {
        &__inner {
            display: block;
            padding: 15px 10px;
            background-color: @c-blue-medium;

            @media screen and (min-width: @breakpoint-xsmall) {
                padding: 15px 20px;
            }

            @media screen and (min-width: @breakpoint-small) {
                padding: 25px 20px;
            }
        }

        &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media screen and (min-width: @breakpoint-xsmall) {
                padding-bottom: 20px;
            }
        }

        &__title {
            display: inline-block;
            margin: 0;
            padding: 0;
            color: @c-white;
            position: relative;
            font-size: 16px;
            font-weight: bold;
            transition: all .15s ease-in;

            @media screen and (min-width: @breakpoint-xsmall) {
                font-size: 22px;
            }
        }

        &__icon-holder {
            margin-right: 10px;
        }

        &__icon {
            &--calculator {
                width: 18px;
                height: 20px;

                @media screen and (min-width: @breakpoint-xsmall) {
                    width: 22px;
                    height: 30px;
                }
            }
        }

        &__form {

            &.form {
                margin-bottom: 0;

                @media screen and (max-width: (@breakpoint-xsmall - 1)) {
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height .3s ease-in-out;
                }

                .form {
                    &__fields {
                        @media screen and (min-width: @breakpoint-medium) and (max-width: (@breakpoint-large - 1)) {
                            display: flex;
                        }

                        @media screen and (min-width: @breakpoint-xxlarge) {
                            display: flex;
                        }
                    }

                    &__column {
                        @media screen and (min-width: @breakpoint-medium) {
                            width: 100%;
                        }

                        @media screen and (min-width: @breakpoint-medium) and (max-width: (@breakpoint-large - 1)) {
                            flex: 1 1 auto;
                        }

                        @media screen and (min-width: @breakpoint-large) {
                            width: 100%;
                        }

                        +.form__column {
                            display: inline-block;
                            width: 100%;
                            margin-top: 25px;

                            @media screen and (min-width: @breakpoint-medium) {
                                margin-top: 0;
                                padding-left: 15px;
                            }

                            @media screen and (min-width: @breakpoint-large) {
                                padding-left: 0;
                                margin-top: 25px;
                            }

                            @media screen and (min-width: @breakpoint-xxlarge) {
                                margin-top: 0;
                                padding-left: 15px;
                            }
                        }
                    }

                    &__field {
                      #calculator_product-advice {
                        color: @c-white;
                      }

                        transition: all .15s ease-in;

                      &--product-advice {
                        display: none;
                        //float: none;
                        //width: 100%;
                      }

                        @media screen and (min-width: @breakpoint-xsmall) and (max-width: (@breakpoint-xxlarge - 1)) {

                            &--length,
                            &--width {
                                width: ~"calc(50% - 7.5px)";
                                float: left;
                            }

                            &--width {
                                margin-left: 15px;
                            }

                            &--thickness {
                                float: left;
                                width: 100%;
                            }
                        }
                    }

                    &__label {
                        color: @c-white;
                        font-size: 13px;

                        @media screen and (min-width: @breakpoint-xsmall) {
                            font-size: 14px;
                        }
                    }

                    &__input {
                        width: ~"calc(100% - 14px)";
                        margin-bottom: 15px;
                    }

                    &__buttons {
                        margin-top: 15px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;

                        @media screen and (min-width: @breakpoint-xsmall) {
                            flex-wrap: nowrap;
                        }
                    }


                    &__button-holder {
                        flex: 1 1 auto;
                        width: 100%;

                        +.form__button-holder {
                            margin-top: 10px;

                            @media screen and (min-width: @breakpoint-xsmall) {
                                margin-top: 0;
                                padding-left: 15px;
                            }
                        }
                    }

                    &__button {
                        border: none;
                        border-bottom: 3px solid @c-blue-darker;
                        font-size: 15px;
                        cursor: pointer;
                        display: block;
                        border-radius: 4px;
                        text-align: center;
                        padding: 10px;
                        line-height: inherit;
                        transition: all .15s ease-in;

                        &--primary {
                            color: @c-white;
                            background-color: @c-blue;
                            width: inherit;

                            &:hover,
                            &:focus {
                                background-color: @c-blue-dark;
                            }
                        }

                        &--secondary {
                            color: @c-white !important;
                            background-color: @c-grey-dark;
                            width: initial;

                            &:hover,
                            &:focus {
                                background-color: @c-grey-darker;
                            }
                        }
                    }
                }
            }
        }
    }

    &__scroll-to {
        display: inline-block;
        width: 100%;
        font-size: 14px;
        margin-bottom: 10px;

        @media screen and (min-width: @breakpoint-small) {
            font-size: 15px;
        }

        .product-calculator {
            &__icon-holder {
                margin-right: 10px;
            }

            &__icon {
                &--calculator {
                    width: 18px;
                    height: 24px;
                }

                &--information {
                    display: inline-block;
                    font-size: 13px;
                    width: 17px;
                    line-height: 17px;
                    color: @c-white;
                    background-color: @color-blue-primary;
                    text-align: center;
                    border-radius: 50%;
                    position: relative;
                    top: -1px;
                    cursor: help;
                }
            }

            &__button {
                border: none;
                border-bottom: 3px solid @c-blue-darker;
                font-size: 14px;
                cursor: pointer;
                border-radius: 4px;
                text-align: center;
                padding: 10px;
                line-height: inherit;
                transition: all .15s ease-in;
                color: @c-white  !important;
                background-color: @c-blue;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (min-width: @breakpoint-xsmall) {
                    font-size: 15px;
                }

                &:hover,
                &:focus {
                    background-color: @c-blue-dark;
                }
            }
        }
    }

    &__icon {
        &--calculator {
            float: left;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &--arrow {
            transform: rotateZ(90deg);
            transition: all .15s ease-in-out;
            width: 14px;

            @media screen and (min-width: @breakpoint-xsmall) {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 1009px) {
    #product-calculator__desktop {
        display: none;
    }
}

#product-calculator__mobile {
    display: none;
}
