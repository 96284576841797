.btn.choice-help {
  background: #00a2e0;
  color: white;
  border-radius: 4px;
  padding: .7em .7em;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  cursor: pointer;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: 'Neuron', Helvetica, sans-serif;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  font-size: 18px;
  margin-top:20px;
  width: 100%;

  span {
    margin-right: 5px;
  }

  &:hover {
    background: #007FBA;
  }
}

.choice-help-mobile {
  float: left;
  width: 100%;
  background: #ededed;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 auto 10px auto;

  .intro_summary__choice {
    display: flex;
    gap: 10px;
    align-items: center;

    .btn.choice-help {
      margin-top: 0;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
  }

  @media (min-width: @breakpoint-medium) {
      display: none;
  }
}
