#search-results {

    .search-results {
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 15px;
            background: @color-border-light;

            @media screen and (min-width: @breakpoint-small) {
                padding: 10px 25px;
            }
        }

        &__title {
            display: inline-block;
            width: 100%;
            .neuron();
        }

        &__close {
            font-size: 18px;
            color: @color-text-primary;
            cursor: pointer;
        }

        &__list {
            display: inline-block;
            width: 100%;
            list-style: none;
            margin: 0;
            padding: 10px 0;

            @media screen and (min-width: @breakpoint-small) {
                padding: 15px 0;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            padding: 0 15px;

            @media screen and (min-width: @breakpoint-small) {
                padding: 0 25px;
            }

            &:not(:nth-last-of-type(1)) {
                margin-bottom: 5px;

                @media screen and (min-width: @breakpoint-small) {
                    margin-bottom: 10px;
                }

                .search-results__link {
                    border-bottom: 1px solid @color-border-dark;
                }
            }
        }

        &__link {
            display: flex;
            width: 100%;
            padding-bottom: 5px;
            text-decoration: none;

            @media screen and (min-width: @breakpoint-small) {
                padding-bottom: 10px;
            }
        }

        &__image-holder {
            flex: 0 0 20%;
            margin-top: 5px;

            @media screen and (min-width: @breakpoint-small) {
                margin-top: 0;
                flex: 0 0 15%;
            }

            @media screen and (min-width: @breakpoint-medium) {
                flex: 0 0 20%;
            }

            @media screen and (min-width: @breakpoint-large) {
                flex: 0 0 11%;
            }

            @media screen and (min-width: @breakpoint-xlarge) {
                flex: 0 0 24%;
            }

            @media screen and (min-width: @breakpoint-xlarge) {
                flex: 0 0 20%;
            }
        }

        &__image {
            float: left;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }

        &__content {
            flex: 0 0 80%;
            display: flex;
            flex-direction: column;
            width: 50%;
            padding-left: 10px;

            @media screen and (min-width: @breakpoint-small) {
                flex: 0 0 85%;
            }

            @media screen and (min-width: @breakpoint-medium) {
                flex: 0 0 80%;
            }

            @media screen and (min-width: @breakpoint-large) {
                flex: 0 0 89%;
            }

            @media screen and (min-width: @breakpoint-xlarge) {
                flex: 0 0 80%;
            }

            @media screen and (min-width: @breakpoint-xlarge) {
                flex: 0 0 76%;
            }

            @media screen and (min-width: @breakpoint-grid-large) {
                flex: 0 0 80%;
            }
        }

        &__name {
            display: block;
            width: 100%;
            font-size: 16px;
            margin: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            @media screen and (min-width: @breakpoint-small) {
                font-size: 18px;
            }
        }

        &__description {
            display: inline-block;
            width: 100%;
            color: @color-text-primary;
            font-size: 12px;
            line-height: 18px;

            @media screen and (min-width: @breakpoint-small) {
                font-size: 13px;
                line-height: 20px;
            }
        }
    }

}