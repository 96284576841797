#checkout {
    #alert-container {
        margin-top: 10px;
    }

    .content-checkout {
        float: left;
        width: 100%;
    }

    #sidebar-checkout-usps {
        display: none;
    }

    .deliver_date {
        width: 100%;
        margin-top: 10px;
        padding: 8px;
        background: #dbe9f4;
        box-sizing: border-box;
        border-radius: 4px;
        color: #1e5076;
    }

    #checkout-back-link {
        .obviousLink;
        text-decoration: none;
        float: left;
        line-height: 30px;

        &:hover {
            text-decoration: underline;
        }
    }

    .checkout-bar {
        margin-bottom: 8px;
        min-height: 40px;
        .box;

        h3 {
            display: inline-block;
            font-size: 20px;
            line-height: 1em;
            margin: 0 0 0 14px;
            padding: 10px 0;
        }
    }

    .checkout-option-bar {
        margin-bottom: 8px;
        height: 40px;
        .box;
        .gradient(#FCFCFC, #E4E4E4, #FCFCFC);

        h3 {
            display: inline-block;
            font-size: 20px;
            margin: 0;
            line-height: 1em;
        }

        label {
            margin: 0;
            padding: 10px 0;
        }

        input {
            margin: 4px 14px 0 14px;
            float: left;
        }

    }

    label {
        -webkit-user-select: none;
        user-select: none;
    }

    #address-street-group,
    #address-place-group {
        display: none;
    }

    input[readonly]:focus {
        border-color: rgba(82, 168, 236, 0.8);
        border-color: @color-border-dark;
        outline: none;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }

    .form-horizontal .control-label {
        width: 124px;
    }

    .required,
    .alt-required {
        position: relative;
    }

    .form-horizontal .controls {
        margin-left: 150px;

        h4 {
            font-size: 20px;
            color: @color-blue-primary;
            margin: 4px 0 7px 0;
        }

        input[type="text"],
        input[type="password"],
        input[type="email"] {
            margin-bottom: 5px;
        }
    }

    .control-group.inline-group {
        margin-bottom: 10px;
    }

    .checkout-form-wrapper {
        position: relative;

        .checkout-form-heading {
            font-size: 20px;
            margin: 0;
            color: @color-blue-primary;
            margin: 5px 0 10px 0;
            display: inline-block;
        }

        .control-group.error {
            .control-label {
                color: @color-text-primary;
            }

            .help-inline,
            .checkbox,
            .radio,
            .input,
            .select,
            .textarea {
                color: @c-red;
            }

            input {
                border-color: @c-red;
                color: @color-text-primary;

                &[required] {
                    -webkit-box-shadow: 0 0 6px #f8b9b7;
                    -moz-box-shadow: 0 0 6px #f8b9b7;
                    box-shadow: 0 0 6px #f8b9b7;
                }

                &:focus {
                    border-color: rgba(82, 168, 236, 0.8);
                    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
                    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
                }

                &:not(:focus) {
                    background-color: @c-red-light  !important;
                }

                &[type="radio"] {
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                }
            }
        }

        .help-inline {
            display: block;
            margin-bottom: 5px;
            padding-left: 0;
            font-size: 13px;

            &.error {
                color: @c-red;
            }
        }
    }

    #checkout-content {

        #checkout-steps {
            border: 1px solid @color-border-dark;
            height: 36px;
            border-radius: 4px;
            .gradient(@co-step1, @co-step2);
            margin-bottom: 8px;
            white-space: nowrap;
            overflow: hidden;

            a {
                cursor: default;
                .neuron;
                text-decoration: none;
                outline: none;
                display: inline-block;
                margin-right: -5px;
                font-size: 18px;
                line-height: 36px;
                color: @color-text-primary;
                padding: 0 23px 0 39px;
                background: @co-step1;
                background: -webkit-linear-gradient(@co-step1, @co-step2);
                background: -moz-linear-gradient(@co-step1, @co-step2);
                background: -o-linear-gradient(@co-step1, @co-step2);
                background: -ms-linear-gradient(@co-step1, @co-step2);
                background: linear-gradient(@co-step1, @co-step2);
                position: relative;

                &:first-child {
                    padding-left: 34px;
                    border-radius: 3px 0 0 3px;
                }

                &:first-child:before {
                    left: 15px;
                }

                &:last-child {
                    border-radius: 0 3px 3px 0;
                    padding-left: 41px;
                    width: 50%;
                }

                &.step-completed {
                    cursor: pointer;
                    color: @c-white;
                    background: @co-step3;
                    background: -webkit-linear-gradient(@co-step3, @co-step4);
                    background: -moz-linear-gradient(@co-step3, @co-step4);
                    background: -o-linear-gradient(@co-step3, @co-step4);
                    background: -ms-linear-gradient(@co-step3, @co-step4);
                    background: linear-gradient(@co-step3, @co-step4);
                }

                &.step-completed:after {
                    background: @co-step3;
                    background: -webkit-linear-gradient(135deg, @co-step4, @co-step3);
                    background: -moz-linear-gradient(135deg, @co-step3, @co-step4);
                    background: -o-linear-gradient(135deg, @co-step3, @co-step4);
                    background: -ms-linear-gradient(135deg, @co-step3, @co-step4);
                    background: linear-gradient(135deg, @co-step3, @co-step4);
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: -18px;
                    width: 36px;
                    height: 36px;
                    -webkit-transform: scale(0.707) rotate(45deg);
                    -moz-transform: scale(0.707) rotate(45deg);
                    -o-transform: scale(0.707) rotate(45deg);
                    -ms-transform: scale(0.707) rotate(45deg);
                    transform: scale(0.707) rotate(45deg);
                    z-index: 1;
                    background: @co-step5;
                    background: -webkit-linear-gradient(135deg, @co-step6, @co-step5);
                    background: -moz-linear-gradient(135deg, @co-step5, @co-step6);
                    background: -o-linear-gradient(135deg, @co-step5, @co-step6);
                    background: -ms-linear-gradient(135deg, @co-step5, @co-step6);
                    background: linear-gradient(135deg, @co-step5, @co-step6);
                    box-shadow: 1px -1px 0 1px rgba(0, 0, 0, 0.2),
                        3px -3px 0 1px rgba(255, 255, 255, 0.1);
                    border-radius: 0 11px 0 50px;
                }

                &:last-child:after {
                    content: none;
                }

                &:before {
                    border-radius: 100%;
                    padding-left: 6px;
                    width: 15px;
                    height: 20px;
                    line-height: 20px;
                    margin: 9px 0;
                    position: absolute;
                    top: 0;
                    left: 21px;
                    background: @co-step7;
                    .gradient(@co-step7, @co-step8);
                    font-weight: bold;
                }

                &.step-disabled-click {
                    cursor: default;
                }
            }
        }

        #checkout-main {
            font-size: 12px;
            .box;

            #checkout-main-inner {
                .padh1;
                padding-top: 19px;
                padding-bottom: 14px;
            }

            .checkout-mobile-step-wrapper {
                background: @color-border-light;
                .neuron;
                font-size: 24px;
                padding: 11px 19px;

                &.top-mobile-steps {
                    border-bottom: 1px solid @color-border-dark;
                    color: darken(@color-border-dark, 15%);
                }

                &.bottom-mobile-steps {
                    border-top: 1px solid @color-border-dark;
                    color: darken(@color-border-dark, 10%);
                }

                &.checkout-mobile-current-step {
                    color: @color-text-primary;
                }

                .checkout-mobile-step-number {
                    display: inline-block;
                    background: @color-background-page;
                    border: 1px solid @color-border-dark;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    margin-right: 12px;
                    -webkit-border-radius: 20px;
                    -moz-border-radius: 20px;
                    -o-border-radius: 20px;
                    border-radius: 20px;

                    &.mobile-step-completed {
                        background: @c-green1;
                        color: @color-background-page;
                    }
                }

                .checkout-mobile-step-name {
                    display: inline-block;
                }
            }

            form {
                margin-bottom: 5px;
            }

            #checkout-button {
                &.checkout-button-top {
                    margin-bottom: 20px;
                }

                .clearfix;
                min-height: 34px;
                margin-bottom: 8px;

                a.btn,
                button {
                    width: 100%;
                }

                .next-checkout-step, .btn-final-checkout {
                    width: 275px !important;
                    float: right;
                }
            }

            #empty-cart {
                display: none;

                h3 {
                    font-size: 20px;
                    text-align: center;
                    margin: 30px 0;
                }
            }

            h1 {
                color: #999;
                text-align: center;
                font-size: 28px;
                line-height: 1em;
                margin: 8px 0 14px 0;
            }

            #checkout-head {
                font-weight: bold;
                margin: 8px 0;
                padding-bottom: 10px;
                border-bottom: 1px solid @color-border-light;
                height: 15px;

                #co-head-cart {
                    width: 52%;
                    float: left;
                }

                #co-head-amount {
                    width: 11%;
                    float: left;
                }

                #co-head-price {
                    width: 11%;
                    float: left;
                }

                #co-head-subtotal {
                    width: 13%;
                    float: left;
                }

                #co-head-remove {
                    width: 13%;
                    float: left;
                    text-align: right;
                }
            }

            .checkout-item {
                min-height: 90px;
                margin: 10px 0;
                overflow: hidden;

                .select_color {
                    position: relative;
                    top: 1px;
                    cursor: pointer;
                    color: #00A2E0;
                    font-size: 15px !important;
                    line-height: 17px !important;
                }

                .product-value-color {
                    position: relative;
                    top: 1px;
                }

                .select-color:hover {
                    cursor: pointer;
                    color: #00699c;
                }

                .co-product-image {
                    width: 15%;
                    float: left;
                }

                .co-product-description {
                    margin-left: 5px;
                    width: 37%;
                    float: left;

                    h5,
                    a {
                        margin: 0 0 2px 0;
                        color: @color-blue-primary;
                        line-height: 18px;
                        .neuron;
                        font-size: 17px;
                        font-weight: 100;
                    }

                    a:hover {
                        color: #000;
                    }

                    p {
                        margin: 0;
                        line-height: 17px;
                        font-size: 12px;

                        &.co-product-availability {
                            display: inline-block;
                        }
                    }

                    span {
                        font-weight: bold;
                    }

                    .send-separate {
                        margin: 5px 0 5px 0;

                        input {
                            margin: 5px;
                        }

                        .send-separate-explanation {
                            color: #000000;
                            font-size: 14px;

                            i.icon-question-sign {
                                color: @c-blue;
                            }
                        }
                    }

                    .co-stock-status {
                        display: block;
                        display: inline-block;
                        display: inline;

                        &.in-stock {
                            color: @c-green1;
                        }

                        &.out-of-stock {
                            color: @c-red;
                        }
                    }

                    .co-product-color {
                        height: 16px;
                        width: 16px;
                        display: inline-block;
                        position: relative;
                        top: 4px;
                    }

                    .toggle {
                        position: relative;
                    }
                }

                .co-product-amount {
                    width: 11%;
                    float: left;
                    margin-top: 14px;

                    input {
                        margin-left: 3px;
                        width: 20px;
                        text-align: center;
                    }
                }

                .co-product-price {
                    margin-top: 20px;
                    width: 11%;
                    float: left;
                }

                .co-product-subtotal {
                    margin-top: 20px;
                    width: 13%;
                    float: left;
                    font-weight: bold;
                }

                .co-product-remove {
                    margin-top: 14px;
                    width: 12%;
                    float: left;
                    text-align: right;

                    button {
                        margin-right: 24px;
                        font-family: Arial;
                        text-align: center;
                        font-size: 22px;
                        color: #AAA;
                        padding: 1px 4px 0 4px;
                        border: 2px solid @color-border-dark;
                    }
                }

                &.free-product {
                    min-height: 70px;
                    background: #f4f9fb !important;

                    .co-product-subtotal {
                        float: right;
                        color: #70BF05;
                    }

                    @media screen and (max-width: (@breakpoint-medium - 1)) {
                        display: none;
                    }
                }
            }

            #checkout-sub {
                .clearfix;
                margin-bottom: 10px;

                #checkout-sub-wrapper {
                    width: 386px;
                    float: right;
                }

                .checkout-sub-green {
                    font-weight: bold;
                    color: @c-green1;
                }

                .checkout-sub-line {
                    border-bottom: 1px solid @color-border-light;
                    height: 32px;
                    margin: 13px 0;
                    font-size: 15px;
                    font-weight: normal;

                    .checkout-sub-shipping-amount {
                        text-align: right;
                        float: right;
                    }

                    .checkout-sub-shipping-delivery {
                        text-align: right;
                        float: right;
                        font-weight: bold;
                        color: #70BF05;
                    }

                    .checkout-sub-amount {
                        text-align: right;
                        float: right;
                        font-weight: bold;
                    }
                    .checkout-sub-amount.discount {
                        color: #5bb75b;
                    }
                }

                .checkout-first-sub-line {
                    margin-top: 4px;
                    border-bottom: 0;
                    height: 18px;
                }

                .checkout-second-sub-line {
                    border-bottom: 0;
                    height: 18px;
                }

                .checkout-last-sub-line {
                    border-top: 1px solid @color-border-light;
                    padding-top: 10px;
                }

                .checkout-sub-shipping {
                    margin-left: 0;
                    text-align: right;
                    display: inline-block;
                }

                .checkout-sub-shipping-comment {
                    margin-left: 0;
                    text-align: right;
                    display: inline-block;
                    font-size: 12px;
                    color: #56ADE5;
                }

                .checkout-sub-total {
                    margin-left: 0;
                    text-align: right;
                    display: inline-block;
                    font-weight: bold;
                }

                .checkout-sub-button {
                    margin-top: 20px;
                }

                #coupon-code-wrapper {
                    float: left;

                    span.help-inline {
                        padding: 0;
                        display: block;
                        margin-top: 5px;
                    }

                    label {
                        display: inline-block;
                        font-size: 15px;
                        font-weight: normal;
                    }

                    input {
                        display: inline-block;
                        height: 20px;
                        margin: 0;
                        width: 249px;
                        margin-right: 10px;

                    }

                    button {
                        display: inline-block;
                    }
                }
            }

            #mobile-only-section {

                // Most of the style declarations are in the grid.less file
                #mobile-usps {
                    margin-top: 20px;
                    padding: 10px !important;
                    .box;
                    .padh1;
                    padding-top: 17px;
                    padding-bottom: 17px;
                    margin-bottom: 20px;

                    h3 {
                        font-size: 22px;
                        margin: 0 0 .4em 0;
                        color: @color-blue-primary;
                    }

                    .usp {
                        line-height: 1.4em;
                        width: 100%;
                        font-size: 14px;
                        float: left;
                        margin-bottom: 5px;
                    }

                    span {
                        font-size: 19px;
                        margin-right: 5px;
                        margin-top: 2px;
                        float: left;
                        color: #5FC719;
                    }

                    a {
                        margin-top: 1px;
                        float: left;
                    }

                    ul {
                        list-style: none;
                        margin: 0;

                        li {
                            font-size: 16px;
                            line-height: 1em;
                            margin-bottom: 0.4em;

                            @media screen and (max-width: (@breakpoint-medium - 1)) {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    #sidebar-checkout-usps {
        padding: 0 10px;
        margin-top: 10px;

        .box;
        font-size: 12px;

        .menu-icon {
            font-size: 19px;
            margin: 3px 3px 0 0;
            color: #5FC719;
        }

        h3 {
            margin: 10px 0 5px;
            font-size: 18px;
            .neuron;
            font-weight: 100;
        }

        ul {
            list-style-type: none;
            margin-left: 3px;

            li {
                position: relative;
                line-height: 20px;
                font-size: 14px;
                padding-left: 20px;
                margin-bottom: 8px;

                span {
                    position: absolute;
                    left: 0;
                    top: 4px;
                    margin-right: 5px;
                    font-size: 14px;
                    color: @c-green1;

                }
            }
        }
    }

    #sidebar-checkout-payment-shipping-methods {
        .box;
        margin-top: 8px;
        padding: 15px 3px;

        img {
            display: block;
            margin: 0 auto;
        }
    }

    #sidebar-checkout-kiyoh {
        .box;
        margin-top: 8px;

        iframe {
            margin: 4px 0 0 3px;
        }
    }
}

.selectCountry {
    display: inline-block;
    font-size: 14px;
    text-decoration: underline;
    color: #00A2E0;
    position: relative;
    top: 3px;
    cursor: pointer;
}

.CountryChoice {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    padding: 100px 0;
    box-sizing: border-box;
    display: none;
    overflow-y: scroll;
    /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;

    .CountryModal {
        position: relative;
        width: 700px;
        margin: auto;
        background: @c-white;
        border-radius: 4px;
        padding: 0;
        float: left;
        left: 50%;
        margin-left: -300px;
        overflow: hidden;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

        h4 {
            .neuron();
            font-size: 18px;
            margin: -25px 0 10px 0;
            padding: 0 20px;
            display: block;
            float: left;
            font-weight: bold;
            width: 100%;
            box-sizing: border-box;
        }

        .close {
            transform: rotate(45deg);
            font-size: 26px;
            margin: 10px 15px;
            opacity: 0.3;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }

        .option {
            width: 33.33%;
            border-top: 1px solid #eee;
            padding: 15px 20px;
            font-size: 16px;
            cursor: pointer;
            box-sizing: border-box;
            float: left;

            span {
                font-size: 14px;
                float: right;
            }

            &:hover,
            &.active {
                background: #f0f7fa;
            }
        }
    }

    @media screen and (max-width: (@breakpoint-small - 1)) {
        padding: 0;

        .CountryModal {
            width: 96vw;
            height: 96vh;
            margin: 2vh 2vw;
            left: 0;
            overflow-y: scroll;
            /* has to be scroll, not auto */
            -webkit-overflow-scrolling: touch;

            .option {
                width: 50%;
            }
        }
    }

    @media screen and (max-width: (@breakpoint-xsmall - 1)) {
        .CountryModal {
            .option {
                width: 100%;
            }
        }
    }
}

#checkout {

    .form-search .hide,
    .form-inline .hide,
    .form-horizontal .hide {
        display: none !important;
    }

    .form-horizontal {
        * {
            box-sizing: border-box;
        }

        .input-mini {
            width: 100px;
        }

        .control-holder {
            &--password {
                @media screen and (min-width: @breakpoint-medium) {
                    display: flex;
                }
            }
        }

        #create-acction {
            @media screen and (min-width: @breakpoint-medium) {
                flex: 1 1 60%;
                margin-right: 40px;
            }

            .control-label {
                @media screen and (min-width: @breakpoint-medium) {
                    flex: 0 0 32.2%;
                }

                @media screen and (min-width: @breakpoint-large) {
                    flex: 0 0 32.25%;
                }

                @media screen and (min-width: @breakpoint-xlarge) {
                    flex: 0 0 27.848%;
                }
            }
        }

        .control-group {
            display: flex;
            margin-bottom: 5px;
            flex-wrap: wrap;

            @media screen and (min-width: @breakpoint-medium) {
                flex-wrap: nowrap;
            }

            .control-label {
                width: 124px;
                margin-right: 15px;
                padding-top: 8px;

                &.control-label--empty {
                    padding-top: 0;
                    margin-bottom: 0;
                    display: none;

                    @media screen and (min-width: @breakpoint-medium) {
                        display: inline;
                    }
                }
            }

            >.control-label {
                font-size: 12px;
                flex: 1 1 100%;
                width: auto;
                font-weight: bold;
                margin-bottom: 0;
                padding-top: 0;

                @media screen and (min-width: @breakpoint-medium) {
                    font-weight: normal;
                    font-size: 14px;
                    padding-top: 8px;
                    flex: 0 0 30%;
                }

                @media screen and (min-width: @breakpoint-large) {
                    flex: 0 0 20%;
                    margin-bottom: 5px;
                }
            }

            >.controls {
                flex: 1 1 100%;
                width: auto;
                margin-left: 0;

                @media screen and (min-width: @breakpoint-medium) {
                    flex: 1 1 70%;
                }

                @media screen and (min-width: @breakpoint-large) {
                    flex: 1 1 66.66%;
                }
            }

            &.inline-group {
                input[type="text"]+input[type="text"] {
                    @media screen and (min-width: @breakpoint-large) {
                        margin-left: 5px;
                    }
                }
            }
        }

        .controls {
            margin-left: 42px;
        }

        .radio-options {
            margin-bottom: 0;

            @media screen and (min-width: @breakpoint-medium) {
                padding-top: 8px;
            }
        }

        .autocomplete-status-text {
            min-height: 17px;
        }

        .postal-code-error {
            display: inline-block;
            max-width: 500px;
            font-size: 13px;
        }

        .postal-code-success {
            font-size: 13px;

            &__additional-text {
                display: inline-block;
                white-space: nowrap;
                color: #999;
                margin-right: 5px;
                font-size: 14px;
            }

            &__link {
                overflow: hidden;
                background-color: transparent;
                cursor: pointer;
                background: transparent;
                border: 0;
                padding: 0;
                color: @color-blue-primary;
                line-height: initial;
                vertical-align: unset;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }

        select,
        textarea,
        input[type="text"],
        input[type="password"],
        input[type="email"],
        .uneditable-input {
            height: 40px;
            padding: 8px 15px 8px 10px;
            border-radius: 2px;
            transition: background-color .2s ease-in-out;
            box-shadow: none;

            &:hover {
                border-color: #888 !important;
            }

            &:focus {
                border-color: @color-blue-primary  !important;
                box-shadow: unset !important;
            }
        }

        #order-type-business-section,
        #alternative-delivery {
            background: #eee;
            padding: 15px 15px 10px;
            margin-bottom: 15px;

            @media screen and (min-width: @breakpoint-large) {
                padding: 15px 0 10px;
            }
        }

        .form__radio {
            display: flex;
            flex-flow: row wrap;

            &--inline {
                display: inline-flex;
            }

            .form__radio-input {
                &:checked {
                    +.form__radio-label {
                        .form__radio-radio {
                            background-color: @c-white;

                            &::after {
                                background-color: @c-blue;
                            }
                        }
                    }
                }
            }

            .form__radio-label {
                cursor: pointer;
                display: flex;
                padding: 2px 8px 2px 25px;
                position: relative;
                transition: opacity .3s ease-out, color .3s ease-out;
                width: 100%;
                margin: 0;
                align-items: center;
                top: 0;

                @media screen and (min-width: @breakpoint-small) {
                    padding: 2px 15px 2px 25px;
                }

                &:hover,
                &:focus {
                    .form__radio-radio {
                        &::after {
                            background-color: @color-border-dark;
                        }
                    }
                }
            }

            .form__radio-radio {
                position: absolute;
                top: 50%;
                left: 0;
                display: inline-block;
                height: 18px;
                width: 18px;
                background-color: @c-white;
                border: 1px solid @color-border-dark;
                border-radius: 50%;
                transform: translateY(-50%);
                transition: border .1s ease-out, background-color .1s ease-out, opacity .1s ease-out;
                box-sizing: border-box;

                &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    display: block;
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    background: transparent;
                    transform: translate(-50%, -50%);
                    transition: background-color .1s ease-out;
                }
            }

            .form__radio-content {
                width: 100%;
                font-size: 12px;
                text-align: center;

                @media screen and (min-width: @breakpoint-small) {
                    font-size: 13px;
                }
            }
        }
    }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    #checkout {
        width: 100% !important;

        .fluidfull,
        .alwaysfull {
            width: 100%;
            padding: 0;
        }
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    #checkout {
        .product-color-change-cart {
            display: inline-block;
            display: inline;
        }

        .form-horizontal {

            input,
            select,
            textarea {
                width: 100%;
            }

            input[type="checkbox"] {
                width: 30px;
            }

            .input-mini {
                width: ~'calc(47% - 2px)';
            }
        }
    }

    #checkout #checkout-content #checkout-main #checkout-main-inner {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    #checkout {
        .btn-checkout {
            margin: 0 auto;
            display: block;
            float: none !important;
        }

        #checkout-back-link {
            display: block;
            float: none;
            margin-bottom: 15px;
            line-height: 15px;
        }

        .terug-groot {
            display: none !important;
        }

        .terug-mobile {
            display: block;
        }
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    body.checkout-step-3 #checkout {
        .co-product-amount {
            text-align: center !important;
        }

        .co-product-price {
            text-align: center !important;
        }

        .co-product-subtotal {
            text-align: center !important;
        }
    }
}



#checkout {
    .accept-conditions {
        margin-left: 2px !important;
    }

    .terug-mobile {
        display: none;
    }

}



@media screen and (max-width: (@breakpoint-medium - 1)) {
    #checkout {
        .hide {
            display: none;
        }
    }
}

#checkout {
    .preferred-shipping-date {
        margin-top: 5px;
    }

    .select_color {
        display: inline-block;
        display: inline;
    }

    .product-color-change-cart {
        display: inline-block;
        display: inline;
    }

    .product-color-cart {
        display: inline-block;
        display: inline;
    }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    body.checkout-step-2 #checkout .checkout-shipping-payment-options .shipping-method-wrap .shipping-method-icon {
        margin: 4px 30px 10px 0 !important;
    }
}

#checkout #checkout-content.cart-second #checkout-main #checkout-sub #checkout-sub-wrapper.cart-total-box span {
    width: auto !important;
}

#checkout #checkout-content.cart-second #checkout-main .checkout-left .totals_items .left_coupn {
    width: 100%;
    position: relative;

    h3 {
        margin-bottom: 0;
        margin-left: 25px;
        cursor: pointer;

        &:hover {
            color: #00A2E0;
        }

        transition: all 0.25s ease-in-out;

        &.open {
            .open-coupon {
                transform: rotate(45deg);
                transition: all 0.25s ease-in-out;
                transform-origin: center;
            }
        }
    }

    .open-coupon {
        left: 0;
        top: -11px;
        font-size: 38px;
        width: auto !important;
        font-weight: 300;
        position: absolute;
    }

    .inner-coupon {
        width: 100%;
        float: left;
        display: none;
    }
}

.checkout-step-2 {

    .tab-content.pickup,
    .tab-content.send {
        width: 100%;
        float: left;
        margin-bottom: 20px;
        display: none;

        &.active {
            display: block;
        }
    }

    #checkout-button {
        width: 100%;
        display: inline-block;
    }

    .shipping-method-description {
        line-height: 19px !important;
        margin-bottom: 5px;
    }

    .tab-bar {
        width: 100%;
        border-radius: 5px 5px 0 0;
        border: 1px solid #CACACA;
        float: left;
        padding: 10px;
        box-sizing: border-box;

        .tab {
            width: 49%;
            border: 1px solid #CACACA;
            float: right;
            margin: 0;
            margin-left: 1%;
            padding: 10px;
            text-align: center;
            font-size: 14px;
            border-radius: 5px;
            cursor: pointer;
            box-sizing: border-box;
            transition: all 0.3s ease;

            &.active {
                font-weight: bold;
                border: 1px solid #00A2E0;
                background: #ecf5f8;
            }

            &.first {
                float: left;
                margin-right: 1%;
                margin-left: 0;
            }

            &:hover {
                box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
            }

            &.pickup .icon {
                width: 11px;
                height: 14px;
                position: relative;
                top: 2px;
                display: inline-block;
                background: url('../../../img/map-marker-alt-solid.svg') no-repeat;
                background-size: 100% auto;
            }

            &.send .icon {
                width: 19px;
                height: 14px;
                position: relative;
                top: 1px;
                display: inline-block;
                background: url('../../../img/truck-solid.svg') no-repeat;
                background-size: 100% auto;
            }
        }
    }

    .postnl-location {
        float: left;
        width: 100%;
        padding: 0;
        margin-bottom: 10px;
        box-sizing: border-box;

        label {
            font-weight: bold;
            font-size: 12px;
            margin-bottom: 0;
        }

        input {
            float: left;
        }

        .content {
            width: ~'calc(100% - 20px)';
            padding-left: 10px;
            float: left;
            box-sizing: border-box;
        }

        .open_times {
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }

            .icon {
                vertical-align: text-bottom;
            }
        }
    }

    .postnl-locations {
        width: 100%;
        float: left;
        display: none;
    }

    .shipping-method-wrap .postnl-locations {
        display: none;
    }

    .shipping-method-wrap.active .postnl-locations {
        display: block;
    }

    .pickup_information {
        position: fixed;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        z-index: 1050;
        display: none;
        overflow-y: scroll;
        /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;

        .inner {
            width: 500px;
            float: left;
            padding: 0;
            box-sizing: border-box;
            background: @c-white;
            position: relative;
            margin-top: 100px;
            margin-bottom: 100px;
            border-radius: 6px;
            left: 50%;
            box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
            margin-left: -250px;

            h3 {
                color: #00A2E0;
                line-height: 1.2em;
                .neuron();
                font-weight: normal;
                padding: 9px 15px;
                border-bottom: 1px solid #eee;
                width: 100%;
                float: left;
                font-size: 19px;
                box-sizing: border-box;
                margin-top: 0;
            }

            .adress,
            .times {
                width: 50%;
                float: left;
                padding: 0 20px 20px 20px;
                box-sizing: border-box;
                line-height: 19px;

                span.green {
                    color: #5FC719;
                }

                strong {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }

            .times {
                p {
                    margin-bottom: 0;

                    .day {
                        width: 80px;
                        font-weight: bold;
                        float: left;
                    }
                }
            }

            .close {
                right: 10px;
                top: 9px;
                font-size: 20px;
                font-weight: bold;
                line-height: 20px;
                color: #000000;
                text-shadow: 0 1px 0 @c-white;
                opacity: 0.2;
                cursor: pointer;
                position: absolute;

                &:hover {
                    opacity: 0.5;
                }
            }

            @media screen and (max-width: (@breakpoint-small - 1)) {
                width: 100%;
                left: 0;
                margin: 0;
                border-radius: 0;
                min-height: 100vh;

                .adress,
                .times {
                    width: 100%;
                }

                h3 {
                    padding-right: 30px;
                }
            }
        }
    }

    @media screen and (max-width: (@breakpoint-small - 1)) {
        #checkout .checkout-shipping-payment-options .shipping-method-wrap {
            .shipping-method-name-price {
                float: left;
                margin-bottom: 10px;
                margin-left: -20px;
                width: auto;
            }

            .shipping-method-name {
                font-size: 13px !important;
                line-height: 19px;
            }

            .shipping-method-name-price p {
                font-size: 11px;
            }
        }
    }
}

#checkout #checkout-content #checkout-main .checkout-item .co-product-amount {
    position: relative;

    .amount-control {
        position: absolute;
        width: 20px;
        height: 20px;
        background: darken(#eee, 5);
        border-radius: 20px;
        top: 6px;
        text-align: center;
        line-height: 18px;
        font-size: 16px;
        font-weight: bold;
        user-select: none;
        cursor: pointer;

        &:hover {
            background: darken(#eee, 15);
            color: @c-white;
        }

        &.down {
            left: -25px;
        }

        &.up {
            right: 20px;
        }
    }

    @media screen and (max-width: (@breakpoint-xlarge - 1)) {
        .amount-control {
            width: 15px;
            height: 15px;
            line-height: 13px;
            font-size: 14px;

            &.down {
                left: -15px;
            }

            &.up {
                right: 7px;
            }
        }

        input {
            width: 15px;
        }
    }

    @media screen and (max-width: (@breakpoint-large - 1)) {
        .amount-control {
            &.down {
                &.left {
                    left: -17px;
                }
            }

            &.up {
                right: 20px;
            }
        }
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        .amount-control {
            &.up {
                right: -20px;
            }

            &.down {
                left: auto;
                right: 33px;
            }
        }

        label {
            float: left;
            margin-right: 3px;
            margin-top: 4px;
        }

        input {
            margin-left: 20px;
        }

    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    #checkout #checkout-content #checkout-main h1 {
        font-size: 21px;
    }

    #checkout #checkout-content #checkout-main .checkout-item .co-mobile-product-head {
        .co-product-image {
            width: 75px !important;
        }

        .co-product-description {
            padding: 0 5px 0 10px !important;
            margin-top: 0 !important;
        }
    }

    #checkout #checkout-content.cart-second #checkout-main .checkout-left .totals_items {
        margin-top: 5px;
        padding: 15px;
    }

    #checkout #checkout-content.cart-second #checkout-main #checkout-sub #checkout-sub-wrapper {
        margin-bottom: 10px;
    }

    #checkout #checkout-content #checkout-main #checkout-sub .tip-line {
        display: none;
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .shipping-method-wrap {
        select {
            width: 100%;
        }
    }
}

/* checkout mobile fix */
@media screen and (max-width: (@breakpoint-medium - 1)) {
    #checkout #checkout-content #checkout-main .checkout-item .co-product-price {
        margin: 20px 10px 20px 0 !important;
    }

    #checkout #checkout-content #checkout-main .checkout-item .co-product-amount {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    #checkout #checkout-content #checkout-main .checkout-item .co-product-subtotal {
        margin: 20px 10px 20px 0 !important;
    }

    body.checkout-step-3 #checkout {
        #checkout-content #checkout-main .checkout-overview-area .overview-block.overview-cart .checkout-item {
            .co-product-price-subtotal-wrap {
                width: 66.667%;
                float: left;
                padding: 0;
                box-sizing: border-box;
                margin: 5px 0 !important;
                font-size: 14px;
            }

            .co-product-amount {
                width: 33.3336%;
                float: left;
                font-size: 14px;
                margin: 5px 0 !important;
            }

            .co-product-subtotal,
            .co-product-price {
                width: 50%;
                float: left;
                box-sizing: border-box;
                padding: 0 10px;
                font-size: 14px;
                margin: 0 !important;
                right: 0;
                left: 0;
                text-align: right;
                position: relative;

                span {
                    font-size: 14px;
                    width: 100%;
                    float: left;
                }
            }

            .co-product-subtotal {
                margin-top: 10px;
            }

            .co-mobile-product-head .co-product-image {
                width: 60px;
                margin-top: 5px;
            }

            .co-product-description {
                width: 73%;
            }
        }
    }
}


@media screen and (max-width: (@breakpoint-medium - 1)) {
    .checkout {
        .polycon-arrow-right {
            display: inline !important;
        }
    }
}


#checkout .color {
    width: 11px;
    height: 11px;
    border-radius: 15px;
    display: inline-block;
    position: relative;
    top: 1px;
    background: @c-white;
    border: 1px solid #e8e8e8;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
    transform: translateY(2px);
    -ms-transform: translateY(2px);
    -o-transform: translateY(2px);
    -webkit-transform: translateY(2px);
    -webkit-o-transform: translateY(2px);

    &.no-color {
        background: @c-white;
    }

}

#checkout #checkout-content.cart-second #checkout-main {

    .checkout-left {
        width: 66%;
        float: left;
        padding: 20px 0 0 0;
        box-sizing: border-box;

        #checkout-head #co-head-cart {
            width: 64%;
        }

        .checkout-item {
            background: @c-white-dark;
            float: left;
            width: 100%;
            border: 0;
            margin-bottom: 0;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
            position: relative;

            &.last {
                margin-bottom: 10px;
            }

            .color-choice-cart {
                font-family: Tahoma, Geneva, sans-serif;
                position: relative !important;
                border: 1px solid @color-border-dark  !important;
                padding: 5px 14px 7px 10px;
                font-size: 17.5px;
                -webkit-border-radius: 6px;
                -moz-border-radius: 6px;
                border-radius: 6px;
                cursor: pointer;
                background-color: #f5f5f5;
                background-image: -moz-linear-gradient(top, @c-white, #e6e6e6) !important;
                background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@c-white), to(#e6e6e6)) !important;
                background-image: -webkit-linear-gradient(top, @c-white, #e6e6e6) !important;
                background-image: -o-linear-gradient(top, @c-white, #e6e6e6) !important;
                background-image: linear-gradient(to bottom, @c-white, #e6e6e6) !important;
                background-repeat: repeat-x;
                border-color: #e6e6e6 #e6e6e6 #bfbfbf;
                border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
                border-bottom-color: #b3b3b3;

                span {
                    font-family: Tahoma, Geneva, sans-serif;
                    font-weight: 400;
                    line-height: 23px;
                    color: #333333;
                    font-size: 14px;
                    margin-top: -1px;
                }

                @media screen and (max-width: (@breakpoint-medium - 1)) {
                    max-width: 200px;
                    margin-top: 4px;
                    margin-bottom: 0;
                }

                @media screen and (max-width: (@breakpoint-xsmall - 1)) {
                    min-width: 100%;
                }

                .color-cart {
                    margin-right: 10px;
                    transform: translateY(2px);
                    margin-top: 0;
                    border: 1px solid @c-white;
                    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
                }

                .color-cart-text {
                    color: #333333;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: Tahoma, Geneva, sans-serif;
                }

            }

            .color-choice-cart:hover {
                background-color: #e6e6e6;
            }

            .btn-checkout-cart {
                background: none;
            }

            .btn {
                position: absolute;
                border: 0;
                right: 5px;
                top: 5px;
                box-shadow: 0 0 0 rgba(0, 0, 0, 0.0);
                margin-right: 0;
                font-size: 24px;

                &:hover {
                    color: #d02727;
                }
            }

            .co-product-description {
                width: 48%;
                padding: 15px;
                box-sizing: border-box;
            }

            .co-product-image {
                border-right: 2px solid #00A2E0;
            }
        }

        .totals_items {
            width: 100%;
            float: left;
            background: @c-white-dark;
            padding: 20px;
            box-sizing: border-box;
            margin-top: 20px;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);

            .checkout-sub-button {
                width: 100%;

                #checkout-button {
                    width: 100%;
                }

                a {
                    width: 100%;
                    box-sizing: border-box;
                }
            }

            h3 {
                font-size: 21px;
                margin: 0 0 20px 0;
            }

            .right_totals {
                width: 50%;
                float: right;
            }

            .left_coupn {
                width: 50%;
                float: left;

                input,
                button {
                    float: left;
                }

                input {
                    margin-right: 4px;
                }
            }

            .checkout-sub-line-shipping,
            .checkout-sub-line-discount,
            .checkout-sub-line {
                width: 100%;
                border-bottom: 1px solid #E9E9E9;
                border-top: 1px solid #E9E9E9;
                float: left;
                padding: 15px 0;
                font-size: 14px;

                .checkout-sub-shipping,
                .checkout-sub-shipping-amount,
                .checkout-sub-amount {
                    float: right;
                    font-weight: bold;
                }

                .checkout-sub-total,
                .checkout-sub-shipping {
                    min-width: 65%;
                    text-align: right;
                }

                .checkout-sub-shipping-amount,
                .checkout-sub-amount {
                    font-weight: bold;
                    min-width: 35%;
                    text-align: right;
                    color: #56ADE5;
                }
            }

            .checkout-sub-line-discount {
                border-top: 0 solid #E9E9E9;
            }

            .checkout-sub-line {
                border: 0;
            }

            .checkout-sub-total {
                font-weight: bold;
                float: right;
                line-height: 24px;

                span {
                    font-weight: 300;
                    width: 100%;
                    float: left;
                    color: #969696;
                    font-size: 12px;
                }
            }

            .checkout-tip {
                font-size: 11px;
                width: 100%;
                float: right;
                text-align: right;
                margin-top: 5px;
            }
        }
    }

    #checkout-sub {
        width: 33%;
        float: left;
        padding: 20px 0 0 20px;
        box-sizing: border-box;
        margin-bottom: 10px;

        #personal_advice {
            width: 100%;
            float: left;
            padding: 10px;
            margin-bottom: 10px;
            background: @c-white;
            border: 1px solid #E9E9E9;
            box-sizing: border-box;
            line-height: 18px;
            position: relative;
            font-size: 13px;
            min-height: 130px;

            div.advice-person img {
                width: 125px;
                height: 125px;
                float: right;
            }

            h3 {
                margin: 0 0 10px 0;
                font-size: 20px;
                position: relative;
                z-index: 5;
            }

            span,
            a {
                font-weight: bold;
            }

            p {
                width: 75%;
                float: left;
                position: relative;
                z-index: 5;
            }

            .ordernr {
                font-size: 13px;
                opacity: 0.6;
                width: 75%;
                float: left;
                position: relative;
                z-index: 5;
            }

            img {
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 1;
            }

            @media screen and (max-width: (@breakpoint-medium - 1)) {
                img {
                    height: 80px !important;
                    width: auto !important;
                }

                p,
                .ordernr {
                    width: 85%;
                }
            }
        }

        .inner {
            width: 100%;
            float: left;
            box-sizing: border-box;
            padding: 0;
            background: @c-white-dark;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

            h3 {
                width: 100%;
                float: left;
                box-sizing: border-box;
                font-size: 18px;
                .neuron();
                padding: 10px 20px;
                margin: 0;
                background: #E9E9E9;
            }
        }

        .checkout-sub-shipping-comment {
            text-align: left;
            margin-bottom: 5px;
            margin-top: -6px;
        }

        .checkout-sub-line {
            font-size: 13px;
        }

        #ps_discount_cartdiscount_code {
            width: 56% !important;
            float: left;
            margin: 0;
        }

        #ps_discount_cartdiscount_save {
            width: 35%;
            float: right;
            margin: 0;
        }

        #checkout-sub-wrapper {
            width: 100% !important;
            float: left;
            box-sizing: border-box;
            background: @c-white-dark;
            padding: 0;

            &.padding {
                padding: 10px 20px;
            }
        }

        #coupon-code-wrapper {
            padding: 10px 20px 15px 20px;
            box-sizing: border-box;
            width: 100%;
            float: left;
            border-bottom: 1px solid #E9E9E9;
        }

        #checkout-button {
            width: 100%;
            box-sizing: border-box;
        }

        #checkout-button a {
            width: 100%;
            box-sizing: border-box;
        }

        .shipping-methods {
            width: 100%;
            float: left;

            .shipping-method-wrap {
                float: left;
                width: 100%;
                border: 1px solid @c-white-dark;
                padding: 10px 5px;
                box-sizing: border-box;
                border-bottom: 1px solid darken(@c-white-dark, 5);

                &.active {
                    background: #ecf5f8;
                    border-top: 1px solid @c-blue;
                    border-bottom: 1px solid @c-blue;
                }

                &:hover {
                    background: darken(@c-white-dark, 2);
                }

                label {
                    float: left;
                    width: 100%;
                    margin-bottom: 0;
                }

                input {
                    float: left;
                }

                .text_wrap {
                    width: 94%;
                    padding-left: 10px;
                    box-sizing: border-box;
                    float: left;

                    .flag {
                        width: 16px;
                        height: 9px;
                        float: left;
                        margin-top: 5px;
                        margin-right: 4px;

                        &.nl {
                            background: url(../../../img/bestellen/nl.png) no-repeat;
                            background-size: 100%;
                        }

                        &.be {
                            background: url(../../../img/bestellen/be.png) no-repeat;
                            background-size: 100%;
                        }
                    }

                    h4 {
                        margin: 0;
                        font-size: 16px;

                        span {
                            color: @color-text-secondary;
                            float: right !important;
                            margin-right: 5px !important;
                            width: auto !important;

                            &.free {
                                color: @c-blue;
                                float: right !important;
                                margin-right: 5px !important;
                                width: auto !important;
                            }
                        }
                    }

                    p {
                        font-size: 13px;
                        color: @color-text-secondary;
                        color: @c-green1;
                        font-weight: bold;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }


    @media screen and (max-width: (@breakpoint-xlarge - 1)) {
        #checkout-sub {
            width: 40%;
        }

        .checkout-left {
            width: 60%;
        }

        #usp-topbalk.bottom-cart #webshop-seal {
            width: 150px;
            margin-top: 5px;
        }

        .checkout-left .totals_items .left_coupn,
        .checkout-left .totals_items .right_totals {
            width: 100%;
        }

        .checkout-left .totals_items .right_totals {
            margin-top: 20px;

            .checkout-sub-line-shipping .checkout-sub-shipping-amount,
            .checkout-sub-line-shipping .checkout-sub-shipping,
            .checkout-tip {
                text-align: left;
            }

            .checkout-sub-total,
            .checkout-sub-amount {
                text-align: left !important;
            }
        }
    }

    @media screen and (max-width: (@breakpoint-large - 1)) {
        #checkout-sub {
            width: 100%;
            margin-bottom: 10px;
            padding: 20px 0 0 0;
        }

        #checkout-sub .shipping-methods .shipping-method-wrap {
            .text_wrap {
                width: 95%;
            }

            input {
                width: 5%;
            }
        }

        .checkout-left {
            width: 100%;
        }
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        #checkout-sub {
            margin-bottom: 0;
        }

        .mobile-checkout-button,
        .mobile-checkout-sub-bottom,
        #mobile-checkout-sub,
        #mobile-only-section,
        #mobile-checkout-sub,
        #mobile-only-section {
            width: 100%;
            float: left;
            box-sizing: border-box;
            background: none;
        }

        #mobile-checkout-sub {
            background: @c-white;
        }

        #checkout-sub .inner {
            background: none;
            box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        }

        .checkout-left {
            padding: 0;
            margin: 0;

            .checkout-item {
                box-shadow: 0 0 0 rgba(0, 0, 0, 0.0);
                margin: 0 0 5px 0;
                box-sizing: border-box;
                border: 1px solid @color-border-dark;

                .co-product-description {
                    width: 60%;
                    padding-top: 0;
                }
            }
        }

        #mobile-usps,
        #mobile-product-questions {
            width: 100%;
            float: left;
            box-sizing: border-box;
        }
    }

    @media screen and (max-width: (@breakpoint-xsmall - 1)) {
        .checkout-left .checkout-item {
            .co-product-description {
                padding: 0;
                margin-top: 0;
            }

            .co-product-image {
                width: 70px;
            }
        }
    }
}

.co-product-discount {
    display: block;
    text-align: right;
    font-size: 10px;
    color: #5bb75b;
}

.co-product-subtotal-price {
    display: block;
    margin-bottom: 5px;
    text-align: right;
}

.vat-icon {
    display: inline-block;
    padding-left: 10px;
}

.vat-icon.valid-vat-icon {
    color: green;
    padding-bottom: 5px;
    display: inline-block;
}

.invalid-vat-text {
    color: orange;
    padding-bottom: 5px;
    display: block;
}

.valid-vat-text {
    color: green;
    padding-bottom: 5px;
    display: block;
}

.unavailable-vat-text {
    padding-bottom: 5px;
    display: block;
}

.invalid-vat-icon {
    color: yellow;
}

#relatedproducts.in-cart {
    .grid {
        width:100%!important;
    }
    .product {
        min-height: 325px;
        height: 325px!important;
    }
    .product-description {
        display: none;
    }

    display:block;
    float: left;
    max-width: 100%;
    pointer-events: auto;
}

.checkout #desktop-footer {
    #mailcampaigns_form_element {
        display:none;
    }

    div.footer-links {
        display:none;
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        div.footer_bottom {
            display:none;
        }
    }
}
