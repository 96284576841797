html body #product-mobile-config #product-configuration .config-wrap {
    @media screen and (max-width: (@breakpoint-xsmall - 1)) {
        width: 100%;
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        margin-bottom: 10px;
    }

    &:nth-of-type(even) .config-block select {
        @media screen and (max-width: (@breakpoint-large - 1)) {
            width: 100%;
            margin-top: 4px;
        }
    }
}