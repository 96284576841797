.product-bulk {
  display: inline-block;
  width: 100%;
  margin: 20px 0 0;
  border-bottom: 1px solid @color-border-dark;
  border-top: 1px solid @color-border-dark;

  &__title {
    margin-bottom: 10px;
    font-size: 19px;
  }

  &__table-header & {
    &__table-item {
      font-size: 14px;
    }
  }

  &__table-body & {
    &__table-item {
      font-size: 13px;
    }
  }
}