#info {
    .information {

        .information {
            &__roadmap {
                float: left;
                width: 100%;
                background: @c-white;
                border: 1px solid @color-border-dark;
                box-sizing: border-box;
                margin-top: 20px;
            }
        }

    }
}

#info.information_searchbar.marginTop {
    margin-top: 20px;
}

#info .info {

    .info-categories {
        display: -ms-grid;
        display: grid;
        grid-template-columns:repeat(auto-fit, minmax(140px, 1fr));
        gap: 10px;
        margin-bottom: 45px;

        * {
            -webkit-transition: all .4s;
            -o-transition: all .4s;
            transition: all .4s;
        }

        @media screen and (max-width: 414px) {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                gap: 0;

            > * {
                margin-bottom: 10px;
            }

            > *:last-child {
                margin-bottom: 0;
            }
        }

        a.category {
            -webkit-box-shadow: rgba(0, 0, 0, .1) 0 4px 6px -1px, rgba(0, 0, 0, .06) 0 2px 4px -1px;
            box-shadow: rgba(0, 0, 0, .1) 0 4px 6px -1px, rgba(0, 0, 0, .06) 0 2px 4px -1px;
            text-decoration: none !important;
            background: #00a2e0
        }

        a.category:hover {
            -webkit-box-shadow: rgba(0, 0, 0, .1) 0 10px 15px -3px, rgba(0, 0, 0, .05) 0 4px 6px -2px;
            box-shadow: rgba(0, 0, 0, .1) 0 10px 15px -3px, rgba(0, 0, 0, .05) 0 4px 6px -2px;
            background: #007eba
        }

        .cat-title {
            color: #fff;
            text-align: center;
            padding: 10px;
            letter-spacing: 1px
        }

        @media screen and (max-width: 414px) {
            a.category {
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex
            }

            a.category img {
                width: 25%
            }

            .cat-title {
                text-align: left;
                font-size: .8em
            }

            .cat-title strong {
                font-size: 1.3em
            }
        }

        @media screen and (min-width: 768px) {
            .info__holder {
                margin-bottom: 20px !important
            }

            .info__categories .info__subtitle {
                margin-bottom: 15px !important
            }

            .info__title {
                margin: 0 0 15px !important
            }
        }
    }

    .info {
        &__categories {
            display: inline-block;
            width: 100%;

            #info-list-documents {
                display: flex;
                flex-wrap: wrap;
            }

            .info-details-wrapper {
                float: left;
                margin: 0 5px 10px 5px;
                width: 370px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
                margin-bottom: 10px;
                border: 1px solid @color-border-dark;
                border-radius: 3px;

                @media screen and (max-width: (@breakpoint-large - 1)) {
                    width: 100%;
                    height: auto;
                    margin: 0 0 10px 0;
                }

                .info-box-header {
                    margin-bottom: -1px;
                    height: 40px;
                    background-color: @color-background-page;
                    border: 0;
                    height: auto;
                    float: left;
                    width: 100%;
                    background: @c-blue;

                    h4,
                    a {
                        padding: 10px;
                        font-size: 20px;
                        margin: 0;
                        line-height: 20px;
                        text-decoration: none;
                    }

                    h4 {
                        padding: 10px 20px;

                        a {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            max-width: 100%;
                            white-space: nowrap;
                            display: block;
                            padding: 0;
                        }
                    }
                }

                .info-box-body {
                    padding: 10px;
                    min-height: 150px;
                    background-color: @color-background-page;
                    .clearfix;
                    width: 100%;
                    border-top: 0;
                    float: left;
                    box-sizing: border-box;
                    line-height: 21px;
                    min-height: 170px;
                    font-size: 14px;
                    color: #636363;
                    padding: 10px 20px 20px 20px;

                    @media screen and (max-width: (@breakpoint-xlarge - 1)) {
                        height: auto;
                        min-height: 10px;
                    }

                    h4 {
                        color: @color-blue-primary;
                        margin: 4px 0;
                        font-size: 18px;
                    }

                    address {
                        margin: 0;
                        font-size: 14px;
                    }

                    p {
                        font-size: 14px;
                    }

                    a.more {
                        float: left;
                        width: 100%;
                        margin-top: 10px;
                    }
                }

                .info-box-inner {
                    display: flex;
                    flex-direction: row;
                }

                .info-box-image-holder {
                    flex: 0 0 25%;
                    max-width: 90px;
                }

                .info-box-image {
                    float: left;
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .info-box-content {
                    margin-left: 10px;
                    font-size: 14px;
                    line-height: 20px;
                }

                h4 {
                    display: block;
                    padding: 10px 0;

                    a {
                        color: @c-white;
                        padding: 0 20px;
                    }
                }
            }

            #load-more-documents {
                color: #FFFFFF;
                width: 50%;
                margin: 0 auto;
                text-decoration: none;
                display: block;

                .loading {
                    border: none;
                    display: none;
                }

                .loading:after {
                    content: ' .';
                    animation: dots 2s steps(5, end) infinite;
                }

                @keyframes dots {
                    0%, 20% {
                        color: rgba(0,0,0,0);
                        text-shadow:
                                .25em 0 0 rgba(0,0,0,0),
                                .5em 0 0 rgba(0,0,0,0);}
                    40% {
                        color: white;
                        text-shadow:
                                .25em 0 0 rgba(0,0,0,0),
                                .5em 0 0 rgba(0,0,0,0);}
                    60% {
                        text-shadow:
                                .25em 0 0 white,
                                .5em 0 0 rgba(0,0,0,0);}
                    80%, 100% {
                        text-shadow:
                                .25em 0 0 white,
                                .5em 0 0 white;
                    }
                }
            }
        }

        &__question {
            display: inline-block;
            width: 100%;
            margin-top: 60px;

            h4 {
                display: inline-block;
                width: 100%;
                text-align: center;
                font-size: 28px;
                margin: 0 0 10px 0;
                font-weight: bold;
            }

            .info-question {
                display: inline-block;
                width: 560px;
                margin-left: -280px;
                left: 50%;
                position: relative;
                box-sizing: border-box;
                padding: 0 20px;
                height: 50px;
                font-size: 18px;
            }

            p.small {
                display: inline-block;
                width: 100%;
                text-align: center;
                font-size: 14px;

                a {
                    text-decoration: none !important;
                }
            }

            .usp_bar {
                display: inline-block;
                width: 100%;
                padding-left: 120px;
                padding-top: 15px;
                border-top: 1px solid @color-border-dark;
                box-sizing: border-box;
                margin-top: 15px;
                font-size: 14px;

                @media screen and (max-width: (@breakpoint-xlarge - 1)) {
                    padding-left: 70px;
                }

                @media screen and (max-width: (@breakpoint-large - 1)) {
                    display: none;
                }

                .mobile {
                    display: none;
                }

                .usp {
                    float: left;
                    margin-right: 25px;

                    .menu-icon {
                        color: @c-green;
                    }
                }

                @media screen and (max-width: (@breakpoint-xlarge - 1)) {
                    .no-tablet {
                        display: none;
                    }
                }
            }
        }
    }

}

#info .products_needed {
    width: 100%;
    float: left;
    background: @c-white;
    border: 1px solid #cacaca;
    padding: 19px;
    box-sizing: border-box;

    #info-description {
        width: 100%;
        margin-top: 0;
        margin-bottom: 20px;
        padding-bottom: 0;
        border-bottom: 1px solid @color-border-dark;
        float: left;

        p {
            float: left;
        }

        a.questionmark-tooltip {
            float: left;

            button {
                float: left;
                margin: 2px 0 0 5px;
            }
        }
    }

    .info-side-product {
        width: 33.33%;
        float: left;
        min-height: 150px;

        .product-meta {
            padding-left: 2px;
            display: inline-block;
            color: @color-text-secondary;
            font-size: .8em;
            display: block;
            position: relative;
            bottom: 0;

            span {
                width: 11px;
                height: 11px;
                margin: 2px 3px 0 0;
                border-radius: 10px;
                float: left;
                background: #42c91d;

                &.soon_available {
                    background: #f08513;
                }
            }
        }

        .btn.add-to-cart {
            font-size: 16px;
        }

        .product-price {
            font-size: 14px;
            margin: 5px 0;
        }

        .product-title {
            font-weight: bold;
            text-decoration: none;
        }

        a {
            text-decoration: none;
        }

        img {
            max-height: 100px;
            max-width: 100px;
            float: left;
            margin-right: 10px;
        }

        .info-side-product-right {
            float: left;
        }
    }
}

#info #info-side #info-side-products {
    .info-side-product {
        height: auto;
        float: left;
        width: 100%;
        box-sizing: border-box;

        img {
            width: 75px;
            height: auto;
            float: left;
            margin: 5px;
        }

        .info-side-product-right {
            width: 145px;
            position: relative;
            float: left;
        }

        .btn {
            margin-bottom: 10px;
        }

        .product-meta {
            padding-left: 2px;
            color: @color-text-secondary;
            font-size: .8em;
            width: 100%;
            float: left;
            position: relative;
            bottom: 0;

            span {
                width: 11px;
                height: 11px;
                margin: 1px 3px 0 0;
                border-radius: 10px;
                float: left;
                background: #42c91d;

                &.soon_available {
                    background: #f08513;
                }
            }
        }
    }
}

#info .quest {
    padding: 10px;
    background: @c-white;
    border: 1px solid @color-border-dark;
    float: left;
    box-sizing: border-box;
    margin-top: 15px;
    font-size: 14px;
    width: 100%;
    text-align: center;

    p a {
        text-decoration: none;
        font-weight: bold;
    }

    h4 {
        width: 100%;
        font-weight: bold;
        font-size: 21px;
        color: @color-blue-primary;
        margin: 10px 0 10px 0;
    }
}

@media screen and (max-width: (@breakpoint-xlarge - 1)) {
    #info .products_needed .info-side-product {
        width: 50%;
    }
}

#info .document_index {
    width: 100%;
    float: left;
    background: @c-white;
    border-radius: 3px;
    border: 1px solid @color-border-dark;
    padding: 10px;
    margin-top: -10px;
    margin-bottom: 20px;
    box-sizing: border-box;
}

#info .document_index h3 {
    font-size: 20px;
    margin: 0;
    line-height: 24px;
    padding: 0 0 10px 0;
    font-weight: normal;
}

#info .document_index h3 a {
    text-decoration: none;
    color: #333;
    line-height: 24px;
}

#info .document_index ul {
    width: 100%;
    float: left;
    list-style: none;
    padding: 0;
    margin: 0;
}

#info .document_index ul li {
    width: 100%;
    float: left;
    padding: 10px 0;
    background: url('../../img/arrow.png') -2px center no-repeat;
    background-size: 10px auto;
    padding-left: 15px;
    box-sizing: border-box;
    line-height: 16px;
    border-top: 1px solid #dadada;

    a {
        color: #333;
        text-decoration: none;
        font-size: 14px;
        line-height: 18px;

        &:hover {
            color: @color-blue-primary;
        }
    }
}

@media screen and (max-width: (@breakpoint-large - 1)) {
    #info .products_needed .info-side-product {
        width: 100%;
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    #info.information_searchbar #info-content {
        background: @c-white;
        padding: 10px;
        border: 1px solid @color-border-dark;
        margin-top: 10px;
    }

    #info .products_needed {
        display: none;
    }

    #info .information_searchbar.marginTop {
        display: none;
    }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    #info.information_searchbar #info-content .info-question {
        width: 100%;
        margin-left: 0;
        left: 0;
    }
}
