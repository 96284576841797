.product {
    overflow: hidden;
    min-height: 410px;
    position: relative;
    padding-top: 1px;
    .marb1;
    background: @c-white;
    padding-bottom: 15px;

    @media screen and (min-width: (@breakpoint-medium)) {
        border:1px solid #e9e9e9;
        border-radius:3px;
        padding-left:5px;
        padding-right:5px;
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        min-height: 400px;
        height: auto;
    }

    &.product--compact {
        min-height: unset;
        padding-bottom: 54px;
        height: auto !important;

        @media screen and (max-width: @breakpoint-medium) {
            min-height: 195px;
        }

        .product-label {
            top: 8px;
            bottom: unset;
        }

        .product-image-link {
            min-height: unset;

            img {
                max-height: 95px;
            }
        }

        .add-to-cart {
            bottom: 5px;
        }
    }

    img {
        max-width: 250px;
        max-height: 250px;
        margin: 0 auto;
        display: block;
    }

    .product-description {
        float: left;
        width: 100%;
        box-sizing: border-box;
    }

    .product-image-link {
        float: left;
        width: 100%;
        min-height: 185px;
        text-align: center;
        margin-bottom: 5px;
        padding: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 99%;
            max-height: 175px;
            width: auto;
        }
    }

    .product-image {
        height: 97px;
        margin-top: 85px;
        text-align: center;
        .neuron;
    }

    .product-text {
        a {
            color: @color-blue-primary;

            &:hover {
                color: @c-lnkh1;
            }

            .product-title {
                margin: 0 0 0 0;
                padding: 0 0px 0 0px;
                font-size: 18px;
                width: 100%;
                float: left;
                box-sizing: border-box;

                span.ais-Highlight {
                    font-size: 18px;
                }
            }
        }

        .product-description {
            color: @color-text-primary;
            padding: 0 0px;
            font-size: 16px;
            line-height: 1.6em;
            margin: 0 0 90px 0;
            box-sizing: border-box;

            @media screen and (min-width: @breakpoint-medium) {
                font-size: 13px;
                line-height: 1.4em;
                margin-bottom: 0;
            }
        }

        .bullets {
            float: left;
            width: 100%;
            color: fade(@c-black, 70%);
            padding: 0px 0px 0px 20px;
            margin: 0px;
            list-style:disc;
            font-size: 13px;
            line-height: 1.4em;
            box-sizing: border-box;

            li {
                float: left;
                width: 100%;
            }
        }
    }

    .product-price-meta {
        float: left;
        width: 100%;
        text-align: left;
        left: 0;
        padding: 0 0px;
        box-sizing: border-box;
        font-size: 16px;

        .product-price {
            display: inline-block;
            font-weight: 500;
            .neuron;
            color: @color-text-primary;
            line-height: 16px;
            margin-top: 5px;
            padding: 0 7px 0 0;
            border-right: 1px solid @color-border-dark;
        }

        .product-meta {
            padding-left: 2px;
            color: @color-text-secondary;
            font-size: .8em;
            position: relative;
            bottom: 0;
            display: inline-flex;
            align-items: center;

            span {
                width: 11px;
                height: 11px;
                margin-right: 3px;
                border-radius: 10px;
                float: left;
                background: @c-green1;

                &.soon_available {
                    background: #f08513;
                }
            }
        }

        &.product-price-meta__discounted {
            .product-price {
                text-decoration: line-through;
                color: @color-text-secondary;

                &.product-price__discounted {
                    text-decoration: unset;
                    color: @c-red;
                }
            }
        }
    }

    .product-reviews {
        padding-left: 2px;
        margin-bottom: 3px;
        min-height: 16px;

        img {
            width: 80px;
            height: 16px;
            margin:0;
        }
    }

    .product-daily-deal {
        position: relative;
        top: -1px;
    }

    @media screen and (max-width: (@breakpoint-xsmall - 1)) {
        min-height: 0;
        height: auto !important;

        .product-text .product-description {
            height: auto !important;
            margin-bottom: 20px !important;
        }
    }
}


.mobile-sticky {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px 0;
    text-align: center;
    color: @c-white;
    background: #5fc719;
    z-index: 999;
    border: 0;
    font-size: 18px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    display: none;
    transform: translate3d(0px, 0, 0);
    -webkit-transition: transform .3s ease-in;
    -moz-transition: transform .3s ease-in;
    -ms-transition: transform .3s ease-in;
    -o-transition: transform .3s ease-in;
    transition: transform .3s ease-in;

    &.disable {
        transform: translate3d(0, 62px, 0);
        -webkit-transform: translate3d(0, 62px, 0);
        -ms-transform: translate3d(0, 62px, 0);
    }

    .menu-icon {
        margin-left: 10px;
    }

    &:active,
    &:visited {
        color: @c-white;
    }

    &:hover {
        color: @c-white;
        background: darken(#5fc719, 10);
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        display: block;
    }

    @media screen and (min-width: 374px) and (max-width: 376px),
    screen and (min-width: 811px) and (max-width: 813px) {
        padding-bottom: 25px;
    }
}

#product-page-body #product-page-side #product-page-side-inner #product-configuration #config-add-to-cart-wrap {
    height: 70px;

    .config-step,
    .config-block {
        height: 70px;
    }

    .btn.add-to-cart.btn-green {
        margin-top: 8px;
    }
}

#product-page-body #product-page-side #product-page-side-inner #product-configuration .config-wrap .config-block {
    .btn.add-to-cart.btn-green {
        margin-top: 8px;
        width: 100%;
    }
}

#product-page-body #product-configuration .stock-wrap {
    width: 100%;
    float: left;
    font-size: 14px;
    color: #5fc719;
    font-weight: bold;

    .stock-circle {
        display: inline-block;
        background-color: #5fc719;
        border-color: #54b016;
        height: 7px;
        width: 7px;
        border-radius: 50%;
        border: 1px solid transparent;
        margin-right: 5px;
    }

    &.orange {
        color: #e5770c;

        .stock-circle {
            background-color: #e5770c;
            border-color: #e5770c;
        }
    }
}

.config-block {
    #select_color {
        padding: 7px 10px;
        margin-top: 7px;
        position: relative;
        width: auto;
        min-width: 170px;
        margin-bottom: 15px;

        span {
            float: left;
            font-size: 14px;
            margin-top: -1px;
            white-space: nowrap;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        img.spinner {
            margin-left: 5px;
        }

        @media screen and (max-width: (@breakpoint-medium - 1)) {
            width: 100%;
            left: 0;
            margin-left: 0;
            box-sizing: border-box;

            span {
                width: auto;
            }
        }
    }

    .color-choice {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        float: left;
        margin-right: 10px;
        background: @c-white;
        border: 1px solid @c-white;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);

        &.no-color {
            background: @c-white;
        }
    }
}

#product-page-content .product-stock {
    padding-left: 2px;
    display: block;
    color: #d00e0e;
    font-size: .8em;
    position: relative;
    bottom: 0;
    display: none;
    margin: 5px 0 15px 0;
    font-weight: bold;

    span {
        width: 14px;
        height: 14px;
        margin: 1px 8px 0 0;
        border-radius: 10px;
        float: left;
        background: #d00e0e;

        &.soon_available {
            background: #f08513;
        }

        &.menu-icon {
            margin-top: 2px;
        }
    }

    &.product-stock--show {
        display: inline-block;
        width: 100%;
    }

    &.product-stock--green {
        color: #5FC719;

        span {
            background: #5FC719;
        }
    }

    &.product-stock--in-stock {
        &.product-stock--green {
            color: #5FC719 !important;

        }

        &.product-stock--orange {
            color: #e5770c !important;

            span {
                background: none;
            }
        }

        &.product-stock--blue {
            color: #00A2E0 !important;

            span {
                background: none;
            }
        }
    }

    &.product-stock--orange {
        color: #e5770c;

        span {
            background: #e5770c;
        }
    }

    &.product-stock--blue {
        color: #00A2E0;

        span {
            background: #00A2E0;
        }
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        display: block;
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) and (min-width: @breakpoint-xsmall) {
    .product {

        .btn.add-to-cart,
        .btn.more-information {
            font-size: 14px !important;
        }
    }
}

@media (min-width: @breakpoint-medium) {
    .product {
        .product-text {
            .bullets {
                margin: 0 0 0 6px;
            }
        }
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .product {
        padding-bottom: 70px;
    }
}
