body {
  .aa-DetachedFormContainer {
    form.aa-Form {
      padding: 0;
    }

    label {
      margin-bottom: 0px;
      line-height: unset;
    }

    input[type="search"] {
      width: 90%;
      height: 32px;
      margin: 0;
      box-shadow: none;
      border:none;

      &:focus {
        box-shadow: none;
        border: none;
        outline: none;
      }
    }
  }

  .aa-SourceHeaderTitle {
    color: @color-blue-primary;
    .neuron();
    font-size: 23px;
  }

  .aa-Input {
    border: 0px;
    box-shadow: none;

    &:focus {
      border: 0px;
      box-shadow: none;
    }
  }

  .autocomplete-showmore {
    background: #04A2DF;
    color: #fff;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    .neuron();
    display: block;
  }

  .aa-Panel {
    z-index: 9999;
  }
}
