#checkout .checkout-main .checkout-main__register-form {

    .form-horizontal {
        .control-group {
            &--name {
                .controls {
                    @media screen and (min-width: @breakpoint-small) and (max-width: (@breakpoint-medium - 1)) {
                        display: flex;
                    }

                    @media screen and (min-width: @breakpoint-large) {
                        display: flex;
                    }

                    .input-holder {
                        @media screen and (max-width: (@breakpoint-medium - 1)) {
                            display: flex;
                        }

                        .input-mini {
                            @media screen and (min-width: @breakpoint-small) and (max-width: (@breakpoint-medium - 1)) {
                                margin-left: 3px;
                            }

                            @media screen and (max-width: (@breakpoint-medium - 1)) {
                                margin-right: 3px;
                            }

                            @media screen and (min-width: @breakpoint-large) {
                                margin-left: 8px;
                            }
                        }
                    }
                }
            }
        }

        .password-block {
            display: flex;
            flex-direction: column;
            padding: 32px 32px 24px;
            margin: 16px -32px;
            background: @color-border-light;

            @media screen and (min-width: @breakpoint-medium) {
                margin: 32px -32px;
            }

            &__title {
                display: inline-block;
                margin: 0;
                font-size: 22px;
                color: @c-blue;
            }

            &__subtitle {
                display: inline-block;
                margin: 0 0 16px;
                font-size: 18px;
                color: @c-blue-dark;
            }

            &__content {
                display: flex;
                flex-direction: column;

                @media screen and (min-width: @breakpoint-medium) {
                    flex-direction: row-reverse;
                }
            }

            .highlighted-content {
                margin-bottom: 16px;

                @media screen and (min-width: @breakpoint-medium) {
                    margin-bottom: 0;
                }
            }

            #create-acction {
                @media screen and (min-width: @breakpoint-medium) {
                    flex: 1 1 50%;
                }

                .control-label {
                    @media screen and (min-width: @breakpoint-medium) {
                        flex: 0 0 100%;
                        text-align: left;
                        margin-bottom: 2px;
                    }
                }

                .control-group {
                    @media screen and (min-width: @breakpoint-medium) {
                        flex-direction: column;
                    }

                    input {
                        @media screen and (min-width: @breakpoint-medium) {
                            width: 100%;
                        }

                        @media screen and (min-width: @breakpoint-large) {
                            width: 75%;
                        }
                    }
                }
            }
        }

        #order-type-business-section,
        #alternative-delivery {
            background: @color-border-light;
            padding: 16px 32px 11px;
            margin-bottom: 15px;
            margin-left: -32px;
            margin-right: -32px;
        }
    }

}