#sidebar-left {
    float: left;
    width: 225px;

    .sidebar-reviews {
        background: @c-white;
        padding: 10px;
        border: 1px solid @color-border-dark;
        float: left;
        width: 100%;
        box-sizing: border-box;
        margin-top: 10px;
        border-radius: 3px;
        margin-bottom: 20px;
        padding-top: 0;

        &--fr {
            padding-top: 10px;
            padding-bottom: 0;
        }
    }

    .vat_toggle {
        background: @c-white;
        padding: 15px;
        float: left;
        width: 100%;
        box-sizing: border-box;
        border-radius: 3px;
        border: 1px solid @color-border-dark;
        margin-bottom: 10px;
    }

    .category_menu {
        background: @c-white;
        padding: 15px;
        float: left;
        width: 100%;
        box-sizing: border-box;
        border-radius: 3px;
        border: 1px solid @color-border-dark;
    }

    .category_menu {
        &.has-filters {
            .top-menu {
                height: 158px;
                overflow: hidden;
            }

            &.open {
                .top-menu {
                    height: auto;
                }

                .show_more {
                    background: url('../../../img/arrow-up.png') no-repeat;
                    background-position: 0 11px;
                    background-size: 12px auto;
                }
            }
        }

        .show_more {
            font-size: 13px;
            color: @color-blue-primary;
            text-decoration: underline;
            border-top: 1px solid @color-border-dark;
            text-emphasis: center;
            padding: 5px 0 0 17px;
            margin-top: 3px;
            cursor: pointer;
            background: url('../../../img/arrow-down.png') no-repeat;
            background-position: 0 11px;
            background-size: 12px auto;
        }
    }

    .product-filter {
        height: 465px;
        background-color: none;
        border: 0;
        padding: 0;
        -webkit-user-select: none;
        user-select: none;
        clear: both;

        @media screen and (max-width: (@breakpoint-medium - 1)) {
            margin: 0 4px 10px 4px !important;
            height: auto;
            min-height: 0;
            display: block;
        }

        h4.sidebar-title {
            .neuron;
            font-size: 20px;
            margin: 0;
            padding: 0 4px 5px 4px;
            font-weight: normal;
            border-bottom: 1px solid @color-border-dark;
        }

        .sidebar-section {
            border-bottom: 0;

            h5 {
                text-transform: uppercase;
                .baseFont;
                font-size: 12px;
                margin: 5px 0;
            }

            p,
            label {
                font-size: 13px;
                line-height: 22px;
                color: @color-text-secondary;

                &:hover {
                    color: @c-lnkh1;
                }
            }

        }

        ul.top-menu {
            border-bottom: 0;

            li {
                border-bottom: 0;

                a {
                    box-sizing: border-box;
                    padding: 3px 0 3px 4px;

                    &.active,
                    &:hover {
                      background: @color-blue-primary;
                      color: @c-white;
                    }
                }
            }
        }

        #product-questions {
            width: auto;
            height: 52px;

            #product-questions-overlay {
                .product-questions-2 {
                    font-size: 15px;
                    margin-top: 6px;
                }
            }
        }

        .sidebar-product-categories {
            a {
                display: block;
                text-decoration: none;
            }
        }

        .sidebar-brands {
            label {
                margin: 0;
            }
        }

        .sidebar-quantities {
            label {
                margin: 0;
            }
        }

        .sidebar-payment-gateways {
            padding-top: 8px;
        }
    }

    .filters {
        width: 100%;
        float: left;
        background: @c-white;
        padding: 15px;
        box-sizing: border-box;
        border-radius: 3px;
        border: 1px solid @color-border-dark;
        margin-top: 10px;
    }
}

#home-content {
    float: right;
    width: 967px;

    @media screen and (max-width: (@breakpoint-grid-large - 1)) {
        width: 685px;
    }

    @media screen and (max-width: (@breakpoint-large - 1)) {
        width: 465px;
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        width: 100%;
    }
}

.active_filters {
    width: 100%;
    float: left;
    margin-bottom: 10px;

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        display: none;
    }

    .name {
        float: left;
        font-size: 13px;
        color: fade(@c-black, 50%);
        padding: 5px 10px 0 0;
        margin-top: 2px;
    }

    .item {
        float: left;
        background: #deeff6;
        border: 1px solid @color-blue-primary;
        color: @c-black;
        padding: 0 5px;
        margin-right: 5px;
        cursor: pointer;
        margin-top: 5px;
        border-radius: 3px;
        color: fade(@c-black, 20%);

        &:hover {
            opacity: 0.8;
        }

        span {
            color: @c-black;
            font-weight: bold;
            float: right;
            margin-left: 5px;
            font-size: 16px;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

#category {
    #category-products {
        float: right;
        width: 967px;
        margin-bottom: 25px;

        .clearfix;

        .short_intro {
            float: left;
            width: 100%;
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 10px;

            .intro_summary {
                display: flex;
                width:100%;

                .intro_summary__text {
                    width: 60%;
                    box-sizing: border-box;
                    padding: 5px;
                }

                .intro_summary__choice {
                    box-sizing: border-box;
                    width: 40%;
                    border-radius: 4px;
                    background: #e9e9e9;
                    padding: 5px 15px 15px 15px;

                    @media screen and (max-width: (@breakpoint-large - 1)) {
                        width: 100%;
                        background: none;
                        padding: unset;
                        border-radius: unset;
                    }
                }
            }

            img.product-wizard {
                float: right;
                margin: 5px;
                cursor: pointer;
                display: none;

                .clearfix;

                &.wizard-mobile {
                    float:none;
                    margin: 0 auto;
                    padding-top:10px;

                    @media screen and (max-width: (@breakpoint-large - 1)) {
                        display: block;
                    }
                }

                &.wizard-desktop {
                    margin: 10px;

                    @media screen and (min-width: (@breakpoint-large)) {
                        display: inline;
                    }
                }
            }

            .results {
                width: 100%;
                margin-bottom: 5px;
                float: left;
                font-size: 12px;

                span {
                    font-weight: bold;
                }
            }

            p {
                color: fade(@c-black, 80%);

                @media screen and (max-width: (@breakpoint-large - 1)) {
                    display: none;
                }
            }

            @media screen and (max-width: (@breakpoint-large - 1)) {
                display: none;
            }
        }

        .category-header {
            margin-bottom: 0;

            span {
                padding: 0 7px 0 19px;
            }

            h1 {
                display: inline-block;
                text-transform: uppercase;
                margin: 0;
                font-size: 32px;
                line-height: 28px;
            }
        }

        .category-header.old {
            .gradient(@co-step3, @co-step4, @co-step3);
            border: 1px solid darken(@co-step4, 0%);
            margin-bottom: 8px;
            .rounded(3px);
            height: 40px;

            span {
                color: @c-txt-white;
                padding: 0 7px 0 19px;
            }

            h1 {
                color: @c-txt-white;
                display: inline-block;
                text-transform: uppercase;
                margin: 0;
                font-size: 24px;
                line-height: 40px;
            }
        }

        #mobile-subcategories {
            display: none;
        }

        .sub-category-header {
            .gradient(@co-step1, @co-step2, @co-step1);
            border: 1px solid @color-border-dark;
            margin-bottom: 8px;
            .rounded(3px);
            height: 30px;

            a {
                display: block;
            }

            h2 {
                .padh1;
                color: @color-text-primary;
                display: inline-block;
                margin: 0;
                font-size: 18px;
                line-height: 30px;
            }
        }

        #hits {
            .ais-Hits {
                width: 100%;
                float: left;

                ol {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        margin: 0;
                        box-shadow: none;
                        width: auto;
                        border: none;
                        padding: 0;

                        @media screen and (max-width: (@breakpoint-small - 1)) {
                            width: 100%;

                            .always1 {
                                width: 100%;
                            }
                        }

                        @media screen and (min-width: (@breakpoint-small)) {
                            width: 50%;

                            .always1 {
                                width: 100%;
                            }
                        }

                        @media screen and (min-width: @breakpoint-medium) {
                            width: auto;
                        }
                    }
                }

                .product {
                    .product-text .bullets {
                        li {
                            float: none;
                        }
                    }
                }

                @media screen and (min-width: @breakpoint-medium) {
                    ol li .always1 {
                        width: 221px;
                    }
                }

                @media screen and (min-width: @breakpoint-large) {
                    ol li .always1 {
                        width: 218px;
                    }
                }

                @media screen and (min-width: @breakpoint-xlarge) {
                    ol li .always1 {
                        width: 233px;
                    }
                }
            }
        }

        #pagination-top, #pagination-bottom {
            float:left;
            width: 100%;
            margin: 10px 0 10px 0;

            ul {
                list-style-type: none;
            }
        }
    }

    #category-summary {
        .box;
        .padh1;
        margin-bottom: 8px;

        p {
            font-size: 16px;
            line-height: 1.5em;
            margin: 19px 0;
        }

        a {
            .obviousLink;
        }

        @media screen and (max-width: (@breakpoint-medium - 1)) {
            .table {
                thead {
                    display: none;
                }

                tr {
                    width: 100%;
                    float: left;

                    td {
                        width: 100%;
                        float: left;
                    }
                }
            }
        }
    }

    #mobile-only-section {
        margin-top: 8px;
        float: left;
        width: 100%;

        #mobile-payment-methods {
            margin: 0 auto;
            width: 197px;

            p {
                text-align: center;
            }
        }

        #mobile-product-questions {
            margin-top: 8px;
        }

        #kiyoh-mobile {
            margin: 0 auto;
            width: 200px;
            margin-top: 8px;
        }
    }
}

#category #category-products .short_intro .result-bar {
    width: 100%;
    float: left;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    padding: 5px;
    margin-top: 3px;
    margin-bottom: 5px;

    .results {
        float: left;
        width: auto;
        margin-bottom: 0;
    }

    .filter-button {
        float: right;
        font-weight: bold;
        background: #00A2E0;
        color: @c-white;
        background-size: 14px auto;
        background-position: 6px 4px;
        line-height: 13px;
        padding: 5px;
        border-radius: 3px;
        display: none;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
        cursor: pointer;

        &.fixed {
            position: fixed;
            width: 100%;
            left: 0;
            top: 51px;
            border-radius: 0;
            text-align: center;
            z-index: 20;
            padding: 10px 0;
        }

        &:hover {
            background: darken(#00A2E0, 5);
        }
    }
}

.mobile_filters {
    position: fixed;
    display: none;
    bottom: 0;
    left: 0;
    background: @c-white;
    padding: 10px;
    border-top: 1px solid #eee;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
    box-shadow: 0 -2px 1px rgba(0, 0, 0, 0.05);

    .filter-button {
        background: #00A2E0;
        text-align: center;
        color: @c-white;
        width: 100%;
        float: left;
        padding: 10px 15px;
        box-sizing: border-box;
        border-radius: 4px;

        &:hover {
            background: #0096d0;
        }
    }

    .reset-button {
        float: left;
        width: 100%;
        margin-right: 2%;

        .ais-ClearRefinements {
            button {
                width: 100%;
                background: #eee;
                color: #565656;
                text-align: center;
                border: none;
                padding: 10px 15px;
                box-sizing: border-box;
                border-radius: 4px;

                &:hover {
                    background: #e4e4e4;
                }
            }
        }
    }
}

.mobile-only-section {
    width: 100%;
    float: left;
    background: @c-white;
}

.filter-mobile-top,
.filter-mobile-bottom {
    display: none;
}

@media screen and (max-width: (@breakpoint-grid-large - 1)) {
    #sidebar-left {
        width: 220px;
    }

    #category #category-products {
        width: 685px;
    }
}

@media screen and (max-width: (@breakpoint-large - 1)) {
    #category #category-products {
        width: 460px;
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    .hide-mobile {
        display: none;
    }

    .mobile_filters {
        display: flex;
    }

    #sidebar-left .filters {
        position: fixed;
        height: 100vh;
        top: 0;
        left: 0;
        padding: 30px 5px 100px 5px;
        overflow: auto;
        overscroll-behavior: contain;
        background: @c-white;
        z-index: 999999;
        display: none;

        .filter-inner {
            height: 100%;
            float: left;
            padding: 20px 20px 120px 20px;
            box-sizing: border-box;
            width: 100%;
            overflow-y: scroll;
            /* has to be scroll, not auto */
            -webkit-overflow-scrolling: touch;
            margin-top: 44px;
        }

        .filter-mobile-top {
            height: 44px;
            line-height: 44px;
            background: #00A2E0;
            font-weight: bold;
            text-transform: uppercase;
            color: @c-white;
            text-align: center;
            position: fixed;
            width: 100%;
            left: 0;
            top: 0;
            display: block;

            .close {
                position: absolute;
                padding: 10px;
                color: @c-white;
                opacity: 1;
                font-size: 26px;
                text-shadow: 0 0 0;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        .filter-mobile-bottom {
            width: 100%;
            position: fixed;
            border-top: 1px solid #eee;
            left: 0;
            bottom: 0;
            background: @c-white;
            box-sizing: border-box;
            padding: 10px;
            display: block;

            .button {
                background: #00A2E0;
                color: @c-white;
                border-radius: 3px;
                width: 100%;
                float: left;
                padding: 10px;
                box-sizing: border-box;
                font-weight: 400;
                font-size: 16px;
                text-align: center;
                opacity: 1;
                border-bottom: 2px solid darken(#00A2E0, 5);

                &:hover {
                    background: darken(#00A2E0, 8);
                }

                .ais-Stats {
                    color: inherit;
                    font-size: inherit;

                    span {
                        font-size: inherit;
                    }
                }
            }
        }

        .intro_summary__choice {
            h3 {
                font-size: 18px;
            }

            .btn.choice-help {
                width: 80%;
                margin:0 auto;
            }
        }
    }

    #category #category-products {
        width: 100%;
    }

    #category #category-products .short_intro .result-bar .filter-button {
        display: block;
    }
}
