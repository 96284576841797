.btn.add-to-cart,
.btn.more-information {
    .neuron;
    background: #5fc719;
    color: white;
    border-radius: 4px;
    padding: .7em 0;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
    cursor: pointer;
    width: 96%;
    position: absolute;
    left: 4px;
    bottom: 15px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: 'Neuron', Helvetica, sans-serif;
    font-size: 18px;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    margin:0 auto;

    span {
        width: 1px;
        height: 14px;
        margin: 0 8px;
        font-size: 14px;
        border-left: 0px;
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        padding-right: 20px;
    }

    i {
        font-size: .85em;
    }

    &:hover {
        background: #009600;
    }
}

.btn-checkout {
    padding: 6px 22px 6px 18px;

    span {
        position: relative;
        left: 8px;
        font-size: 16px;
    }
}

.btn-green {
    .neuron;
    background: #5fc719;
    color: white;
    border-radius: 4px;
    padding: .7em 0;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
    cursor: pointer;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: 'Neuron', Helvetica, sans-serif;
    font-size: 18px;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);

    span {
        width: 1px;
        height: 14px;
        margin: 0 8px;
        font-size: 14px;
        border-left: 0px;
        padding-right: 20px;
    }

    i {
        font-size: .85em;
    }

    &:hover,
    &:active,
    &:focus {
        background: #009600;
        color: @c-white;
    }

    &[disabled], &.disabled {
        background-color: #e6e6e6;
        color: #333;
    }
}

.btn-blue {
    .neuron;
    background: @color-blue-primary;
    color: @c-white;
    border-radius: 4px;
    padding: .7em 0;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
    cursor: pointer;
    width: 96%;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: 'Neuron', Helvetica, sans-serif;
    font-size: 18px;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    margin:0 auto 10px auto;

    i {
        font-size: .85em;
    }

    &:hover,
    &:active,
    &:focus {
        color: @c-white;
        background: @c-blue2;
    }

    span {
        border-left: 1px solid rgba(255, 255, 255, 0.5);
        padding-left: 10px;
    }

    svg {
        margin-right: 10px;
    }
}

.button {
    display: inline-block;
    padding: 8px 15px;
    font-size: 15px;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: none;
    background-image: none;
    transition: @transition-cubic;

    &.button {
        &--green {
            color: @c-white;
            background-color: @c-green;
            border-color: @c-green;

            &:hover {
                background-color: @c-green-dark;
                border-color: @c-green-dark;
            }
        }

        &--blue {
            color: @c-white;
            background-color: @c-blue;
            border-color: @c-blue;

            &:hover {
                background-color: @c-blue-dark;
                border-color: @c-blue-dark;
            }
        }
    }
}

.btn {
    *:first-child {
        margin-right: 3px;
    }
}
