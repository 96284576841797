.product-images {
    border: 1px solid #efefef;

    img {
        height: auto;
        max-height: 400px;
        width: auto;
        display: block;
        margin: 0 auto;
    }

    &.slick-slider {
        .slick-arrow {
            width: 30px;
            height: 30px;

            &.slick-prev {
                left: 0;
            }

            &.slick-next {
                right: 0;
            }

            img {
                max-height: 30px;
                transition: all .2s ease-in-out;
            }

            @media screen and (min-width: @breakpoint-xsmall) {
                display: none !important;
            }

            @media screen and (min-width: @breakpoint-small) {
                width: 50px;
                height: 50px;

                &.slick-prev {
                    left: -10px;
                }

                &.slick-next {
                    right: -10px;
                }

                img {
                    max-height: 50px;
                }
            }

            @media screen and (min-width: @breakpoint-large) {
                width: 70px;
                height: 70px;

                &.slick-prev {
                    left: -20px;
                }

                &.slick-next {
                    right: -20px;
                }

                img {
                    max-height: 70px;
                }
            }
        }
    }

    .slick-prev,
    .slick-next {
        width: 70px;
        height: 70px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        color: black;
        cursor: pointer;
        border-radius: 50%;
        transition: color .2s ease;
        text-align: center;

        i {
            font-size: 23px;
            line-height: 34px;
            top: 50%;
            position: relative;
            transform: translateY(-50%);
        }
    }

    .slick-prev {
        left: -20px;

        img {
            margin-right: 4px;
            max-height: 30px;
        }
    }

    .slick-next {
        right: -20px;

        img {
            margin-left: 4px;
            max-height: 30px;
        }
    }
}

.product-sub-images {
    &--hide {
        display: none !important;
    }

    &.slick-slider {
        @media screen and (max-width: (@breakpoint-small - 1)) {
            display: none;
        }

        .slick-arrow {
            width: 30px;
            height: 30px;

            &.slick-prev {
                left: 5px;
            }

            &.slick-next {
                right: 5px;
            }

            img {
                max-height: 30px;
                transition: all .2s ease-in-out;
            }
        }

        .slick-prev,
        .slick-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            color: black;
            cursor: pointer;
            border-radius: 50%;
            transition: color .2s ease;
            text-align: center;

            i {
                font-size: 23px;
                line-height: 34px;
                top: 50%;
                position: relative;
                transform: translateY(-50%);
            }
        }

        .slick-prev {
            left: -20px;

            img {
                margin-right: 4px;
                max-height: 30px;
            }
        }

        .slick-next {
            right: -20px;

            img {
                margin-left: 4px;
                max-height: 30px;
            }
        }

        .single {
            cursor: pointer;
            margin: 5px 2.5px;
            border: 1px solid #efefef;
            background-color: @c-white;
            padding: 5px;

            &.slick-current {
                .single-img {
                    opacity: 1;
                }
            }

            &:hover,
            &:focus {
                .single-img {
                    opacity: 1;
                }
            }
        }

        .single-fill {
            display: block;
            overflow: hidden;
            position: relative;
            text-align: center;
            height: 100px;
            background: @c-white;
            cursor: pointer;
            border: 5px solid @c-white;
            text-align: center;
        }

        .single-img {
            opacity: 0.5;
            transition: all 0.5s;
            display: inline-block;
            position: relative;
            max-width: 100%;
            max-height: 100%;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
}

.product-images:not(.slick-initialized) {
    > .zoom:not(:first-child) {
        display: none;
    }
}

.product-sub-images:not(.slick-initialized) {
    display: none;
}
