#campaign {
    .grid {
        float: left;
        width: 100%;
    }
}

.campaign {
    display: inline-block;
    width: 100%;
    margin-top: -10px;

    @media screen and (min-width: @breakpoint-medium) {
        margin-top: -20px;
    }

    *,
    ::after,
    ::before {
        box-sizing: border-box;
    }

    .container {
        width: 100%;
        max-width: 1260px;
        padding: 0 15px;
        margin: 0 auto;
        box-sizing: border-box;
    }

    .bg-white {
        display: inline-block;
        width: 100%;
        background: @c-white;
        box-shadow: 1px 0 13px fade(@c-black, 10%);
    }

    .campaign-banner {
        float: left;
        width: 100%;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &.campaign-banner {
            &--static {
                background-position: top;
                height: 250px;

                @media screen and (min-width: @breakpoint-small) {
                    height: 200px;
                }

                @media screen and (min-width: @breakpoint-medium) {
                    height: 200px;
                }

                @media screen and (min-width: @breakpoint-grid-large) {
                    height: 250px;
                }

                @media screen and (min-width: @breakpoint-xxlarge) {
                    height: 250px;
                }

                &::after {
                    opacity: 0;
                }
            }
        }

        &::after {
            background-color: @c-white;
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: .15;
            z-index: 0;
        }

        &__inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 40px 0 25px;
            position: relative;
            z-index: 1;

            @media screen and (min-width: @breakpoint-medium) {
                flex-direction: row;
                padding: 80px 0;
            }
        }

        &__left {
            flex: 1 1 100%;
        }

        &__right {
            flex: 0 0 auto;
            text-align: right;
            margin-top: 25px;

            @media screen and (min-width: @breakpoint-medium) {
                margin-top: 0;
            }
        }

        &__image {
            max-height: 175px;

            @media screen and (min-width: @breakpoint-medium) {
                max-height: 320px;
            }
        }

        &__title {
            color: @c-white;
            .neuron();
            font-size: 24px;
            line-height: 26px;
            margin-top: 0;
            margin-bottom: 10px;
            text-shadow: 1px 1px 5px fade(@c-black, 50%);

            @media screen and (min-width: @breakpoint-medium) {
                font-size: 38px;
                line-height: 36px;
                margin-bottom: 5px;
            }
        }

        &__subtitle {
            display: block;
            color: @c-white;
            margin-top: 0;
            margin-bottom: 15px;
            font-size: 20px;
            .neuron();
            position: relative;
            text-shadow: 1px 1px 5px fade(@c-black, 50%);

            @media screen and (min-width: @breakpoint-medium) {
                font-size: 24px;
                margin-bottom: 25px;
            }
        }

        &__list {
            display: inline-block;
            width: 100%;
            margin: 0;
            list-style: none;
        }

        &__item {
            padding-left: 30px;
            margin-left: 10px;
            color: @c-white;
            .neuron();
            font-size: 18px;
            margin-bottom: 5px;
            line-height: 24px;
            background-image: url('../../../img/check-ico.png');
            background-size: 20px;
            background-position: 0 50%;
            background-repeat: no-repeat;
            text-shadow: 1px 1px 5px fade(@c-black, 50%);

            @media screen and (min-width: @breakpoint-medium) {
                font-size: 24px;
                line-height: 32px;
                padding-left: 40px;
                background-size: auto;
                margin-bottom: 15px;
            }

            &:nth-last-of-type(1) {
                margin-bottom: 0;
            }
        }
    }

    .campaign-youtube {
        &__inner {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column;
            width: 100%;
            padding: 30px 0;

            @media screen and (min-width: @breakpoint-medium) {
                padding: 10px 0;
            }

            @media screen and (min-width: @breakpoint-large) {
                flex-direction: row;
                padding: 20px 0;
            }
        }

        &__item {
            float: left;
            width: 100%;
            box-sizing: border-box;

            @media screen and (min-width: @breakpoint-medium) {
                width: 75%;
            }

            @media screen and (min-width: @breakpoint-large) {
                width: ~'calc(50% - 30px)';
            }

            &:nth-of-type(1) {
                margin-bottom: 40px;

                @media screen and (min-width: @breakpoint-large) {
                    margin-bottom: 0;
                    margin-right: 30px;
                }
            }

            &:nth-last-of-type(1) {
                @media screen and (min-width: @breakpoint-large) {
                    margin-left: 30px;
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__title {
            color: @color-text-primary;
            .neuron();
            font-size: 24px;
            margin-top: 0;
            margin-bottom: 15px;

            @media screen and (min-width: @breakpoint-medium) {
                font-size: 30px;
            }

            @media screen and (min-width: @breakpoint-large) {
                font-size: 25px;
            }

            @media screen and (min-width: @breakpoint-xlarge) {
                font-size: 30px;
            }
        }

        &__subtitle {
            color: @color-text-primary;
            .neuron();
            font-size: 20px;
            line-height: 26px;
            margin-top: 5px;
            margin-bottom: 15px;
        }

        &__contact {
            text-align: left;
            padding: 10px 0 25px 0;
            //border-bottom: 1px solid @c-gray-default;

            @media screen and (min-width: @breakpoint-medium) {
                text-align: left;
            }

            .rule {
                display: inline-block;
                position: relative;
                margin-right: 10px;
                margin-bottom: 5px;
            }

            .icon-whatsapp {
                float: left;
                margin-right: 3px;
                overflow: hidden;
                padding: 0;
                color: @c-white;
                cursor: default;
                height: 19px;
                pointer-events: none;
                width: 19px;
                background-image: url('../../../img/whatsapp.svg');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: unset;
            }

            .advice-icon {
                color: @color-blue-primary;
            }
        }

        &__video {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
            max-width: 100%;
            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                background-image: url('../../../img/play-button-red.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 70px auto;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            &.campaign-youtube__video {
                &--has-iframe {
                    &::before {
                        display: none;
                    }
                }
            }

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &__image {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin-top: 0
        }
    }

    .campaign-rating {
        display: table;
        width: 100%;
        margin-top: 10px;
        position: relative;

        &__inner {
            display: inline-block;
            width: 100%;
        }

        &__content {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            @media screen and (min-width: @breakpoint-medium) {
                flex-direction: row;
            }

            &:hover {
                .campaign-rating__amount {
                    color: @color-blue-primary;
                }
            }
        }

        &__rating {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            @media screen and (min-width: @breakpoint-medium) {
                margin-bottom: 0;
            }
        }

        &__score {
            display: inline-block;
            padding: 15px;
            font-size: 16px;
            font-weight: bold;
            color: @color-text-primary;
            background-image: url('../../../img/round-only-kiyoh.png');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        &__stars {
            display: inline-block;
            width: 130px;
            height: 21px;
            margin-left: 10px;
            position: relative;
        }

        &__bar {
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url(../../../img/rating.jpg);
            background-size: 130px auto;
            background-position: 0 0;
            background-repeat: no-repeat;

            &--inner {
                background-position: 0 -21px;
                z-index: 1;
                background-size: 130px auto;
            }
        }

        &__reviews {
            display: flex;
            align-items: center;
        }

        &__amount {
            display: flex;
            align-items: center;
            color: @color-text-primary;

            span {
                margin-right: 5px;
            }
        }

        &__kiyoh {
            font-family: Arial;
            color: @color-text-primary;
            font-weight: bold;
            margin-left: 5px;
            margin-top: 3px;
        }
    }

    .campaign-category {

        &__content {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column-reverse;
            width: 100%;

            @media screen and (min-width: @breakpoint-large) {
                flex-direction: row;
            }

            &--all-products {
                display: inline-block;
            }

            &--reverse {
                @media screen and (min-width: @breakpoint-large) {
                    flex-direction: row-reverse;
                }

                .campaign-category {
                    &__item {
                        &:nth-of-type(1) {
                            margin-bottom: 40px;

                            @media screen and (min-width: @breakpoint-large) {
                                width: ~'calc(60% - 30px)';
                                margin-bottom: 0;
                                margin-left: 30px;
                                margin-right: 0;
                            }
                        }

                        &:nth-last-of-type(1) {
                            @media screen and (min-width: @breakpoint-large) {
                                width: ~'calc(40% - 30px)';
                                margin-right: 30px;
                                margin-left: 0;
                            }
                        }
                    }
                }
            }

            .product-count {
                display: block;
                padding-bottom: 0;
            }

            .product-slider {
                >.slick-prev {
                    left: -25px;
                }

                >.slick-next {
                    right: -25px;
                }
            }

            .image-product {

                .image-product {
                    &__inner {
                        background-color: @c-white;
                        overflow: hidden;
                        position: relative;
                        margin-bottom: 8px;
                        border: 1px solid @color-border-dark;
                        border-radius: 3px;
                    }

                    &__image-holder {
                        float: left;
                        width: 100%;
                        min-height: 185px;
                        text-align: center;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &__image {
                        max-width: 100%;
                        width: auto;
                    }
                }

            }
        }

        &__item {
            float: left;
            width: 100%;
            box-sizing: border-box;

            @media screen and (min-width: @breakpoint-medium) {
                width: 75%;
            }

            &:nth-of-type(1) {
                margin-top: 25px;

                @media screen and (min-width: @breakpoint-large) {
                    width: ~'calc(55% - 30px)';
                    margin-top: 0;
                    margin-right: 30px;
                }
            }

            &:nth-last-of-type(1) {
                @media screen and (min-width: @breakpoint-large) {
                    width: ~'calc(45% - 30px)';
                    margin-left: 30px;
                }
            }
        }

        &__list {
            .slick-arrow {
                display: flex !important;
                width: 100%;
                justify-content: center;
                align-items: center;
                padding: 5px 0;

                @media screen and (min-width: @breakpoint-small) {
                    padding: 10px 0;
                }

                img {
                    cursor: pointer;
                    height: 20px;
                }
            }
        }

        &__video {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
            max-width: 100%;
            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                background-image: url('../../../img/play-button-red.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 70px auto;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            &.campaign-category__video {
                &--has-iframe {
                    &::before {
                        display: none;
                    }
                }
            }

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin-top: 0;
        }
    }

}


.clr {

    &:before,
    &:after {
        content: ' ';
        width: 100%;
        display: table;
        clear: both;
    }
}

.white-container {
    display: inline-block;
    width: 100%;
    background: @c-white;
    box-shadow: 1px 0 13px rgba(0, 0, 0, 0.1);

    &.t2 {
        box-shadow: none;
        margin-bottom: -25px;
    }
}

.new-container {
    max-width: 1260px;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box;

    &.content {
        padding: 0 15px 133px 15px;
    }

    .product-text {
        text-align: left;
    }

    .product-count {
        width: 100%;
        display: block;
        font-size: 14px;
        box-sizing: border-box;
        margin-bottom: 10px;
        display: none;
        color: rgba(0, 0, 0, 0.8);
        padding: 0 10px 10px 10px;

        .line {
            height: 4px;
            width: 100%;
            display: block;
            margin-top: 5px;
            background: #aadbee;

            .inner {
                display: block;
                background: #00a2e0;
                height: 4px;
            }
        }
    }
}

.line-title {
    position: relative;

    &.t2 {
        top: -25px;
        margin-bottom: -25px;
    }

    & span {
        display: table;
        margin: 0 auto;
        background: @c-blue;
        color: @c-white;
        font-size: 26px;
        text-align: center;
        .neuron();
        position: relative;
        z-index: 2;
        padding: 10px 20px;
    }

    &:before {
        content: ' ';
        width: 100%;
        height: 2px;
        display: block;
        background: @c-blue;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        z-index: 1;
    }
}

.content-text-block {
    max-width: 810px;
    margin: 0 auto 30px auto;
    color: @c-black;
    text-align: center;

    & h2 {
        font-size: 36px;
        line-height: 36px;
        margin: 75px 0 10px 0;
        .neuron();
    }

    & p {
        font-size: 14px;
        line-height: 20px;

        @media screen and (min-width: @breakpoint-medium) {
            font-size: 16px;
            line-height: 26px;
        }

        & a {
            color: @c-blue;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media screen and (max-width: (@breakpoint-xxlarge - 1)) {
    .new-container .product-count {
        display: block;
    }
}

@media screen and (max-width: (@breakpoint-xlarge - 1)) {
    .youtube-info-block {
        & h3 {
            font-size: 38px;
            line-height: 38px;
        }

        & ul {
            & li {
                font-size: 21px;
                display: inline-block;
                margin-right: 0;
                width: 49%;
            }
        }
    }

    .youtube-rating-item {
        width: 100%;
        display: block;
        border-right: 0;
        font-size: 21px;

        &:nth-of-type(1) {
            border-right: 0;
            padding-bottom: 15px;
        }

        &:nth-of-type(2) {
            text-align: left;
        }
    }
}

@media screen and (max-width: (@breakpoint-large - 1)) {
    .youtube-c-item {
        .number {
            margin-top: 5px;
            font-size: 14px;
        }
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    .banner-body {
        padding: 50px 0;
    }

    .banner-container {
        padding: 15px;
    }

    .banner-item {
        width: auto;
        display: table;
        margin: 0 auto;
    }

    .banner-item.bn-logo {
        float: right;
    }

    .banner-item>.list li {
        margin-bottom: 15px;
    }

    .banner-item.bn-logo img {
        max-width: 210px;
    }

    .youtube-rating-item:nth-child(1) {
        padding-bottom: 5px;
    }

    .youtube-rating-item:nth-child(2) {
        font-size: 16px;
    }

    .youtube-info-block {

        h3,
        ul {
            display: none;
        }
    }
}

@media screen and (max-width: (@breakpoint-small - 1)) {

    .content-text-block h2 {
        font-size: 26px;
        line-height: 26px;
        margin-top: 50px;
    }

    .banner-body {
        padding-bottom: 0;
        padding-top: 20px;
    }

    .banner-item {
        margin-bottom: 20px;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;

        &>.list {
            & li {
                padding-left: 40px;
                font-size: 26px;
                line-height: 32px;
                margin-bottom: 0;
                background: url('../../../img/check-ico.png') 0 50% no-repeat;
            }
        }

        &.bn-logo {
            text-align: right;

            & img {
                margin-top: -20px;
                max-width: 100px;
            }
        }
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .youtube-set-rating-block {
        top: 1px;
    }

    .youtube-c-item .number {
        margin-top: 5px;
    }

    .youtube-rating-body span {
        font-size: 18px;
    }

    .youtube-rating-item .kiyoh {
        display: none;
        font-size: 13px;
    }
}
