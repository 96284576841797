/*
				       h                                                           d
				     h:h                                                           d:d
				    h::h                                                           d::d
				   h:::h                                                           d:::d
				  h::::h                                                           d::::d
				  h::::h hhhhh            ooooooo          ooooooo         ddddddddd::::d
				  h::::hh:::::hhhh    oooo:::::::oooo  oooo:::::::oooo   dd:::::::::::::d
				  h::::::::::::::::: o:::::::::::::::oo:::::::::::::::o ::::::::::::::::d
				  h:::::::hhh:::::: o::::::ooooo::::::::::::ooooo::::::o ::::::ddddd::::d
				  h::::::h   h:::: o::::::o     o::::::::::o     o::::::o ::::d    d::::d
				  h:::::h     h::: o:::::o       o:::::::::o     o::::::o :::d     d::::d
				  h:::::h     h::: o:::::o       o:::::::::o     o::::::o :::d     d::::d
				  h:::::h     h::: o::::::o     o::::::::::o     o::::::o :::d     d::::d
				  h:::::h     h:::: o::::::ooooo::::::::::::ooooo::::::d :::::ddddd:::::d
				  h:::::h     h::::: o:::::::::::::::oo:::::::::::::::o ::::::::::::::::d
				  h:::::h     h:::::h oooo:::::::oooo  oooo:::::::oooo   d::::::::::::::d
				  hhhhhhh     hhhhhhh     ooooooo          ooooooo        ddddddddddddddd

																	© Copyright Hood 2013

/*------------------------------------------------------------------------------------------ Your media queries */
// These will allow you to use "shortcodes" rather than typing the media queries yourself. Also, these ones will
//  not break when you change the grid layout values.

// Colors
@c-page: #FDFDFD; // Page background color

@c-txt1: #333; // Main text color
@c-txt2: #555; // Secondairy text color

@c-txt-white: #FDFDFD; // Contrasting white text color

@c-lnk1: #444; // Main link color
@c-lnkh1: #2d8dca; // Main link hover color

@c-lnk2: blue; // Secondary link color
@c-lnkh2: teal; // Secondary link hover color

@c-bord1: #CACACA; // Main border color

@c-blue1: #00a2e0; // Primary blue
@c-blue2: #4bbeed; // Lighter blue for certain elements

@c-green1: #5FC719; // Green for shopping cart icon, checkmarks and positive stock statuses in checkout
@c-red: #D6312A; // Red for negative stock statuses in checkout

@media @grid-fluid {

	#checkout #checkout-content #checkout-main #checkout-sub #coupon-code-wrapper {
		border: 1px solid @c-bord1;
		background: @c-page;
		float: none;
		padding: 10px;
		overflow: hidden;

		input {
			height: 35px;
			box-sizing: border-box;
			width: 100%;
			margin-bottom: 10px;
		}

		button {
			float: right;
		}
	}

	body.checkout-cart #checkout #checkout-content #checkout-main #checkout-main-inner {
		padding: 0;
	}

	#product-page-body #product-page-side #product-page-side-inner {

		#product-configuration {
			#config-add-to-cart-wrap .config-block {
				text-align: center;

				button {
					width: 100%;
				}
			}

			.config-wrap {
				position: relative;
				height: 70px;

				.config-block {
					float: none;
					position: absolute;
					width: auto !important;
					left: 54px;
					right: 0;
					margin: 0;
				}

				.config-step {
					float: none;
					position: absolute;
					left: 0;
				}
			}
		}
	}

	#info {
		width: 100%;

		#info-content {
			border: none;
			padding: 0;
			background: none;

			.info-box-body {
				min-height: 5px;
			}

			.info-details-wrapper {
				margin-left: 0;
				float: none;
				width: 100%;
			}
		}
	}

	#checkout #checkout-content #checkout-main {}

	// Bootstrap form elements

	#checkout .form-horizontal {
		.radio.inline {
			margin-right: 10px;
		}

		.radio.inline+.radio.inline {
			margin-left: 0;
			margin-right: 0;
		}

		input[type="text"] {}

		.control-group {
			.control-label {
				text-align: left;
				display: inline;
				float: none;
				width: auto;
			}

			.controls {
				margin-left: 0;
			}
		}
	}

	// Modal

	.modal {
		.modal-body {
			padding: 11px 11px 0 11px;

			p {
				margin-bottom: 6px;
				font-size: 13px;
			}

			form.modal-form {
				.control-group {
					margin-bottom: 8px;

					label {}

					.controls {}

					select {}

					input {}
				}
			}
		}

		.modal-footer {
			.btn {
				margin: 7px auto;
			}
		}
	}

	.modal,
	.modal.container,
	.modal.modal-overflow {
		top: 1%;
		right: 3%;
		left: 3%;
		bottom: auto;
		width: auto !important;
		height: auto !important;
		margin: 0 !important;
		padding: 0 !important;
	}

	.modal.fade.in,
	.modal.container.fade.in,
	.modal.modal-overflow.fade.in {
		top: 2%;
		bottom: auto;
	}

	.modal-body,
	.modal-overflow .modal-body {
		position: static;
		margin: 0;
		height: auto !important;
		max-height: none !important;
		overflow: visible !important;
	}

	.modal-footer,
	.modal-overflow .modal-footer {
		position: static;
	}

	// END Modal


	#desktop-nav,
	// #search,
	#homepage-slider,
	#slider-nav {
		// Hide elements for the mobile layouts all at once
		display: none;
	}

	#nav-main #nav-outer #search {
		position: absolute;
		right: 0;
		top: -58px;
		width: 100%;

		button.btn {
			top: 6px;
			right: 3px;
			font-size: 20px;
			padding-left: 4px;
		}

		input {
			height: 40px;
			width: 100%;
			padding: 1px 42px 1px 10px;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			margin: 0;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			border-radius: 3px;
		}
	}

	#fluid-search-spacer {
		height: 48px;
	}


	#header #logo {
		margin-left: 0;
	}

	#product-page-side {
		margin: 0 auto;
	}

	// Mobile checkout adjustments
	body.checkout-cart #checkout #checkout-content #checkout-main {
		background: none;
		border: none;
		padding: 0;
	}

	#checkout #checkout-content #checkout-main .checkout-item {
		background: #F6F6F6;

		.co-mobile-product-head {
			background: @c-page;
			overflow: hidden;
			padding: 10px;
			border-bottom: 1px solid @c-bord1;

			.co-product-image {
				width: 110px;
				float: left;
			}

			.co-product-description {
				float: left;
				margin-top: 10px;
				min-width: 122px;
			}
		}

		.co-product-amount {
			display: block;
			left: 0;
			right: 0;
			height: 20px;
			text-align: right;
			width: auto;
			margin-left: 10px;
			margin-top: 10px;

			label {
				display: inline-block;
			}

		}

		.co-product-price {
			display: block;
			height: 32px;
			width: auto;
			font-size: 16px;
			margin: 15px 10px 5px 0;
			text-align: right;
			float: right;

			span {
				font-size: 16px;
			}
		}

		.co-product-subtotal {
			display: inline-block;
			font-size: 16px;
			float: right;
			width: auto;
			margin-right: 10px;
			text-align: right;

			span {
				font-weight: normal;
			}
		}

		.co-product-remove {
			float: left;
			width: 35px;
			margin: 11px 0 11px 10px;

			button {
				height: 35px;
				width: 35px;
				margin: 0;
			}
		}

		.co-mobile-product-price-label,
		.co-product-amount-label {
			font-size: 12px;
			line-height: 21px;
		}

		.co-product-subtotal {
			.co-mobile-product-price-label {
				text-align: right !important;
			}
		}

		.co-desktop-product-subtotal-delete {
			clear: both;
			height: 58px;
			border-top: 1px solid @c-bord1;
		}
	}

	#mobile-only-section {
		.checkout-sub-green {
			font-weight: bold;
			color: @c-green1;
		}

		.checkout-sub-title {
			text-align: left;
			float: left;
		}

		.checkout-sub-amount {
			float: right;
		}

		#mobile-checkout-sub {
			border: 1px solid @c-bord1;
			font-size: 16px;
			background: @c-page;
		}

		#mobile-checkout-sub-head {
			background: #F6F6F6;
			padding: 10px;
			border-bottom: 1px solid @c-bord1;
			text-align: right;
			overflow: auto;

			.checkout-sub-amount {
				width: 103px;
			}
		}

		.mobile-checkout-sub-bottom {
			padding: 10px;
			text-align: right;
			overflow: auto;

			.checkout-sub-amount {
				margin-left: 40px;
				width: 125px;
				font-weight: bold;
				float: right;
			}
		}

		.mobile-checkout-button {
			min-height: 40px;
			margin: 20px 0 0 0;

			.btn {
				float: none;
				display: block;
				margin: 0;
				font-size: 20px;
				line-height: 26px;
			}
		}

		#mobile-payment-gateways {
			width: 197px;
			margin: 0 auto 20px auto;
		}
	}

	body.checkout-step-3 #checkout #checkout-content #checkout-main .checkout-overview-area {
		.overview-block.overview-address {
			.overview-address-sub {
				&.overview-address-invoice {
					border: none;
					padding: 0;
				}
			}

			.overview-address-sub {
				padding: 14px 0;
				display: block;
				min-height: 0;
			}
		}

		.overview-block.overview-cart {
			.checkout-item {
				padding: 0;
				border: none;
				border-bottom: 1px solid @c-bord1;
				margin: 0;

				.co-mobile-product-head {}

				.co-product-amount,
				.co-product-price,
				.co-product-subtotal {
					position: relative;
				}

				.co-product-price-subtotal-wrap {
					display: block;
					margin-right: 10px;
					margin-top: 10px;

					.co-product-subtotal {
						margin: 0 0 10px 10px;
						display: block;
					}

					.co-product-price {
						margin: 0 0 0 10px;
						display: block;
					}
				}
			}

			.overview-cart-sub {
				border-top: none;
			}
		}
	}
}

@media @grid-fluid-a {

	.product {
		height: auto;
		min-height: 0;

		.product-text .product-description {
			margin-bottom: 93px;
		}
	}

	#header {
		#winkelwagen {
			width: 165px;
		}
	}

	#header #logo {
		margin-left: 0;

		.logo-signature-a {
			display: none;
		}

		.logo-signature-b {
			display: block;
		}
	}

	#breadcrumbs {
		display: none;
	}

	#relatedproducts #related-header p {
		font-size: 18px;
	}
}

// Extra breakpoints for fluid grid a
@media screen and (max-width: (@breakpoint-xxsmall - 1)) {



	.modal {
		.modal-body {
			form.modal-form.form-horizontal .control-group label.control-label {
				float: none;
				margin-bottom: 0;
			}
		}
	}

	#checkout #checkout-content #checkout-main .checkout-mobile-step-wrapper {
		.checkout-mobile-step-number {
			margin-right: 4px;
			width: 20px;
			height: 20px;
			line-height: 19px;
			font-size: 14px;
			-webkit-border-radius: 20px;
			-moz-border-radius: 20px;
			-o-border-radius: 20px;
			border-radius: 20px;
		}

		.checkout-mobile-step-name {
			font-size: 15px;
		}
	}

	#product-page-body #product-page-side #product-page-side-inner #product-configuration {

		.config-wrap {
			.config-block {
				left: 0;
			}
		}
	}

	#mob-dropdown #mob-dropdown-inner ul li a {
		font-size: 16px;

		.menu-icon {}
	}

	.config-step {
		display: none;
	}
}

@media screen and (max-width: (@breakpoint-xxsmall - 1)) {
	#mobile-side-menu {
		width: 190px;

		ul li a {
			padding-left: 19px;
		}
	}

	#product-page-body #product-page-side #product-page-side-inner #product-configuration {
		#config-add-to-cart-wrap .config-block {
			button {
				font-size: 16px;
			}
		}
	}

	#header #cart #empty-cart-message {
		font-size: 11px;
	}

	#category #category-products {
		#category-header h1 {
			font-size: 16px;
		}

		.sub-category-header h2 {
			font-size: 16px;
		}
	}

	#checkout .form-horizontal {
		position: relative;
		left: 0;

		.control-group {
			.controls {
				width: 92%;
			}

			.input-medium,
			.input-large {
				width: 100%;
			}
		}
	}

	#checkout .checkout-option-bar {
		height: auto;

		input {
			margin: 7px 14px 0 14px;
		}

		h3 {
			font-size: 16px;
			text-align: left;
			display: block;
			padding: 5px;
			margin-left: 36px;
		}
	}

	.modal .modal-footer {
		.btn {

			display: block;
			margin: 8px auto;
		}

		a.btn {
			margin-bottom: 0;
		}
	}

	#header #logo {
		.logo-signature-b {
			display: none;
		}
	}

	#product-page-body #product-page-main {
		#product-page-main-inner #product-page-mobile-top-button {
			width: 100%;

			button {
				width: 100%;
				padding: 4px 0 6px 0;

				span {
					padding: 0;
					left: 2px;
				}
			}
		}

		#mobile-product-configuration #mobile-config-add-to-cart button {
			width: 100%;
		}
	}

	#header #cart {
		width: 110px;
		border: none;

		a h4 {
			margin: 15px 0 1px 0;
		}
	}
}

@media @grid-fluid-b {

	.product {
		.btn.add-to-cart {
			font-size: 18px;

			span {
				padding-left: 4px;
			}
		}
	}

	#header {
		#winkelwagen {
			width: 188px;
		}
	}

	#header #cart {
		width: 190px;
	}

	#header #user-name {
		max-width: 158px;
	}
}

// Extra breakpoint for fluid grid b
@extra-bb2: (@minw-a - 180);
@extra-bb2b: (@fluid-grid-a-transition + 1);

@media @grid-solid {
	.product {
		.product-hover-wrap {
			.product-image-link {
				img {
					-webkit-transition: .25s;
					-moz-transition: .25s;
					-o-transition: .25s;
					transition: .25s;
				}

				position: relative;
			}

			&:hover {
				.product-image-link {
					img {
						opacity: .6;
					}
				}
			}
		}
	}
}

@media @grid-a {

	#nav-main #nav-outer {
		#nav-inner {
			ul#desktop-nav li a {
				padding: 14px 10px;
				font-size: 16px;
			}

			li.last {
				a {
					-webkit-border-top-right-radius: 3px;
					-moz-border-radius-topright: 3px;
					border-top-right-radius: 3px;
				}
			}
		}

		#search {
			top: 48px;
			right: 0;

			input {
				width: 185px;
			}
		}
	}

	#home-main #home-content #homepage-slider #slider {
		.slide-container {
			min-height: 230px;

			.slide-text {
				float: none;
				width: 225px;

				a.call-to-action {
					margin-top: 10px;
					right: 50px;
					bottom: 30px;

					&:after {}
				}
			}

			.slide-image {
				margin-right: 0;
				float: right;
			}
		}
	}

	#home-main #home-content #homepage-slider #slider-nav {
		display: none;
	}
}

@media @grid-b {

	#product-page-main-inner {
		width: 528px;
	}

	#contact #product-questions {

		#product-questions-overlay {

			.product-questions-1 {
				font-size: 20px;
			}

			.product-questions-2 {
				font-size: 14px;
				margin-top: 3px;
			}
		}

		.product-questions-3 {
			font-size: 18px;
		}
	}
}

@media @grid-c {

	#header #cart .btn-group .dropdown-menu {
		left: -87px;
	}

	body.checkout-step-3 #checkout #checkout-content #checkout-main .checkout-overview-area .overview-block.overview-address .overview-address-sub {
		width: 263px;
	}

	#home-main #home-content #homepage-slider #slider .slide-container {
		height: 240px;

		.slide-text {
			width: 639px;

			h3 {
				font-size: 34px;
				font-weight: normal;
			}

			p {
				font-size: 16px;
			}

			a {
				font-size: 27px;
				padding: 14px 23px 14px 30px;

				&:after {
					border-width: 24px;
					right: -24px;
				}
			}
		}
	}

	#home-main #home-content #homepage-slider #slider-nav .slider-buttons a {
		padding: 11px 22px;
		font-size: 15px;
	}

	#nav-main #nav-outer #nav-inner ul#desktop-nav li a {
		padding: 14px 23px;
		font-size: 21px;
	}

	#nav-main #nav-outer #search input {
		width: 187px;
	}

	#checkout-main #checkout-content #checkout-steps .checkout-steps-body a {
		padding: 0 37px 0 45px;
		font-size: 20px;
	}

	#product-page-main-inner {
		width: 824px;
	}
}

// Vertical media queries
@media (max-height: 700px) {
	#mobile-side-menu ul li a {
		padding-top: 5px;
		padding-bottom: 5px;
		font-size: 18px;
	}
}

@media (max-height: 480px) {
	#mobile-side-menu ul li {
		line-height: 15px;

		a {
			padding-top: 2px;
			padding-bottom: 2px;
		}
	}
}

//		Container widths
//-------------------------------------------------------------------------------------------------------------
//	These are the exact widths in which the site is contained.
//	@fluid-grid-a-transition is the breakpoint between Fluid grid A and B.
//-------------------------------------------------------------------------------------------------------------

@fluid-grid-a-transition: 460px;
@grid-a-width: 680px;
@grid-b-width: 900px;
@grid-c-width: 1200px;

//		Columns
//-------------------------------------------------------------------------------------------------------------
//	You can specify the amount of columns here.
//	With @columns you can define a global number of columns that applies to all grids.
//	If you want to specify manual column numbers, set @use-manual-column-numbers to 1.
//	Using manual column numbers is recommended since it allows you to optimize the presentation of your content
//	for every device size. Manual column numbers for the fluid grids is particularly useful, since more than 2
//	or 3 columns on smartphones is usually not a good user experience.
//-------------------------------------------------------------------------------------------------------------
@columns: 3;

@use-manual-column-numbers: 1;
@fluid-a-columns: 1;
@fluid-b-columns: 2;
@grid-a-columns: 3;
@grid-b-columns: 4;
@grid-c-columns: 5;

//		Gutter width in between columns
//-------------------------------------------------------------------------------------------------------------
//	Set it to any value you want. Try to experiment until you're satisfied. Don't use decimals or IE will start
//	to have mental breakdowns and murder your hamster.
//-------------------------------------------------------------------------------------------------------------
@gutter: 8px;

//		Margin in pixels that is maintained between the container width and browser window.
//-------------------------------------------------------------------------------------------------------------
//	By default, the layout jumps down to a smaller grid when the distance to the browser window is smaller than
//	half the @gutter value and vice versa. You can add more margin if your site complains about claustrophobia.
//	Don't use very low or negative values as this creates horizontal scrollbars, especially in IE. Always IE...
//-------------------------------------------------------------------------------------------------------------
@extra-breakpoint-margin: 50px;

//		Fluid Layout
//-------------------------------------------------------------------------------------------------------------
//	@fluid-enabled allows you to choose whether to use a fluid grid or not. Set to 1 to enable or 0 to disable.
//	The fluid grid is active below @grid-a-width + @extra-breakpoint-margin - 1px.
//	With @automatically-generate-fluid-breakpoint set to 1, it automatically calculates the breakpoint based on
//	the width of Grid A. When enabled, the transition to fluid is completely buttery smooth. Sweet!
//	@fluid-gutter controls the padding between the elements themselves and the browser window when the fluid
//	grid is active. Some padding on the sides of the browser is recommended. Your mobile visitors will like it.
//	@fluid-window-padding defines extra padding besides the general padding. You probably won't need it.
//-------------------------------------------------------------------------------------------------------------
@fluid-enabled: 1;
@automatically-generate-fluid-breakpoint: 0;
@manual-fluid-breakpoint: 767px;

@automatically-generate-fluid-gutter: 0;
@manual-fluid-gutter: 0.4%;

@fluid-window-padding: 8%;

//		Container class
//-------------------------------------------------------------------------------------------------------------
//	If you're using any other type of framework that uses the default class '.container', (e.g. Bootstrap) you
//	can specify another class below between the quotes to prevent conflicts. And explosions.
//-------------------------------------------------------------------------------------------------------------
@container_class: ~".grid";

//		That's it! LESS now takes your settings and stuffs it in the functions to create your layouts!
//-------------------------------------------------------------------------------------------------------------
@import "functions.less";
