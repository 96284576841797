.form-header {
    font-size: 20px;
    color: @color-blue-primary;
    margin: 5px 0 10px 0;
    display: inline-block;
}

.form-horizontal {
    .control-group {
        margin-bottom: 0;
    }

    input,
    textarea,
    select {
        margin-bottom: 20px;
    }

    .radio-options {
        margin-bottom: 20px;

        input {
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width: (@breakpoint-large - 1)) {
    .horizontal {
        display: block;
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .form-horizontal .control-label {
        float: none;
        text-align: left;
    }

    .form-horizontal .controls {
        margin-left: 0;
    }

    .form-horizontal input,
    .form-horizontal textarea,
    .form-horizontal select {
        width: 90%;
    }

    .form-horizontal .input-mini {
        width: 45%;
    }

    .form-horizontal .radio-options input {
        width: auto;
    }
}

.control-group {
    &.error {
        margin-bottom: 15px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }

        select {
            margin-bottom: 0 !important;
        }
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .form-horizontal input[type="checkbox"] {
        width: 30px;
    }
}

div.control-group {
  min-height: 45px;
}

// Questionmark tooltip icon. Used in shipping estimates in checkout
.btn.button-questionmark-tooltip {
    padding: 8px 4px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 10px;
    line-height: 0;
    cursor: help;
}

.control-group .controls .questionmark-tooltip {
    position: relative;
    top: -3px;
    left: 15px;
}
