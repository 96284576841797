.modal-box {
    &.modal-box {
        &--added-to-cart {
            .modal-box {
                &__title {
                    font-size: 15px;
                    line-height: 20px;

                    @media screen and (min-width: @breakpoint-medium) {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .modal-box {
        &__add-to-cart {
            display: flex;
            justify-content: center;
            padding: 16px 16px 0 16px;

            @media screen and (min-width: @breakpoint-medium) {
                padding: 20px 20px 0 20px;
            }
        }

        &__product {
            flex: 1 1 auto;
            width: 100%;
            display: flex;

            @media screen and (min-width: @breakpoint-medium) {
                padding: 10px 20px 0 0;
                border-right: 1px solid @color-border-light;
            }
        }

        &__product-image {
            display: inline-block;
            width: 25%;

            @media screen and (min-width: @breakpoint-medium) {
                min-width: 100px;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__product-info {
            display: inline-block;
            width: 75%;
            padding-left: 15px;
        }

        &__product-name {
            margin: 0;
            color: @color-blue-primary;
            font-size: 16px;
        }

        &__product-price {
            display: block;
            margin-top: 4px;
            font-size: 14px;
        }

        &__product-discount {
            display: block;
            margin-top: 4px;
            font-size: 14px;
            color: #5bb75b;
        }

        &__cart {
            display: none;
            flex: 1 1 auto;
            width: 100%;
            padding: 10px 0 0 20px;

            @media screen and (min-width: @breakpoint-medium) {
                display: block;
            }
        }

        &__cart-title {
            margin: 0;
            color: @color-blue-primary;
            font-size: 16px;
        }

        &__cart-products {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 14px;

            li {
                width: 100%;
                display: block;
                margin-top: 3px;

                .price {
                    float: right;

                    &.free {
                        font-weight: bold;
                        color: @color-blue-primary;
                    }
                }
            }
        }

        &__tip {
            width: 100%;
            margin-top: 10px;
            padding: 5px;
            border-radius: 5px;
            background: #e8f2f6;
            border: 1px solid #c1dce8;
            color: #7da9bc;
            font-size: 13px;
            line-height: 21px;
            box-sizing: border-box;
            display: block;
        }

        &__buttons {
            margin-bottom: 0;
            text-align: right;
            background-color: @c-gray-lighter;
            border-top: 1px solid @color-border-dark;
            border-radius: 0 0 6px 6px;
            box-shadow: inset 0 1px 0 @c-white;
            padding: 10px 16px;
            width: 100%;
            float: left;
            box-sizing: border-box;
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            flex-direction: column-reverse;

            @media screen and (min-width: @breakpoint-medium) {
                padding: 10px 25px;
                flex-direction: row;
                margin-top: 10px;
            }

            @media screen and (min-width: @breakpoint-xxlarge) {
                margin-top: 20px;
            }

            .btn {
                width: 100%;
                border: 1px solid @color-border-dark;
                border-color: #e6e6e6 #e6e6e6 #bfbfbf;
                border-bottom-color: #b3b3b3;
                .neuron();
                font-size: 16px;
                transition: none;
                padding: 4px 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media screen and (min-width: @breakpoint-medium) {
                    width: 30%;
                    min-width: 250px;
                    padding: 8px 15px;
                }

                &:hover,
                &:focus {
                    border: 1px solid @color-border-dark;
                    border-bottom-color: #b3b3b3;
                    transition: background-position .1s linear;
                }

                &:not(:first-child) {
                    margin-bottom: 5px;

                    @media screen and (min-width: @breakpoint-medium) {
                        margin-bottom: 0;
                    }
                }

                span {
                    margin-left: 0;
                    top: 0;
                    left: 0;
                }
            }
        }

        @media screen and (max-width: @breakpoint-medium - 1) {
            &__buttons.desktop {
                display: none;
            }

            &__buttons.mobile {
                background-color: #fff;
                margin-top: 10px;

                .btn {
                    font-size: 18px;
                    padding: 8px 15px;
                }
            }
        }

        @media screen and (min-width: @breakpoint-medium) {
            &__buttons.mobile {
                display: none;
            }
        }

        &__related-products {
            display: inline-block;
            width: 100%;
            position: relative;
            margin-bottom: 20px;

            &.related-products {

                .related-products {
                    &__title {
                        font-size: 18px;
                        width: 100%;
                        text-align: center;
                        margin: 15px 0 10px;
                        color: @color-blue-primary;

                        @media screen and (min-width: @breakpoint-large) {
                            margin: 10px 0 20px;
                            padding: 12px 0;
                        }
                    }

                    &__arrows {
                        width: 150px;
                        height: 75px;
                        background: url('../../../img/arrows.png') no-repeat;
                        background-size: 100% auto;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 53px;
                        z-index: 100;
                        display: none;

                        @media screen and (min-width: @breakpoint-medium) {
                            display: block;
                        }
                    }

                    &__products {
                        float: left;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                    }

                    &__product {
                        padding: 15px;
                        padding-bottom: 55px;
                        float: left;
                        min-height: 325px;
                        box-sizing: border-box;
                        overflow: hidden;
                        position: relative;
                        padding-top: 1px;
                        background-color: #fdfdfd;
                        border: 1px solid #cacaca;
                        border-radius: 3px;
                        background: @c-white;
                        width: 88%;
                        margin-bottom: 20px;
                        height: auto !important;

                        @media screen and (min-width: @breakpoint-medium) {
                            width: 42%;
                            margin: 0 4%;
                            padding-bottom: 70px;
                            margin-bottom: 8px;
                            height: auto;
                        }

                        @media screen and (min-width: @breakpoint-xxlarge) {
                            min-height: 400px;
                        }

                        &:not(:first-child) {
                            display: none;

                            @media screen and (min-width: @breakpoint-medium) {
                                display: block;
                            }
                        }

                        a {
                            width: 100%;
                            float: left;
                        }

                        p {
                            float: left;
                            width: 100%;
                            line-height: 18px;

                            @media screen and (min-width: @breakpoint-large) {
                                line-height: 21px;
                            }
                        }

                        .btn.add-to-cart {
                            position: absolute;
                            bottom: 10px;
                            width: 90%;
                            right: 0;
                            text-align: center;

                            @media screen and (min-width: @breakpoint-large) {
                                height: 45px;
                            }
                        }
                    }

                    &__image {
                        width: 100%;
                        float: left;
                        padding: 0 20px;
                        position: relative;
                        box-sizing: border-box;
                        text-align: center;
                        padding-top: 5px;

                        img {
                            max-width: 100%;
                            height: auto;
                            max-height: 125px;

                            @media screen and (min-width: @breakpoint-xxlarge) {
                                max-height: 200px;
                            }
                        }
                    }

                    &__price {
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                        border-radius: 50%;
                        color: @c-white;
                        font-size: 15px;
                        background: @color-blue-primary;
                        text-align: center;
                        position: absolute;
                        bottom: 0;
                        right: 0;

                        @media screen and (min-width: @breakpoint-large) {
                            width: 70px;
                            height: 70px;
                            line-height: 70px;
                            font-size: 18px;
                        }
                    }

                    &__name {
                        font-size: 14px;
                        width: 100%;
                        float: left;
                        margin: 10px 0 5px 0;
                        color: @color-blue-primary;

                        @media screen and (min-width: @breakpoint-medium) {
                            font-size: 16px;
                        }
                    }

                    &__availability {
                        float: left;
                        width: 100%;
                        text-align: left;
                        font-size: 15px;

                        @media screen and (min-width: @breakpoint-medium) {
                            font-size: 16px;
                        }
                    }

                    &__availability-text {
                        display: inline-block;
                        color: @color-text-secondary;
                        font-size: .8em;
                        position: relative;
                        bottom: 0;
                        margin-bottom: 0;
                    }

                    &__availability-circle {
                        width: 11px;
                        height: 11px;
                        margin: 2px 3px 0 0;
                        border-radius: 10px;
                        background: @c-green-light;
                        margin-top: 5px;
                        display: inline-block;
                        float: none;
                    }

                    &__description {
                        display: inline-block;
                        font-size: 13px;

                        @media screen and (min-width: @breakpoint-xxlarge) {
                            font-size: 14px;
                        }

                        p {
                            margin: 0;
                        }
                    }
                }

            }
        }
    }

}
