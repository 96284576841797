.price-agreements {

    .price-agreements {
        &__item {
            background: @c-white;
            border: 1px solid @color-border-light;
            border-radius: 3px;
            padding: 15px 10px;
            box-shadow: 0 0 5px 0 fade(@c-black, 10%);
            transition: all .2s ease-in-out;

            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }

        &__form {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 0;

            @media screen and (min-width: @breakpoint-xsmall) {
                flex-direction: row;
            }
        }

        &__product {
            display: flex;
            flex: 1 1 auto;
            width: 100%;

            @media screen and (min-width: @breakpoint-small) {
                align-items: center;
            }
        }

        &__image-holder {
            display: inline-block;
            width: 100%;
            flex: 0 0 25%;
            margin-right: 10px;
        }

        &__image {
            float: left;
            width: 100%;
            height: 80px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        &__content {
            flex: 1 1 auto;
        }

        &__name {
            margin: 0;
            margin-bottom: 5px;

            @media screen and (min-width: @breakpoint-medium) {
                margin-bottom: 10px;
            }
        }

        &__link {
            color: @color-blue-primary;
            font-size: 18px;
            .neuron;

            &:hover,
            &:focus {
                color: @color-blue-primary;
                text-decoration: underline;
            }
        }

        &__options {
            list-style: none;
            margin: 0;
        }

        &__option {
            font-size: 12px;
            line-height: 17px;

            @media screen and (min-width: @breakpoint-medium) {
                font-size: 13px;
            }
        }

        &__option-name {
            font-weight: bold;
        }

        &__option-value {
            font-size: 14px;
            .neuron();

            @media screen and (min-width: @breakpoint-medium) {
                font-size: 15px;
            }
        }

        &__amount {
            display: flex;
            align-items: center;
            flex: 0 0 25%;
            margin: 15px 5px;
            width: 100%;

            @media screen and (min-width: @breakpoint-large) {
                margin-right: 10px;
                width: auto;
            }

            @media screen and (min-width: @breakpoint-xlarge) {
                margin: 0 10px 0 0;
                flex: 0 0 45%;
            }

            @media screen and (min-width: @breakpoint-xxlarge) {
                margin-right: 10px;
                width: 100%;
                flex: 0 0 35%;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type=number] {
                -moz-appearance: textfield;
            }

            .price-agreements-amount {
                &__holder {
                    display: flex;
                    align-items: center;
                    margin: 0;
                    width: 100%;
                }

                &__inner {
                    position: relative;
                    width: 100%;
                }

                &__counter {
                    color: @color-blue-primary;
                    position: absolute;
                    font-weight: bold;
                    cursor: pointer;
                    font-size: 22px;
                    text-align: center;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    bottom: 0;
                    user-select: none;

                    &--plus {
                        right: 0;
                    }
                }

                &__input {
                    margin-top: 10px;
                    display: block;
                    text-align: center;
                    width: 100%;
                    height: 40px;
                    padding-left: 40px;
                    padding-right: 40px;
                    margin: 0;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &__unit {
                    font-size: 14px;
                    border: 1px solid @color-blue-primary;
                    border-radius: 0 4px 4px 0;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 15px;
                    background: @c-gray-lighter;
                    position: relative;
                    left: -1px;
                    color: @color-blue-primary;
                    cursor: default;
                }
            }
        }

        &__column {
            display: flex;
            align-items: center;
            width: 100%;

            @media screen and (min-width: @breakpoint-medium) {
                flex-direction: row;
            }

            &--left {
                flex-direction: column;

                @media screen and (min-width: @breakpoint-small) {
                    flex: 1 1 auto;
                }

                @media screen and (min-width: @breakpoint-xlarge) {
                    flex-direction: row;
                }
            }

            &--right {
                @media screen and (min-width: @breakpoint-xsmall) {
                    flex: 0 0 35%;
                    flex-direction: column;
                    width: auto;
                }

                @media screen and (min-width: @breakpoint-xlarge) {
                    flex: 0 0 25%;
                }

                @media screen and (min-width: @breakpoint-xxlarge) {
                    flex: 0 0 30%;
                    flex-direction: row;
                }
            }
        }

        &__label {
            margin: 0;
            .neuron();
            font-size: 17px;
            line-height: 12px;
            width: auto;
            margin-right: 15px;

            &--amount {
                @media screen and (min-width: @breakpoint-xsmall) and (max-width: (@breakpoint-small - 1)) {
                    display: none;
                }

                @media screen and (min-width: @breakpoint-medium) and (max-width: (@breakpoint-large - 1)) {
                    display: none;
                }

                @media screen and (min-width: @breakpoint-xlarge) {
                    display: none;
                }
            }

            &--price {
                font-weight: bold;
                text-decoration: underline;
                margin-bottom: 5px;
            }
        }

        &__price-holder {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            padding: 0;
            white-space: nowrap;
            width: 100%;

            @media screen and (min-width: @breakpoint-xsmall) {
                margin-bottom: 20px;
                padding: 0 15px;
            }

            @media screen and (min-width: @breakpoint-large) {
                margin-bottom: 10px;
            }

            @media screen and (min-width: @breakpoint-xxlarge) {
                margin-bottom: 0;
            }
        }

        &__price-inner {
            display: inline-block;
            width: 100%;
            margin-bottom: 3px;
        }

        &__price {
            display: inline-block;
            .neuron();
            color: @color-text-primary;
            text-decoration: line-through;
        }

        &__discount-percentage {
            display: inline-block;
            .neuron();
            font-size: 16px;
            background: @c-green;
            color: @c-white;
            padding: 2px 5px;
        }

        &__discounted-price {
            display: inline-block;
            .neuron();
            color: @c-green;
            font-size: 18px;
        }

        &__button-holder {
            flex: 0 0 auto;

            @media screen and (min-width: @breakpoint-xsmall) {
                width: 100%;
                padding: 0 15px;
            }

            @media screen and (min-width: @breakpoint-xxlarge) {
                width: auto;
            }
        }

        &__button {
            background-color: @c-green;
            border: none;
            border-radius: 3px;
            color: @c-white;
            transition: all .2s ease-in-out;
            font-size: 20px;
            padding: 10px 15px;
            width: 100%;

            &:hover,
            &:focus {
                background-color: @c-green-dark;
            }
        }
    }
}