.categorie-offerte-block {
    float: left;
    width: 100%;
    position: relative;
    min-height: 60px;
    margin: 25px 0;

    @media screen and (min-width: @breakpoint-grid-large) {
        margin: 40px 0;
    }

    .wrapper {
        float: left;
        width: 100%;
        padding: 0 4px;
    }

    .col-md-6 {
        float: left;
        width: 100%;
        box-sizing: border-box;

        @media screen and (min-width: @breakpoint-grid-large) {
            width: ~'calc(50% - 12.5px)';

            &:nth-of-type(1) {
                margin-right: 12.5px;
            }

            &:nth-last-of-type(1) {
                margin-left: 12.5px;
            }
        }

        &.first {
            margin-bottom: 25px;

            @media screen and (min-width: @breakpoint-grid-large) {
                margin-bottom: 0;
            }

            >a {
                display: inline-block;
                width: 100%;

                &:nth-of-type(1) {
                    margin-bottom: 12.5px;
                }

                &:nth-last-of-type(1) {
                    margin-top: 12.5px;
                }
            }

            .col-md-12 {
                height: 140px;

                @media screen and (min-width: @breakpoint-grid-large) {
                    height: 173px;
                }
            }
        }
    }

    .col-md-12 {
        display: inline-block;
        width: 100%;
        position: relative;
        box-sizing: border-box;

        .col-60 {
            float: right;
            width: 65%;
            height: 100%;
            display: flex;
            align-items: center;

            @media screen and (min-width: @breakpoint-xsmall) {
                width: 60%;
            }

            .title {
                font-size: 20px;
                font-weight: bold;
                max-width: 340px;
                padding-right: 8px;
                padding-left: 25px;
                margin-bottom: 35px;
                text-align: right;

                @media screen and (min-width: @breakpoint-xsmall) {
                    text-align: left;
                    font-size: 21px;
                    padding: 0;
                }

                @media screen and (min-width: @breakpoint-small) {
                    font-size: 25px;
                }

                @media screen and (min-width: @breakpoint-grid-large) {
                    max-width: none;
                }
            }
        }
    }

    .underline {
        position: absolute;
        background: @c-blue;
        width: 100%;
        bottom: 0;

        p {
            color: @c-white;
            padding: 5px 0;
            text-align: center;
            width: 100%;
            margin: 3px 0;

            @media screen and (max-width: (@breakpoint-xxsmall - 1)) {
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .categorie-offerte-block {
        .col-md-12 {
            background-position: -40% !important;
        }

        .categorie-last {
            background-position: -60% !important;
        }
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .categorie-offerte-block {
        .col-md-12 {
            background-position: -30% !important;
        }

        .categorie-last {
            background-position: -45% !important;
        }
    }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    .categorie-offerte-block {
        .col-md-12 {
            background-position: -20% !important;
        }

        .categorie-last {
            background-position: -25% !important;
        }
    }
}