body.checkout-step-4 {

    #search,
    #breadcrumbs,
    #usp-topbalk,
    #navigation,
    #cart-normal,
    #mobile-search-wrapper,
    #fluid-search-spacer {
        display: none;
    }

    #header #cart #cart-in-checkout {
        display: block;
    }

    #checkout-complete-wrapper {
        h2 {
            font-size: 24px;
            margin-top: 0;
        }

        h3 {
            font-size: 18px;
        }

        a {
            .obviousLink;
            display: block;
            line-height: 1.5em;
        }
    }

    #facebook-wrapper {
        h4 {
            font-size: 18px;
            margin-top: 0;
        }
    }

    #checkout-steps {
        margin-top: 8px;
    }
}