.review-widget {
  width: 100%;
  margin: 10px 0 0 0;
  border: 2px solid #e8e8e8;
  color: #000;
  font-family: Arial;
  float: left;
  background: @c-white;

  &:hover {
    color: #000;
  }

  .bottom {
    background: #e8e8e8;
    width: 100%;
    text-align: center;
    padding: 0;
    color: #000;
    font-size: 24px;
    line-height: 38px;
    font-weight: bold;
    float: left;
  }

  .inner {
    padding: 5px !important;
    width: 100%;
    box-sizing: border-box;
    float: left;
  }

  .stars {
    width: 100px;
    float: right;
    height: 18px;
    background: #292929;
    position: relative;

    .bar {
      background: url(../../../img/stars.png);
      width: 100%;
      height: 18px;
      float: left;
      position: relative;
      z-index: 10;
    }

    .bar-inner {
      width: 94%;
      float: left;
      background: #f8c541;
      height: 18px;
      position: absolute;
      top: 0;
      z-index: 5;
    }
  }

  .rating {
    background: url(../../../img/round-only-kiyoh.png) center center no-repeat;
    background-size: auto 100%;
    float: left;
    text-align: center;
    line-height: 54px;
    font-size: 18px;
    font-weight: bold;
    width: 70px;
    height: 54px;
  }

  .random_review {
    border: 2px solid #e8e8e8;
    float: left;
    margin-top: 5px;
    font-size: 14px;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .company_name {
    display: none !important;
  }

  .amount {
    float: right;
    font-size: 12px;
    text-align: right;
    margin-left: 20px;

    span {
      display: block;
    }
  }
}

//@media (max-width: @breakpoint-large){
#checkout {
  .review-widget {
    .rating {
      display: block;
      width: 100%;
    }

    .amount {
      display: block;
      width: 100%;
      text-align: center;
      margin: 5px 0 10px 0;
    }

    .stars-span {
      width: 100%;
      margin: 0 auto;
      display: block;
      float: left;
      text-align: center;
      margin-bottom: 5px;
    }

    .stars {
      width: 100px;
      float: none;
      margin: 0 auto;
      height: 18px;
      background: #292929;
      position: relative;

      .bar {
        width: 100px;
        height: 18px;
        float: left;
        position: relative;
        z-index: 10;
      }

      .bar-inner {
        width: 94px;
        float: left;
        background: #f8c541;
        height: 18px;
        position: absolute;
        top: 0;
        z-index: 5;
      }
    }
  }
}
