.payment-index-display-none {
  display: none;
}

.checkout-radio-more {
  padding: 11px 0 5px !important;
}

#payment-method-more {
  label {
      margin: 0 auto;
      display: block;
  }
}

.payment-method-name-price-more {
  width: 100%;
  margin: 0 5px 0 0 !important;
}

.payment-method-name-more {
  text-align: center;
}

.payment-index-last-icon {
  background: url(../../../img/arrow-down.png) center center no-repeat;
  background-size: contain;
  height: 30px;
  width: 30px !important;
  display: block;
  transition: all 0.7s;
  margin: 1px auto 0;
}

.payment-index-hide {
  display: none !important;
  transition: all 1s;
}
