.info-categories {

    .info-categories {
        &__list {
            display: flex;
            align-items: center;
            margin: 0;
            list-style: none;
            border: 1px solid @color-border-dark;
        }

        &__item {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: @c-gray-lighter;
            border-bottom: 1px solid @color-border-dark;

            &--active {
                border-bottom: 1px solid @color-background-page;
                background-color: @color-background-page
            }

            &:not(:nth-last-of-type(1)) {
                border-right: 1px solid @color-border-dark;
            }
        }

        &__icon {
            color: @c-blue;
        }
    }

}