#product-page-content {
    #product-page-head {
        #deliverytime {
            table {
                td {
                    display: table-cell;
                    font-size: 13px;
                    width: 50%;
                    padding-left: 5px;
                    float: unset;
                    border-bottom: 1px solid #dddddd;
                    border-top: none;

                    @media screen and (max-width: (@breakpoint-xsmall - 1)) {
                        &:nth-child(1) {
                            width: unset;
                            min-width: 40%;
                        }
                    }
                }
            }
        }
    }
}