@base-unit: 5px;

.mb-0 { margin-bottom: 0 !important; }
.mt-0 { margin-top: 0 !important; }
.ml-0 { margin-left: 0 !important; }
.mr-0 { margin-right: 0 !important; }

.mb-1 { margin-bottom: @base-unit * 1 !important;}
.mb-2 { margin-bottom: @base-unit * 2 !important;}
.mb-3 { margin-bottom: @base-unit * 4 !important;}
.mb-4 { margin-bottom: @base-unit * 6 !important;}
.mb-5 { margin-bottom: @base-unit * 10 !important;}

.mt-1 { margin-top: @base-unit * 1 !important;}
.mt-2 { margin-top: @base-unit * 2 !important;}
.mt-3 { margin-top: @base-unit * 4 !important;}
.mt-4 { margin-top: @base-unit * 6 !important;}
.mt-5 { margin-top: @base-unit * 10 !important;}

.ml-1 { margin-left: @base-unit * 1 !important;}
.ml-2 { margin-left: @base-unit * 2 !important;}
.ml-3 { margin-left: @base-unit * 4 !important;}
.ml-4 { margin-left: @base-unit * 6 !important;}
.ml-5 { margin-left: @base-unit * 10 !important;}

.mr-1 { margin-right: @base-unit * 1 !important;}
.mr-2 { margin-right: @base-unit * 2 !important;}
.mr-3 { margin-right: @base-unit * 4 !important;}
.mr-4 { margin-right: @base-unit * 6 !important;}
.mr-5 { margin-right: @base-unit * 10 !important;}

.p-0 { .pb-0; .pt-0; .pl-0; .pr-0; }
.p-1 { .pb-1; .pt-1; .pl-1; .pr-1; }
.p-2 { .pb-2; .pt-2; .pl-2; .pr-2; }
.p-3 { .pb-3; .pt-3; .pl-3; .pr-3; }
.p-4 { .pb-4; .pt-4; .pl-4; .pr-4; }
.p-6 { .pb-5; .pt-5; .pl-5; .pr-5; }

.pb-0 { padding-bottom: 0 !important; }
.pt-0 { padding-top: 0 !important; }
.pl-0 { padding-left: 0 !important; }
.pr-0 { padding-right: 0 !important; }

.pb-1 { padding-bottom: @base-unit * 1 !important;}
.pb-2 { padding-bottom: @base-unit * 2 !important;}
.pb-3 { padding-bottom: @base-unit * 4 !important;}
.pb-4 { padding-bottom: @base-unit * 6 !important;}
.pb-5 { padding-bottom: @base-unit * 10 !important;}

.pt-1 { padding-top: @base-unit * 1 !important;}
.pt-2 { padding-top: @base-unit * 2 !important;}
.pt-3 { padding-top: @base-unit * 4 !important;}
.pt-4 { padding-top: @base-unit * 6 !important;}
.pt-5 { padding-top: @base-unit * 10 !important;}

.pl-1 { padding-left: @base-unit * 1 !important;}
.pl-2 { padding-left: @base-unit * 2 !important;}
.pl-3 { padding-left: @base-unit * 4 !important;}
.pl-4 { padding-left: @base-unit * 6 !important;}
.pl-5 { padding-left: @base-unit * 10 !important;}

.pr-1 { padding-right: @base-unit * 1 !important;}
.pr-2 { padding-right: @base-unit * 2 !important;}
.pr-3 { padding-right: @base-unit * 4 !important;}
.pr-4 { padding-right: @base-unit * 6 !important;}
.pr-5 { padding-right: @base-unit * 10 !important;}

.p-0 { .pb-0; .pt-0; .pl-0; .pr-0; }
.p-1 { .pb-1; .pt-1; .pl-1; .pr-1; }
.p-2 { .pb-2; .pt-2; .pl-2; .pr-2; }
.p-3 { .pb-3; .pt-3; .pl-3; .pr-3; }
.p-4 { .pb-4; .pt-4; .pl-4; .pr-4; }
.p-6 { .pb-5; .pt-5; .pl-5; .pr-5; }
