.bestel-fr {
  .methods .method .content2 {
    width: 100%;
  }

  .methods .method .days-big {
    height: 120px;
  }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
  .bestel-fr {
    .methods .method .inner {
      height: 380px;
    }

    .methods .method .days-big {
      height: 130px;
    }
  }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
  .bestel-fr {
    .methods .method .inner-big {
      height: 420px;
    }

    .methods .method .days-big {
      height: 150px;
    }
  }
}

@media (max-width: 510px) {
  .bestel-fr {
    .methods .method .inner-big {
      height: 465px;
    }

    .methods .method .days-middle {
      height: 170px;
    }

    .methods .method .days-big {
      height: 170px;
    }
  }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
  .bestel-fr {
    .methods .method .days-big {
      height: 230px;
    }

    .methods .method .inner-middle {
      height: 480px;
    }
  }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
  .bestel-fr {
    .methods .method .inner-big {
      height: 560px;
    }

    .methods .method .inner-small {
      height: 500px;
    }
  }
}

@media screen and (max-width: (@breakpoint-xxsmall - 1)) {
  .bestel-fr {
    .methods .method .inner-big {
      height: 650px;
    }

    .methods .method .inner-middle {
      height: 520px;
    }
  }
}