#login-forms {

    a.blue {
        display: inline-block;
        color: @color-blue-primary;
        line-height: 21px;

        &:hover,
        &:focus {
            color: @c-blue-dark;
            text-decoration: underline;
        }
    }

    .form-search .hide,
    .form-inline .hide,
    .form-horizontal .hide {
        display: none !important;
    }

    .login-forms {
        &--new {
            margin-top: 16px;

            @media screen and (min-width: @breakpoint-medium) {
                margin-top: 0;
            }

            .form-header {
                display: inline-block;
                width: 100%;
                margin: 8px 0 16px;
                color: @c-blue;
                font-size: 24px;
                text-align: center;
            }

            .page-box {
                .box-body {
                    padding: 16px 32px;
                }
            }

            .input-large {
                @media screen and (min-width: @breakpoint-xsmall) {
                    width: 255px;
                }
            }
        }

        i.toggle-password {
            margin-left: -30px;
            cursor: pointer;
        }
    }

    .form-horizontal {

        &.form-horizontal {
            &--new {

                .input-large {
                    width: 100%;

                    @media screen and (min-width: @breakpoint-large) {
                        width: 250px;
                    }
                }

            }
        }

        * {
            box-sizing: border-box;
        }

        .input-mini {
            width: 100px;
        }

        .control-group {
            display: flex;
            margin-bottom: 8px;
            flex-wrap: wrap;

            @media screen and (min-width: @breakpoint-medium) {
                flex-wrap: nowrap;
            }

            &.error {
                .control-label {
                    color: @color-text-primary;
                }

                .help-inline,
                .checkbox,
                .radio,
                .input,
                .select,
                .textarea {
                    color: @c-red;
                }

                input {
                    border-color: @c-red;
                    color: @color-text-primary;

                    &[required] {
                        -webkit-box-shadow: 0 0 6px #f8b9b7;
                        -moz-box-shadow: 0 0 6px #f8b9b7;
                        box-shadow: 0 0 6px #f8b9b7;
                    }

                    &:focus {
                        border-color: rgba(82, 168, 236, 0.8);
                        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
                        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
                        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
                    }

                    &:not(:focus) {
                        background-color: @c-red-light  !important;
                    }

                    &[type="radio"] {
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                    }
                }
            }

            .help-inline {
                display: block;
                margin-bottom: 5px;
                padding-left: 0;
                font-size: 13px;

                &.error {
                    color: @c-red;
                }
            }

            .control-label {
                width: 124px;
                margin-right: 15px;
                padding-top: 8px;

                @media screen and (max-width: (@breakpoint-medium - 1)) {
                    text-align: left;
                    display: inline;
                    float: none;
                    width: auto;
                }

                &.control-label--empty {
                    padding-top: 0;
                    margin-bottom: 0;
                    display: none;

                    @media screen and (min-width: @breakpoint-large) {
                        display: inline;
                    }
                }
            }

            >.control-label {
                font-size: 12px;
                flex: 1 1 100%;
                width: auto;
                font-weight: bold;
                margin-bottom: 0;
                padding-top: 0;

                @media screen and (min-width: @breakpoint-medium) {
                    font-weight: normal;
                    font-size: 14px;
                    padding-top: 8px;
                    flex: 0 0 30%;
                }

                @media screen and (min-width: @breakpoint-large) {
                    flex: 0 0 30%;
                    margin-bottom: 5px;
                }
            }

            >.controls {
                flex: 1 1 100%;
                width: auto;
                margin-left: 0;

                @media screen and (min-width: @breakpoint-medium) {
                    flex: 1 1 70%;
                }

                @media screen and (min-width: @breakpoint-large) {
                    flex: 1 1 70%;
                }
            }
        }

        .radio-options {
            margin-bottom: 0;

            @media screen and (min-width: @breakpoint-medium) {
                padding-top: 8px;
            }
        }

        .autocomplete-status-text {
            min-height: 17px;
            margin-bottom: 10px;
        }

        .postal-code-error {
            display: inline-block;
            max-width: 500px;
            font-size: 13px;
        }

        .postal-code-success {
            font-size: 13px;

            &__additional-text {
                display: inline-block;
                white-space: nowrap;
                color: #999;
                margin-right: 5px;
                font-size: 14px;
            }

            &__link {
                overflow: hidden;
                background-color: transparent;
                cursor: pointer;
                background: transparent;
                border: 0;
                padding: 0;
                color: @color-blue-primary;
                line-height: initial;
                vertical-align: unset;
                font-size: initial;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }

        select,
        textarea,
        input[type="text"],
        input[type="tel"],
        input[type="password"],
        input[type="email"],
        .uneditable-input {
            height: 40px;
            padding: 8px 15px 8px 10px;
            margin-bottom: 5px;
            border-radius: 2px;
            transition: background-color .2s ease-in-out;
            box-shadow: none;

            &:hover {
                border-color: #888 !important;
            }

            &:focus {
                border-color: @color-blue-primary  !important;
                box-shadow: unset !important;
            }
        }

        #businessFields {
            background: #eee;
            padding: 15px 15px 10px;
            margin-bottom: 15px;

            @media screen and (min-width: @breakpoint-large) {
                padding: 15px 0 10px;
            }
        }

        .form__radio {
            display: flex;
            flex-flow: row wrap;

            &--inline {
                display: inline-flex;
            }

            .form__radio-input {
                &:checked {
                    +.form__radio-label {
                        .form__radio-radio {
                            background-color: @c-white;

                            &::after {
                                background-color: @c-blue;
                            }
                        }
                    }
                }
            }

            .form__radio-label {
                cursor: pointer;
                display: flex;
                padding: 2px 10px 2px 25px;
                position: relative;
                transition: opacity .3s ease-out, color .3s ease-out;
                width: 100%;
                margin: 0;
                align-items: center;
                top: 0;

                @media screen and (min-width: @breakpoint-small) {
                    padding: 2px 15px 2px 25px;
                }

                &:hover,
                &:focus {
                    .form__radio-radio {
                        &::after {
                            background-color: @color-border-dark;
                        }
                    }
                }
            }

            .form__radio-radio {
                position: absolute;
                top: 50%;
                left: 0;
                display: inline-block;
                height: 18px;
                width: 18px;
                background-color: @c-white;
                border: 1px solid @color-border-dark;
                border-radius: 50%;
                transform: translateY(-50%);
                transition: border .1s ease-out, background-color .1s ease-out, opacity .1s ease-out;
                box-sizing: border-box;

                &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    display: block;
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    background: transparent;
                    transform: translate(-50%, -50%);
                    transition: background-color .1s ease-out;
                }
            }

            .form__radio-content {
                width: 100%;
                font-size: 12px;
                text-align: center;

                @media screen and (min-width: @breakpoint-small) {
                    font-size: 13px;
                }
            }
        }
    }
}
