#roadmap {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: @breakpoint-large) {
        flex-direction: row;
    }

    .roadmap {
        &__form-holder {
            flex: 1 1 100%;
            padding: 40px 25px;
            position: relative;
            background-image: url('../../../img/vloer-rol-dark.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            @media screen and (min-width: @breakpoint-xxlarge) {
                padding: 40px;
            }

            &::after {
                content: '';
                position: absolute;
                background-color: @c-black;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: .1;
                z-index: 1;
            }
        }

        &__title {
            font-size: 24px;
            color: @c-white;
            margin: 0 0 15px 0;
            position: relative;
            z-index: 2;

            @media screen and (min-width: @breakpoint-xlarge) {
                font-size: 26px;
            }

            @media screen and (min-width: @breakpoint-xxlarge) {
                font-size: 28px;
            }
        }

        &__intro {
            display: inline-block;
            width: 100%;
            margin-bottom: 15px;
            position: relative;
            z-index: 2;

            p {
                font-size: 13px;
                line-height: 20px;
                color: @c-white;
                margin: 0;

                @media screen and (min-width: @breakpoint-grid-large) {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

        &__form {
            position: relative;
            z-index: 2;
            margin: 0;

            .form {
                &__fields {
                    width: 100%;
                }

                &__column {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    @media screen and (min-width: @breakpoint-large) {
                        flex-direction: row;
                    }

                    .form__field {
                        @media screen and (min-width: @breakpoint-large) {
                            width: ~'calc(100% - 7.5px)';

                            &:not(:nth-last-of-type(1)) {
                                margin-right: 15px;
                            }
                        }

                        @media screen and (min-width: @breakpoint-xxlarge) {
                            width: ~'calc(100% - 12.5px)';

                            &:not(:nth-last-of-type(1)) {
                                margin-right: 25px;
                            }
                        }
                    }
                }

                &__field,
                &__button-holder {
                    width: 100%;
                    transition: all .15s ease-in;
                }

                &__field {
                    &--small {
                        width: 50%;

                        @media screen and (min-width: @breakpoint-medium) {
                            width: ~'calc(50% - 7.5px)';
                        }
                    }
                }

                &__label {
                    font-size: 14px;
                    color: @c-white;
                    .neuron();

                    @media screen and (min-width: @breakpoint-large) {
                        font-size: 15px;
                    }
                }

                &__input,
                &__select {
                    width: 100%;
                    height: 35px;
                    margin-bottom: 15px;
                    box-sizing: border-box;
                }

                &__buttons {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 10px;

                    @media screen and (min-width: @breakpoint-grid-large) {
                        flex-direction: row;
                    }
                }

                &__button {
                    display: block;
                    width: 100%;
                    border: 0;
                    border-bottom: 3px solid @c-blue-darker;
                    font-size: 17px;
                    cursor: pointer;
                    border-radius: 4px;
                    text-align: center;
                    text-decoration: none;
                    padding: 10px;
                    line-height: inherit;
                    box-sizing: border-box;
                    transition: all .15s ease-in;
                    .neuron();

                    &--primary {
                        color: @c-white;
                        background-color: @c-blue;

                        &:hover,
                        &:focus {
                            background-color: @c-blue-dark;
                        }
                    }

                    &--secondary {
                        color: @c-white;
                        background-color: @c-grey-dark;

                        &:hover,
                        &:focus {
                            background-color: @c-grey-darker;
                        }
                    }
                }
            }
        }

        &__usps {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1 1 100%;
            background-color: @c-blue;
            box-sizing: border-box;

            @media screen and (min-width: @breakpoint-large) {
                flex: 0 0 300px;
            }

            @media screen and (min-width: @breakpoint-grid-large) {
                flex: 0 0 350px;
            }
        }

        &__inner {
            padding: 40px 25px;

            @media screen and (min-width: @breakpoint-xxlarge) {
                padding: 40px;
            }
        }

        &__subtitle {
            font-size: 22px;
            line-height: 31px;
            text-align: center;
            color: @c-white;
            margin: 0 0 15px 0;
        }

        &__list {
            margin: 0;
            list-style: none;
        }

        &__item {
            display: flex;
            align-items: flex-start;
            flex-direction: row;

            &:not(:last-of-type) {
                margin-bottom: 15px;
            }
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            background-color: @c-white;
            border-radius: 50%;
            color: @c-blue;
            text-align: center;
            font-size: 14px;
        }

        &__text {
            color: @c-white;
            font-size: 14px;
        }

        &__reviews {
            background-color: #E8E8EA;

            &--fr {
                background-color: @c-white;
                padding: 10px 0;
            }

            * {
                box-sizing: border-box;
            }

            .review-widget {
                margin: 0;
                color: @color-text-primary;
                line-height: 1em;

                .inner {
                    padding: 10px !important;
                }

                .random_review {
                    margin-top: 10px;
                    line-height: 18px;
                }

                .bottom {
                    font-size: 20px;
                    line-height: 35px;
                }
            }
        }
    }
}
