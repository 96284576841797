body.checkout-step-3 {

    #search,
    #breadcrumbs,
    #usp-topbalk,
    #navigation,
    #cart-normal,
    #mobile-search-wrapper,
    #fluid-search-spacer {
        display: none;
    }

    #header #cart #cart-in-checkout {
        display: block;
    }

    #checkout #checkout-content #checkout-main {
        .checkout-bar {
            position: relative;
            margin-bottom: -1px;
            -webkit-border-top-left-radius: 3px;
            -webkit-border-top-right-radius: 3px;
            -moz-border-radius-topleft: 3px;
            -moz-border-radius-topright: 3px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;

            .edit-link {
                .obviousLink;
                position: absolute;
                right: 10px;
                top: 12px;
            }
        }

        .checkout-overview-area {

            .overview-block {
                -webkit-border-bottom-right-radius: 3px;
                -webkit-border-bottom-left-radius: 3px;
                -moz-border-radius-bottomright: 3px;
                -moz-border-radius-bottomleft: 3px;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;

                min-height: 10px;
                margin-bottom: 20px;
                border: 1px solid @color-border-dark;

                padding: 14px;

                p {
                    font-size: 12px;
                    margin: 0;
                }

                &.overview-cart {
                    padding: 0;

                    .overview-cart-header {
                        height: 15px;
                        padding: 12px 14px;
                        position: relative;
                        border-bottom: 1px solid @color-border-light;

                        .overview-cart-header-column {
                            display: inline-block;
                            font-weight: bold;
                            position: absolute;

                            &.overview-cart-header-product {
                                left: 14px;
                            }

                            &.overview-cart-header-amount {
                                right: 217px;
                            }

                            &.overview-cart-header-price {
                                right: 126px;
                            }

                            &.overview-cart-header-subtotal {
                                right: 14px;
                            }
                        }

                        .overview-cart-header-right {
                            float: right;
                        }
                    }

                    .checkout-item {
                        position: relative;
                        min-height: 70px;
                        margin: 0 14px 5px 14px;
                        padding-top: 10px;

                        @media screen and (min-width: 746px) {
                            .co-product-amount {
                                font-size: 16px;
                                margin-top: 20px;
                                position: absolute;
                                right: 219px;
                                width: auto;
                                float: none;

                                .co-product-amount-label {
                                    display: inline;
                                }

                                .checkout-confirm-amount {
                                    display: inline;
                                    font-weight: bold;
                                }
                            }

                            .co-product-price {
                                font-size: 16px;
                                position: absolute;
                                right: 100px;
                                width: auto;
                                float: none;
                            }

                            .co-product-subtotal {
                                font-size: 16px;
                                position: absolute;
                                right: 0;
                                width: auto;
                                float: none;
                            }
                        }

                    }

                    .giveaway {
                        background: none;
                        margin: 0 14px 5px 14px;
                        box-shadow: none;

                        .giveaway-image {
                            width: 14.8%;
                            border: 0px;
                        }

                        .giveaway-title {
                            width: 63%;
                        }

                        .giveaway-price {
                            text-align: right;
                            width: 15%;
                            font-size: 16px;
                        }

                        .giveaway-amount {
                            width: 6%;
                            padding-top: 25px;
                            box-sizing: border-box;

                            span {
                                background: transparent;
                                padding: 0px;
                                border: 0px;
                                border-radius: 0px;
                                font-weight: bold;
                                font-size: 16px;
                            }
                        }

                        @media screen and (max-width: (@breakpoint-medium)) {
                            border: none;
                            border-bottom: 1px solid #cacaca;
                            margin:0px;
                            padding: 10px;

                            .giveaway-title {
                                padding: 0 5px 0 10px;
                                margin: 0 0 0 5px;
                                width: 50%;
                            }

                            .giveaway-image {
                                width: 75px;
                            }

                            .giveaway-price {
                                width: 25%;
                                padding: 10px 0 0 0;
                            }

                            .giveaway-amount {
                                padding-top: 10px;
                                span {
                                    font-size: 14px;
                                    font-weight: normal;
                                }
                            }
                        }
                    }

                    .overview-cart-sub {
                        font-size: 14px;
                        margin: 10px 14px 5px 14px;
                        border-top: 1px solid @color-border-light;
                        position: relative;

                        .overview-cart-shipping,
                        .overview-cart-subtotal,
                        .overview-cart-btw,
                        .overview-cart-discount {
                            position: relative;
                            height: 30px;
                        }

                        .overview-cart-total {
                            position: relative;
                            height: 30px;
                            font-weight: bold;

                            .overview-cart-sub-name {
                                top: 8px;
                            }
                        }

                        .overview-cart-total.discount {
                            span.overview-cart-sub-amount {
                                color: #5bb75b;
                            }
                        }

                        .overview-cart-sub-name {
                            position: absolute;
                            top: 6px;
                            right: 110px;
                        }

                        .overview-cart-sub-amount {
                            position: absolute;
                            top: 6px;
                            right: 0;
                        }
                    }
                }

                &.overview-address {
                    padding: 0 14px;

                    .overview-address-sub {
                        min-height: 133px;
                        padding: 14px 0 14px 30px;
                        display: inline-block;
                        vertical-align: top;
                        width: 169px;
                        font-size: 14px;
                        line-height: 1.4em;

                        &.overview-address-type {
                            padding: 14px 0;
                        }

                        &.overview-address-invoice {
                            border-right: 1px solid @color-border-dark;
                            border-left: 1px solid @color-border-dark;
                        }
                    }

                    .overview-address-divider {
                        display: inline-block;
                        width: 1px;
                        height: 100%;
                        background-color: @color-border-dark;
                    }
                }

                &.overview-shipping {
                    .shipping-method-name-price {
                        display: inline-block;
                        vertical-align: top;

                        .shipping-method-price {
                            display: block;
                            font-weight: normal;
                            font-size: 14px;
                        }

                        .shipping-method-name {
                            display: block;
                            font-weight: bold;
                            font-size: 20px;
                            .neuron;
                        }
                    }

                    .shipping-method-icon {
                        background-image: url('../../../../img/sprite.png');
                        background-size: 400px;
                        width: 65px;
                        height: 40px;
                        display: inline-block;
                        margin-right: 20px;

                        &#shipping-icon-pickup {
                            background-position: 169px -89px;
                        }

                        &#shipping-icon-truckFreight {
                            background-position: 240px -183px;
                        }

                        &#shipping-icon-postnl {
                            background-position: -560px 200px;
                        }

                        &#shipping-icon-colisprive {
                            background-position: -480px 150px;
                        }

                        &#shipping-icon-budbee {
                            background-position: 102px 245px;
                        }

                        &#shipping-icon-existing_order {
                            background-position: 185px 200px;
                        }
                    }
                }

                &.overview-payment {
                    .payment-method-name-price {
                        display: inline-block;
                        vertical-align: top;
                        margin: 10px 5px 0 20px;

                        .payment-method-name {
                            display: block;
                            font-weight: bold;
                            font-size: 20px;
                            .neuron;
                        }

                        .payment-method-price {
                            display: block;
                        }
                    }

                    .payment-method-icon {
                        background-image: url('../../../../img/sprite.png');
                        background-size: 400px;
                        width: 65px;
                        height: 40px;
                        display: inline-block;

                        &#payment-method-icon-ideal {
                            background-position: 0 0;
                        }

                        &#payment-method-icon-paypal {
                            background-position: -240px 0;
                        }

                        &#payment-method-icon-mastercard {
                            background-position: -480px 0;
                        }

                        &#payment-method-icon-bancontact {
                            background-position: -160px 0;
                        }

                        &#payment-method-icon-visa {
                            background-position: -320px 0;
                        }

                        &#payment-method-icon-moneyorder {
                            background-position: -160px -48px;
                        }

                        &#payment-method-icon-account {
                            background-position: 0 -123px;
                        }

                        &#payment-method-icon-billink, &#payment-method-icon-billinkwrisk, &#payment-method-icon-billinkb2c {
                            background-position: 0 -165px;
                        }

                        &#payment-method-icon-billie {
                            background-position: 0 -123px;
                        }

                        &#payment-method-icon-directbank {
                            background-position: 0 -248px;
                        }

                        &#payment-method-icon-cartebleue {
                            background-position: 0 -205px;
                        }

                        &#payment-method-icon-inghome {
                            background-position: -80px -138px;
                        }

                        &#payment-method-icon-giropay {
                            background-position: -80px -183px;
                        }

                        &#payment-method-icon-sofort {
                            background-position: -1px -247px;
                        }

                        &#payment-method-icon-kbc {
                            background-position: -80px -92px;
                        }

                        &#payment-method-icon-americanexpress {
                            background-position: -159px -92px;
                        }

                        &#payment-method-icon-in3 {
                            background-position: -229px -138px;
                        }
                        &#payment-method-icon-creditcard {
                            background-position: -298px -248px;
                        }
                        &#payment-method-icon-klarnapaylater {
                            background-position: -1px -291px;
                        }
                        &#payment-method-icon-klarnasliceit {
                            background-position: -158px -291px;
                        }
                    }
                }

                &.paypal-card-fields {
                  #paypal-card-form {
                    padding: 0;
                  }

                  #paypal-card-explanation {
                    background: #f8f9fa;
                    padding: 10px;
                    border-radius: 5px;
                    margin-bottom: 15px;
                    font-size: 14px;
                    display: flex;

                    p {
                      margin-left: 5px;
                    }
                  }

                  .paypal-fields-combined {
                    display: flex;
                    flex-wrap: wrap;

                    #card-expiry-field-container, #card-cvv-field-container {
                      flex-basis: 0;
                      flex-grow: 1;
                      max-width: 100%;
                      min-height: 1px;
                      position: relative;
                      width: 100%;
                    }
                  }
                }
            }
        }
    }
}

.checkout-step-3 {
    #checkout-steps {
        float: left;
        width: 100%;
        margin-top: 8px;
    }

    #checkout-main {
        float: left;
        width: 100%;
    }
}


body.checkout-step-3 #checkout #checkout-content #checkout-main .checkout-overview-area .overview-block p .cart-option {
    width: auto !important;
    display: inline-block !important;
}

body.checkout-step-3 #checkout #checkout-content #checkout-main .checkout-overview-area .overview-block.overview-cart .checkout-item .co-mobile-product-head .co-product-image {
    margin-top: 0 !important;
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    body.checkout-step-3 #checkout #checkout-content #checkout-main .checkout-overview-area .overview-block.overview-cart .checkout-item .co-mobile-product-head .co-product-image {
        margin-top: 5px !important;
        width: 55px !important;
    }
}
