// Default white box with grey border and rounded corners
.box (@radius: 3px) {
  background-color: @color-background-page;
  border: 1px solid @color-border-dark;
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  -ms-border-radius: @radius;
  -o-border-radius: @radius;
  border-radius: @radius;
}

// Main padding left and right
.padh1 (@p: 19px) {
  padding-left: @p;
  padding-right: @p;
}

// Secondary padding left and right
.padh2 (@p: 7px) {
  padding-left: @p;
  padding-right: @p;
}

// Primary margin bottom to correspond with default gutter of 8px
.marb1 (@m: 8px) {
  margin-bottom: @m;
}

.clearfix() {
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

.obviousLink () {
  color: @color-blue-primary;
  text-decoration: underline;

  &:hover {
    color: @c-blue2;
  }
}

// Gradient with vendor prefixes
.gradient(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
  background: @color;
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0, @start),
      color-stop(1, @stop));
  background: -ms-linear-gradient(bottom,
      @start,
      @stop);
  background: -moz-linear-gradient(center bottom,
      @start 0%,
      @stop 100%);
  background: -o-linear-gradient(@stop,
      @start);
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)", @stop, @start)); // Normal gradient
}

// Tricolore gradient with vendor prefixes
.gradient-tri(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
  background: @color;
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0, @start),
      color-stop(.5, @stop),
      color-stop(1, @start));
  background: -ms-linear-gradient(bottom,
      @start,
      @stop,
      @start);
  background: -moz-linear-gradient(center bottom,
      @start 0%,
      @stop 50%,
      @start 100%);
  background: -o-linear-gradient(@stop,
      @start @stop);
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)", @stop, @start, @stop)); // Normal gradient
}

// Gradient as background-image to allow transition on hover states
.gradient-img(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
  background-image: @color;
  background-image: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0, @start),
      color-stop(1, @stop));
  background-image: -ms-linear-gradient(bottom,
      @start,
      @stop);
  background-image: -moz-linear-gradient(center bottom,
      @start 0%,
      @stop 100%);
  background-image: -o-linear-gradient(@stop,
      @start);
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)", @stop, @start));
}

// Inner shadow with vendor prefixes
.inner-shadow(@horizontal: 0, @vertical: 1px, @blur: 2px, @alpha: 0.4) {
  -webkit-box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
  -moz-box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
  box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
}

// Box shadow with vendor prefixes
.drop-shadow(@x-axis: 0, @y-axis: 1px, @blur: 2px, @alpha: 0.1) {
  -webkit-box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
  -moz-box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
  box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
}

// Text shadow with vendor prefixes
.text-shadow(@x-axis: 0, @y-axis: 1px, @blur: 2px, @r, @g, @b, @alpha: 0.1) {
  text-shadow: @x-axis @y-axis @blur rgba(@r, @g, @b, @alpha);
}

// Border radius with vendor prefixes
.rounded(@radius: 3px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}