.order-product-slider {
  position: relative;
}

.order-product-slider__container {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  }
}

.order-product-slider__track {
  display: inline-flex;
}

.order-product-slider__fade-left,
.order-product-slider__fade-right {
  opacity: 0;
  transition: opacity .3s;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80px;
  pointer-events: none;

  @media screen and (max-width: @breakpoint-xsmall - 1) {
    width: 40px;
  }

  img {
    position: absolute;
    top: ~"calc(50% - 20px)";
    height: 40px;
  }

  &--active {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }
}

.order-product-slider__fade-left {
  left: 0;
  background: linear-gradient(90deg, white, transparent);

  .order-product-slider__arrow {
    left: 10px;
  }
}

.order-product-slider__fade-right {
  right: 0;
  background: linear-gradient(-90deg, white, transparent);

  .order-product-slider__arrow {
    right: 10px;
  }
}