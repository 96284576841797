#product-page-body {
    #product-page-content {

        #advice_side {
            @media screen and (max-width: (@breakpoint-large - 1)) {
                padding-bottom: 0;
                float: none;
                display: inline-block;
            }

            @media screen and (max-width: (@breakpoint-small - 1)) {
                padding-bottom: 15px;
            }

            .advice-person {

                @media screen and (max-width: (@breakpoint-large - 1)) {
                    display: block;
                }

                @media screen and (max-width: (@breakpoint-medium - 1)) {
                    width: 100%;
                    max-width: 200px;
                }

                @media screen and (max-width: (@breakpoint-small - 1)) {
                    display: none;
                }
            }

            .btn-blue-style {
                color: @c-white;

                @media screen and (max-width: (@breakpoint-small - 1)) {
                    margin: 0 auto;
                }

                a {
                    color: @c-white;
                }
            }

            .text {
                @media screen and (max-width: (@breakpoint-large - 1)) {
                    width: 60%;
                    float: right;
                }

                @media screen and (max-width: (@breakpoint-small - 1)) {
                    width: 100%;
                    float: right;
                    padding-left: 0;
                    padding-right: 0;
                }

                h4 {
                    font-size: 22px;
                    font-weight: 500;
                    color: #00A2E0;
                    width: 100%;
                    margin: 8px 0 12px 0;
                    float: left;

                    @media screen and (max-width: (@breakpoint-large - 1)) {
                        margin: 31px 0 12px 0;
                    }

                    @media screen and (max-width: (@breakpoint-small - 1)) {
                        margin-top: 10px;
                    }
                }
            }

            .inner {
                @media screen and (max-width: (@breakpoint-small - 1)) {
                    text-align: center;
                    margin: 0 auto;
                    width: 251px;
                }

                @media screen and (max-width: (@breakpoint-xsmall - 1)) {
                    width: unset;
                }
            }

            .rule {
                float: left;
                font-size: 14px;
                margin: 0 14px 14px 0;

                @media screen and (max-width: (@breakpoint-xsmall - 1)) {
                    width: 100%;
                }

                &.no-whatsapp {
                    @media screen and (max-width: (@breakpoint-small - 1)) {
                        margin-right: 0;
                    }

                    @media screen and (max-width: (@breakpoint-xsmall - 1)) {
                        display: flex;
                        justify-content: center;
                    }
                }

                img {
                    margin-top: -3px;
                }
            }

            .advice-person-mobile {
                display: none !important;

                @media screen and (max-width: (@breakpoint-small - 1)) {
                    display: block !important;
                    width: 100%;
                    max-width: 100%;
                    float: left;
                    margin-top: 15px;
                }

                img {
                    @media screen and (max-width: (@breakpoint-small - 1)) {
                        max-width: 250px;
                        display: block;
                        margin: 0 auto;
                        float: unset;
                    }
                }
            }
        }
    }
}