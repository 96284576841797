.modal-box {
    &__workshops {
        &.workshop {

            .workshop {
                &__title {
                    display: inline-block;
                    width: 100%;
                    font-size: 26px;
                    margin: 0 0 10px 0;
                    color: @color-blue-primary;
                }

                &__subtitle {
                    display: inline-block;
                    width: 100%;
                    font-size: 18px;
                    margin: 10px 0 5px 0;
                }

                &__intro {
                    font-size: 13px;
                }

                &__swatch {
                    display: inline-block;
                    width: 100%;
                    padding: 4px 4px;
                    box-sizing: border-box;
                    cursor: pointer;
                    transition: width .15s ease-in-out;

                    @media screen and (min-width: @breakpoint-xsmall) {
                        width: 50%;
                    }

                    @media screen and (min-width: @breakpoint-small) {
                        width: 33.33%;
                        width: ~'calc(100% / 3)';
                    }

                    @media screen and (min-width: @breakpoint-medium) {
                        width: 25%;
                    }

                    &--active {
                        .workshop__swatch-inner {
                            border-color: @color-blue-primary;
                        }
                    }
                }

                &__swatch-inner {
                    display: inline-block;
                    width: 100%;
                    height: 160px;
                    border: 4px solid @c-white;
                    box-shadow: 0 0 4px fade(@c-black, 20%);
                    color: @c-white;
                    background: @c-white;
                    padding: 12px 10px 10px 10px;
                    box-sizing: border-box;
                    position: relative;
                    text-shadow: 0 0 1px fade(@c-black, 10%);
                }

                &__date-name {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    margin-top: 30px;
                    font-size: 15px;
                    font-weight: normal;
                    color: fade(@c-white, 90%);
                }

                &__date-number {
                    display: inline-block;
                    width: 100%;
                    font-size: 30px;
                    text-align: center;
                }

                &__date-month {
                    width: 100%;
                    font-size: 16px;
                    text-align: center;
                }

                &__status {
                    position: absolute;
                    bottom: 0;
                    margin-bottom: 7px;
                    font-size: 12px;
                }

                &__time {
                    position: absolute;
                    top: 0;
                    font-size: 13px;
                    width: 100%;
                    background: #2f7095;
                    left: 0;
                    text-align: center;
                    padding: 7px 0;
                }

                &__circle {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    margin-top: 3px;
                    border-radius: 20px;
                    margin-right: 7px;
                    box-shadow: 0 0 4px fade(@c-black, 20%);
                }
            }

        }
    }
}