.modal-box {
    height: 100%;
    min-width: 32rem;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 99999994;
    opacity: 1;
    visibility: visible;
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out;

    &::before {
        content: '';
        height: 100%;
        vertical-align: middle;
        display: none;
    }

    +.modal-box {
        z-index: 99999995;
    }

    &.modal-box {
        &--full-screen-sticky-bar {
            .modal-box {
                &__title {
                    padding: 0;
                }

                &__content {
                    padding: 0;
                }
            }
        }

        &--full-screen {
            box-shadow: none;
            right: 0;
            outline: none;

            .modal-box {
                &__container {
                    border-radius: 0;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    max-width: none;
                    -ms-transform: translateX(~'calc(50% + 50vw)');
                    -webkit-transform: translateX(~'calc(50% + 50vw)');
                    transform: translateX(~'calc(50% + 50vw)');
                }

                &__wrapper {
                    margin-top: 44px;
                    height: ~'calc(100% - 44px)';
                }

                &__header {
                    background-color: @color-blue-primary;
                    left: 0;
                    padding: 12px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 2;
                }

                &__title {
                    color: @c-white;
                    overflow: hidden;
                    text-align: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &__content {
                    height: 100%;
                    padding: 0;
                    overflow-y: auto;
                    -webkit-overflow-scrolling: touch;
                }
            }

            &.modal-box {
                &--loading {
                    .modal-box__wrapper {
                        margin-top: 0;
                        height: 100%;
                    }
                }
            }
        }

        &--has-gap {
            text-align: right;
            min-width: 0;

            .modal-box__container {
                width: ~'calc(100% - 44px)';
            }
        }

        &--is-open {
            right: 0;

            .modal-box {
                &__container {
                    -ms-transform: translateX(0);
                    -webkit-transform: translateX(0);
                    transform: translateX(0);
                }

                &__overlay {
                    opacity: .65;
                    visibility: visible;
                }
            }
        }

        &--desktop-is-open,
        &--added-to-cart {
            .modal-box {
                &__body {
                    padding: 0;

                    .form__row {
                        padding: 0 16px;

                        @media screen and (min-width: @breakpoint-large) {
                            padding: 0 25px;
                        }
                    }
                }

                &__roadmap {
                    padding: 10px;
                }

                &__top {
                    width: ~'calc(100% - 32px)';
                    padding: 10px 16px 0;

                    @media screen and (min-width: @breakpoint-large) {
                        width: ~'calc(100% - 50px)';
                        padding: 25px 25px 0;
                    }
                }
            }
        }

        &--hide-footer {
            .modal-box {
                &__body {
                    padding: 16px;

                    @media screen and (min-width: @breakpoint-large) {
                        padding: 25px;
                    }
                }

                &__footer-container {
                    opacity: 0;
                    height: 0;
                    padding: 0;
                }
            }
        }

        &--loading {
            .modal-box__wrapper {
                margin-top: 0;
                height: 0;
            }
        }

        &--workshop-modal {
            .modal-box__top {
                display: flex;
            }
        }
    }

    .modal-box {
        &__loading {
            position: relative;
            height: 220px;
            text-align: center;
        }

        &__container {
            background-clip: padding-box;
            display: inline-block;
            margin: 8px 0;
            position: relative;
            text-align: left;
            vertical-align: middle;
            width: ~'calc(100% - 16px)';
            transition: transform .3s ease-in-out;
            z-index: 2;
        }

        &__wrapper {
            background-color: @c-white;
        }

        &__overlay {
            animation: fade-in-overlay .2s ease-in-out;
            background-color: @color-text-primary;
            opacity: 0;
            overflow: hidden;
            visibility: hidden;
            // position: absolute;
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 1;
            transition: all .3s ease-in-out;
        }

        &__header {
            position: relative;
            padding: 16px 16px 0;

            @media screen and (min-width: @breakpoint-large) {
                padding: 25px 25px 0;
            }
        }

        &__title {
            word-break: keep-all;
            margin: 0;
            padding-right: 24px;
            line-height: 20px;
            font-size: 16px;

            @media screen and (min-width: @breakpoint-large) {
                font-size: 18px;
            }
        }

        &__close {
            position: absolute;
            top: 6px;
            right: 6px;
        }

        &__close-button {
            color: @color-blue-primary;
            line-height: 0;
            padding: 8px;
            border: none;
            background: transparent;
        }

        &__label {
            color: @c-white;
            display: none;
        }

        &__icon {
            fill: @c-white;
        }

        &__flex-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            position: relative;
        }

        &__body {
            flex: 1 1 auto;
            padding: 16px 16px 0;
            overflow: scroll;
            transition: all .3s ease-in-out;
            background-color: @c-white;

            @media screen and (min-width: @breakpoint-large) {
                padding: 25px 25px 0;
            }
        }

        &__warning {
            background: #fee5ce;
            border: 1px solid #e0bd9c;
            border-radius: 2px;
            padding: 5px;
            margin: 10px;
            font-weight: 700;
            font-size:.8em;
        }

        &__top {
            display: none;

            @media screen and (min-width: @breakpoint-xsmall) {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }

            @media screen and (orientation: landscape) and (max-width: (@breakpoint-large - 1)) {
                display: none;
            }
        }

        &__intro {
            font-size: 13px;
            flex: 1 1 75%;
            padding-right: 10px;

            @media screen and (min-width: @breakpoint-xsmall) {
                flex: 1 1 85%;
            }

            @media screen and (min-width: @breakpoint-medium) {
                flex: 1 1 90%;
            }

            p {
                margin: 0;
            }
        }

        &__image {
            flex: 1 1 25%;

            @media screen and (min-width: @breakpoint-xsmall) {
                flex: 1 1 15%;
            }

            @media screen and (min-width: @breakpoint-medium) {
                flex: 1 1 10%;
            }
        }

        &__image-background {
            float: left;
            width: 100%;
            padding-bottom: 67.5%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        &__form {
            margin: 0;

            .option-price {
                color: #191919b3;

                sup {
                    top: -0.25em;
                }
            }

            >.form__group {
                &:nth-of-type(1) {
                    &:not(.form__group--radio-buttons) {
                        margin-bottom: 0;
                        margin-top: 10px;
                    }
                }

                .form__group-inner {
                    padding: 0 16px;

                    @media screen and (min-width: @breakpoint-large) {
                        padding: 0 25px;
                    }
                }
            }

            .form {
                &__group {
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 10px;

                    &--color {
                        .color-choice {
                            position: relative;
                            display: flex;
                            width: 100%;
                            align-items: center;
                            justify-content: space-between;

                            @media screen and (min-width: @breakpoint-xsmall) {
                                justify-content: unset;
                            }

                            &__inner {
                                display: flex;
                                align-items: center;
                                background: @c-gray-lighter;
                                padding: 6px 10px;
                                border-radius: 5px;
                                border: 1px solid @color-border-dark;
                            }

                            &__circle {
                                width: 15px;
                                height: 15px;
                                border-radius: 15px;
                                float: left;
                                margin-right: 10px;
                                background: @c-white;
                                border: 1px solid @color-border-light;
                                box-shadow: 0 0 2px fade(@c-black, 15%);
                            }

                            &__name {
                                display: inline-block;
                                font-size: 13px;
                            }

                            &__change-color {
                                border: 0;
                                transition: all 0.3s ease-in;
                                font-size: 15px;
                                background: @color-blue-primary;
                                padding: 7px 10px;
                                margin-left: 15px;
                                line-height: inherit;
                                white-space: nowrap;
                            }
                        }
                    }

                    &--radio-buttons {
                        .form__group-inner {
                            padding: 0;
                        }

                        +.form__group .form__group-inner {
                            padding: 0 16px;

                            @media screen and (min-width: @breakpoint-large) {
                                padding: 0 25px;
                            }
                        }
                    }

                    &--amount {
                        width: auto;
                        margin-bottom: 0;

                        .form {
                            &__group-inner {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                @media screen and (min-width: @breakpoint-small) {
                                    display: inline-block;
                                }
                            }

                            &__label-holder {
                                @media screen and (max-width: (@breakpoint-small - 1)) {
                                    margin-bottom: 0;
                                    margin-right: 15px;
                                    width: auto;
                                }
                            }

                            &__controls {
                                display: flex;
                                align-items: center;
                                margin: 0;

                                @media screen and (min-width: @breakpoint-small) {
                                    margin: 0 0 10px;
                                }
                            }

                            &__controls-inner {
                                position: relative;
                                width: 100%;
                            }
                        }
                    }
                }

                &__label-holder {
                    position: relative;
                    width: 100%;
                    margin-bottom: 5px;

                    &--has-tooltip {
                        display: flex;

                        .questionmark-tooltip {
                            margin-left: 5px;

                            .btn.button-questionmark-tooltip {
                                position: relative;
                                top: -2px;
                                background: @color-blue-primary;
                                color: @c-white;
                                font-weight: 400;
                            }
                        }
                    }
                }

                &__label {
                    margin-bottom: 0;
                }

                &__controls-inner {
                    select {
                        margin: 0;
                        height: 30px;
                        min-width: 200px;
                        width: 100%;
                    }
                }

                &__input {
                    margin: 0;

                    &--select {
                        height: 40px;
                        min-width: 250px;
                        width: 100%;
                    }

                    &--number {
                        height: 24px;
                        display: block;
                        text-align: left;
                        text-align: center;
                        width: ~'calc(100% - 82px)';
                        padding-left: 40px;
                        padding-right: 40px;
                        -moz-appearance: textfield;
                        -webkit-appearance: textfield;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;

                        @media screen and (min-width: @breakpoint-small) {
                            height: 30px;
                        }

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                        }
                    }
                }

                &__counter {
                    color: @color-blue-primary;
                    position: absolute;
                    font-weight: bold;
                    cursor: pointer;
                    font-size: 22px;
                    width: 40px;
                    text-align: center;
                    height: 34px;
                    line-height: 34px;
                    bottom: 0;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    @media screen and (min-width: @breakpoint-small) {
                        height: 40px;
                        line-height: 40px;
                    }

                    &--plus {
                        right: 0;
                    }
                }

                &__unit {
                    border: 1px solid @color-blue-primary;
                    border-radius: 0 4px 4px 0;
                    background: @c-gray-lighter;
                    position: relative;
                    left: -1px;
                    color: @color-blue-primary;
                    cursor: default;
                    height: 32px;
                    line-height: 32px;
                    font-size: 13px;
                    padding: 0 10px;

                    @media screen and (min-width: @breakpoint-small) {
                        height: 38px;
                        line-height: 38px;
                        font-size: 14px;
                        padding: 0 15px;
                    }
                }

                &__table {
                    margin-bottom: 0;

                    tbody {
                        .neuron;
                    }
                }

                &__table-row {
                    cursor: pointer;

                    &--header {
                        font-size: 13px;

                        th {
                            padding: 0 8px 3px;
                        }
                    }

                    &--selected {
                        background: @c-gray-light;
                    }
                }

                &__table-column {
                    font-size: 13px;
                    vertical-align: middle;
                    padding: 5px 8px;
                    transition: background-color .2s ease-in-out;

                    &:first-child {
                        text-align: center;
                        padding: 8px 3px 8px 7px;
                    }

                    &:last-child {
                        white-space: nowrap;
                    }
                }
            }
        }

        &__footer {
            flex: 0 0 auto;
            transition: all .3s ease-in-out;

            .btn {
                float: right;
                width: 100%;
                padding: 10px;
                border: 0;
                transition: all .3s ease-in;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media screen and (min-width: @breakpoint-small) {
                    min-width: 250px;
                }

                span {
                    margin-left: 10px;
                    left: 0;
                    top: 0;
                }

                &-green {
                    background: @c-green-light;

                    &:hover,
                    &:focus {
                        background: @c-green;
                    }
                }

                &-blue {
                    background: @color-blue-primary;

                    &:hover,
                    &:focus {
                        background: @c-blue2;
                    }
                }
            }
        }

        &__footer-container {
            left: 0;
            right: 0;
            overflow: hidden;
            background-color: @c-white;
            box-shadow: 0 0 8px 0 fade(@c-black, 20%);
            z-index: 7;
            position: relative;
            top: auto;
            bottom: 0;
            transform-origin: bottom;
            display: block;
            -ms-transform: translateY(0);
            -webkit-transform: translateY(0);
            transform: translateY(0);
            transition: all .3s ease-in-out;
            opacity: 1;
            height: 40px;
            padding: 13px 0;
        }

        &__footer-wrapper {
            margin: 0 auto;
            max-width: 128rem;
            padding: 0 20px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;

            .btn:last-child {
                align-self: flex-end;
                margin-left: auto;
            }
        }

        &__footer-price {
            width: 100%;
            white-space: nowrap;
            margin-right: 15px;
            display: none;

            .form {
                &__group {

                    &--price,
                    &--delivery-time {
                        margin: 0;
                    }
                }

                &__price {
                    font-size: 26px;
                    line-height: 20px;
                    text-align: left;
                }
            }
        }

        &__footer-usps {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            padding: 0 20px 10px 0;
            float: right;
            min-width: 275px
        }

        &__footer-usps > * {
            font-size: 13px;
            line-height: 2;
            white-space: nowrap
        }

        &__footer-usps .polycon-checkmark {
            font-size: 19px;
            float: left;
            margin: 3px 5px 0 0;
            color: #5fc719
        }

        @media screen and (max-width: 400px) {
            &__footer-usps {
                display: none;
            }
        }

        @media screen and (min-width: 401px) {
            &__footer-container {
                height: auto;
            }
        }

        @media screen and (min-width: 1008.02px) and (max-width: 1199px) {
            &__footer-usps > * {
                font-size: 12px
            }

            &__footer-usps .polycon-checkmark {
                font-size: 15px;
                margin: 5px 5px 0 0
            }
        }
        @media screen and (min-width: 1199.02px) and (max-width: 1308px) {
            &__footer-usps > * {
                font-size: 10px
            }

            &__footer-usps .polycon-checkmark {
                font-size: 13px;
                margin: 3px 3px 0 0
            }
        }
        @media screen and (min-height: 700px) {
            .modal-box .product-options.product-options--1 .product-options__list {
                max-height: 37vh !important
            }
        }
        @media screen and (min-width: 768px) {
            .modal-box:not(.modal-box--loading):not(.modal-box--added-to-cart) .modal-box__flex-container {
                max-height: calc(100vh - 94px)
            }

            &__footer-usps {
                padding: 10px 20px 0 0;
                min-width: none
            }

            &__footer-usps > * {
                font-size: 14px
            }

            &__footer-usps .polycon-checkmark {
                font-size: 15px;
                margin: 5px 5px 0 0
            }
        }
        @media screen and (max-width: 350px) {
            &__footer-usps {
                min-width: auto
            }

            &__footer-usps > * {
                font-size: 11px
            }
        }
    }

    .form {
        &__group {
            &--price {
                margin: 10px 0;
                .neuron;

                .discount-time-remaining {
                    margin-top: 5px;
                }

                .form {
                    &__group-inner {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        @media screen and (max-width: (@breakpoint-medium - 1)) {
                            display: block;

                            .price-volume-discount {
                                margin-bottom: 5px;
                            }
                        }

                        .form__price-wrap {
                            display: flex;

                            sup {
                                top:-.4em;
                                font-size:60%;
                            }
                        }
                    }

                    &__label {
                        font-size: 17px;
                        line-height: 30px;
                        float: left;
                        margin-bottom: 0;
                        -ms-transform: translateY(4px) !important;
                        -webkit-transform: translateY(4px) !important;
                        transform: translateY(4px) !important;
                    }
                }

                &--discounted {
                    .form__discounted-price {
                        color: @c-red;
                    }
                    .form__original-price {
                        text-decoration: line-through;
                        margin-left: 11px;
                    }
                }
            }

            &--delivery-time {

                .control-group,
                .controls {
                    margin-bottom: 0;
                }

                .control-label,
                .stock {
                    display: none;
                }

                .stock-description {
                    display: inline-block;
                    width: 100%;
                    font-size: 12px;

                    @media screen and (min-width: @breakpoint-medium) {
                        font-size: 13px;
                    }
                }
            }
        }

        &__price {
            display: inline-block;
            font-size: 28px;
            text-align: left;
            white-space: nowrap;

            @media screen and (min-width: @breakpoint-medium) {
                font-size: 30px;
                flex: 0 0 auto;
                width: auto;
                text-align: right;
            }
        }

        &__price-discount {
            color: @c-white;
            font-weight: 100;
            font-size: 13px;
            margin-right: 10px;
            display: none;
            position: relative;
            white-space: nowrap;
            .baseFont();
        }
    }
}

@media screen and (min-width: @breakpoint-small) {
    .modal-box {
        &.modal-box {
            &--has-gap {
                .modal-box__container {
                    width: ~'calc(100% - 100px)';
                }
            }
        }

        .modal-box {
            &__form {
                .form {
                    &__controls-inner {
                        select {
                            height: 30px;
                            width: auto;
                        }
                    }

                    &__input {
                        &--select {
                            width: auto;
                        }
                    }
                }
            }

            &__footer {
                .btn {
                    width: 40%;
                    min-width: 250px;
                    padding: 10px 22px 10px 15px;
                }
            }
        }
    }
}

@media screen and (min-width: @breakpoint-medium) {
    .modal-box {
        &::before {
            display: inline-block;
        }


        +script+.modal-box,
        +.modal-box {
            &--desktop-from-center {

                .modal-box {
                    &__container {
                        -ms-transform: translateY(0);
                        -webkit-transform: translateY(0);
                        transform: translateY(0);
                        animation: scale-popup .15s ease-in-out;
                    }
                }

                .modal-box {
                    &__overlay {
                        opacity: .65;
                        visibility: visible;
                    }
                }
            }
        }

        &.modal-box {
            &--next-step {
                opacity: 0;
                visibility: hidden;
            }

            &--leave-to-right {
                .modal-box {
                    &__container {
                        -ms-transform: translateX(~'calc(50% + 50vw)') !important;
                        -webkit-transform: translateX(~'calc(50% + 50vw)') !important;
                        transform: translateX(~'calc(50% + 50vw)') !important;
                    }
                }
            }

            &--hidden-from-size-md {
                display: none !important;
            }

            &--full-screen {
                background-color: transparent;
                box-shadow: 10px 0 10px 0 fade(@c-black, 80%);

                .modal-box {
                    color: @color-text-primary;
                    padding-left: 0;
                    padding-right: 32px;
                    text-align: left;
                    text-overflow: initial;
                    white-space: normal;

                    &__container {
                        border-radius: 3px;
                        box-sizing: content-box;
                        height: auto;
                        margin: 1.6rem;
                        width: 80%;
                        overflow: hidden;
                        max-width: 800px;
                    }
                }
            }

            &--full-screen-has-sticky-bar {
                .modal-box {
                    &__title {
                        padding-left: 0;
                        padding-top: 0;
                    }
                }
            }

            &--has-gap {
                width: 100%;
                text-align: center;
            }
        }

        .modal-box {
            &__container {
                border-radius: 3px;
                box-sizing: content-box;
                height: auto;
                margin: 25px;
                width: 80%;
                display: inline-block;
            }

            &__wrapper {
                margin-top: 0;
                height: auto;
            }

            &__header {
                background-color: transparent;
                padding: 25px 25px 0;
                position: relative;
                height: auto;
            }

            &__label {
                display: inline-block;
            }

            &__form {
                .form {
                    &__row {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 10px;
                    }

                    &__column {
                        &:first-child {
                            flex: 0 0 40%;
                        }

                        &:last-child {
                            flex: 0 0 50%;
                            text-align: right;
                        }
                    }

                    &__group {

                        &--price,
                        &--delivery-time {
                            margin: 0;
                        }

                        &--delivery-time {
                            .stock-description {
                                float: left;
                            }
                        }
                    }
                }
            }

            &__content {
                padding: 0 25px 25px;
                overflow-y: visible;
            }

            &__footer {
                .btn {
                    padding: 14px 22px 14px 15px;
                }
            }
        }
    }
}

@media screen and (orientation: landscape) and (max-width: (@breakpoint-large - 1)) {
    .modal-box {
        .modal-box {
            &__form {
                .form {
                    &__label {
                        font-size: 13px;
                    }

                    &__group {
                        &:not(.form__group--radio-buttons) {
                            .form__group-inner {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                            }
                        }

                        &--amount {
                            width: 100%;

                            .form {
                                &__controls {
                                    margin: 0;
                                }

                                &__controls-inner {
                                    width: auto;
                                }
                            }
                        }
                    }

                    &__label-holder {
                        width: auto;
                        margin-bottom: 0;
                        flex: 0 0 25%;
                    }

                    &__controls {
                        margin: 0;
                        flex: 0 0 75%;
                    }

                    &__controls-inner {
                        select {
                            height: 25px;
                            width: auto;
                            padding: 0 6px;
                        }
                    }

                    &__counter {
                        height: 27px;
                        line-height: 27px;
                        font-size: 20px;
                    }

                    &__unit {
                        height: 25px;
                        line-height: 25px;
                    }

                    &__input {
                        &--number {
                            height: 25px;
                            padding: 0 40px;
                        }
                    }

                    &__row {
                        .form__column {
                            &:nth-of-type(1) {
                                flex: unset;
                                width: 100%;
                            }

                            &:nth-of-type(2) {
                                display: none;
                            }
                        }
                    }
                }
            }

            &__footer {
                .btn {
                    padding: 10px 22px 10px 15px;
                    white-space: nowrap;
                    font-size: 14px;
                }
            }

            &__footer-container {
                padding: 8px 0;
                height: 40px;
            }

            &__footer-wrapper {
                align-items: center;
            }

            &__footer-price {
                display: block;
            }
        }
    }
}

.modal-box {

    .product-amount-holder {
        display: flex;
        align-items: center;
        margin: 0 0 10px;
        margin-top: 10px;
    }

    .table td {
        font-size: 13px;
        vertical-align: middle;
        transition: background-color .2s ease-in-out;
        padding: 8px 3px 8px 7px;
    }

    .table td input[type="radio"] {
        display: inline-block;
        margin-top: -2px;
        width: 14px;
        height: 14px;
        margin-bottom: 0;
    }

    .product-option-radio__label {
        margin: 0;
        .neuron();
        display: inline-block;
        line-height: 18px;
        width: auto;
        font-size: 16px;
    }

    .product-option-radio__stock {
        width: 100%;
        display: flex;
        align-items: baseline;
        font-size: 11px;
        line-height: initial;
    }

    .product-option-radio__stock-text {
        font-size: 14px;
    }

    .controls {
        margin-bottom: 5px;
    }
}

.modal-scrollable .modal-box {
    right: -100% !important;
}

.modal-scrollable+.modal-backdrop {
    opacity: 0 !important;
}
