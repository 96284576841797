.checkout-steps {

    .checkout-steps {
        &__list {
            display: flex;
            flex-flow: row;
            list-style: none;
            margin: 16px 0;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                background-color: @color-border-dark;
                height: 1px;
                top: 10px;
                left: 0;
                width: 100%;
                z-index: -1;
            }
        }

        &__item {
            flex: 1;
            text-align: center;

            &--completed {
                .checkout-steps__step {
                    background: @c-green;
                    color: @c-white;
                    border-color: @c-green;
                }
            }

            &--current {
                .checkout-steps__step {
                    background: @c-blue;
                    color: @c-white;
                    border-color: @c-blue;
                }
            }
        }

        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: @color-text-primary;
        }

        &__step {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            text-align: center;
            font-size: 15px;
            color: @c-txt-white;
            background: @color-border-dark;
            border: 1px solid @color-border-dark;
            .neuron();
        }

        &__name {
            display: inline-block;
            width: 100%;
            font-size: 13px;
            line-height: initial;
            margin-top: 5px;
            .neuron();

            @media screen and (min-width: @breakpoint-medium) {
                font-size: 14px;
            }

            @media screen and (min-width: @breakpoint-large) {
                font-size: 15px;
            }
        }
    }
}