/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    outline: none;

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

/*slider logo*/

.container-slider-logo-top {
    padding-top: 75px;
    padding-bottom: 60px;
}

.slider-logo,
.product-slider {
    &>.slick-prev {
        width: 18px;
        height: 29px;
        font-size: 0;
        display: block;
        border: 0;
        background: url('../../img/boot/arrow.png') 0 0 no-repeat;
        position: absolute;
        left: -65px;
        top: 50%;
        cursor: pointer;
        z-index: 100;
        margin-top: -14px;

        &:hover {
            opacity: 0.8;
        }
    }

    &>.slick-next {
        width: 18px;
        height: 29px;
        font-size: 0;
        display: block;
        border: 0;
        background: url('../../img/boot/arrow.png') -18px 0 no-repeat;
        position: absolute;
        right: -65px;
        top: 50%;
        cursor: pointer;
        z-index: 100;
        margin-top: -14px;

        &:hover {
            opacity: 0.8;
        }
    }

    &>.slick-dots {
        margin: 0 auto;
        display: table;

        & li {
            width: 10px;
            height: 10px;
            margin: 0 5px;
            border: 1px solid #dedede;
            display: inline-block;
            overflow: hidden;
        }

        & li button {
            width: 100%;
            height: 100%;
            background: transparent;
            cursor: pointer;
            font-size: 0;
            display: block;
            overflow: hidden;
        }

        & li.slick-active {
            border: 1px solid @color-text-primary;
            background: @color-text-primary;
        }
    }

    &>.slick-list>.slick-track>.slick-slide {
        padding: 0 10px;
        text-align: center;

        & img {
            display: inline-block;
        }
    }
}

@media screen and (max-width: (@breakpoint-xxlarge - 1)) {
    .slider-logo {
        &>.slick-prev {
            left: 0;
        }

        &>.slick-next {
            right: 0;
        }
    }

    .product-slider>.slick-prev,
    .product-slider>.slick-next {
        display: none !important;
    }
}
