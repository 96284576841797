//// autocomplete  (By Jack)
.tt-query {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
    z-index: 1 !important;
    color: #999 !important;
}

.tt-input {
    z-index: 90 !important;
}

.tt-menu {
    /* used to be tt-dropdown-menu in older versions */
    width: 400px;
    border-radius: 7px;
    font-size: 16px;
    left: auto !important;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    background-color: @c-white;
    overflow: hidden;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.tt-menu .all {
    float: left;
    width: 100%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    background: @c-white;
    border:0 5px 5px 0;
}

.tt-suggestion {
    padding: 10px 10px 10px 10px;
    line-height: 24px;
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
    border-bottom: #eee 1px solid;
    white-space: normal;
    cursor: pointer;

    .price_wrap {
        position: absolute;
        right: 10px;
        width: 140px;
        text-align: right;
        font-weight: bold;

        .stock {
            width: 100%;
            float: left;
            font-size: 11px;
            color: @c-green1;
            margin-top: -5px;
        }
    }

    .right-text {
        float: left;
        width: 200px;

        .name {
            width: 100%;
            float: left;
            font-weight: bold;
            font-size: 13px;
            margin-top: 4px;
            display: block;
            line-height: 15px;
        }

        .cat {
            width: 100%;
            float: left;
            color: @color-blue-primary;
            font-size: 12px;
            margin-top: 0;
            color: #999;
        }
    }

    img {
        width: 50px;
        height: auto;
        float: left;
        margin-right: 5px;
    }
}

.tt-suggestion.tt-cursor,
.tt-suggestion:hover,
.tt-selection {
    background-color: #f4f4f4;
}

.tt-suggestion p {
    margin: 0;
}

#checkout-main-inner .tt-menu {
    width: 300px;
    font-size: 12px;
    left:0 !important;
    right: auto !important;

    span.name {
        float: left;
        font-weight: bold;
        width: 100%;
        line-height: 18px;
    }

    span.cat {
        float: left;
        width: 100%;
        color: #757575;
    }

    .tt-suggestion {
        padding: 5px;
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    .twitter-typeahead {
        width: 100%;
        float: left;
    }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    .tt-menu {
        width: 280px;
        left:0 !important;

        .tt-suggestion {
            img {
                display: none;
            }
        }
    }

    #checkout-main-inner .tt-menu {
        width: 265px;
        line-height: 18px;

        .right-text .name {
            display: block;
            float: left;
            line-height: 16px;
            width: 100%;
        }

        .right-text .cat {
            display: block;
            float: left;
            width: 100%;
        }
    }
}