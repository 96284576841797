.submenu {
    background-color: @color-blue-primary;
    display: inline-block;
    width: 100%;
    position: fixed;
    top: 50px;
    z-index: 999;
    box-shadow: 0 3px 5px fade(@c-black, 20%);
    transform: translateY(-50px);
    transition: @transition-cubic;

    #usp-topbalk.scroll+& {
        transform: translateY(0);
    }

    @media screen and (min-width: @breakpoint-medium) {
        display: none;
    }

    .submenu {
        &__list {
            display: flex;
            align-items: center;
            margin: 0;
            list-style: none;
        }

        &__item {
            flex: 1;
            text-align: center;

            &:not(:last-of-type) {
                border-right: 1px solid fade(@c-white, 50%);
            }
        }

        &__link {
            display: flex;
            flex-direction: column;
            padding: 10px 0;
        }

        &__icon {
            max-height: 18px;
            margin-bottom: 3px;
        }

        &__text {
            color: @c-white;
            font-size: 11px;
            line-height: initial;
        }
    }
}