.sidebar {
    &__account-manager {
        margin-top: 15px;
    }
}

#sidebar-left {
    .hide-mobile {
        width: 100%;
        float: left;
    }
    .product-filter {
        min-height: 670px;
        padding: 0;

        h4.sidebar-title {
            .neuron;
            font-size: 20px;
            margin: 0;
            padding: 10px 0;
            font-weight: normal;
        }

        .sidebar-section {
            border-bottom: 1px solid @color-border-dark;

            h5 {
                text-transform: uppercase;
                .baseFont;
                font-size: 12px;
                margin: 5px 0;
            }

            p,
            label {
                font-size: 13px;
                line-height: 22px;
                color: @color-text-secondary;

                &:hover {
                    color: @c-lnkh1;
                }
            }
        }

        .sidebar-product-categories {
            a {
                display: block;
                text-decoration: none;
            }
        }

        .sidebar-brands {
            label {
                margin: 0;
            }
        }

        .sidebar-quantities {
            label {
                margin: 0;
            }
        }

        .sidebar-payment-gateways {
            padding-top: 8px;
            border-bottom: 1px solid @color-border-dark;
        }

        #kiyoh {
            padding-top: 18px;
        }
    }
}



#sidebar-left .product-filter ul.top-menu {
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
        width: 100%;
        display: block;
        padding: 0;
        margin: 0;
        font-size: 14px;
        border-bottom: 1px solid #eee;

        &.first {
            margin-top: 3px;
        }

        a {
            padding: 2px 0;
            width: 100%;
            display: block;
        }
    }
}

#category-nav {
    margin: 0;

    li {
        list-style: none;
    }

    &>li {
        margin: .4em 0;
    }

    &>li>a {
        .neuron;
        color: @c-lnk1;
        font-size: 15px;
        line-height: 1.2em;
        font-weight: bold;

        &:hover {
            color: @c-lnkh1;
        }
    }

    li ul {
        margin-left: 25px;

        a {
            font-size: 13px;
        }
    }
}

#category #sidebar-left .product-filter {
    @media screen and (max-width: (@breakpoint-small - 1)) {
        margin: 0;
        margin: 140px 4px 10px 4px;
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        margin: 90px 4px 10px 4px;
        height: auto;
    }
}