#checkout .checkout-main .checkout-main__login-form {
    &.login-form {
        margin: 0;
        text-align: center;

        @media screen and (min-width: @breakpoint-large) {
            width: 75%;
            margin: 0 auto;
        }

        @media screen and (min-width: @breakpoint-grid-large) {
            width: 60%;
        }

        .form-horizontal {
            margin-bottom: 0;

            .control-group {
                &--submit {
                    margin-bottom: 16px;

                    @media screen and (min-width: @breakpoint-medium) {
                        text-align: center;
                    }

                    .button {
                        width: 100%;

                        @media screen and (min-width: @breakpoint-medium) {
                            width: 50%;
                        }

                        @media screen and (min-width: @breakpoint-large) {
                            width: 55%;
                        }
                    }
                }

                &--forgot-password {
                    margin-bottom: 12px;

                    label {
                        text-align: center;
                        margin: 0;

                        a {
                            display: inline-block;
                            color: @c-blue;
                            border-bottom: 1px solid @c-blue;
                            transition: @transition-cubic;

                            &:hover {
                                color: @c-blue-dark;
                                border-color: @c-blue-dark;
                            }
                        }
                    }
                }
            }

            .controls {
                position: relative;

                p {
                    font-size: 13px;
                }

                .input-large {
                    width: 100%;
                }

                #ps_customer_login_password {
                    padding-right: 40px;
                }

                .control-toggle-password {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 40px;
                    width: 40px;
                    font-size: 18px;
                    cursor: pointer;

                    &:hover {
                        color: @c-blue;
                    }

                    &--active {
                        color: @c-blue;
                    }

                    svg {
                        transition: @transition-cubic;
                    }
                }
            }
        }

    }
}