.account-manager {

    .box-body {
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .account-manager {
        &__title {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-bottom: 0 !important;
        }

        &__content {
            padding-left: 10px;
            padding-right: 10px;
        }

        &__name {
            display: inline-block;
            width: 100%;
            margin-bottom: 5px;

            strong {
                display: inline-block;
            }
        }

        &__contact {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
        }

        &__icon {
            float: left;
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            margin-right: 10px;
        }

        &__link {
            font-size: 15px;
            color: @color-blue-primary;
            text-decoration: none;

            &:hover,
            &:focus {
                color: @color-blue-primary;
                text-decoration: underline;
            }
        }

        &__image-holder {
            float: left;
            width: 100%;
        }

        &__image {
            float: left;
            width: 100%;
            height: 0;
            padding-bottom: 37.5%;
            max-height: 200px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }

}