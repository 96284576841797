.info {
    * {
        box-sizing: border-box;
    }
}

.aa-Label {
    margin-bottom:0px;
    line-height: unset;
}

.info {
    &__holder {
        display: block;
        background-color: @color-background-page;
        border-radius: 3px;
        padding: 25px;

        @media screen and (min-width: @breakpoint-medium) {
            border: 1px solid @color-border-dark;
        }

        &--flex {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
            background-color: @c-white;
            border-color: @color-border-light;
            padding: 0;
            margin-bottom: 40px;

            @media screen and (min-width: @breakpoint-medium) {
                flex-direction: column;
                margin-bottom: 60px;
            }

            @media screen and (min-width: @breakpoint-xlarge) {
                flex-direction: row;
            }

            .info {
                &__search {
                    padding: 25px 0 0;
                    width: 100%;

                    @media screen and (min-width: @breakpoint-medium) {
                        padding: 25px;
                    }
                }
            }
        }
    }

    &__title {
        font-size: 32px;
        margin: 0 0 25px;

        @media screen and (min-width: @breakpoint-medium) {
            margin-top: 15px;
        }
    }

    &__subtitle {
        font-size: 24px;
        color: @c-blue;
        margin: 0 0 10px;

        @media screen and (min-width: @breakpoint-medium) {
            margin: 0 0 25px;
        }
    }

    &__search {
        flex: 1 1 100%;
    }

    &__contact-usp {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        flex: 1 1 100%;
        padding: 15px 25px 50px;
        border: 1px solid @color-border-light;
        position: relative;

        @media screen and (min-width: @breakpoint-medium) {
            border: none;
            padding: 25px 100px 25px 25px;
        }

        @media screen and (min-width: @breakpoint-xlarge) {
            flex: 0 0 40%;
            margin-left: 50px;
            padding: 25px 100px 25px 0;
        }

        @media screen and (min-width: @breakpoint-grid-large) {
            flex: 0 0 33%;
            margin-left: 100px;
        }
    }
}
