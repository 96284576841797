#advice_side {
    width: 100%;
    box-sizing: border-box;
    float: right;
    padding: 5px 5px 0 5px;
    border: 1px solid #CACACA;
    margin-bottom: 5px;
    min-height: 90px;

    .polycon-advice {
        width: 50px;
        float: left;
        height: 50px;
        margin-top: 22px;
        color: @color-blue-primary;
        position: relative;
        font-size: 44px;
        left: -20px;
    }

    .advice-person {
        width: 30%;
        height: auto;
        float: left;

        @media screen and (max-width: (@breakpoint-medium - 1)) {
            width: 12%;
        }

        @media screen and (max-width: (@breakpoint-small - 1)) {
            width: 20%;
        }

        @media screen and (max-width: (@breakpoint-xsmall - 1)) {
            width: 30%;
        }

        img {
            width: 100%;
            height: auto;
            float: left;
        }
    }

    .text {
        padding-left: 10px;
        width: 70%;
        float: left;
        box-sizing: border-box;

        @media screen and (max-width: (@breakpoint-xsmall - 1)) {
            width: 70%;
        }

        h4 {
            font-size: 16px;
            font-weight: 500;
            color: @color-blue-primary;
            width: 100%;
            margin: 10px 0 4px 0;
            float: left;
        }

        .rule {
            font-size: 12px;
            float: left;
            margin: 0 5px 4px 0;

            &.mobile {
                display: none;
            }

            .advice-icon {
                color: @color-blue-primary;
            }
        }
    }
}

body #advice_side .text {
    padding-left: 13px;
    width: 70%;
    float: left;
    box-sizing: border-box;
}

body #advice_side .inner {
    display: inline-block;

    @media screen and (max-width: (@breakpoint-small - 1)) {
        display: block;
    }
}