.my-account .my-account__projects {
    .projects {

        .projects {
            &__add-button {
                display: block;
                margin: 8px;

                @media screen and (min-width: @breakpoint-xsmall) {
                    display: inline-block;
                    margin: 8px 0 8px 8px;
                }
            }

            &__list {
                display: flex;
                flex-wrap: wrap;
            }

            &__item {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 8px;

                @media screen and (min-width: @breakpoint-small) {
                    width: 50%;
                }

                @media screen and (min-width: @breakpoint-medium) {
                    width: 100%;
                }

                @media screen and (min-width: @breakpoint-large) {
                    width: 50%;
                }

                @media screen and (min-width: @breakpoint-grid-large) {
                    width: 33.33%;
                }

                &.projects {
                    &__item {
                        &--has-image {
                            .projects__title {
                                color: @c-white;
                            }
                        }
                    }
                }
            }

            &__inner {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                padding: 40px 25px;
                position: relative;
                box-shadow: 0 0 5px 0 fade(@c-black, 10%);
            }

            &__image-holder {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 0;

                &::before {
                    content: '';
                    background-color: fade(@c-black, 50%);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }

            &__image {
                float: left;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;
            }

            &__title {
                display: inline-block;
                width: 100%;
                margin: 0 0 15px;
                font-size: 18px;
                color: @c-blue;
                text-align: center;

                @media screen and (min-width: @breakpoint-small) {
                    font-size: 20px;
                }
            }

            &__buttons {
                display: flex;
            }

            &__button {
                border: none;
                border-radius: 3px;
                color: @c-white;
                transition: @transition-cubic;
                font-size: 16px;
                padding: 8px 15px;
                width: 100%;
                background-image: none;
                box-shadow: none;

                &:not(:nth-last-of-type(1)) {
                    margin-right: 8px;
                }

                &.btn {
                    &.btn-green {
                        background-color: @c-green-light;

                        &:hover {
                            background-color: @c-green;
                        }
                    }

                    &.btn-blue {
                        background-color: @c-blue;

                        &:hover {
                            background-color: @c-blue-medium;
                        }
                    }
                }
            }
        }
    }

}