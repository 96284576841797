.social-footer {

    .social-footer {
        &__list {
            display: flex;
        }

        &__item {
            display: inline-block;
            line-height: 30px !important;

            &:not(:nth-last-of-type(1)) {
                margin-right: 10px;
            }
        }

        &__link {
            float: left;
        }

        &__icon {
            float: left;
            font-size: 24px;
            color: @c-blue;
            transition: @transition-cubic;

            &:hover {
                color: @c-blue-dark;
            }

        }
    }

}