.general-product-slider {
    .product-count {
        width: 100%;
        display: block;
        font-size: 14px;
        box-sizing: border-box;
        margin-bottom: 10px;
        color: rgba(0, 0, 0, 0.8);
        padding-top: 10px;
        padding-bottom: 10px;

        &__line {
            height: 4px;
            width: 100%;
            display: block;
            overflow: hidden;
            margin-top: 5px;
            background: fade(@c-blue, 30%);

            &-inner {
                display: block;
                background: @c-blue;
                height: 4px;
                position: relative;
                left: 0;
                width: 50%;
                transition: left @transition-default-time ease;
            }
        }
    }

    &__inner {

        & > .slick-prev,
        & > .slick-next {
            width: 60px;
            height: 60px;
            font-size: 0;
            display: block;
            border: 0;
            position: absolute;
            left: -50px;
            top: ~"calc(50% - 30px)";
            cursor: pointer;
            z-index: 100;
            margin-top: -14px;
            background: transparent;

            &:after {
                content: '';
                width: 18px;
                height: 30px;
                position: absolute;
                top: 15px;
                left: 22px;
                background: url(../../../img/boot/arrow.png) 0 0 no-repeat;
            }

            &:hover {
                opacity: 0.8;
            }
        }

        & > .slick-next {
            left: unset;
            right: -50px;

            &:after {
                background: url(../../../img/boot/arrow.png) -18px 0 no-repeat;
            }
        }

        @media screen and (max-width: (@breakpoint-medium - 1)) {
            &>.slick-prev {
                left: 0;
                top: 23%;
            }

            &>.slick-next {
                right: 0;
                top: 23%;
            }
        }
    }

    .slick-track {
        margin-left: 0;
        margin-right: 0;
    }
}
