.product-usps {
    display: inline-block;
    width: 100%;
    padding: 20px 0 10px;
    margin: 10px 0;
    border-top: 1px solid @color-border-dark;

    .product-usps {
        &__list {
            margin: 0 25px 10px !important;
        }

        &__item {
            display: flex;
            align-items: center;
            padding: 4px 0;
            font-size: 13px;

            @media screen and (min-width: @breakpoint-small) {
                font-size: 14px;
                padding: 5px 0;
            }

            @media screen and (min-width: @breakpoint-medium) {
                font-size: 14px;
                padding: 5px 0;
            }

            .polycon-checkmark {
                color: #5fc719;
            }
        }

        &__icon {
            width: 25px;
            text-align: center;
            margin-right: 10px;
        }

        &__image {
            &--stars {
                margin-top: -4px;
                margin-right: 5px;
            }
        }

        &__text {
            width: 100%;
            padding-left: 5px;
        }

        &__link {
            color: @color-blue-primary;
        }
    }
}
