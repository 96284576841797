.spinner-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin: -20px 0 0 -20px;
    border: 8px solid @color-border-light;
    border-radius: 50%;
    background-color: @c-white;

    &--small {
        margin: -10px 0 0 -10px;
        width: 20px;
        height: 20px;
        border-width: 5px;

        .spinner-animation__inner {
            top: -5px;
            position: relative;

            &::before {
                top: -5px;
                left: 5px;
                width: 5px;
                height: 5px;
            }
        }
    }

    &--transparent {
        border-color: fade(@c-white, 60%);
        background-color: transparent;

        .spinner-animation__inner {
            &::before {
                background-color: @c-white;
                animation: squash 1.4s linear 0s infinite;
            }
        }
    }

    &__inner {
        display: inline-block;
        width: 100%;
        height: 100%;
        animation: spin .7s linear 0s infinite;

        &::before {
            content: '';
            position: absolute;
            top: -8px;
            left: 8px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: @c-blue-darker;
            animation: squash 1.4s linear 0s infinite, change-color 1.4s linear 0s infinite;
        }
    }
}




//
// Keyframes
//

@keyframes fade-in-content {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes fade-in-overlay {
    0% {
        opacity: 0
    }

    100% {
        opacity: .65
    }
}

@keyframes fade-out-overlay {
    0% {
        opacity: .65
    }

    100% {
        opacity: 0
    }
}

@keyframes scale-popup {
    0% {
        transform: scale(.8)
    }

    100% {
        transform: scale(1)
    }
}

@keyframes spin {
    0% {
        transform: rotateZ(0deg)
    }

    42% {
        transform: rotateZ(180deg)
    }

    100% {
        transform: rotateZ(360deg)
    }
}

@keyframes squash {

    0%,
    50%,
    100% {
        transform: scaleX(1)
    }

    21%,
    71% {
        transform: scaleX(1.5)
    }

    45%,
    95% {
        transition-timing-function: ease-in-out
    }
}

@keyframes change-color {

    0%,
    45%,
    100% {
        background: @c-blue-darker
    }

    50%,
    95% {
        background: @color-blue-primary
    }
}