.review-trustpilot-widget {
    display: inline-block;
    width: 100%;
    background: @c-white-dark;
    padding: 10px 25px;
    margin: 25px 0 10px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;

    &--full-width {
        @media screen and (min-width: @breakpoint-large) {
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.checkout-trustpilot-widget {
    display: block;
    margin: 10px;

    @media screen and (min-width: @breakpoint-xsmall) {
        margin: 10px 19px;
    }

    @media screen and (min-width: @breakpoint-medium) {
        margin: 19px 19px 0;
    }

    .checkout-main & {
        margin: 0;
        padding: 16px 32px 16px;
    }
}