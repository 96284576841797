.modal-box {
    .product-options {
        &.product-options {
            &--1 {
                .product-options__list {
                    max-height: 33vh;
                }
            }

            &--2 {
                .product-options__list {
                    max-height: 35vh;
                }
            }
        }

        .product-options {
            &__header {
                position: sticky;
                top: 0;
                background-color: @c-white;
                display: flex;
                border-bottom: 1px solid @color-border-dark;

                .product-options {
                    &__column {
                        padding: 9px 8px;
                        font-weight: bold;

                        &:nth-of-type(1) {
                            padding: 8px 5px 8px 13px;
                        }
                    }
                }
            }

            &__list {
                margin: 0;
                list-style: none;
                overflow-y: scroll;
                border-bottom: 1px solid @color-border-dark;
                .neuron();
            }

            &__item {
                display: flex;
                background-color: @c-white;
                transition: background-color .2s ease-in-out;
                align-items: center;
                cursor: pointer;

                &:not(:last-child) {
                    border-bottom: 1px solid @color-border-light;
                }

                &:hover,
                &:focus {
                    background-color: @c-gray-lighter;
                }

                &--selected {
                    background-color: @c-gray-light;
                }
            }

            &__column {
                flex: 0 0 auto;
                font-size: 13px;
                padding: 5px 8px;

                &:nth-of-type(1) {
                    width: 13px;
                    padding: 8px 5px 8px 13px;
                    text-align: center;

                    input[type="radio"] {
                        margin-top: 0;
                    }
                }

                &:nth-of-type(2) {
                    flex: 1 1 auto;
                }
            }

            &__label {
                display: inline-block;
                line-height: 18px;
                width: auto;
                margin-bottom: 0;
                font-size: 14px;

                &--discounted {
                    .product-options {
                        &__discounted-price {
                            color: @c-red;
                            font-size: 1.3em;
                            margin-right: 8px;
                            vertical-align: text-bottom;
                            padding-right: 2px;
                            border-right: 1px solid @c-gray-default;
                        }

                        &__original-price {
                            text-decoration: line-through;
                        }
                    }
                }
            }
        }

        .product-option-radio {
            &__stock {
                width: 100%;
                display: flex;
                align-items: baseline;
                font-size: 12px;
                line-height: initial;

                &--green {
                    color: @c-green;

                    .product-option-radio__stock-circle {
                        background-color: @c-green;
                        border-color: @c-green-dark;
                    }
                }

                &--orange {
                    color: @c-orange;

                    .product-option-radio__stock-circle {
                        background-color: @c-orange;
                        border-color: @c-orange-dark;
                    }
                }

                &--red {
                    color: @c-red;

                    .product-option-radio__stock-circle {
                        background-color: @c-red;
                        border-color: @c-red-dark;
                    }
                }

                &--gray {
                    color: @c-gray;

                    .product-option-radio__stock-circle {
                        background-color: @c-gray;
                        border-color: @c-gray-dark;
                        display: none;
                    }
                }
            }

            &__stock-circle {
                height: 7px;
                width: 7px;
                border-radius: 50%;
                border: 1px solid transparent;
                margin-right: 5px;
            }

            &__stock-text {
                @media screen and (min-width: @breakpoint-medium) {
                    font-size: 12px;
                }

                @media screen and (min-width: @breakpoint-large) {
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (min-width: @breakpoint-small) {
    .modal-box {
        .product-options {

            .product-options {
                &__label {
                    font-size: 16px;
                }

                &__stock {
                    font-size: 13px;
                }

            }
        }
    }
}

@media screen and (min-width: @breakpoint-medium) {
    .modal-box {
        .product-options {

            .product-options {
                &__header {
                    padding: 0 25px;
                }

                &__item {
                    padding: 0 25px 0 17px;
                }

                &__column {
                    &:nth-of-type(1) {
                        padding: 8px 18px 8px 8px;
                    }
                }
            }

        }
    }
}

@media screen and (orientation: landscape) and (max-width: (@breakpoint-large - 1)) {
    .modal-box {
        .product-options {

            .product-options {
                &__column {
                    padding: 4px 8px;
                    font-size: 12px;
                }

                &__label {
                    display: block;
                    font-size: 14px;
                }

                &__stock {
                    font-size: 13px;
                }
            }
        }
    }
}

// 
// Product option list heights
//

@media screen and (min-height: 600px) {
    .modal-box {
        .product-options {

            &.product-options {
                &--1 {
                    .product-options__list {
                        max-height: 35vh;
                    }
                }

                &--2 {
                    .product-options__list {
                        max-height: 24vh;
                    }
                }
            }

        }
    }
}

@media screen and (min-height: 700px) {
    .modal-box {
        .product-options {

            &.product-options {
                &--1 {
                    .product-options__list {
                        max-height: 46vh;
                    }
                }

                &--2 {
                    .product-options__list {
                        max-height: 36vh;
                    }
                }
            }

        }
    }
}

@media screen and (min-height: 800px) and (max-width: (@breakpoint-medium - 1)) {
    .modal-box {
        .product-options {

            &.product-options {
                &--1 {
                    .product-options__list {
                        max-height: 54vh;
                    }
                }

                &--2 {
                    .product-options__list {
                        max-height: 50vh;
                    }
                }
            }

        }
    }
}

@media screen and (min-height: 768px) and (min-width: @breakpoint-medium) {
    .modal-box {
        .product-options {

            &.product-options {
                &--1 {
                    .product-options__list {
                        max-height: 50vh;
                    }
                }

                &--2 {
                    .product-options__list {
                        max-height: 40vh;
                    }
                }
            }

        }
    }
}

@media screen and (orientation: landscape) and (max-width: (@breakpoint-large - 1)) {
    .modal-box {
        .product-options {

            &.product-options {
                &--1 {
                    .product-options__list {
                        max-height: 35vh;
                    }
                }

                &--2 {
                    .product-options__list {
                        max-height: 30vh;
                    }
                }
            }

        }
    }
}