@media screen and (max-width: (@breakpoint-medium - 1)) {
    span.shipping-method-price {
        width: auto !important;
    }
}


.dpd-locations {
    float: right;
    width: auto;

    .btn.dpd-pickup {
        margin-top: 3px;
        padding: 9px 10px !important;
    }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    .dpd-locations {
        float: left;
        width: 100%;

        .btn.dpd-pickup {
            margin: 0;
        }
    }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    body .btn.dpd-pickup {
        width: 95%;
        margin: 0 10px 9px 10px;
        padding: 6px 10px;
    }
}

.selected-store {
    float: left;
    padding: 20px 0 20px 20px !important;
    width: ~'calc(100% - 20px)';
    background: @c-white;
    border-radius: 10px;
    border: 1px solid gray;
    left: 0;
    margin: 0;

    .bold {
        font-weight: 700;
        margin-right: 5px;
    }

    img {
        max-height: 19px;
        margin-right: 6px;
    }

    .store-name {
        display: inline;
        padding-right: 5px;
    }

    .store-address {
        display: inline;
        padding-right: 5px;
    }

    .store-location {
        display: inline;
        padding-right: 5px;
    }

    .store-distance {
        display: inline;
        padding-right: 5px;
    }

    td {
        padding-bottom: 6px;
    }

}

@media screen and (max-width: (@breakpoint-large - 1)) {
    .selected-store {
        p {
            display: inline-block !important;
            width: auto;
        }

        span {
            display: inline;
        }
    }
}


@media screen and (max-width: (@breakpoint-large - 1)) {
    .selected-store {
        table {
            width: 70%;
        }
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    .selected-store {
        table {
            width: 100%;
        }
    }
}


@media screen and (max-width: (@breakpoint-small - 1)) {
    .selected-store {
        float: left;
        padding: 20px 0 20px 20px !important;
        width: 91% !important;
        background: @c-white;
        border-radius: 10px;
        border: 1px solid gray;
        left: 0;
        margin: 0;
        margin-left: 17px;

        table {
            width: 100%;
        }

        p {
            display: inline-block !important;
            width: auto;
        }

        span {
            display: inline;
        }
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .selected-store {
        table {
            img {
                display: none;
            }
        }
    }
}

.methods {
    width: 100%;
    float: left;

    .method {
        width: 49%;
        float: left;
        box-sizing: border-box;
        margin-bottom: 20px;

        &.left {
            float: left;
        }

        &.right {
            float: right;
        }

        .inner {
            background: #F9F9F9;
            width: 100%;
            height: 375px;
            float: left;
            box-sizing: border-box;
            padding: 40px 40px 20px 40px;
            min-height: 365px;

            &.small {
                min-height: 283px;
            }

            img {
                float: left;
            }
        }

        .content {
            width: 330px;
            float: right;
            box-sizing: border-box;
            padding-left: 20px;

            &.x2 {
                margin-top: 5px;
            }

            h3 {
                margin: 0 0 10px 0;
                font-size: 24px;
            }
        }

        .days {
            width: 100%;
            height: 100px;
            float: left;
            background: #EBEBEB;
            margin-top: 0;
            padding: 10px 0;
            padding-right: 40px;
            box-sizing: border-box;

            .country {
                width: 30px;
                height: 30px;
                float: left;
                margin: 7px 5px 0 0;

                &.nl {
                    background: url('../../../img/bestellen/nl.png') no-repeat;
                }

                &.be {
                    background: url('../../../img/bestellen/be.png') no-repeat;
                }
            }

            .day {
                width: 30px;
                height: 30px;
                border-radius: 30px;
                background: #70AFDD;
                color: @c-white;
                line-height: 30px;
                text-align: center;
                float: left;
                margin-right: 5px;
                font-size: 14px;
            }
        }
    }

    @media screen and (max-width: (@breakpoint-xlarge - 1)) {
        .method {
            width: 100%;

            .inner {
                min-height: 0;
            }

            .content {
                width: 620px;
            }
        }
    }

    @media screen and (max-width: (@breakpoint-large - 1)) {
        .method {
            .inner {
                padding: 20px;
            }

            img {
                width: 100px;
                left: 50%;
                margin-left: -50px;
                position: relative;
                margin-bottom: 20px;
            }

            .content {
                width: 100%;
                padding: 0;
            }

            .days {
                padding-left: 20px;
            }
        }
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        .method {
            .days {
                padding: 5px;

                .day {
                    margin-bottom: 5px;
                }
            }
        }
    }
}
