.whatsapp-logo {
    float: left;
    margin-right: 3px;
    overflow: hidden;
    padding: 0;
    color: @c-white;
    cursor: default;
    height: 17px;
    pointer-events: none;
    width: 17px;
    background-image: url('../../../img/whatsapp.svg');
    background-size: cover;
    background-repeat: no-repeat;
}
