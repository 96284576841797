.email-validator_spacer {
    position: relative;
}

.e-mail-alert-close {
    position: absolute !important;
    top: 50% !important;
    z-index: 20;
    transform: translateY(-58%);
    -ms-transform: translateY (-58%);
    -webkit-transform: translateY (-58%);
    -moz-transform: translateY (-58%);
    -o-transform: translateY (-58%);
    right: 7px !important;
    line-height: 20px !important;
}

.email-alert-info {
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
    max-width: 100%;
    min-width: 210px;
    top: 0;
    padding: 7px 35px 7px 14px;
    left: 479px;
    position: absolute;

    &:hover {
        span {
            color: #3b99fc;
        }
    }

    span {
        text-decoration: underline;
        transition: all 0.2s ease;
    }
}

@media screen and (max-width: (@breakpoint-xlarge - 1)) {
    .email-alert-info {
        left: 419px;
    }
}

@media screen and (max-width: (@breakpoint-large - 1)) {
    .email-alert-info {
        left: 439px;
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    .email-alert-info {
        top: 20px;
        left: 230px;
    }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    .email-alert-info {
        float: none;
        min-width: 210px;
        top: 0;
        left: 0;
        position: relative;
    }

    .emailSuggestionInline {
        display: block !important;
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .email-alert-info {
        max-width: 79%;
        min-width: 62%;
    }

    .email-validator-inside {
        margin-right: 20px;
    }
}

@media screen and (max-width: (@breakpoint-xxsmall - 1)) {
    .email-alert-info {
        max-width: 77%;
        min-width: 77%;
    }
}