.alert {
    padding: 8px 24px 8px 16px;
    margin-bottom: 8px;
    font-size: 14px;
    .rounded();
    clear: both;

    @media screen and (min-width: @breakpoint-large) {
        padding: 16px 32px 16px 24px;
        margin-bottom: 16px;
    }
}

.alert-checkout {
    max-width: 575px;
}