.product-daily-deal {
    background: @c-red;
    color: @c-white;
    .neuron;
    text-align: center;
    padding: 4px 0;
    width: 172px;
    margin: 0 auto;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;

    .product-daily-deal__label {
        border-right: 1px solid @c-white;
        padding-right: 6px;
        margin-right: 3px;
    }

    .product-daily-deal__icon {
        width: 1em;
        height: 1em;
        display: inline-block;
        background: url(../../../img/icons/alarm-fill.svg) no-repeat;
        background-size: 0.9em;
        vertical-align: middle;
    }
}
