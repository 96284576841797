//		TO DO
//-------------------------------------------------------------------------------------------------------------
//	1: Nesting columns properly so that double padding is neglected for one or the other
//-------------------------------------------------------------------------------------------------------------

//		Don't touch anything below!
//-------------------------------------------------------------------------------------------------------------
//	It doesn't have any parameters to tweak. Everything is all based on the variables in grid.less.
//-------------------------------------------------------------------------------------------------------------

@fluid-under: (@manual-fluid-breakpoint * @fluid-enabled);
@maxw-fluid-a: @fluid-grid-a-transition;
@minw-fluid-b: (@maxw-fluid-a + 1);
@minw-a: ((@fluid-under + 1) * @fluid-enabled );
@maxw-a: (@grid-b-width + @extra-breakpoint-margin * 2 + @gutter);
@minw-b: (@maxw-a + 1);
@maxw-b: (@minw-c - 1);
@minw-c: (@grid-c-width + @extra-breakpoint-margin * 2 + @gutter);

// Switch to allow usage of a global number of columns or a manual number of columns
.manual-columns-switch (@switch) when (@switch =0) {
	@fluid-a-columns: @columns;
	@fluid-b-columns: @columns;
	@grid-a-columns: @columns;
	@grid-b-columns: @columns;
	@grid-c-columns: @columns;
}

.manual-columns-switch(@use-manual-column-numbers);
@max-columns: @grid-c-columns;

// Generate fluid breakpoint based on @gutter value in px when @fluid-breakpoint-switch is on
.fluid-breakpoint-switch (@fluid-breakpoint-switch) when (@fluid-breakpoint-switch =1) {
	@minw-a: ((@grid-a-width + @extra-breakpoint-margin * 2 + @gutter) * @fluid-enabled );
	@fluid-under: ((@minw-a - 1) * @fluid-enabled);
}

.fluid-breakpoint-switch(@automatically-generate-fluid-breakpoint);

// Generate fluid gutter or use manual values
// I call it the magic buttery smooth fluid transition formula <3
@fluid-gutter: @manual-fluid-gutter;

.fluid-gutter-switch (@fluid-gutter-switch) when (@fluid-gutter-switch =1) {
	@fluid-gutter: percentage(@gutter / @minw-a / 2);
}

.fluid-gutter-switch(@automatically-generate-fluid-gutter);

// Cache media queries as variables. Usage: @media @grid-n { }
@grid-fluid-a: ~"(max-width: @{maxw-fluid-a})";
@grid-fluid-b: ~"(min-width: @{minw-fluid-b}) and (max-width: @{fluid-under})";
@grid-fluid: ~"(max-width: @{fluid-under})";
@grid-a: ~"(min-width: @{minw-a}) and (max-width:@{maxw-a})";
@grid-b: ~"(min-width: @{minw-b}) and (max-width: @{maxw-b})";
@grid-c: ~"(min-width: @{minw-c})";
@grid-solid: ~"(min-width: @{minw-a})";

/*-------------------------------------------------------------------------------- Container class and clearfix
*/
.grid {
	margin: 0 auto;

	// Clearfix
	&:after {
		visibility: hidden;
		display: block;
		content: "";
		clear: both;
		height: 0;
	}
}

//	IE6 clearfix
* html .grid {
	zoom: 1;
}

//	IE7 clearfix
*:first-child+html .grid {
	zoom: 1;
}

//--------------------------------------------------------- Mixin functions to auto generate classes and styles
.alwaysColumnsSolid(@column-index, @grid-width, @columns) {
	.always@{column-index} {
		width: (@grid-width / @columns * @column-index - @gutter / @columns * (@columns - @column-index));
	}
}

;

.alwaysColumnsFluid(@column-index, @columns) {
	.always@{column-index} {
		width: (100% * (@column-index / @columns) - 2 * @fluid-gutter);
		float: left;
		padding: 0 @fluid-gutter;
		float: left;
	}
}

;


.fluid-a-Columns(@column-index, @columns: @fluid-a-columns) {

	.fla@{column-index},
	.fluid@{column-index} {
		width: (100% * (@column-index / @columns) - 2 * @fluid-gutter);
		float: left;
	}
}

;

.fluid-b-Columns(@column-index, @columns: @fluid-b-columns) {

	.flb@{column-index},
	.fluid@{column-index} {
		width: (100% * (@column-index / @columns) - 2 * @fluid-gutter);
		float: left;
	}
}

;

.solidOnlyColumns(@i) {

	.a@{i},
	.b@{i},
	.c@{i},
	.d@{i},
	.always@{i},
	.solid@{i} {
		float: left;
		padding: 0 (@gutter/2);
	}
}

;

.solidColumns(@grid-index, @column-index, @grid-width, @columns) {

	.@{grid-index}@{column-index},
	.solid@{column-index} {
		width: (@grid-width / @columns * @column-index - @gutter / @columns * (@columns - @column-index));
	}

	.subgrid@{grid-index}@{column-index} {
		width: (@grid-width / @columns * @column-index - @gutter / @columns * (@columns - @column-index) + @gutter );
		float: left;
	}
}

/*------------------------------------------- Always(n) classes that allow overrides with more specific classes
*/
/*Fluid Grid A*/
@media @grid-fluid-a {
	.classGen(@i) when (@i > 0) {
		.alwaysColumnsFluid(@i, @fluid-a-columns);
		.classGen(@i - 1);
	}

	.classGen(@fluid-a-columns);
}

/*Fluid Grid B*/
@media @grid-fluid-b {

	.classGen(@i) when (@i > 0) {
		.alwaysColumnsFluid(@i, @fluid-b-columns);
		.classGen(@i - 1);
	}

	.classGen(@fluid-b-columns);
}

/*Grid A*/
@media @grid-a {

	.classGen(@i) when (@i > 0) {
		.alwaysColumnsSolid(@i, @grid-a-width, @grid-a-columns);
		.classGen(@i - 1);
	}

	.classGen(@max-columns);

	.alwaysfull {
		width: @grid-a-width;
	}

	.ahide {
		display: none;
	}
}

/*Grid B*/
@media @grid-b {
	.classGen(@i) when (@i > 0) {
		.alwaysColumnsSolid(@i, @grid-b-width, @grid-b-columns);
		.classGen(@i - 1);
	}

	.classGen(@max-columns);

	.alwaysfull {
		width: @grid-b-width;
	}

	.bhide {
		display: none;
	}
}

/*Grid C*/
@media @grid-c {
	.classGen(@i) when (@i > 0) {
		.alwaysColumnsSolid(@i, @grid-c-width, @grid-c-columns);
		.classGen(@i - 1);
	}

	.classGen(@max-columns);

	.alwaysfull {
		width: @grid-c-width;
	}

	.chide {
		display: none;
	}
}

/*----------------------------------------------------------------------------- General Solid grid declarations
*/
@media @grid-solid {
	.classGen(@i) when (@i > 0) {
		.solidOnlyColumns(@i);
		.classGen(@i - 1);
	}

	.classGen(@max-columns);

	.alwaysfull {
		float: left;
		padding: 0 (@gutter/2);
	}

	.solidhide {
		display: none !important;
	}
}

/*----------------------------------------------------------------------------- General Fluid grid declarations
*/
@media @grid-fluid {
	@{container_class} {
		width: (100% - @fluid-window-padding);
		margin: 0 auto;
		// &>*	{ padding: 0 @fluid-gutter; float: left; }
	}

	.fluidfull,
	.alwaysfull {
		width: (100% - 2 * @fluid-gutter);
		padding: 0 @fluid-gutter;
		float: left;
	}

	.fluidhide {
		display: none;
	}
}

/*------------------------------------------------------------------------------------------------ Fluid grid A
*/
@media @grid-fluid-a {
	.classGen(@i) when (@i > 0) {
		.fluid-a-Columns(@i);
		.classGen(@i - 1);
	}

	.classGen(@fluid-a-columns);

	.flahide {
		display: none;
	}
}

/*------------------------------------------------------------------------------------------------ Fluid grid B
*/
@media @grid-fluid-b {
	.classGen(@i) when (@i > 0) {
		.fluid-b-Columns(@i);
		.classGen(@i - 1);
	}

	.classGen(@fluid-b-columns);

	.flbhide {
		display: none;
	}
}

/*------------------------------------------------------------------------------------------------------ Grid A
*/
@media @grid-a {
	@{container_class} {
		width: (@grid-a-width + @gutter);
	}

	.classGen(@i) when (@i > 0) {
		.solidColumns(a, @i, @grid-a-width, @grid-a-columns);
		.classGen(@i - 1);
	}

	.classGen(@max-columns);

	.ahide {
		display: none;
	}
}


/*------------------------------------------------------------------------------------------------------ Grid B
*/
@media @grid-b {
	@{container_class} {
		width: (@grid-b-width + @gutter);
	}

	.classGen(@i) when (@i > 0) {
		.solidColumns(b, @i, @grid-b-width, @grid-b-columns);
		.classGen(@i - 1);
	}

	.classGen(@max-columns);

	.bhide {
		display: none;
	}
}


/*------------------------------------------------------------------------------------------------------ Grid C
*/
@media @grid-c {
	@{container_class} {
		width: (@grid-c-width + @gutter);
	}

	.classGen(@i) when (@i > 0) {
		.solidColumns(c, @i, @grid-c-width, @grid-c-columns);
		.classGen(@i - 1);
	}

	.classGen(@max-columns);

	.chide {
		display: none;
	}
}

/*--------------------------------------------------------------------------------------------- General classes
*/
.fr {
	float: right;
}

.fn {
	float: none;
}