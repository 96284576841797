#info {
    a {
        .obviousLink;
        line-height: 1.5em;
    }

    #info-body {
        padding: 19px;
        line-height: 1.5em;
        margin-bottom: 8px;
        .box;

        #info-title {
            h1 {
                margin: 0 0 .5em 0;
                color: @color-blue-primary;
                font-size: 22px;
            }
        }

        a.btn {
            text-decoration:none;
            color: #000;
        }

        p.paragraph-header {
            color: @color-blue-primary;
            margin-bottom: .25em;
            font-weight: bold;
            font-size: 16px;
        }

        p,
        ul {
            font-size: 14px;
            margin-bottom: 1.2em;
        }

        .image-attachment {
            margin: 20px 0;

            .image-caption {
                margin: .5em 0;
                font-size: 14px;
                font-style: italic;
            }
        }

        h3 {
            clear: both;
        }

        .faq-item {
            h4 {
                background-color: #efefef;
                padding: 10px;
                border-radius: 5px;
            }

            .answer {

            }
        }
    }

    #info-side {
        #info-side-top {
            margin-bottom: 8px;

            #print-button,
            #share-button {
                .neuron;
                font-size: 18px;
            }

            #share-button {
                float: right;
            }

            #info-description {
                margin-top: 8px;

                p {
                    display: inline-block;
                    color: @color-text-primary;
                    font-size: 13px;
                    .neuron;
                    font-weight: bold;
                    margin: 0;
                    line-height: 1.3em;
                }
            }
        }

        #info-side-products {
            .info-side-product {
                height: 84px;
                margin-bottom: 10px;
                .box;
                position: relative;

                img {
                    width: 100px;
                }

                .info-side-product-right {
                    .neuron;
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 14px;

                    .product-title {
                        color: @color-blue-primary;
                        margin: 5px 0;
                    }

                    .product-price {
                        margin: 5px 0;
                    }

                    button {
                        padding: 4px 7px;
                        font-size: 14px;
                        .neuron;
                        margin-right: 7px;
                    }
                }
            }
        }
    }

    #info-content {
        .clearfix;
        .box;
        padding: 25px;

        .info-question {
            display: block;
            width: 98%;
        }

        .info-box-header {
            margin-bottom: -1px;
            height: 40px;
            background-color: #FDFDFD;
            border: 1px solid @color-border-dark;
            -webkit-border-top-left-radius: 3px;
            -webkit-border-top-right-radius: 3px;
            -moz-border-radius-topleft: 3px;
            -moz-border-radius-topright: 3px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;

            h4,
            a {
                padding: 10px;
                font-size: 20px;
                margin: 0;
                line-height: 20px;
                text-decoration: none;
            }
        }

        .info-box-body {
            padding: 10px;
            min-height: 150px;
            -webkit-border-bottom-right-radius: 3px;
            -webkit-border-bottom-left-radius: 3px;
            -moz-border-radius-bottomright: 3px;
            -moz-border-radius-bottomleft: 3px;
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
            border: 1px solid @color-border-dark;
            background-color: @color-background-page;
            .clearfix;

            h4 {
                color: @color-blue-primary;
                margin: 4px 0;
                font-size: 18px;
            }

            address {
                margin: 0;
                font-size: 14px;
            }

            p {
                font-size: 14px;
            }
        }

        .info-details-wrapper {
            width: 372px;
            margin-bottom: 10px;
            float: left;
            margin-right: 10px;
        }
    }

    #submit-button {
        float: right;
        font-size: 19px;
        padding: 5px 13px;
        color: @c-white;
        .neuron;
    }
}
