#product-page-body #product-page-content {

    #product-page-side #product-page-side-inner,
    #product-mobile-config {

        #product-configuration .product-form .config-wrap {
            &--singleOption {
                width: 100% !important;
                height: auto !important;

                +.config-wrap--amount {
                    margin-top: 15px;
                }

                .config-block {
                    height: auto;

                    .table {
                        margin-bottom: 0;

                        th {
                            &:nth-child(3) {
                                text-align: right;
                            }
                        }

                        tr {
                            cursor: pointer;
                        }

                        th {
                            font-size: 14px;
                        }

                        td {
                            vertical-align: middle;
                            padding: 5px 8px;
                            transition: background-color .2s ease-in-out;

                            &:first-child {
                                text-align: center;
                                padding: 8px 3px 8px 7px;
                            }

                            &:last-child {
                                white-space: nowrap;
                                text-align: right;
                            }
                        }
                    }

                    .table-hover {
                        tr {
                            &.selected-option {
                                td {
                                    background-color: @c-white-dark;
                                }
                            }
                        }
                    }

                    .product-option-row {

                        .daily-deal-badge {
                            color: white;
                            padding: 3px 8px;
                            background: @c-red;
                            border-radius: 14px;
                            margin-right: 4px;
                            text-align: center;

                            @media screen and (max-width: (@breakpoint-xxlarge - 1)) {
                                display: block;
                                margin-bottom: 6px;
                                margin-right: 0;
                            }
                        }

                        .product-option__discount-price {
                            color: @c-red;
                            font-size: 1.3em;
                            padding-right: 3px;
                            margin-right: 6px;
                            border-right: 1px solid @c-gray-default;
                            vertical-align: text-bottom;
                        }

                        .product-option__original-price {
                            text-decoration: line-through;
                        }

                        @media screen and (min-width: @breakpoint-medium) {
                            .product-option__discount-price {
                                color: #f48413;
                                float:right;
                                font-size: 1em;
                                border-right: 0;
                                margin: -1px 0 0 14px;
                                padding: 0;

                                sup {
                                    top: -0.23em;
                                }
                            }

                            .product-option__original-price {
                                float:left;
                                text-decoration: none;
                                font-size: 1.3em;
                                position: relative;
                                vertical-align: text-bottom;

                                sup {
                                    top: -0.175em;
                                }

                                &:after {
                                    content: '';
                                    position: absolute;
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3Cline x1='5%25' y1='10%25' x2='95%25' y2='90%25' stroke='%23f48413' stroke-width='1.5'/%3E%3C/svg%3E");
                                    display: block;
                                    top: -2px;
                                    left: -2px;
                                    right: -4px;
                                    bottom: -2px;
                                    height: auto;
                                    background-color: transparent;
                                    -webkit-transform: none;
                                    -ms-transform: none;
                                    transform: none;
                                }
                            }
                        }

                        .product-option-radio {
                            display: inline-block;
                            width: 100%;
                            margin-top: 10px;
                            background: transparent;

                            &__item {
                                display: inline-block;
                                width: 100%;
                                margin: 4px 0;
                                line-height: 18px;
                            }

                            &__label {
                                display: inline-block;
                                line-height: 18px;
                                width: auto;
                                margin-bottom: 0;
                                font-size: 13px;

                                @media screen and (min-width: @breakpoint-small) {
                                    font-size: 14px;
                                }

                                @media screen and (min-width: @breakpoint-large) {
                                    font-size: 16px;
                                }

                                sup {
                                    top: -0.3em;
                                }

                                @media screen and (max-width: (@breakpoint-medium - 1)) {
                                    span.eurosign {
                                        display: none;
                                    }
                                }
                            }

                            &__giveaway {
                                margin: 0;
                                color:#ba2023;
                                font-size:12px;
                                font-weight:bold;
                            }

                            &__stock {
                                width: 100%;
                                display: flex;
                                align-items: baseline;
                                font-size: 11px;
                                line-height: initial;

                                &--green {
                                    color: @c-green;

                                    .product-option-radio__stock-circle {
                                        background-color: @c-green;
                                        border-color: @c-green-dark;
                                    }
                                }

                                &--orange {
                                    color: @c-orange;

                                    .product-option-radio__stock-circle {
                                        background-color: @c-orange;
                                        border-color: @c-orange-dark;
                                    }
                                }

                                &--red {
                                    color: @c-red;

                                    .product-option-radio__stock-circle {
                                        background-color: @c-red;
                                        border-color: @c-red-dark;
                                    }
                                }

                                &--gray {
                                    color: @c-gray;

                                    .product-option-radio__stock-circle {
                                        background-color: @c-gray;
                                        border-color: @c-gray-dark;
                                        display: none;
                                    }
                                }
                            }

                            &__stock-circle {
                                height: 7px;
                                width: 7px;
                                border-radius: 50%;
                                border: 1px solid transparent;
                                margin-right: 5px;
                            }

                            &__stock-text {
                                @media screen and (min-width: @breakpoint-medium) {
                                    font-size: 12px;
                                }

                                @media screen and (min-width: @breakpoint-large) {
                                    font-size: 14px;
                                }
                            }

                            &__radioButton {
                                display: inline-block;
                                margin-top: -2px;
                                width: 14px;
                                height: 14px;
                            }
                        }
                    }
                }
            }

            &--workshop-date {
                margin-bottom: 15px;

                @media screen and (min-width: @breakpoint-large) {
                    margin-top: 40px;
                }
            }

            &--amount {
                clear: both;
                width: 100%;

                @media screen and (min-width: @breakpoint-xsmall) {
                    width: auto;
                }

                .config-block {
                    position: relative !important;

                    @media screen and (min-width: @breakpoint-large) {
                        width: ~"calc(100% - 12px)";
                    }

                    .product-amount {
                        text-align: center;
                        width: ~"calc(100% - 62px)";
                        padding-left: 30px;
                        padding-right: 30px;
                        -moz-appearance: textfield;
                        margin: 0;

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        &-holder {
                            display: flex;
                            align-items: center;
                            margin: 0 0 10px;

                            &--has-unit {
                                .product-amount {
                                    border-top-right-radius: 0;
                                    border-bottom-right-radius: 0;
                                }
                            }

                            @media screen and (min-width: @breakpoint-large) {
                                margin-top: 10px;
                            }
                        }

                        &-inner {
                            position: relative;
                            width: 100%;
                        }

                        &-unit {
                            font-size: 14px;
                            border: 1px solid @color-blue-primary;
                            border-radius: 0 4px 4px 0;
                            height: 38px;
                            line-height: 38px;
                            padding: 0 15px;
                            background: darken(@c-txt-white, 2%);
                            position: relative;
                            left: -1px;
                            color: @color-blue-primary;
                            cursor: default;
                        }

                        &-counter {
                            color: @color-blue-primary;
                            position: absolute;
                            font-weight: bold;
                            cursor: pointer;
                            font-size: 22px;
                            width: 40px;
                            text-align: center;
                            height: 40px;
                            line-height: 40px;
                            bottom: 0;
                            -webkit-touch-callout: none;
                            -webkit-user-select: none;
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;

                            &__minus {
                                left: 0;
                            }

                            &__plus {
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
