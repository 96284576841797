body.brands-page #breadcrumbs {
    display: block !important;
}


.brands-overview {
    float: right;
    width: 967px;

    * {
        box-sizing: border-box;
    }

    @media screen and (max-width: (@breakpoint-grid-large - 1)) {
        width: 685px;
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        width: 100%;
    }

    .brands-overview {
        &__title {
            margin: 0;
            font-size: 32px;
            line-height: 28px;
            text-transform: uppercase;
        }

        &__count {
            display: inline-block;
            width: 100%;
            padding: 5px;
            margin-top: 3px;
            margin-bottom: 10px;
            font-size: 12px;
            line-height: 21px;
            border-bottom: 1px solid @color-border-light;
            border-top: 1px solid @color-border-light;

            span {
                font-weight: bold;
            }
        }

        &__description {
            @media screen and (max-width: (@breakpoint-large) - 1) {
                display: none;
            }
        }

        &__logo {
            float: left;
            margin-bottom: 25px;
        }

        &__text {
            p {
                color: fade(@c-black, 80%);
                font-size: 14px;
                line-height: 20px;
            }
        }

        &__brands {
            float: left;
            width: 100%;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            list-style: none;
            margin: 0 -8px 24px;
        }

        &__item {
            flex: 0 0 50%;
            padding: 8px;

            @media screen and (min-width: @breakpoint-small) {
                flex: 0 0 33.33%;
            }

            @media screen and (min-width: @breakpoint-grid-large) {
                flex: 0 0 25%;
            }
        }

        &__link {
            display: inline-block;
            width: 100%;
            background-color: @c-white;
            border-radius: 0;
            box-shadow: 0 2px 4px -2px fade(@c-black, 20%);
            transition: @transition-cubic;

            &:hover {
                border-radius: 4px;
                box-shadow: 0 4px 8px -2px fade(@c-black, 20%);
            }
        }

        &__figure {
            text-align: center;
        }

        &__name {
            font-size: 15px;
            text-align: center;
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;

            @media screen and (min-width: @breakpoint-small) {
                font-size: 16px;
            }

            @media screen and (min-width: @breakpoint-grid-large) {
                font-size: 18px;
            }
        }

        &__product-count {
            display: block;
            margin-bottom: 5px;
            font-size: 13px;
            text-align: center;
            .neuron();

            @media screen and (min-width: @breakpoint-small) {
                font-size: 14px;
            }

            @media screen and (min-width: @breakpoint-grid-large) {
                font-size: 15px;
            }
        }
    }
}

.brands-overview {
    #hits {
        margin-left: 15px;

        .ais-Hits {
            ol {
                list-style: none;
                padding: 0;

                li {
                    margin: 0;
                    box-shadow: none;
                    width: auto;
                    border: none;
                    padding: 0;

                    @media screen and (max-width: (@breakpoint-small - 1)) {
                        width: 100%;

                        .always1 {
                            width: 100%;
                        }
                    }

                    @media screen and (min-width: (@breakpoint-small)) {
                        width: 50%;

                        .always1 {
                            width: 100%;
                        }
                    }

                    @media screen and (min-width: @breakpoint-medium) {
                        width: auto;
                    }
                }
            }

            .product {
                .product-text .bullets {
                    li {
                        float: none;
                    }
                }
            }

            @media screen and (min-width: @breakpoint-medium) {
                ol li .always1 {
                    width: 221px;
                }
            }

            @media screen and (min-width: @breakpoint-large) {
                ol li .always1 {
                    width: 218px;
                }
            }

            @media screen and (min-width: @breakpoint-xlarge) {
                ol li .always1 {
                    width: 233px;
                }
            }
        }
    }
}
