body.checkout-cart {
    #checkout #checkout-content #checkout-main #checkout-main-inner {
        padding-top: 0;
    }
}

.payment-logos-cart {
    width: 100%;
    padding: 20px 0;

    img {
        width: 100%;
    }
}

@media screen and (max-width: (@breakpoint-large - 1)) {
    .payment-logos-cart {
        img {
            width: 70%;
            margin: 0 auto;
            display: block;
        }
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    .payment-logos-cart {
        img {
            width: 100%;
            margin: 0 auto;
            display: block;
        }
    }
}

.cart-second {
    .kiyoh {
        width: 150px !important;
        float: right !important;
        background: @c-white url('../../../img/kiyoh.png') center center no-repeat !important;
        background-size: auto 25px !important;
        border-left: 1px solid #CACACA !important;
        height: 36px !important;
        padding: 0 !important;
    }

    #checkout-sub iframe {
        margin-top: 10px;
    }
}

.cart-discount-number {
    color: #5bb75b;
    display: block;
    padding-top: 5px;
    font-weight: normal;
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    .cart-discount-number {
        font-size: 14px;
    }
}

.btn.disabled,
.btn[disabled] {
  pointer-events: none!important;
}

.mobile-sticky.next-step.disabled {
    pointer-events: none!important;
    cursor: default;
}

.giveaway {
    display: flex;
    width: 100%;
    background: #fafafa;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
    margin: 10px 0 0 0;
    box-sizing: border-box;
    position: relative;

    .giveaway-image {
        width: 15.3%;
        border-right: 2px solid #00A2E0;
        box-sizing: border-box;

        img {
            height: auto;
            max-width: 100%;
            border: 0px;
        }
    }

    .giveaway-title {
        width: 50%;
        padding: 15px;
        box-sizing: border-box;

        h5 {
            color: #00a2e0;
            font-size: 17px;
            font-weight: 100;
            font-family: 'Neuron', Helvetica, sans-serif;
            line-height: 18px;
        }
    }

    .giveaway-amount {
        width: 20%;
        padding-top: 25px;
        box-sizing: border-box;

        span {
            background: #fff;
            padding: 15px;
            border: 1px solid #CCCCCC;
            border-radius: 5px;

        }
    }

    .giveaway-price {
        padding: 15px;
        width: 17%;
        box-sizing: border-box;
        color: #70BF05;
        font-weight: bold;
        text-transform: uppercase;
        padding-top: 25px;
    }
}

.giveaway.solidhide {
    display: block;
    position: unset;
    border: 1px solid #cacaca;
    margin: -5px 0 10px 0;

    .giveaway-header {
        display: flex;
        width: 100%;
        background: #FDFDFD;
        border-bottom: 1px solid #CACACA;
        padding: 10px;
        box-sizing: border-box;

        .giveaway-image {
            width: 75px;
        }

        .giveaway-title {
            padding: 0px 5px 0px 10px;

            h5 {
                margin: 0 0 2px 0;
            }
        }
    }

    .giveaway-footer {
        display: flex;
        padding: 10px 0 20px 0;

        .giveaway-amount {
            width: 50%;
            margin-left: 10px;
            font-size: 14px;

            span {
                padding: 10px;
            }
        }

        .giveaway-price {
            width: 50%;
            text-align: right;
            font-size: 14px;
        }
    }
}
