.flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-grid-1 {
  width: 8.3333%;
}

.flex-grid-2 {
  width: 16.6667%;
}

.flex-grid-3 {
  width: 25%;
}

.flex-grid-4 {
  width: 33.3333%;
}

.flex-grid-5 {
  width: 41.6667%;
}

.flex-grid-6 {
  width: 50%;
}

.flex-grid-7 {
  width: 58.3333%;
}

.flex-grid-8 {
  width: 66.6667%;
}

.flex-grid-9 {
  width: 75%;
}

.flex-grid-10 {
  width: 83.3333%;
}

.flex-grid-11 {
  width: 91.6667%;
}

.flex-grid-12 {
  width: 100%;
}
