#desktop-footer {
    flex-shrink: 0;
    margin-top: auto;
    width: 100%;

    #footer-content {
        @media screen and (min-width: @breakpoint-medium) {
            margin-top: 50px;
        }
        padding-top: 18px;
        padding-left: 27px;
        background-color: @color-background-page;
        border-top: 1px solid @color-border-dark;

        h4 {
            color: @c-lnk1;
            .neuron;
            font-weight: 100;
            font-size: 18px;
        }

        ul {
            list-style: none;
            margin-left: 0;
            padding-top: 0;

            li {
                font-size: 12px;
            }
        }
    }

    h4 {
        margin-bottom: 5px;
    }

    h5 {
        font-weight: bold;
        font-size: 16px;
        margin: 0 0 5px 0;
        .neuron;
    }

    .contact_info {
        font-size: 14px;
        font-weight: 300;
        line-height: 26px;

        .company_name {
            font-weight: bold;
        }

        .street {
            width: 100%;
            display: block;
        }

        .email {
            margin-top: 10px;
            display: block;
        }

        .btw {
            margin-top: 10px;
        }
    }
}

#mobile-footer {
    margin-top: 8px;
    border-top: 1px solid @color-border-dark;
    background: @color-background-page;

    #mobile-footer-outer {
        padding-bottom: 24px;

        #mobile-footer-inner {
            padding: 0 30px;

            h4 {

                margin: 24px 0 0;
                color: @c-lnk1;
                .neuron;
                font-weight: 100;
                font-size: 18px;
            }

            ul {
                margin: 0;
                list-style-type: none;

                li.mobile-footer-block {
                    width: 220px;
                }

                li {
                    line-height: 25px;
                    font-size: 12px;
                }
            }
        }
    }
}

#desktop-footer {

    .la-switcher {
        padding-bottom: 20px;

        .active {
            display: none;
        }

        a {
            padding: 5px 0;
            font-size: 14px;
            img {
                margin: 4px 6px 0 0;
                height: 12px;
                box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
            }
        }
    }

    #footer-content {
        padding: 0;

        h4 {
            font-size: 21px;
            margin-bottom: 10px;
        }

        .solid1 {
            width: 25%;
            margin: 0;
            padding: 0;

            ul {
                li {
                    font-size: 14px;
                    line-height: 26px;
                }
            }

            @media screen and (max-width: (@breakpoint-xlarge - 1)) {
                width: 33.33%;

                &.flahide {
                    display: none;
                }
            }

            @media screen and (max-width: (@breakpoint-large - 1)) {
                width: 50%;
            }
        }

        .footer_bottom {
            width: 100%;
            color: @c-white;
            padding: 15px 0;
            margin-top: 20px;
            background: #00A7E5;

            .copy {
                font-size: 14px;
            }
        }

        #usp-bottombalk {
            width: 100%;
            border-bottom: 1px solid @color-border-dark;

            .grid {
                padding: 0;
                box-sizing: border-box;

                @media screen and (max-width: (@breakpoint-medium - 1)) {
                    width: 100%;
                }

                @media screen and (min-width: @breakpoint-medium) {
                    padding: 0 4px;
                }
            }

            .inner {
                width: 100%;
                padding: 8px 0;
                box-sizing: border-box;
                font-size: 14px;
                border-top: 0;
                border-radius: 0 0 4px 4px;

                @media screen and (min-width: @breakpoint-medium) {
                    padding: 8px 10px;
                }

                .usp {
                    float: left;
                    margin-right: 15px;
                    line-height: 26px;

                    .icon-whatsapp {
                        margin-right: 3px;
                        overflow: hidden;
                        padding: 0;
                        color: @c-white;
                        cursor: default;
                        height: 19px;
                        pointer-events: none;
                        width: 19px;
                        background-image: url('../../img/whatsapp.svg');
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: unset;
                    }

                    &.mobile {
                        display: none;
                    }

                    .menu-icon {
                        font-size: 19px;
                        margin: 3px 3px 0 0;
                        color: #5FC719;
                    }

                    span.small {
                        font-size: 11px;
                        opacity: 0.5;
                    }

                    @media screen and (max-width: (@breakpoint-xlarge - 1)) {
                        &.no-tablet {
                            display: none;
                        }
                    }

                    @media screen and (max-width: (@breakpoint-large - 1)) {
                        &.no-tablet-small {
                            display: none;
                        }
                    }

                    @media screen and (max-width: (@breakpoint-medium - 1)) {
                        width: 50%;
                        margin-left: 0;
                        margin-right: 0;

                        &.no-mobile {
                            display: none;
                        }

                        &.mobile {
                            display: block;
                        }
                    }

                    @media screen and (max-width: (@breakpoint-small - 1)) {
                        width: 100%;
                    }
                }

                #webshop-seal {
                    width: 89px;
                    float: right;
                    margin-top: 0;
                    margin-right: 4px;

                    img {
                        vertical-align: baseline;
                    }
                }

                @media screen and (max-width: (@breakpoint-medium - 1)) {
                    #webshop-seal {
                        display: none;
                    }
                }
            }

            &.bottom-cart {
                clear: both;
                border: 0;
                padding: 0;

                .inner {
                    border: 0;
                    padding: 0;
                    margin: 0;
                }

                #webshop-seal {
                    margin-top: -5px;
                    width: 283px;
                }

                @media screen and (max-width: (@breakpoint-medium - 1)) {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        position: relative;
        margin-top: 15px;

        #footer-content {
            height: auto;
        }
    }

    @media screen and (max-width: (@breakpoint-small - 1)) {
        #footer-content {
            .footer_bottom {
                .copy {
                    width: 100%;
                    text-align: center;
                }

                #payment_logos {
                    text-align: center;
                    width: 100%;
                    margin-top: 10px;

                    img {
                        display: inline-block;
                        float: none;
                        width: 60% !important;
                    }
                }
            }

            .solid1 {
                width: 100%;
            }
        }
    }
}


@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    #desktop-footer #footer-content .footer_bottom #payment_logos img {
        width: 95% !important;
    }
}
