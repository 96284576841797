#advice_top {
    width: 350px;
    float: right;
    margin-right: 20px;

    .polycon-advice {
        width: 50px;
        float: left;
        height: 50px;
        margin-top: 22px;
        color: @color-blue-primary;
        position: relative;
        font-size: 44px;
        left: -20px;
    }

    .advice-person {
        width: 90px;
        margin-top: 7px;
        height: auto;
        float: left;

        img {
            width: 100%;
            height: auto;
            float: left;
        }
    }

    .text {
        margin-left: 10px;
        width: 250px;
        float: left;

        h4 {
            font-size: 19px;
            font-weight: 500;
            color: @color-blue-primary;
            width: 100%;
            margin: 27px 0 4px 0;
            float: left;
        }

        .rule {
            font-size: 12px;
            float: left;
            margin:0 5px 0 0;

            &.mobile {
                display: none;
            }

            .advice-icon {
                color: @color-blue-primary;
            }

        }
    }

    @media screen and (max-width: (@breakpoint-large - 1)) {
        width: 295px;

        .advice-person {
            width: 80px;
            margin-top: 17px;
        }

        .text {
            width: 200px;
        }
    }

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        display: none;
    }
}