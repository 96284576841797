.addresses {
  .btn {
    text-shadow: none;

    i {
      color: white;
    }
  }

  .control-group--button {
    border-top: 1px solid #CACACA;
    padding-top: 10px;
  }
}

#customer_addresses_addresses {
  .control-group {
    align-items: flex-start;

    a.rmElement {
      background: #1688ce;
      border-bottom: 5px solid #106da6;

      &:hover {
        background: #106da6;
      }
    }
  }
}
