.product-discounts {
    position: relative;
    top: -10px;

    @media screen and (min-width: @breakpoint-medium) {
        top: -17px;
    }

    section {
        padding: 20px 0;
    }

    h1 {
        font-size: 48px;
        font-weight: bold;
        margin: 0 0 .2em;
    }

    h2 {
        font-size: 36px;
        margin: 0;
    }

    h3 {
        font-size: 30px;
    }

    h4 {
        font-size: 20px;
    }

    .product-discounts__primary-header {
        background: @c-red;
        padding: 65px 0 75px;
        color: @c-white;
        text-align: center;
    }

    .product-discounts__secondary-header {
        background: @c-white;
        text-align: center;
    }

    &__discount-section {
        background: @c-gray-lighter;

        &:nth-child(even) {
            background: @c-white;
        }
    }

    &__disclaimer {
        p {
            font-size: .9em;
        }
    }

    @media screen and (max-width: (@breakpoint-small - 1)) {
        h1 {
            font-size: 32px;
        }

        h2 {
            font-size: 22px;
        }

        h3 {
            font-size: 26px;
            margin: 0px 0 20px;
        }

        h4 {
            font-size: 16px;
            margin: 10px 0 25px;
        }

        .product-discounts__primary-header {
            padding: 20px 0 25px;
        }

        .product-discounts__secondary-header {
            padding-bottom: 0;
        }
    }
}