#navigation {
  margin-bottom: 0;

  .alwaysfull {
    position: relative;
  }
}

#nav-main {
  position: relative;

  #nav-outer {
    box-sizing: border-box;
    position: relative;
    height: 51px;
    border-bottom: 2px solid darken(@c-blue2, 16%);
    border-top: 1px solid darken(@c-blue2, 15%);
    border-left: 1px solid darken(@c-blue2, 10%);
    border-right: 1px solid darken(@c-blue2, 10%);
    .gradient(@c-blue2, #00a3e0, lighten(#86cef4, 6%));
    .rounded(7px);
    .drop-shadow(0px, 2px, 19px, 0.15);

    #nav-inner {
      height: 49px;
      border-top: 1px solid lighten(@c-blue2, 25%);

      .rounded(7px);

      ul#desktop-nav {
        position: relative;
        padding-top: 0;
        padding-left: 0;
        margin: 0;

        li {
          list-style: none;
          display: inline;
          font-size: 12px;
          text-transform: uppercase;
          color: @c-white;
          font-size: 0;

          &.home a {
            -webkit-border-top-left-radius: 6px;
            -webkit-border-bottom-left-radius: 6px;
            -moz-border-radius-topleft: 6px;
            -moz-border-radius-bottomleft: 6px;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }

          a {
            color: @c-white;
            display: inline-block;
            font-size: 17px;
            text-decoration: none;
            padding: 14px 8px 14px 8px;
            text-shadow: 1px 1px 2px rgba(79, 119, 138, 0.47);
            .neuron;

            &:hover {
              .gradient-img(@c-blue2,
                lighten(#00a3e0, 1%),
                lighten(#86cef4, 10%));
            }

            &:active {
              .gradient-img(@c-blue2,
                darken(#86cef4, 12%),
                darken(#86cef4, 5%));
            }
          }

          .menu-icon {
            padding: 0 6px 0 0;
            font-size: 16px;
            position: relative;
            top: 1px;
          }

          .polycon-home {
            font-size: 26px;
            left: 5px;
            line-height: 0;
            position: relative;
            top: 5px;
            color: darken(@color-blue-primary, 10%);
            text-shadow: -1px 0 0 darken(@color-blue-primary, 30%)
          }
        }
      }
    }

    #search {
      margin-top: 6px;
      position: absolute;
      right: 10px;
      top: 0;

      button.btn {
        background: transparent;
        border: none;
        position: absolute;
        right: 0;
        top: 3px;
        z-index: 99;
        box-shadow: none;
      }

      input {
        border-radius: 3px;
        width: 171px;
        font-size: 14px;
        padding-right: 30px;

        &:focus {
          background: @c-white;
          box-shadow: none;
          .inner-shadow(0px, 1px, 7px, 0.2);
        }
      }
    }
  }
}


// ------------------------------------------------ Browser specific styles
// Firefox
@-moz-document url-prefix() {
  #nav-main #nav-outer {
    height: 49px !important;
  }
}

#mobile-nav {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;

  #mobile-nav-button {
    display: block;
    font-size: 20px;
    text-transform: uppercase;
    color: @c-white;
    font-size: 18px;
    text-decoration: none;
    padding: 10px 33px 10px 10px;
    font-size: 24px;
    border-radius: 6px;
    .neuron;

    &:hover {
      .text-shadow(0px, 2px, 7px, 255, 255, 255, 0.5);
      .gradient-img(@c-blue2, lighten(#00a3e0, 1%), lighten(#86cef4, 10%));
    }

    &:active {
      .gradient-img(@c-blue2, darken(#86cef4, 12%), darken(#86cef4, 5%));
    }
  }

  &.navOpen {
    #mobile-nav-button {
      .gradient-img(@c-blue2, darken(#86cef4, 12%), darken(#86cef4, 5%));
    }
  }

  #menu-icon {
    position: absolute;
    right: 10px;
    top: 4px;
    font-size: 40px;
    color: @c-white;
  }
}
