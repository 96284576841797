// ------------------------------------------------ Header styles
#header {
  height: 97px;
  margin-bottom: 11px;
  background: @color-background-page;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .4);

  #logo {
    margin-top: 16px;
    margin-left: 19px;

    .logo-trademark {
      float: left;
      width: 46px;
      height: 56px;
    }

    .logo-signature-a,
    .logo-signature-b {
      float: left;
      .neuron;
      font-weight: normal;
      font-style: normal;
      font-size: 21px;
      color: #565656;
      line-height: 15px;
      margin-left: 5px;
      margin-top: 22px;
    }

    .logo-signature-b {
      font-size: 14px;
      display: none;
    }

    .logo-blok {
      width: 15px;
      height: 15px;
      background: #0785c8;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .logo-middenblauw {
      background: @color-blue-primary;
    }

    .logo-lichtblauw {
      background: #84d0f5;
    }

    .logo-b1 {
      margin: 3px 0 0 3px;
    }

    .logo-b2,
    .logo-b4 {
      margin: -15px 0 0 24px;
    }

    .logo-b3,
    .logo-b5 {
      margin: 6px 0 0 3px;
    }

    position: absolute;
    z-index: 200;

    h1 {
      text-indent: -999999px;
      position: absolute;
      font-size: 0;
    }

    @media screen and (max-width: (@breakpoint-large - 1)) {
      margin-left: 0;

      .logo-signature-a {
        display: none;
      }

      .logo-signature-b {
        display: block;
        margin-top: 15px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: (@breakpoint-xsmall - 1)) {
      .logo-signature-b {
        font-size: 14px;
        margin-top: 20px;
      }
    }
  }

  #cart {
    color: @color-text-secondary;
    margin-top: 12px;
    width: 160px;

    .polycon-cart {
      color: @c-green1;
      font-size: 22px;
      position: relative;
      top: 4px;
    }

    #cart-in-checkout {
      display: none;

      .cart-in-checkout-title {
        margin: 25px 0 8px 0;
      }
    }

    #cart-js-update-wrap {
      #empty-cart-message {
        display: none;
      }

      #non-empty-cart {
        position: absolute;
        top: 23px;
        right: 5px;
      }

      &.cart-empty {
        #empty-cart-message {
          display: block;
        }

        #non-empty-cart {
          display: none;
        }
      }
    }

    #empty-cart-message {
      .neuron;
      color: #444;
      margin-top: 13px;
      font-size: 14px;
    }

    a {
      text-decoration: none;

      h4 {
        font-weight: lighter;
        margin: 20px 0 6px 0;
        font-size: 17px;
        color: @c-lnk1;
        .neuron;

        &:hover {
          color: @c-lnkh1;
          text-decoration: none;
        }
      }

      &#view-cart,
      &#order {
        color: @c-lnk1;
        display: inline-block;
        font-size: 12px;
        border-bottom: 1px solid @color-border-dark;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        &:hover {
          color: @c-lnkh1;
          text-decoration: none;
        }
      }
    }

    .btn-group {
      position: static;

      &>.dropdown-toggle {
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
      }

      white-space: normal;
      font-size: 14px;
      vertical-align: initial;

      a.btn {
        border: 2px solid @color-border-dark;
        padding: 0 5px;
        margin-left: 3px;

        .rounded(4px);
      }

      .dropdown-menu {
        .rounded(3px);
        .padh1;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 282px;
        top: 22px;
        left: -296px;
        background: darken(@color-background-page, 2%);
        text-align: left;
        color: @color-text-primary;
        font-size: 14px;

        #cart-dropdown-header {
          font-size: 20px;
          margin: 0 0 10px 0;
        }

        .dropdown-product {
          border: 1px solid @color-border-dark;
          border-bottom: none;
          background: @color-background-page;
          padding: 10px;

          span {
            display: inline-block;
          }

          .dropdown-product-title {
            .neuron;
            font-size: 22px;
            color: @color-blue-primary;
            padding: 0;

            &:hover {
              background: none;
            }
          }

          .dropdown-product-amount {
            color: @color-text-secondary;
          }

          .dropdown-product-price {
            float: right;
            color: @color-text-secondary;
          }
        }

        .dropdown-discount {
          padding: 10px;
          border: 1px solid @color-border-dark;
          border-bottom: none;

          .dropdown-discount-amount {
            float: right;
          }
        }

        .dropdown-shipping {
          padding: 10px;
          border: 1px solid @color-border-dark;
          border-bottom: none;

          .dropdown-shipping-amount {
            float: right;
          }
        }

        .dropdown-total {
          padding: 10px;
          border: 1px solid @color-border-dark;

          .dropdown-total-amount {
            float: right;
            font-weight: bold;
          }
        }

        .dropdown-footer {
          .clearfix;
          padding: 15px 0 5px 0;

          a.dropdown-edit-cart {
            display: block;
            float: left;
            .obviousLink;
            margin-top: 8px;
          }

          a.dropdown-checkout-button {
            button {
              float: right;
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}

#user {
  font-size: 17px;
  margin: 12px 0;
}

#user-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  max-width: 128px;
  position: relative;
  top: 2px;
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
  #header {
    #cart {
      a h4 {
        font-size: 14px;
      }
    }

    #user {
      font-size: 14px;
      margin: 7px 0;
    }
  }
}