#product-page-body #product-page-side #product-page-side-inner #product-configuration {
    &::after {
        content: "";
        clear: both;
        display: table;
    }

    .config-wrap .config-block {
        select {
            height: 41px;
        }

        input {
            height: 30px;
        }
    }

    .config-wrap#config-price-wrap {
        padding: 0 !important;
        width: 100%;
        height: unset;
        margin-bottom: 0;
        margin-top: 7px;

        p {
            margin-bottom: 0;
        }

        #config-price {
            height: unset;
            width: 100%;
            margin-left: 0;
            padding: 0;
        }
    }

    .config-wrap {
        float: left;
        width: 100%;

        .config-block {
            margin-left: 0;
            padding: 0;
            width: 100%;
            background-color: transparent;
        }

        &:nth-last-child(1) {
            width: 100% !important;
            padding: 0 !important;
        }

        &:nth-of-type(odd) {
            .config-block {
                input {
                    display: block;
                    width: 240px;
                    margin-top: 10px;
                    text-align: left;
                }

                select {
                    width: 253px;
                }
            }
        }

        &:nth-of-type(even) {
            .config-block {
                input {
                    display: block;
                    width: 140px;
                    margin-top: 10px;
                    text-align: left;
                }
            }

            #config-amount {
                #select_color {
                    margin-top: 7px;
                    padding: 10px 8px;
                }
            }
        }
    }

    .config-wrap-cart {
        width: 100% !important;
        height: unset !important;
        margin-top: 17px;

        .btn.add-to-cart {
            position: inherit;
        }
    }
}
