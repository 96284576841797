.campaign {
    .order_cards_button {
        width: 50%;
        padding: 10px 15px;
        border: 0;
        transition: all .2s cubic-bezier(0.2, 0, 0, 1);
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 10px;

        @media screen and (min-width: @breakpoint-small) {
            min-width: 200px;
            padding: 10px 22px 10px 15px;
            margin-top: 0;
        }

        @media screen and (max-width: @breakpoint-small) {
            width:90%;
        }

        &.btn {
            &.btn-green {
                color: #fff;
                background: @c-green;

                &:hover {
                    background-color: @c-green-dark;
                    border-color: @c-green-dark;
                }
            }
        }

        span {
            font-size: 16px;
            margin-left: 10px;
        }
    }

    .campaign-giveaway {
        display: inline-block;
        width: 100%;
        background: @c-blue-dark;
        padding: 40px 25px;

        @media screen and (min-width: @breakpoint-medium) {
            padding: 40px;
        }

        .grid {
            display: flex;
            width: 100%;
            margin: 0 auto;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;

            @media screen and (min-width: @breakpoint-large) {
                width: 688px;
            }
        }

        .campaign-giveaway {
            &__title {
                display: inline-block;
                width: 100%;
                margin: 0 0 15px;
                color: @c-white;
                .neuron();
                font-size: 28px;
                text-align: center;

                @media screen and (min-width: @breakpoint-medium) {
                    margin-bottom: 25px;
                }
            }

            &__description {
                display: inline-block;
                width: 100%;
                margin-bottom: 5px;

                @media screen and (min-width: @breakpoint-medium) {
                    margin-bottom: 15px;
                }

                p {
                    color: @c-white;
                    font-size: 15px;
                }
            }

            &__form {

                .form {
                    @media screen and (min-width: @breakpoint-small) {
                        display: flex;
                        align-items: flex-end;
                    }

                    .form {
                        &__group {
                            @media screen and (min-width: @breakpoint-small) {
                                flex: 1;
                                margin-bottom: 0;

                                &:not(:nth-last-of-type(1)) {
                                    padding-right: 8px;
                                }

                                &:nth-last-of-type(1) {
                                    flex: 0 0 auto;
                                }
                            }
                        }

                        &__group-inner {
                            display: flex;
                            flex-wrap: wrap;
                        }

                        &__label-holder {
                            flex: 1 1 100%;
                            width: auto;
                            margin-bottom: 0;
                            padding-top: 0;
                            margin-right: 15px;
                        }

                        &__label {
                            float: left;
                            width: 100%;
                            font-size: 14px;
                            text-align: left;
                            color: @c-white;
                            .neuron();

                            @media screen and (min-width: @breakpoint-medium) {
                                font-size: 16px;
                                margin-bottom: 5px;
                            }
                        }

                        &__controls {
                            flex: 1 1 100%;
                            width: 100%;
                            margin-left: 0;

                            select,
                            textarea,
                            input[type="text"],
                            input[type="email"],
                            input[type="password"],
                            input[type="email"] {
                                width: 100%;
                                height: 40px;
                                margin-bottom: 0;
                                padding: 8px 15px 8px 10px;
                                border-radius: 2px;
                                transition: background-color .2s ease-in-out;
                            }

                            @media screen and (max-width: @breakpoint-medium) {
                                margin-right: 5px;
                            }
                        }

                        &__button {
                            float: right;
                            width: 100%;
                            padding: 10px 15px;
                            border: 0;
                            transition: all .2s cubic-bezier(0.2, 0, 0, 1);
                            font-size: 16px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 10px;

                            @media screen and (min-width: @breakpoint-small) {
                                min-width: 200px;
                                padding: 10px 22px 10px 15px;
                                margin-top: 0;
                            }

                            &.btn {
                                &.btn-green {
                                    background: @c-green;

                                    &:hover {
                                        background-color: @c-green-dark;
                                        border-color: @c-green-dark;
                                    }
                                }
                            }

                            span {
                                font-size: 16px;
                                margin-left: 10px;
                            }
                        }
                    }
                }

            }

            &__sticky-button {
                align-items: center;
                justify-content: center;
                .neuron();
                font-size: 20px;

                @media screen and (max-width: (@breakpoint-medium - 1)) {
                    display: flex;
                }

                svg {
                    font-size: 18px;
                }
            }
        }

    }
}
