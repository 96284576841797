.social-logins {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.button {
  &.button {
    &--social {
      display: flex;
      align-items: center;
      flex-basis: calc(50% - 7px); // 2px border + half of 10px gap size
      @media screen and (max-width: @breakpoint-medium) {
        flex-basis: 100%;
      }
      background: transparent;
      padding: 0;
      margin: 0;
      border-radius: 3px;
      border: 1px solid transparent;
      cursor: pointer;
      position: relative;
      height: 40px;
      font-size: 15px;

      .bootstrapFont();

      &.button {
        &--facebook {
          background-color: @c-facebook;
          color: @c-white;
          border-color: @c-facebook;

          &:hover {
            background-color: @c-facebook-hover;
          }

          .button__social-icon {
            background-image: url('../../../img/icons/icon-facebook.svg');
          }
        }

        &--google {
          background-color: @c-google-background;
          border-color: @c-google-border;
          color: @c-google-text;

          &:hover {
            background-color: @c-google-hover;
          }

          .button__social-icon {
            background-image: url('../../../img/icons/icon-google.svg');
          }
        }
      }
    }
  }

  .button {
    &__social-icon {
      flex: 0 0 auto;
      display: inline-flex;
      width: 40px;
      height: 40px;
      border-radius: 3px;
      background-size: 18px;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__social-text {
      display: inline-flex;
      font-size: 15px;
      text-align: center;
      .bootstrapFont();
    }
  }
}
