#ehet-body {
    #footer-spacer {
        height: 413px;
    }

    #usp-topbalk {
        display: none;
    }
}

@media (max-width: 768px) {
    .eigen-huis-en-tuin-scroll {
        margin-top: 120px;
    }
}

@media (max-width: 440px) {
    .eigen-huis-en-tuin-scroll {
        margin-top: 142px;
    }
}

@media (min-width: 768px) {
    .eigen-huis-en-tuin .hero .hero-titel {
        margin-top: 15px !important;
    }

    .eigen-huis-en-tuin-scroll .hero .hero-titel {
        margin-top: 116px !important;
    }
}

.eigen-huis-en-tuin {

    .hero {
        display: flex;
        width: 100%;
        min-height: 300px;
        align-items: center;
        background-position: center right;
        background-size: auto 125%;
        background-repeat: no-repeat;
        background-color: #edefef;

        .hero-titel {
            font-size: 32px;
            float: left;
            .neuron();
            width: 100%;
            display: block;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .hero-content {
            font-family: Tahoma, Geneva, sans-serif;
            padding: 0 20px 0 0;
            margin-bottom: 25px;
        }

        .logos {
            img {
                width: 100px;
                height: auto;
            }

            img:nth-child(1) {
                margin-right: 10px;
            }
        }

    }

    .gebruikt-in {
        background: @c-white;
        padding: 60px 0;

        .gebruikt-titel {
            font-size: 32px;
            text-align: center;
            width: 100%;
            display: block;
            .neuron();
            margin-bottom: 20px;
        }

        .gebruikt-video {
            border: none !important;
            padding: 0 15px !important;
        }

        .gebruikt-prod {
            border: 1px solid #ecebeb;
            margin-bottom: 20px;
            padding: 15px;
            border-radius: 11px;

            iframe {
                height: 313px;
                width: 100%;
                margin-top: 15px;
            }

            .product-title {
                margin: 10px 0 10px 0;
                line-height: 1em;
                padding: 0 5px;
                font-size: 21px;
                color: #00a2e0;
                display: inline-block;
            }

            .product-info {
                padding: 0 5px;

                .green {
                    color: #42c91d;
                }
            }

            .price {
                font-weight: 700;
                color: #000;
            }

            button {
                width: 95%;
                padding: 10px 0;
                display: table;
                margin: 0 auto;
            }

            .cta {
                width: 100%;
                display: table;
                padding: 10px 0;
                margin: 0 auto;

                .polycon-cart {
                    margin-left: 15px;
                }

            }

            ul {
                list-style: none;
                margin-left: 17px;
            }

            ul li:before {
                color: #42c91d;
                content: '✓';
                margin-left: -1em;
                margin-right: 10px;
            }

            .product-description {
                font-size: 14px;
                line-height: 21px;
                padding: 0 5px;
            }
        }
    }

    .projecten-met {
        background: #edefef;
        padding: 60px 0;

        .head {
            height: 250px;
            position: relative;
            width: 100%;
            margin-top: 34px;
        }

        .projecten-titel {
            .neuron();
            font-size: 32px;
            text-align: center;
            width: 100%;
            display: block;
            margin-bottom: 40px;
        }

        .ehet-slider {
            float: left;
            width: 100%;
            padding: 0 25px;

            &__item {
                .head {
                    &::before {
                        content: 'Project van klant';
                        position: absolute;
                        top: -34px;
                        left: 0;
                        width: 100%;
                        background: @color-blue-primary;
                        color: @c-white;
                        padding: 5px 25px;
                        text-align: center;
                    }
                }

                &--has-overlay {
                    cursor: pointer;

                    .head {
                        &::before {
                            content: 'Handleiding';
                        }
                    }
                }
            }

            &__item-inner {
                position: relative;
                padding: 10px;

                &:hover,
                &:focus {
                    .overlay {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }

            .slick-arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;

                >img {
                    height: 35px;
                }
            }

            .slick-prev {
                left: 6px;

                @media screen and (min-width: @breakpoint-small) {
                    left: 0;
                }
            }

            .slick-next {
                right: 6px;

                @media screen and (min-width: @breakpoint-small) {
                    right: 0;
                }
            }
        }

        .prod-hover {
            cursor: pointer;
            margin-bottom: 30px;
        }

        .overlay {
            opacity: 0;
            visibility: hidden;
            -webkit-transition: all .8s !important;
            -o-transition: all .3s !important;
            transition: all .3s !important;
            position: absolute;
            width: ~"calc(100% - 20px)" !important;
            color: @c-white;
            text-align: center;
            top: 10px;
            left: 10px;
            height: ~"calc(100% - 20px)" !important;
            background: rgba(40, 174, 255, 0.65);
            padding: 35px 25px 10px;

            h3 {
                margin-top: 20px;
                font-size: 24px;
                .neuron();

                @media screen and (min-width: @breakpoint-medium) {
                    font-size: 28px;
                }

                @media screen and (min-width: @breakpoint-xlarge) {
                    margin-top: 40px;
                }
            }

            .overlay-cta {
                background: #00a2e0;
                color: @c-white  !important;
                border-radius: 4px;
                padding: 10px 30px;
                border-bottom: 3px solid #005884;
                text-align: center;
                display: table;
                cursor: pointer;
                position: absolute;
                left: 50%;
                bottom: 0;
                -webkit-transform: translateY(-50%) translateX(-50%);
                -ms-transform: translateY(-50%) translateX(-50%);
                transform: translateY(-50%) translateX(-50%);
            }

            .overlay-cta:hover {
                background: #0a4563;
            }

            .used-prod {
                a:hover {
                    font-weight: 700;
                }
            }

            .titel {
                .neuron();
                width: 100%;
                font-weight: 700;
                color: @c-white;
                font-size: 21px;
                margin: 40px 0 10px;
            }
        }

        .bekijk-alles {
            background: #00a2e0;
            color: @c-white  !important;
            border-radius: 4px;
            padding: 10px 30px;
            border-bottom: 3px solid #005884;
            text-align: center;
            display: table;
            margin: 15px auto 0;
            cursor: pointer;
        }

        .bekijk-alles:hover {
            background: #007eba;
        }

    }


    .large-6 {
        float: left;
        width: 50%;
        -webkit-box-sizing: inherit;
        box-sizing: inherit;
    }

    .workshops {
        width: 100%;
        padding: 60px 0;
        background: @c-white;

        ul {
            list-style: none;
        }

        ul li:before {
            color: #42c91d;
            content: '✓';
            margin-left: -1em;
            margin-right: 1em;
        }

        .facebook {
            .titel {
                .neuron();
                font-size: 32px;
                color: @color-blue-primary;
                text-align: left;
                width: 100%;
                display: block;
                margin-bottom: 10px;
            }

            .content {
                margin-top: 15px;
            }
        }

        .workshop {
            img {
                width: 300px;
            }

            .titel {
                .neuron();
                font-size: 32px;
                color: @color-blue-primary;
                text-align: left;
                width: 100%;
                margin-top: 20px;
                display: block;
                margin-bottom: 10px;
            }

            .workshop-btn {
                background: @color-blue-primary;
                color: @c-white  !important;
                border-radius: 4px;
                padding: 10px 30px;
                border-bottom: 3px solid #005884;
                text-align: center;
                display: table;
                margin: 20px 0 0;
                cursor: pointer;
            }

            .workshop-btn:hover {
                background: #007eba;
            }

        }

    }

}

@media (max-width: 571px) {
    .eigen-huis-en-tuin .projecten-met .prod-hover {
        width: 50% !important
    }
}

@media (max-width: 473px) {
    .eigen-huis-en-tuin .projecten-met .prod-hover {
        width: 100% !important
    }
}

@media (max-width: 767px) {
    .eigen-huis-en-tuin {
        .workshop {
            .col-md-6 {
                margin-bottom: 35px;
            }
        }
    }
}

.ehet-body {
    .menu-bar.scroll .mobile-bar .mobile-cart {
        color: @c-white  !important;
    }
}

@media (max-width: 764px) {

    .eigen-huis-en-tuin {
        background: #f6f7f8 !important;
    }

    .eigen-huis-en-tuin .gebruikt-in .gebruikt-titel {
        margin-bottom: 10px;
        text-align: left;
    }

    .eigen-huis-en-tuin .producten-kolom .row {
        padding: 0 15px;

        .row {
            padding: 0;
        }
    }

    .eigen-huis-en-tuin .gebruikt-in {
        padding: 10px 0 0;
    }

    .eigen-huis-en-tuin .video-kolom h2 {
        margin-bottom: -10px !important;
    }

    .eigen-huis-en-tuin .gebruikt-in .gebruikt-prod .product-title {
        margin: 0 0 .2em 0;
    }

    .eigen-huis-en-tuin .projecten-met .projecten-titel {
        margin-bottom: 10px;
        text-align: left;
    }

    .eigen-huis-en-tuin {
        .gebruikt-prod {
            width: 100% !important;
            -webkit-box-flex: 0 !important;
            -ms-flex: 0 0 100% !important;
            flex: 0 0 100% !important;
            max-width: 100% !important;

            .col-md-6 {
                width: 100%;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .producten-kolom {
            width: 100%;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;

            .col-md-12 {
                width: 100% !important;
                -webkit-box-flex: 0 !important;
                -ms-flex: 0 0 100% !important;
                flex: 0 0 100% !important;
                max-width: 100% !important;
            }
        }
    }

    .eigen-huis-en-tuin {

        .gebruikt-prod {
            img {
                max-width: 300px;
                display: block;
                margin: 0 auto;
            }
        }

        .large-6 {
            width: 100% !important;
        }

        .gebruikt-in {
            .col-md-6 {
                .col-md-6 {
                    margin-bottom: 0;
                }
            }
        }

        .hero-mobile {
            display: block;
            background: transparent;

            .hero-titel {
                text-align: center;
                font-size: 25px;
            }

            .logos {
                position: relative;
                display: table;
                margin: 0 auto;
            }

            .hero-content {
                position: relative;
                font-family: Tahoma, Geneva, sans-serif;
                margin-bottom: 25px;
                -webkit-box-shadow: 10px 0 5px -2px #f6f7f8;
                box-shadow: 0 -20px 22px 15px #f6f7f8;
                background: #f6f7f8;
                position: relative;
            }
        }

        .hero-desktop {
            display: none;
        }

        .mobile-image {
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

.ehet-body {
    .menu-bar .mobile-bar #search button {
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        border: 0;
        z-index: 100;
    }
}

@media (min-width: 765px) {
    .eigen-huis-en-tuin {
        .hero-mobile {
            display: none;
        }
    }
}

@media (max-width: 473px) {
    .eigen-huis-en-tuin {
        .fb-desktop {
            display: none;
        }

        .fb-mobile {
            display: block;
        }
    }

    .eigen-huis-en-tuin .gebruikt-prod img {
        width: 100%;
    }
}

@media (min-width: 473px) {
    .eigen-huis-en-tuin {
        .fb-mobile {
            display: none !important;
        }
    }
}

@media (max-width: 850px) {
    .eigen-huis-en-tuin {
        .workshop {
            width: 100% !important;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

@media screen and (max-width: (@breakpoint-large - 1)) {
    body.eigen-huis-en-tuin {

        .video-kolom,
        .stay100 {
            width: 100% !important;
            -webkit-box-flex: 0 !important;
            -ms-flex: 0 0 100% !important;
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }

    .eigen-huis-en-tuin {
        .gebruikt-prod {
            .col-md-6 {
                width: 100%;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .video-kolom {
            width: 100%;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;

            .col-md-12 {
                padding: 0 0 !important;
            }
        }

        .producten-kolom {
            width: 100%;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;

            .stay100 {
                padding: 0 !important;
            }

            .col-md-12 {
                width: 50%;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
}

@media (max-width: 473px) {
    .eigen-huis-en-tuin .gebruikt-prod img {
        width: 100% !important;
    }
}

@media (max-width: 914px) {
    .pop-up-ehet .pop-up .titel {
        font-size: 21px;
        margin-top: 0;
    }
}


@media (max-width: 450px) {
    .eigen-huis-en-tuin .gebruikt-in .gebruikt-prod iframe {
        height: 230px;
    }
}

@media (max-width: 500px) {
    .pop-up-ehet .pop-up .row {
        padding: 0 15px;
    }
}

.ehet-body {

    .menu-bar .bottom-bar ul li a {
        color: @c-white  !important
    }

    .menu-bar .mid-bar #cart ul li a {
        color: white !important;
    }

    a {
        color: unset !important;
        text-decoration: none !important;
    }
}

.pop-up-ehet {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999994;
    top: 0;
    left: 0;

    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 2000000;
        background: rgba(103, 103, 103, 0.62);
        top: 0;
        left: 0;
        z-index: 200;
    }

    .pop-up {
        width: 60%;
        display: block;
        left: 50%;
        z-index: 220;
        top: 30%;
        -webkit-transform: translateY(-50%) translateX(-50%);
        -ms-transform: translateY(-50%) translateX(-50%);
        transform: translateY(-50%) translateX(-50%);
        position: absolute;
        -webkit-box-shadow: 0 0 62px -1px #948888;
        box-shadow: 0 0 62px -1px #948888;

        &__text {
            display: inline-block;
            width: 100%;

            @media screen and (min-width: 768px) {
                font-size: 18px;
            }
        }

        &__form {
            display: inline-block;
            width: 100%;
            margin-top: 15px;

            @media screen and (min-width: 1024px) {
                margin-top: 0;
                margin-left: 25px;
            }
        }

        &__form-eht {
            margin: 0;

            @media screen and (max-width: 575px) {
                display: flex;
            }

            input {
                margin: 0;
                padding: 10px 6px;
                flex: 1 1 auto;
            }
        }

        &__button {
            background: #00a2e0;
            color: @c-white  !important;
            border-radius: 4px;
            padding: 0 30px;
            border: none;
            border-bottom: 3px solid #005884;
            text-align: center;
            display: inline-block;
            margin: 0 0 0 5px;
            height: 42px;
            cursor: pointer;
            position: relative;
            flex: 0 0 auto;

            &:hover,
            &:focus {
                background: #007eba;
            }
        }

        .row {
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
            -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
            margin-right: -15px !important;
            margin-left: -15px !important;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            background-color: @c-white;

            &:nth-of-type(1) {
                border-top-left-radius: 11px;
                border-top-right-radius: 11px;
            }

            &:nth-last-child(1) {
                border-bottom-left-radius: 11px;
                border-bottom-right-radius: 11px;
            }

            &--blue {
                background-color: #174166;
            }
        }

        .titel {
            .neuron();
            font-size: 27px;
            color: @c-black;
            text-align: left;
            width: 100%;
            display: block;
            margin-bottom: 10px;
        }

        @media (min-width: 768px) {
            .col-md-6 {
                -webkit-box-flex: 0 !important;
                -ms-flex: 0 0 50% !important;
                flex: 0 0 50% !important;
                max-width: 50% !important;
            }
        }

        .col-md-12 {
            padding: 15px 10px;
            color: @c-white;
            position: relative;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;

            @media (min-width: 1024px) {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 25px 40px;
            }
        }

        .col-md-6 {
            padding: 40px 40px 0;
            min-height: 300px;
            position: relative;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;

            .logos {
                margin-top: 25px;

                img {
                    margin-right: 15px;
                    max-width: 90px;
                    height: auto;
                }
            }

            .close-ehet-popup {
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 20px;
                color: @c-white;
                font-size: 32px;
                font-weight: 700;
            }
        }

    }

}

.ehet-body {
    .btn-blue-style {
        background: #00a2e0;
        cursor: pointer;
        color: @c-white  !important;
        border-radius: 4px;
        padding: 10px 30px;
        border-bottom: 3px solid #005884;
        text-align: center;
        display: table;
        color: @c-white  !important;
        margin: 20px 0 0;
        cursor: pointer;
    }

    .btn-blue-style:hover {
        background: #007eba;
        color: @c-white  !important;
    }
}

.btn-blue-style {
    background: #00a2e0;
    cursor: pointer;
    color: @c-white  !important;
    border-radius: 4px;
    padding: 10px 30px;
    border-bottom: 3px solid #005884;
    text-align: center;
    display: table;
    color: @c-white  !important;
    margin: 20px 0 0;
    cursor: pointer;
}

.btn-blue-style:hover {
    background: #007eba;
    color: @c-white  !important;
}

@media (max-width: 1550px) {
    .pop-up-ehet {
        .pop-up {
            width: 70%;
        }
    }
}

@media (max-width: 1330px) {
    .pop-up-ehet {
        .pop-up {
            width: 80%;
        }
    }
}

@media (max-width: 1170px) {
    .pop-up-ehet {
        .pop-up {
            .titel {
                font-size: 24px;
            }

            .btn-blue-style {
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 1056px) {
    .pop-up-ehet {
        .pop-up {
            width: 90%;
        }
    }
}

@media (max-width: 1056px) {
    .pop-up-ehet {
        .pop-up {
            .col-md-6:nth-child(1) {
                width: 60% !important;
                -webkit-box-flex: 0 !important;
                -ms-flex: 0 0 60% !important;
                flex: 0 0 60% !important;
                max-width: 60% !important;
            }

            .col-md-6:nth-last-child(1) {
                width: 40% !important;
                -webkit-box-flex: 0 !important;
                -ms-flex: 0 0 40% !important;
                flex: 0 0 40% !important;
                max-width: 40% !important;
            }
        }
    }
}

.mobile-social-buttons {
    display: none;
}

@media (max-width: 747px) {

    .mobile-social-buttons {
        display: block;
        width: 100%;
        margin-bottom: 20px;

        .content {
            display: table;
            margin: 0 auto;
            text-align: center;
            padding: 0 15px;

            .meer-producten {
                a {
                    margin-bottom: 30px;
                    margin-top: 0;
                    display: block;
                }
            }

            p {
                .neuron();
                font-size: 22px;
            }
        }

        img {
            width: 60px;
            margin: 0 20px;
        }
    }

    .pop-up-ehet {
        .pop-up {
            background: transparent;
            -webkit-box-shadow: none;
            box-shadow: none;
            width: 60%;
            position: absolute;
            min-width: 409px;
            top: 50%;

            .row {
                -webkit-box-shadow: none !important;
                box-shadow: none !important;
            }

            .col-md-6:nth-child(1) {
                width: 100% !important;
                -webkit-box-flex: 0 !important;
                -ms-flex: 0 0 100% !important;
                flex: 0 0 100% !important;
                max-width: 100% !important;
                -webkit-transform: translateY(100%);
                transform: translateY(100%);
                -ms-transform: translateY(100%);
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 11px;
                border-bottom-left-radius: 11px;
            }

            .col-md-6 {
                padding: 20px 30px 20px;
                min-height: 225px;
            }

            .col-md-6:nth-last-child(1) {
                width: 100% !important;
                -webkit-box-flex: 0 !important;
                -ms-flex: 0 0 100% !important;
                flex: 0 0 100% !important;
                max-width: 100% !important;
                -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
                -ms-transform: translateY(-100%);
                border: none !important;
                border-radius: 0 !important;
            }
        }
    }
}

@media (max-width: 500px) {
    .pop-up-ehet {
        .pop-up {
            width: 80%;
            min-width: unset;
        }
    }
}

@media (max-width: 485px) {
    .pop-up-ehet .pop-up .titel {
        font-size: 21px !important;
    }

    .pop-up-ehet {
        .pop-up {
            width: 90%;
        }
    }
}

@media (max-width: 350px) {
    .pop-up-ehet .pop-up .titel {
        font-size: 17px !important;
    }
}

@media (max-width: 381px) {
    .pop-up-ehet .pop-up .titel {
        font-size: 19px !important;
    }

    .pop-up-ehet {
        .pop-up {
            .col-md-6 {
                padding: 20px 15px 20px;
            }
        }
    }

    .btn-blue-style {
        font-size: 14px;
    }

    .pop-up-ehet {
        .pop-up {
            width: 90%;
        }
    }
}

.information-wrapper.ehet-wrapper {
    .information-block {
        .blue {
            img {
                max-width: 100px;
                display: block;
                margin: 0 auto;
            }
        }

        .te-zien {
            position: relative;
            float: left;
            width: 52%;
            .neuron();

            .titel {
                font-size: 18px;
                text-align: center;
                margin-top: 40px;
            }
        }

        .ga-naar {
            position: relative;
            float: left;
            width: 100%;

            .btn-blue-style {
                margin: 45px auto 0;
            }
        }
    }
}

@media screen and (min-width: @breakpoint-small) {
    .information-wrapper.ehet-wrapper .information-block {
        .blue {
            img {
                max-width: 140px;
            }
        }

        .te-zien {
            .titel {
                font-size: 22px;
            }
        }
    }
}

@media screen and (min-width: @breakpoint-medium) {
    .information-wrapper.ehet-wrapper .information-block {
        .te-zien {
            .titel {
                font-size: 26px;
            }
        }
    }
}

@media screen and (min-width: 1310px) {
    .information-wrapper.ehet-wrapper .information-block {
        .ga-naar {
            width: 28%;
        }
    }
}

@media screen and (max-width: 1310px) {
    .information-wrapper.ehet-wrapper {
        padding: 0 4px;
    }

    .information-wrapper.ehet-wrapper .information-block {
        .blue {
            width: 100%;
        }

        .ga-naar {
            width: 40%;

            .btn-blue-style {
                margin: 27px auto 0;
            }
        }

        .te-zien {
            width: 60%;

            .titel {
                margin-top: 20px;
                margin-bottom: 30px;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}

@media (max-width: 538px) {
    .information-wrapper.ehet-wrapper .information-block {

        .te-zien,
        .ga-naar {
            width: 100%;
        }

        .te-zien .titel {
            margin-bottom: 10px;
        }

        .ga-naar .btn-blue-style {
            margin: 10px auto 20px;
        }
    }
}

@media (max-width: 464px) {
    body .information-wrapper.ehet-wrapper .information-block {
        background: transparent !important;
    }

    .information-wrapper.ehet-wrapper .information-block .blue {
        margin-bottom: 0 !important
    }

    .information-wrapper.ehet-wrapper .te-zien .titel {
        margin-bottom: 10px;
        padding: 0 10px;
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    .information-wrapper.ehet-wrapper {
        padding: 0;
    }
}

@media screen and (min-width: @breakpoint-medium) {
    .ehet-body {
        .modal-scrollable {
            #full-width {
                display: table !important;
                margin-top: 0 !important;
                top: 50%;
                left: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                opacity: 1 !important;
            }
        }
    }
}

.ehet-body {

    .cc-banner .cc-btn:last-child {
        color: #000 !important;
    }

    .cc-compliance .cc-btn {
        color: #000 !important;
    }

    .modal-scrollable {
        #full-width {
            display: block;
            opacity: 1 !important;

            .modal-header .close {
                padding: 0 !important;
                margin: 2px 0 0 0 !important;
            }
        }
    }
}

@media (max-width: 374px) {
    .modal .modal-header h3 {
        font-size: 23px;
    }
}

.gebruikt-in-2 {
    padding: 10px 0 0 !important;
}

.eigen-huis-en-tuin {

    .row:before,
    .row:after {
        display: inline-block !important;
    }
}

.ehet-body {
    .modal.with-access .modal-footer {
        display: block !important;
    }

    .modal.with-access .btn.btn-checkout {
        float: right !important;
        background-image: -moz-linear-gradient(top, @c-white, #e6e6e6);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@c-white), to(#e6e6e6));
        background-image: -webkit-linear-gradient(top, @c-white, #e6e6e6);
        background-image: -o-linear-gradient(top, @c-white, #e6e6e6);
        background-image: linear-gradient(to bottom, @c-white, #e6e6e6);
        background-repeat: repeat-x;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        border-bottom-color: rgba(0, 0, 0, 0.25);
        border-bottom-color: #b3b3b3;
    }

    .modal.with-access .modal-footer .btn.btn-dismiss {
        float: left !important;
        background-image: -moz-linear-gradient(top, @c-white, #e6e6e6);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@c-white), to(#e6e6e6));
        background-image: -webkit-linear-gradient(top, @c-white, #e6e6e6);
        background-image: -o-linear-gradient(top, @c-white, #e6e6e6);
        background-image: linear-gradient(to bottom, @c-white, #e6e6e6);
        background-repeat: repeat-x;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        border-bottom-color: rgba(0, 0, 0, 0.25);
        border-bottom-color: #b3b3b3;
    }

    .modal.with-access .modal-header {
        display: block !important;
    }

    .modal-header {
        display: block !important;
    }
}

@media screen and (max-width: (@breakpoint-large - 1)) and (min-width: @breakpoint-medium) {
    .eigen-huis-en-tuin .producten-kolom .col-md-12 {
        padding: 0 30px;
    }

    .eigen-huis-en-tuin .gebruikt-in .gebruikt-prod {
        border: none !important;
    }

    .eigen-huis-en-tuin .gebruikt-in .gebruikt-prod .row {
        border: 1px solid #ecebeb;
        border-radius: 11px;
    }

}

.gebruikt-in-inner {
    padding: 15px;
    margin-top: 22px;
}

@media (max-width: 1040px) {
    body .eigen-huis-en-tuin .col-sm-12.prod-hover {
        -ms-flex: 0 0 50% !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
}

@media (max-width: 571px) {
    body .eigen-huis-en-tuin .projecten-met .prod-hover {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }
}

.align-centered {
    margin: 0 auto;
}

.kiyoh-product {
    width: 18px !important;
}

.kiyoh-text {
    float: right;
    font-size: 15px;
    margin-left: 7px;
    transition: all 0.4s;

    &:hover,
    &:focus {
        color: #02abe3;
    }
}

@media (max-width: 1310px) {
    #webshop-seal {
        display: none;
    }
}

.ehet-body {
    #usp-topbalk.on-top.scroll {
        margin-top: 60px !important;
        .eigen-huis-en-tuin .gebruikt-in .gebruikt-prod
    }
}

.ehet-body {
    .product-show-img {
        max-height: 260px;
        margin: 0 auto;
        display: block;
    }
}



.ehet-body {
    .eigen-huis-en-tuin .gebruikt-in .gebruikt-prod .product-title {
        float: left;
        width: 100%;
    }

    .eigen-huis-en-tuin .gebruikt-in .gebruikt-prod iframe {
        margin-top: 61px;
    }

    #footer-spacer {
        height: 416px;
    }
}

@media (max-width: 970px) {
    .eigen-huis-en-tuin .gebruikt-in .gebruikt-titel {
        text-align: left;
    }
}

@media (max-width: 850px) {
    .eigen-huis-en-tuin .gebruikt-in .gebruikt-in-inner .gebruikt-prod {
        width: 50%;
    }
}
