.color-picker {

    .color-picker {
        &__colors {
            display: inline-block;
            padding: 5px;
            overflow: scroll;
            width: ~'calc(100% - 10px)';
        }

        &__most-selected {
            width: 100%;
            display: inline-block;
            padding: 10px;
            box-sizing: border-box;
            background: rgba(0, 162, 224, .2);
            border-radius: 4px;
            border-bottom: 3px solid @color-blue-primary;
            box-shadow: 0 1px 5px fade(@c-black, 80%);
            padding: 5px;
            margin-bottom: 15px;
        }

        &__title {
            display: inline-block;
            font-size: 18px;
            color: @color-blue-primary;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 10px;
        }

        &__subtitle {
            margin: 5px 0 10px;
            display: inline-block;
            width: ~'calc(100% - 10px)';
            font-size: 16px;
            padding: 0 5px;
        }




        //
        // Search field
        //

        &__search {
            display: inline-block;
            width: 100%;
            margin-top: 5px;
            margin-bottom: 15px;
        }

        &__search-field {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        &__search-input {
            display: inline-block;
            width: 100%;
            height: auto;
            padding: 6px 10px;
            margin-bottom: 0;
            box-sizing: border-box;
            border-color: @color-border-dark;
            border-radius: 5px 5px 0 0;
        }

        &__search-filter {
            display: inline-block;
            width: 100%;
        }

        &__search-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
            font-size: 13px;
            line-height: initial;
            color: @color-text-primary;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
            white-space: nowrap;
            border-radius: 0 0 5px 5px;
            padding: 9px 12px 8px 7px;
            background-color: @c-gray-lighter;
            transition: all .15s ease-in-out;
            border-color: #d8d8d8 #d1d1d1 #bababa;
            border-top: 0;
            width: 100%;

            &:hover,
            &:focus {
                color: @color-text-primary;
                background-color: @c-gray-light;
            }

            &--success {
                color: @c-white;
                background-color: @c-green;

                &:hover,
                &:focus {
                    color: @c-white;
                    background-color: @c-green-dark;
                }
            }

            span {
                margin-right: 5px;
            }
        }

        &__search-text {
            font-size: 15px;
            margin-top: 10px;
            display: inline-block;
            width: 100%;
            .neuron();
            text-align: center;
        }




        //
        // Color Swatch
        //

        &__swatch {
            float: left;
            padding: 4px 4px;
            box-sizing: border-box;
            cursor: pointer;
            width: 50%;
            transition: width .15s ease-in-out;

            &:hover,
            &:focus {
                .color-picker__swatch-inner {
                    opacity: .8;
                }
            }

            &.light {
                .color-picker {
                    &__swatch-inner {
                        text-shadow: 0 0 1px fade(@c-black, 0%);
                        color: fade(@c-black, 80%);
                    }

                    &__swatch-name {
                        color: fade(@c-black, 95%);
                    }
                }
            }

            &--no-color {
                .color-picker__swatch-inner {
                    background: url('../../../img/no-color.jpg') no-repeat;
                    background-size: cover;
                }
            }

            &--active {
                .color-picker {
                    &__swatch-selected {
                        opacity: .7;
                        font-weight: 300;
                        font-size: 12px;
                        display: block;
                    }

                    &__swatch-inner {
                        border-color: @color-blue-primary;
                    }
                }
            }
        }

        &__swatch-inner {
            width: 100%;
            height: 95px;
            float: left;
            border: 4px solid @c-white;
            box-shadow: 0 0 4px fade(@c-black, 20%);
            color: @c-white;
            background: @c-white;
            padding: 12px 10px 10px 10px;
            box-sizing: border-box;
            position: relative;
            text-shadow: 0 0 1px fade(@c-black, 10%);
            transition: all .15s ease-in-out;
        }

        &__swatch-name {
            float: left;
            width: 100%;
            font-size: 10px;
            font-weight: normal;
            color: fade(@c-white, 90%);
            text-transform: uppercase;
        }

        &__swatch-status {
            position: absolute;
            bottom: 7px;
            font-size: 11px;

            &--in-stock {
                .color-picker__swatch-circle {
                    background-color: @c-green;
                }
            }

            &--out-of-stock {
                .color-picker__swatch-circle {
                    background-color: @c-orange;
                }
            }
        }

        &__swatch-costs {
            position: absolute;
            right: -6px;
            top: -10px;
            background: @c-white;
            box-shadow: 0 0 3px fade(@c-black, 10%);
            color: @c-black;
            padding: 3px 5px;
            font-size: 12px;
            border-radius: 4px;
        }

        &__swatch-circle {
            width: 8px;
            height: 8px;
            margin-top: 3px;
            border-radius: 20px;
            float: left;
            margin-right: 5px;
            box-shadow: 0 0 4px fade(@c-black, 80%);
        }

        &__swatch-selected {
            display: none;
        }
    }

}

@media screen and (min-width: @breakpoint-xsmall) {
    .color-picker {
        .color-picker {
            &__search-field {
                flex-wrap: nowrap;
            }

            &__search-input {
                border-radius: 5px 0 0 5px;
            }

            &__search-filter {
                width: auto;
            }

            &__search-btn {
                border-radius: 0 5px 5px 0;
                border-top: 1px solid #d8d8d8;
                border-left: 0;
                width: auto;
            }

            &__swatch-name {
                font-size: 12px;
            }
        }
    }
}

@media screen and (min-width: @breakpoint-small) {
    .color-picker {
        .color-picker {
            &__title {
                font-size: 20px;
                margin-top: 5px;
            }

            &__subtitle {
                font-size: 18px;
            }

            &__swatch {
                width: 33.33%;
            }
        }
    }
}

@media screen and (min-width: @breakpoint-medium) {
    .color-picker {
        .color-picker {
            &__colors {
                max-height: 400px;
            }

            &__swatch {
                width: 25%;
            }
        }
    }
}

@media screen and (min-width: @breakpoint-large) {
    .color-picker {
        .color-picker {
            &__search-btn {
                font-size: 14px;
                padding-top: 8px;
            }
        }
    }
}



// Old styling

.select2-container {
    font-size: 14px;
    color: #555;
    max-width: 270px;
    margin-top: 10px;
    width: 100%;

    .select2-choice {
        border: 1px solid #ccc;
        background: @c-white;
    }

    .select2-chosen {
        background: @c-white;
    }

    .select2-choice .select2-arrow {
        background: @c-white;
        border: none;
    }

    &.select2-container-active {

        .select2-choice,
        .select2-choices {
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
}

.select2-drop-active {
    border: 1px solid #ccc;
}

.select2-search {
    margin-top: 4px;

    input {
        border-radius: 1px;
        border: 1px solid #CCC;
        background: @c-white url('../../../img/select2.png') no-repeat 100% -22px;
        background: url('../../../img/select2.png') no-repeat 100% -22px;
        padding-left: 6px;

        &:focus {
            border: 1px solid @c-blue2;
            -webkit-box-shadow: inset0 1px 7px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: inset0 1px 7px rgba(0, 0, 0, 0.2);
            box-shadow: inset0 1px 7px rgba(0, 0, 0, 0.2);
        }
    }
}

.select2-results {
    .select2-highlighted {
        background-color: @color-blue-primary;
    }

    .select2-result {
        font-size: 14px;

        .select2-result-color-preview {
            width: 32px;
            height: 32px;
            display: inline-block;
            margin-right: 7px;
            vertical-align: middle;
        }

        span.select2-result-color-label {
            position: relative;
            bottom: -1px;
        }
    }
}
