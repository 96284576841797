html,
body {
    height: 100%;

    &.modal_open,
    &.modal-is-open {
        overflow: hidden;

        @media screen and (max-width: (@breakpoint-small - 1)) {
            height: 100%;
        }

        #desktop-footer {
            position: relative;
        }

        .mobile-sticky {
            display: none;
        }
    }
}

body {
    padding: 0;
    margin: 0;
    color: @color-text-primary;
    background-size: 100% 1000px;
    background-position: left 900px;
    font-family: Tahoma, Geneva, sans-serif;
    position: relative;
    height: auto !important;
    min-height: 100%;
    line-height: normal;

    ::selection,
    ::-moz-selection {
        background: @c-blue2;
        color: @c-txt-white;
    }
}

.horizontal {
    display: flex;

    &>* {
        width: 100%;
    }

    &.gutter-default>* {
        margin: 0;

        @media screen and (min-width: @breakpoint-large) {
            margin: 0 4px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.body-content {
    .clearfix;
    .box;
    padding: 25px;
}

.page-box {
    margin-bottom: 10px;

    @media screen and (max-width: (@breakpoint-medium - 1)) {
        margin: 0 4px 10px 4px;
    }

    .box-header {
        display: inline-block;
        width: 100%;
        margin-bottom: -1px;
        height: 40px;
        border: 1px solid @color-border-dark;
        -webkit-border-top-left-radius: 3px;
        -webkit-border-top-right-radius: 3px;
        -moz-border-radius-topleft: 3px;
        -moz-border-radius-topright: 3px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background: #FCFCFC;
        background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #E4E4E4), color-stop(1, #FCFCFC));
        background: -ms-linear-gradient(bottom, #E4E4E4, #FCFCFC);
        background: -moz-linear-gradient(center bottom, #E4E4E4 0%, #FCFCFC 100%);
        background: -o-linear-gradient(#FCFCFC, #E4E4E4);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#e4e4e4', GradientType=0);
        box-sizing: border-box;

        h3,
        h4 {
            padding: 10px;
            font-size: 20px;
            margin: 0;
            line-height: 20px;
        }
    }

    .box-body {
        display: inline-block;
        width: 100%;
        padding: 10px;
        -webkit-border-bottom-right-radius: 3px;
        -webkit-border-bottom-left-radius: 3px;
        -moz-border-radius-bottomright: 3px;
        -moz-border-radius-bottomleft: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border: 1px solid @color-border-dark;
        background-color: @color-background-page;
        box-sizing: border-box;
    }
}


/* user */
.user-options {
    font-size: 12px;

    ul {
        list-style-type: none;
        margin: 0;
        width: 100%;

        li {
            text-align: center;
            margin-bottom: 4px;
        }
    }

    a {
        color: @c-lnk1;
        border-bottom: 1px solid @color-border-dark;
    }
}

/* Align labels after radio button */
.radio-options .control-label {
    float: none;
    display: inline;
    padding: 0 1em 0 0.5em;
    position: relative;
    top: 3px;
}

.user-options {
    margin: 20px 10px 0;

}

/* header */
.header-box {
    .box;
    position: relative;
    bottom: 3px;
    width: auto;
    height: 90px;
    padding: 0 20px;
    box-sizing: initial;

    &:first-child {
        margin-right: 10px;
    }

    a:hover {
        color: @c-lnkh1;
        text-decoration: none;
        cursor: pointer;
    }

    .header-box-icon {
        width: 28px;
        display: inline-block;
        text-align: center;
    }

    a:hover .header-box-icon {
        color: @color-text-primary;
    }
}


select,
input,
button,
a {
    outline: 0 !important; // Prevent ugly dotted outline on active elements in mainly IE.
}

.product img {
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    .neuron;
    font-weight: normal;
    line-height: normal;
}

a {
    color: @c-lnk1;

    &:hover {
        color: @c-lnkh1;
        text-decoration: none;
    }
}


#product-page-body,
#general-page-body {
    a {
        color: @color-blue-primary;
    }

    h3 {
        margin-bottom: 5px;
    }

    ul:not(.nav) {
        line-height: 1.5em;
        margin-bottom: 1.2em;
        font-size: 14px;

        li {
            margin-bottom: 5px;
        }
    }
}

.product-label.label {
    position: absolute;
    left: 8px;
    bottom: 20px;
    z-index: 5;
    line-height: 1.25;
    background-color: rgb(66, 201, 29);
    padding: 0.2rem 0.4rem 0.4rem 0.4rem;
}

@media screen and (max-width:768px) {
    .product-label.label {
        bottom: initial;
        top: 150px;
    }
}
.slick-disabled {
    opacity: 0;
    pointer-events: none;
}

.block {
    height: 100px;
    margin: 10px 0;
    width: 100%;
    background: #777;
}

.youtube {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    width: 100%;
    margin-bottom: 10px;

    .embed-responsive-item {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

// Loading spinner animation
.spinner,
.alt-spinner {
    display: none;
}

.is-visually-hidden {
    border: 0;
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px !important;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px !important;
}

.is-hidden {
    display: none !important;
}

.is-visible {
    display: block !important
}

@media screen and (min-width: @breakpoint-small) {
    .is-hidden-from-size-s {
        display: none !important
    }

    .is-visible-from-size-s {
        display: block !important
    }
}

@media screen and (min-width: @breakpoint-medium) {
    .is-hidden-from-size-m {
        display: none !important
    }

    .is-visible-from-size-m {
        display: block !important
    }
}

@media screen and (min-width: @breakpoint-large) {
    .is-hidden-from-size-l {
        display: none !important
    }

    .is-visible-from-size-l {
        display: block !important
    }
}

@media screen and (min-width: @breakpoint-xlarge) {
    .is-hidden-from-size-xl {
        display: none !important
    }

    .is-visible-from-size-xl {
        display: block !important
    }
}

@media screen and (min-width: @breakpoint-xxlarge) {
    .is-hidden-from-size-xxl {
        display: none !important
    }

    .is-visible-from-size-xxl {
        display: block !important
    }
}

.text-align {
    &--center {
        text-align: center;
    }

    &--right {
        text-align: right;
    }
}
